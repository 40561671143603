import React, { useEffect } from 'react';
import s from './TvIncreaseAggregateCoverageChart.module.css';
import { Line } from 'react-chartjs-2';
import { round } from '../../../../helpers/chart_helpers';

export const TvIncreaseAggregateCoverageChart = React.memo(props => {
  const { tvIncreaseAggregateCoverageChart_data } = props 
  
  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
      },

      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        boxHeight: 1,
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
          title: function (TooltipItem) {
            return TooltipItem[0].label + ' млн';
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          align: 'end',
          text: 'Совокупный охват TV & OLV',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },

        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
      x: {
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет (млн. руб)',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },
    },
  };

  useEffect(() => {
    tvIncreaseAggregateCoverageChart_data.datasets && tvIncreaseAggregateCoverageChart_data.datasets.map(item => (document.getElementById(`${item.label}+3`).style.backgroundColor = item.borderColor));
  }, [tvIncreaseAggregateCoverageChart_data]);

  return (
    <div>
      <div style={{ position: 'relative' }} className={s.wrapper}>
        <Line width={300} height={220} data={tvIncreaseAggregateCoverageChart_data} options={options} />
      </div>
      <div id='7' className={`${s.legendWrapperNew} ${s.audioLegend}`} >
        <div id='OLV+3' className={s.legend}></div>
      </div>
      <div id='8' className={`${s.legendWrapperNew} ${s.radioLegend}`} >
        <div id='TV+3' className={s.legend}></div>
      </div>
      <div id='9' className={`${s.legendWrapperNew} ${s.audioRadioLegend}`} >
        <div id='TV + OLV+3' className={s.legend}></div>
      </div>
    </div>
  );
});
