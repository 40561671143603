import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import s from './Step4SeveralFlightsPage.module.css';
import { Header } from '../../components/Header/Header';
import { findValueView, makeGeoTitlePPT, makeGenderTitle, makeIncomeTitle } from '../../../helpers/common_helpers';
import { StepButton } from '../../components/common/StepButton/StepButton';
import { LinePieChart } from '../../components/Charts/LinePieChart/LinePieChart';
import { SaveXLSFlightsOlv } from '../../components/saveXLSFlights/SaveXLSFlightsOlv/SaveXLSFlightsOlv';
import { SaveXLSFlightsOlv_Opt } from '../../components/saveXLSFlights/SaveXLSFlightsOlv_Opt/SaveXLSFlightsOlv_Opt';
import { SaveXLSFlightsOlv_Tv } from '../../components/saveXLSFlights/SaveXLSFlightsOlv_Tv/SaveXLSFlightsOlv_Tv';
import { SaveXLSFlightsOlv_Tv_Opt } from '../../components/saveXLSFlights/SaveXLSFlightsOlv_Tv_Opt/SaveXLSFlightsOlv_Tv_Opt';
import { StartButton } from '../../components/common/StartButton/StartButton';
import { ExportPPTFlights } from '../../components/ExportPPTFlights/ExportPPTFlights';
import { roundDependingOnTheOrder } from '../../../helpers/flightTable_helpers';
import download from '../../../assets/images/downloadNew.png';
import flow from '../../../assets/images/notebook.png';
import { getFreqNumber } from '../../../helpers/chart_helpers';
import { getStartIp } from '../../../bll/app_reducer';
import moment from 'moment';

export const Step4SeveralFlightsPage = React.memo(props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isExportPPTFlights, setIsExportPPTFlights] = useState(false);

  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsGeoArr = useSelector(state => state.flights.flightsGeoArr);
  const flightsAgeArr = useSelector(state => state.flights.flightsAgeArr);
  const flightsGenderArr = useSelector(state => state.flights.flightsGenderArr);
  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const findIsCbuUploadArr = flightsCbuArr.filter(fl => fl.isCBUUpload === 1).map(fl => fl.flight);
  const findIsOptimizationArr = flightsOptimizationArr.filter(fl => fl.isOptimization > -1).map(fl => fl.flight);
  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);
  const flightsPostData = useSelector(state => state.flights.flightsPostData);
  const flightsIncomeArr = useSelector(state => state.flights.flightsIncomeArr);
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod);
  const flightsLtvArr = useSelector(state => state.flights.flightsLtvArr);

  const lastFlightWithCbuUpload = findIsCbuUploadArr[findIsCbuUploadArr.length - 1];
  const lastFlightWithIsOptimization = findIsOptimizationArr[findIsOptimizationArr.length - 1];

  const olvBudgets = [];
  let sumOLVBudgets = null;
  const tvBudgets = [];
  let sumTVBudgets = null;
  const onClickBackButton = () => {
    history.push(`/flightsStep2`);
  };
  const goToDetailPage = flight => {
    history.push(`/flightsStep5/${flight}`);
  };
  const formAFlowchart = () => {
    history.push('/flightsStep6');
  };
  const onClickPowerPointFlightsBtn = () => {
    setIsExportPPTFlights(true);
  };
  const flightsChartsAllColors = [];
  flightsChartsData.map(i => {
    i.linePieChartData.datasets.map(c => {
      !flightsChartsAllColors.find(a => Object.keys(a)[0] === c.label) && flightsChartsAllColors.push({ [c.label]: c.backgroundColor });

      return c;
    });
    return i;
  });
  const findReachGeneralTV_OLV = (currentFlightsChartData, frequencyNumber) => {
    const reachGeneralArrTV_OLV = currentFlightsChartData && currentFlightsChartData.tvDistributionChart_data && currentFlightsChartData.tvDistributionChart_data.datasets[2].data;
    const reachGeneralTV_OLV = reachGeneralArrTV_OLV && reachGeneralArrTV_OLV[frequencyNumber];
    return reachGeneralTV_OLV;
  };
  useEffect(() => {
    flightsChartsAllColors.map(item => (document.getElementById(Object.keys(item)[0]).style.backgroundColor = Object.values(item)[0]));
  }, [flightsChartsAllColors]);

  useEffect(() => {
    dispatch(getStartIp(history));
  }, []);

  return (
    <>
      <Header />
      <div className={s.step3Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />

        <div className={s.contentBlock}>
          <h1>Результат планирования</h1>
          <div style={{ minHeight: '250px', marginTop: '20px' }}>
            <div className={s.tableTitle}>Рекомендованный сплит бюджета:</div>
            <div className={s.table}>
              {flightArr.map((flight, i) => {
                const currentPresenceOfTvArr = flightsPresenceOfTvArr.find(item => item.flight === flight.id);
                const budgetTV = currentPresenceOfTvArr.flightBudgetTV;

                const geo = flightsGeoArr.find(item => item.flight === flight.id) && flightsGeoArr.find(item => item.flight === flight.id).geo;
                const geoTitle = makeGeoTitlePPT(geo);

                const currentAge = flightsAgeArr.find(item => item.flight === flight.id);
                const agemin = currentAge.agemin;
                const agemax = currentAge.agemax;

                const currentLtv = flightsLtvArr.find(item => item.flight === flight.id).ltv;
                const ltvTitle = currentLtv === 1 ? 'LTV' : '';

                const currentIncome = flightsIncomeArr.find(item => item.flight === flight.id) && flightsIncomeArr.find(item => item.flight === flight.id).income;
                const incomeTitle = makeIncomeTitle(currentIncome);

                const currentGender = flightsGenderArr.find(item => item.flight === flight.id) && flightsGenderArr.find(item => item.flight === flight.id).gender;
                const genderTitle = makeGenderTitle(currentGender);

                const currentFlightsTableParameters = flightsTableParametersArr.find(item => item.flight === flight.id);
                const publishers = currentFlightsTableParameters.publishers;

                const currentFrequencyArr = flightsFrequencyArr.find(item => item.flight === flight.id);
                const frequency = currentFrequencyArr.frequency;

                const currentCbu = flightsCbuArr.find(item => item.flight === flight.id).cbu;

                const currentFlightsChartData = flightsChartsData.find(item => item.flight === flight.id);
                const reachGeneralArrOLV = currentFlightsChartData && currentFlightsChartData.olvDistribution_data.datasets[0].data;
                const frequencyNumber = getFreqNumber(frequency) - 1;
                const reachGeneral = currentCbu === null ? reachGeneralArrOLV && reachGeneralArrOLV[frequencyNumber] : findReachGeneralTV_OLV(currentFlightsChartData, frequencyNumber);

                const currentPeriod = flightsPeriod.find(item => item.flight === flight.id);
                const startDate = currentPeriod.startDate;
                const finishDate = currentPeriod.finishDate;

                const DONUT = publishers.filter(item => item.isChecked === 1);
                const donutBudget = DONUT.map(item => item.Budget);
                const budgetPOST = donutBudget.reduce(function (a, b) {
                  return +a + +b;
                });
                olvBudgets.push(budgetPOST);
                tvBudgets.push(budgetTV);

                const allBudgetForFlight = budgetPOST + budgetTV;

                sumOLVBudgets = olvBudgets.reduce(function (accumulator, currentValue) {
                  return accumulator + +roundDependingOnTheOrder(+currentValue);
                }, 0);
                sumTVBudgets = tvBudgets.reduce(function (accumulator, currentValue) {
                  return accumulator + +roundDependingOnTheOrder(+currentValue);
                }, 0);

                return (
                  <div key={i} className={s.flightBlock}>
                    <div className={s.flight}>
                      <div style={{ fontWeight: 'bold' }}>ФЛАЙТ {flight.flight}</div>
                      <div>
                        {geoTitle}, {genderTitle}
                      </div>
                      <div>
                        {agemin}-{agemax}, {incomeTitle}, {ltvTitle}
                      </div>
                      <div>
                        {moment.utc(startDate).format('DD.MM')}-{moment.utc(finishDate).format('DD.MM')}
                      </div>
                    </div>
                    <LinePieChart flight={flight.id} flightsChartsData={flightsChartsData} />

                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Бюджет OLV <br />
                        на флайт: <br />
                        <span style={{ fontWeight: 'bold' }}> {findValueView(budgetPOST, 'roundBudget')} ₽</span>
                      </div>
                    )}
                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Бюджет TV <br />
                        на флайт: <br />
                        <span style={{ fontWeight: 'bold' }}> {findValueView(budgetTV, 'roundBudget')} ₽</span>
                      </div>
                    )}
                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Общий бюджет <br />
                        на флайт: <br />
                        <span style={{ fontWeight: 'bold' }}> {findValueView(allBudgetForFlight, 'roundBudget')} ₽</span>
                      </div>
                    )}
                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Совокупный охват f={frequency} <br />
                        на флайт:
                        <br />
                        <span style={{ fontWeight: 'bold' }}> {findValueView(reachGeneral, 'roundReach')} %</span>
                      </div>
                    )}

                    <StepButton
                      onClick={() => {
                        goToDetailPage(flight.id);
                      }}
                      title={'Перейти на\n страницу с\n детализацией'}
                      buttonColor={'blue'}
                    />
                  </div>
                );
              })}
            </div>
            <div className={s.customLegendWrapper}>
              {flightsChartsAllColors.map((item, i) => {
                return (
                  <div key={i} className={s.LegendItemWrapper}>
                    <div id={Object.keys(item)[0]} className={s.legend}></div>
                    <div>{Object.keys(item)[0]}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={s.bottomBlock}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
              <div className={s.bottomDelimiter}></div>
            </div>
            {flightsChartsData.length === flightArr.length && (
              <div className={s.bottomCard}>
                <div style={{ marginRight: 30 }}>Total OLV : {findValueView(sumOLVBudgets, 'roundBudget')} ₽</div>
                <div style={{ marginRight: 30 }}>Total TV : {findValueView(sumTVBudgets, 'roundBudget')} ₽</div>
                <div>Суммарный бюджет: {findValueView(sumOLVBudgets + sumTVBudgets, 'roundBudget')} ₽</div>
              </div>
            )}
            {/* {flightsChartsData.length === flightArr.length && <div className={s.bottomCard}>Total TV : {findValueView(sumTVBudgets, 'roundBudget')} ₽</div>} */}
            <div className={s.btnBlock}>
              {!flightsUpload && !findIsCbuUploadArr.length > 0 && !findIsOptimizationArr.length > 0 && flightsChartsData.length === flightArr.length && flightsPostData.length === flightArr.length && <SaveXLSFlightsOlv />}
              {!flightsUpload && !findIsCbuUploadArr.length > 0 && findIsOptimizationArr.length > 0 && flightsChartsData.length === flightArr.length && flightsPostData.length === flightArr.length && flightsChartsData.find(fl => fl.flight === lastFlightWithIsOptimization).chartsData.bud_parts && <SaveXLSFlightsOlv_Opt />}
              {!flightsUpload && findIsCbuUploadArr.length > 0 && !findIsOptimizationArr.length > 0 && flightsChartsData.length === flightArr.length && flightsPostData.length === flightArr.length && flightsChartsData.find(fl => fl.flight === lastFlightWithCbuUpload).chartsData.tv_olv_data && <SaveXLSFlightsOlv_Tv />}
              {!flightsUpload && findIsCbuUploadArr.length > 0 && findIsOptimizationArr.length > 0 && flightsChartsData.length === flightArr.length && flightsPostData.length === flightArr.length && flightsChartsData.find(fl => fl.flight === lastFlightWithIsOptimization).chartsData.bud_parts && flightsChartsData.find(fl => fl.flight === lastFlightWithCbuUpload).chartsData.tv_olv_data && <SaveXLSFlightsOlv_Tv_Opt />}
              {flightsUpload && !findIsCbuUploadArr.length > 0 && !findIsOptimizationArr.length > 0 && <SaveXLSFlightsOlv />}
              {flightsUpload && !findIsCbuUploadArr.length > 0 && findIsOptimizationArr.length > 0 && <SaveXLSFlightsOlv_Opt />}
              {flightsUpload && findIsCbuUploadArr.length > 0 && !findIsOptimizationArr.length > 0 && <SaveXLSFlightsOlv_Tv />}
              {flightsUpload && findIsCbuUploadArr.length > 0 && findIsOptimizationArr.length > 0 && <SaveXLSFlightsOlv_Tv_Opt />}
              <StartButton onClick={onClickPowerPointFlightsBtn} view={'red'} id={'downloadBtn'}>
                <img id='downloadBtnImg' src={download} alt='download' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

                <div id='downloadBtnDiv'>
                  <div id='downloadBtnTextUnload'>Выгрузить </div>
                  <div id='downloadBtnTextСalculations'> PPT </div>
                </div>
              </StartButton>

              <StartButton onClick={formAFlowchart} view={'red'}>
                <img src={flow} alt='download' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

                <div>
                  <div>Сформировать </div>
                  <div> флоучарт </div>
                </div>
              </StartButton>
              {isExportPPTFlights && <ExportPPTFlights />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
