import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './DownloadCBU.module.css';
import { DownloadCBUButton } from '../../../common/DownloadCBUButton/DownloadCBUButton';
import { Warning } from '../../../common/Warning/Warning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import SampleCBU from '../../../../../assets/sample/cbu_input.xlsx';
import * as XLSX from 'xlsx';
import { setCbu, setIsCBUUpload } from '../../../../../bll/inputParameters_reducer';
import { setError } from '../../../../../bll/app_reducer';

export const DownloadCBU = React.memo(props => {
  const [warningWrangCBU, setWarningWrangCBU] = useState(false);
  console.log(warningWrangCBU);
  const dispatch = useDispatch();
  const presenceOfTvFlight = useSelector(state => state.input.presenceOfTvFlight);
  const isCBUUpload = useSelector(state => state.input.isCBUUpload);
  const fileRef = React.createRef();

  const onClickUploadCBU = () => {
    fileRef.current.click();
  };

  const onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data
              .concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
              .map(item => ({
                TRP: +item['TRP'],
                'R1+': +item['R1+'],
                'R2+': +item['R2+'],
                'R3+': +item['R3+'],
                'R4+': +item['R4+'],
                'R5+': +item['R5+'],
                'R6+': +item['R6+'],
                'R7+': +item['R7+'],
                'R8+': +item['R8+'],
                'R9+': +item['R9+'],
                'R10+': +item['R10+'],
                'R11+': +item['R11+'],
                'R12+': +item['R12+'],
                'R13+': +item['R13+'],
                'R14+': +item['R14+'],
                'R15+': +item['R15+'],
                'R16+': +item['R16+'],
                'R17+': +item['R17+'],
                'R18+': +item['R18+'],
                'R19+': +item['R19+'],
                'R20+': +item['R20+'],
              }))
              .map(item => Object.values(item));
          }
        }

        const errArr = [];
        data.map(it => {
          const copyIt = [...it];
          copyIt.splice(0, 1);
          const maxItemArr = Math.max.apply(null, copyIt);
          maxItemArr < 1 || (maxItemArr > 100 && errArr.push(maxItemArr));
          const findedNanArr = it.filter(i => isNaN(i));
          findedNanArr.length > 0 && errArr.push('Nan');
          return it;
        });
        data.length > 100 && errArr.push('longArr');

        const trpArr = data.map(it => +it[0]);
        const correctFile = errArr.length > 0 ? false : true;
        const allTrpAboveZero = trpArr.indexOf(0) > -1 ? false : true;
        (!correctFile || !allTrpAboveZero) && setWarningWrangCBU(true);
        correctFile && allTrpAboveZero && dispatch(setCbu(data));
        correctFile && allTrpAboveZero && dispatch(setIsCBUUpload(1));
      } catch (error) {
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        ЗАГРУЗИТЬ <br /> CBU TV
      </div>
      <div className={s.blockContent}>
        <DownloadCBUButton onClick={onClickUploadCBU} isDisabled={presenceOfTvFlight === 0 ? true : false}>
          {isCBUUpload === 1 ? (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon icon={faCheck} />
            </div>
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </DownloadCBUButton>
        <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
        <div className={s.linkWrapper}>
          Образец CBU
          <br /> можно скачать
          <br />
          <a className={s.link} href={SampleCBU} download='cbu_input.xlsx'>
            здесь
          </a>
        </div>
      </div>
      <Warning text={'Неправильный формат файла CBU, смотрите образец'} warningVisible={warningWrangCBU} setWarningVisible={setWarningWrangCBU} />
    </div>
  );
});
