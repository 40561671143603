import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditableSpanForFlights } from '../../common/EditableSpanForFlights/EditableSpanForFlights';
import { setFlightsPresenceOfTvArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';


export const FlightsCPPValue = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const currentPresenceOfTv= flightsPresenceOfTvArr.find(item => item.flight === flight);

  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const currentCbuArr = flightsCbuArr.find(item => item.flight === flight);
  const cbu = currentCbuArr.cbu
  const isCBUUpload = currentCbuArr.isCBUUpload

  const onChangeFlightCPPValue = (newValue) => {
    const updatedStateFlightsPresenceOfTvArr = flightsPresenceOfTvArr.map(item => {
      const newItem = { ...item };
      newItem.flightCPP = newValue
      newItem.flightBudgetTV = isCBUUpload === 1 ? cbu[cbu.length - 1][0] * newValue : ''
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsPresenceOfTvArr(updatedStateFlightsPresenceOfTvArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return <EditableSpanForFlights isDisabled={isDisabled} value={currentPresenceOfTv.flightCPP} name={'flightCPP'} onChange={onChangeFlightCPPValue} view={'severalFlights'} location={'flightBudget'} />;
});
