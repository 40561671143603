import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsGeo.module.css';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { EditableSpan } from '../../common/EditableSpan/EditableSpan';
import { setFlightsGeoArr, setFlightsInputDataChangedArr, setFlightsIntroductoryArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsGeo = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();

  const flightsGeoArr = useSelector(state => state.flights.flightsGeoArr);
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const handleOnChange = (selectedName, flight) => {
    const updatedGeo = currentFlightsGeoArr.geo.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      return i.name=== selectedName ? selectedItem : otherItem;
    });
    const updatedFlightsGeoArr = flightsGeoArr.map(item => {
      const newItem = { ...item };
      newItem.geo = updatedGeo;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsGeoArr(updatedFlightsGeoArr));
      const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };
  const onChangePopulation = newValue => {
    const updatedGeoState = currentFlightsGeoArr.geo.map(item => {
      const updatedItem = { ...item };
      updatedItem.population = newValue;
      const otherItem = { ...item };
      return item.name === 'cityХ' ? updatedItem : otherItem;
    });
    const updatedFlightsGeoArr = flightsGeoArr.map(item => {
      const newItem = { ...item };
      newItem.geo = updatedGeoState;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsGeoArr(updatedFlightsGeoArr));
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return (   
    <div className={s.blockContent}>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('NAT', flight);
        }}
        checked={currentFlightsGeoArr.geo[0].isChecked}
        label={'РФ 0+'}
        id={'national'}
      />
    </div>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('Moscow', flight);
        }}
        checked={currentFlightsGeoArr.geo[1].isChecked}
        label={'Москва'}
        id={'Moscow'}
      />
    </div>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('Petersburg', flight);
        }}
        checked={currentFlightsGeoArr.geo[2].isChecked}
        label={'Санкт-Петербург'}
        id={'Petersburg'}
      />
    </div>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('cityХ', flight);
        }}
        checked={currentFlightsGeoArr.geo[3].isChecked}
        label={'Другое, указать\n численность населения'}
        id={'cityХ'}
      />
       {currentFlightsGeoArr.geo[3].isChecked && <EditableSpan value={currentFlightsGeoArr.geo[3].population} name={'populationCityX'} onChange={onChangePopulation} variable={'PopulationCityX'} location={'PopulationCityX'} />} 
    </div>
  </div>
  );
});
