import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../App.css';
import moment from 'moment';
import s from './Step6SeveralFlightsPage.module.css';
import { Header } from '../../components/Header/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/common/StepButton/StepButton';
import { setCurrentPathname, getStartIp } from '../../../bll/app_reducer';
import download from '../../../assets/images/downloadNew.png';
import { addCurrentNumberSelectedMediaAllFlights, findFlightByPeriod, findSelectedMediaAllFlightsForFlow, MONTH, addCurrentNumberFlightsAndPeriodsArr } from '../../../helpers/flightTable_helpers';
import { findValueView, roundDependingOnTheOrder } from '../../../helpers/common_helpers';
import { getFreqNumber } from '../../../helpers/chart_helpers';
import { ExportPPTFlow } from '../../../ui/components/ExportPPTFlow/ExportPPTFlow';

export const Step6SeveralFlightsPage = React.memo(props => {
  const [isExportPPTFlow, setIsExportPPTFlow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsAndPeriodsArr = findFlightByPeriod(flightsPeriod, flightsTableParametersArr, flightArr);
  const selectedMediaAllFlights = findSelectedMediaAllFlightsForFlow(flightsPeriod, flightsTableParametersArr, flightArr);

  // добавляем каждому флайту порядковый номер для отображения его в нужной строке таблицы
  let currentFlightsNumberArr = flightArr.map(item => {
    return {
      flight: item.flight,
      id: item.id,
      currentNumber: 0,
    };
  });
  const totalArr = [];
  for (var w = 0; w < flightsAndPeriodsArr.length; w++) {
    totalArr.push({
      group: '',
      Publisher: 'ИТОГО:',
      number: flightsAndPeriodsArr[w].currentFlightNumber,
    });
  }
  totalArr.sort((a, b) => a.number - b.number);
  MONTH.map(month => {
    const findFlightArr = flightsAndPeriodsArr.filter(f => f.monthArr.find(ma => ma === month)).sort((a, b) => a.currentFlightNumber - b.currentFlightNumber);
    //   const findFlightArrFiltered = []
    //   findFlightArr.map(i=> {
    //   const item = currentFlightsNumberArr.find(fl=> fl.id ===i.flight)
    //   item.currentNumber === 0 && findFlightArrFiltered.push(i)
    //   return i
    //  })
    for (var w = 0; w < findFlightArr.length; w++) {
      const findCurrentFlightsNumberItem = currentFlightsNumberArr.find(fl => fl.id === findFlightArr[w].flight);
      if (findCurrentFlightsNumberItem.currentNumber === 0) {
        findCurrentFlightsNumberItem.currentNumber = w + 1;
      }
    }
    return month;
  });
  const budgets = [];
  let sumBudgets = [];

  //добавляем каждому медиа порядковый номер для отображения его в нужной строке таблицы
  let currentMediaNumberArr = selectedMediaAllFlights.map(item => {
    return {
      media: item.Publisher,
      currentNumber: 0,
      currentFlightNumber: item.number,
    };
  });
  selectedMediaAllFlights.map(item => {
    MONTH.map(month => {
      const t = flightsAndPeriodsArr.filter(ff => ff.monthArr.find(f => f === month) && ff.mediaArr.find(f => f.Publisher === item.Publisher));
      //  t.sort((a, b) => a.currentFlightNumber -b.currentFlightNumber)
      for (var w = 0; w < t.length; w++) {
        const findCurrentMediaNumberItem = currentMediaNumberArr.find(fl => fl.currentFlightNumber === t[w].currentFlightNumber && fl.media === item.Publisher);

        if (findCurrentMediaNumberItem.currentNumber === 0) {
          findCurrentMediaNumberItem.currentNumber = w + 1;
        }
      }
      return month;
    });
    return item;
  });
  const selectedMediaAllFlightsWithCurrentNumber = addCurrentNumberSelectedMediaAllFlights(selectedMediaAllFlights, currentMediaNumberArr);

  const flightsAndPeriodsArrWithCurrentNumber = addCurrentNumberFlightsAndPeriodsArr(flightsAndPeriodsArr, currentFlightsNumberArr);

  const onClickBackButton = () => {
    history.push('/flightsStep4');
  };
  const body = useRef(null);
  const head = useRef(null);

  const bodyResult = useRef(null);
  const headResult = useRef(null);

  const onClickExportPPTFlow = () => {
    setIsExportPPTFlow(true);
  };
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const u = [...body.current.children];
    if (u) {
      u.map(e => {
        const arrCells = [...e.cells];
        arrCells.find(item => item.innerHTML.indexOf(',\n 0 ₽') > -1) && e.remove();
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const headArr = [...head.current.children[0].cells];
    for (var h = 2; h < headArr.length - 2; h++) {
      // eslint-disable-next-line no-loop-func
      const oneMonthArr = [...headArr].filter(l => l.innerHTML === headArr[h].innerHTML);
      let colspan = null;
      colspan = oneMonthArr.length;
      headArr[h].colSpan = colspan;
      headArr[h].className = 'firstMonth';
      oneMonthArr.map(l => {
        l.className !== 'firstMonth' && l.remove();
        return l;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...body.current.children];
    if (u) {
      u.map(e => {
        for (var b = 0; b < e.children.length; b++) {
          // eslint-disable-next-line no-loop-func
          if (e.cells[b].innerHTML !== '' && [...e.children].find(l => l.innerHTML === e.children[b].innerHTML)) {
            // eslint-disable-next-line no-loop-func
            const r = e.children[b].innerHTML !== '' && [...e.children].filter(l => l.innerHTML === e.children[b].innerHTML);
            let colspan = null;
            colspan = r.length;
            e.children[b].colSpan = colspan;
            e.children[b].className = 'first';
            r.map(r => {
              r.className !== 'first' && r.remove();
              return r;
            });
          }
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...body.current.children];
    let mediaNameCell = null;
    if (u) {
      u.map(e => {
        const firstMediaNameCell = e.children[0];
        if (mediaNameCell === null || firstMediaNameCell.innerText !== mediaNameCell.innerText) {
          mediaNameCell = firstMediaNameCell;
        } else {
          mediaNameCell.rowSpan++;
          firstMediaNameCell.remove();
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...body.current.children];
    if (u) {
      u.map(e => {
        for (var b = 0; b < e.children.length; b++) {
          if (e.cells[b].innerHTML === '' || e.cells[b].innerHTML === ' ') {
            e.children[b].classList.add('cellWithOutData');
          } else if (e.cells[b].innerHTML === '' || e.cells[b].innerHTML === ' ' || e.cells[b].innerHTML.indexOf('Промо') > -1 || e.cells[b].innerHTML.indexOf('Имидж') > -1 || e.cells[b].innerHTML === 'ИТОГО:') {
            e.children[b].classList.add('flightData');
          } else {
            e.children[b].classList.add('cellWithData');
          }
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...body.current.children];
    let headerCell = null;
    if (u) {
      u.map(e => {
        const firstCell = e.children[e.children.length - 1];
        if (headerCell === null || firstCell.innerText !== headerCell.innerText) {
          headerCell = firstCell;
        } else {
          headerCell.rowSpan++;
          firstCell.remove();
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...bodyResult.current.children];
    if (u) {
      u.map(e => {
        const arrCells = [...e.cells];
        arrCells.find(item => item.innerHTML.indexOf(',\n 0 ₽') > -1) && e.remove();
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const headArr = [...headResult.current.children[0].cells];
    for (var h = 2; h < headArr.length - 2; h++) {
      // eslint-disable-next-line no-loop-func
      const oneMonthArr = [...headArr].filter(l => l.innerHTML === headArr[h].innerHTML);
      let colspan = null;
      colspan = oneMonthArr.length;
      headArr[h].colSpan = colspan;
      headArr[h].className = 'firstMonthResult';
      oneMonthArr.map(l => {
        l.className !== 'firstMonthResult' && l.remove();
        return l;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...bodyResult.current.children];
    if (u) {
      u.map(e => {
        for (var b = 0; b < e.children.length; b++) {
          // eslint-disable-next-line no-loop-func
          if (e.cells[b].innerHTML !== '' && [...e.children].find(l => l.innerHTML === e.children[b].innerHTML)) {
            // eslint-disable-next-line no-loop-func
            const r = e.children[b].innerHTML !== '' && [...e.children].filter(l => l.innerHTML === e.children[b].innerHTML);
            let colspan = null;
            colspan = r.length;
            e.children[b].colSpan = colspan;
            e.children[b].className = 'first';
            r.map(r => {
              r.className !== 'first' && r.remove();
              return r;
            });
          }
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...bodyResult.current.children];
    let mediaNameCell = null;
    if (u) {
      u.map(e => {
        const firstMediaNameCell = e.children[1];
        if (mediaNameCell === null || firstMediaNameCell.innerText !== mediaNameCell.innerText) {
          mediaNameCell = firstMediaNameCell;
        } else {
          mediaNameCell.rowSpan++;
          firstMediaNameCell.remove();
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...bodyResult.current.children];
    if (u) {
      u.map(e => {
        for (var b = 0; b < e.children.length; b++) {
          if (e.cells[b].innerHTML === '' || e.cells[b].innerHTML === ' ') {
            e.children[b].classList.add('cellWithOutDataResult');
          } else if (e.cells[b].innerHTML === '' || e.cells[b].innerHTML === ' ' || e.cells[b].innerHTML.indexOf('Промо') > -1 || e.cells[b].innerHTML.indexOf('Имидж') > -1 || e.cells[b].innerHTML === 'ИТОГО:') {
            e.children[b].classList.add('flightDataResult');
          } else {
            e.children[b].classList.add('cellWithDataResult');
          }
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...bodyResult.current.children];
    let headerCell = null;
    if (u) {
      u.map(e => {
        const firstCell = e.children[0];
        if (headerCell === null || firstCell.innerText !== headerCell.innerText) {
          headerCell = firstCell;
        } else {
          headerCell.rowSpan++;
          firstCell.remove();
        }
        return e;
      });
    }
  }, []);

  useEffect(() => {
    const u = [...bodyResult.current.children];
    let headerCell = null;
    if (u) {
      u.map(e => {
        const firstCell = e.children[e.children.length - 1];
        if (headerCell === null || firstCell.innerText !== headerCell.innerText) {
          headerCell = firstCell;
        } else {
          headerCell.rowSpan++;
          firstCell.remove();
        }
        return e;
      });
    }
  }, []);

  {
    flightArr.map((flight, i) => {
      const budgetPOST =
        flightsChartsData.find(item => item.flight === flight.id) &&
        flightsChartsData
          .find(item => item.flight === flight.id)
          .olvDonutData.datasets[0].data.reduce(function (a, b) {
            return +a + +b;
          });
      budgets.push(budgetPOST);
      sumBudgets = budgets.reduce(function (accumulator, currentValue) {
        return accumulator + +roundDependingOnTheOrder(+currentValue);
      }, 0);
      return flight;
    });
  }

  useEffect(() => {
    dispatch(getStartIp(history));
  }, []);

  return (
    <>
      <Header />
      <div className={s.step5Wrapper}>
        <div className={s.wrapper}>
          <div style={{ marginRight: '10px' }}>
            <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />
          </div>
          <div className={s.flowTableBlock}>
            <h1>Флоу для спланированных флайтов на год</h1>

            <div className={s.table} id='table'>
              <table className={s.flowTable}>
                <thead className={s.thead} ref={head}>
                  <tr>
                    <th className={s.th}>Площадка</th>
                    <th className={s.th}>Янв.</th>
                    <th className={s.th}>Янв.</th>
                    <th className={s.th}>Янв.</th>
                    <th className={s.th}>Янв.</th>

                    <th className={s.th}>Фев.</th>
                    <th className={s.th}>Фев.</th>
                    <th className={s.th}>Фев.</th>
                    <th className={s.th}>Фев.</th>

                    <th className={s.th}>Мар.</th>
                    <th className={s.th}>Мар.</th>
                    <th className={s.th}>Мар.</th>
                    <th className={s.th}>Мар.</th>

                    <th className={s.th}>Апр.</th>
                    <th className={s.th}>Апр.</th>
                    <th className={s.th}>Апр.</th>
                    <th className={s.th}>Апр.</th>

                    <th className={s.th}>Май</th>
                    <th className={s.th}>Май</th>
                    <th className={s.th}>Май</th>
                    <th className={s.th}>Май</th>

                    <th className={s.th}>Июн.</th>
                    <th className={s.th}>Июн.</th>
                    <th className={s.th}>Июн.</th>
                    <th className={s.th}>Июн.</th>

                    <th className={s.th}>Июл.</th>
                    <th className={s.th}>Июл.</th>
                    <th className={s.th}>Июл.</th>
                    <th className={s.th}>Июл.</th>

                    <th className={s.th}>Авг.</th>
                    <th className={s.th}>Авг.</th>
                    <th className={s.th}>Авг.</th>
                    <th className={s.th}>Авг.</th>

                    <th className={s.th}>Сен.</th>
                    <th className={s.th}>Сен.</th>
                    <th className={s.th}>Сен.</th>
                    <th className={s.th}>Сен.</th>

                    <th className={s.th}>Окт.</th>
                    <th className={s.th}>Окт.</th>
                    <th className={s.th}>Окт.</th>
                    <th className={s.th}>Окт.</th>

                    <th className={s.th}>Ноя.</th>
                    <th className={s.th}>Ноя.</th>
                    <th className={s.th}>Ноя.</th>
                    <th className={s.th}>Ноя.</th>

                    <th className={s.th}>Дек.</th>
                    <th className={s.th}>Дек.</th>
                    <th className={s.th}>Дек.</th>
                    <th className={s.th}>Дек.</th>
                    <th style={{ width: '40px' }}></th>
                    <th className={s.th}>Бюджет</th>
                  </tr>
                </thead>
                <tbody id='body' ref={body}>
                  {selectedMediaAllFlightsWithCurrentNumber &&
                    selectedMediaAllFlightsWithCurrentNumber.map((item, i) => {
                      const findBudgetValueByMonth = media => {
                        const monthBudgetsArr = [];
                        flightsChartsData.map(chartData => {
                          const indDon = chartData.olvDonutData.labels.indexOf(media);
                          const valBudg = indDon > -1 ? chartData.olvDonutData.datasets[0].data[indDon] : null;
                          monthBudgetsArr.push(valBudg);

                          return chartData;
                        });
                        const sumMonthBudgetsArr = monthBudgetsArr.reduce(function (accumulator, currentValue) {
                          return accumulator + +currentValue;
                        }, 0);
                        return `${findValueView(sumMonthBudgetsArr, 'roundBudget')} ₽`;
                      };

                      const findMonthValue = (media, month) => {
                        const findFlightArr = [];
                        flightsAndPeriodsArr
                          .sort((a, b) => a.currentFlightNumber - b.currentFlightNumber)
                          .map(f => {
                            const currentMediaNumber = currentMediaNumberArr.find(fl => fl.currentFlightNumber === f.currentFlightNumber && fl.media === media) && currentMediaNumberArr.find(fl => fl.currentFlightNumber === f.currentFlightNumber && fl.media === media).currentNumber;
                            const findMonth = f.monthArr.find(ma => ma === month);
                            const findMedia = f.mediaArr.find(me => me.Publisher === media);
                            findMonth && findMedia ? findFlightArr.splice(currentMediaNumber - 1, 0, f.flight) : findFlightArr.push(0);
                            return f;
                          });
                        const currentValueArr = findFlightArr.map(flight => {
                          if (flight === 0) {
                            return '';
                          } else {
                            const currentFlightNumber = flightArr.find(fl => fl.id === flight) && flightArr.find(fl => fl.id === flight).flight;
                            const currentFrequency = flightsFrequencyArr.find(frequency => frequency.flight === flight).frequency;
                            const currentFlightChartsData = flightsChartsData.find(chartsData => chartsData.flight === flight);
                            const indContr = currentFlightChartsData && currentFlightChartsData.olvContributionOfEachMedia_data.labels.indexOf(media);
                            const valReach = indContr > -1 ? currentFlightChartsData && currentFlightChartsData.olvContributionOfEachMedia_data.datasets[0].data[indContr] : null;
                            const indDon = currentFlightChartsData && currentFlightChartsData.olvDonutData.labels.indexOf(media);
                            const valBudg = indDon > -1 ? currentFlightChartsData && currentFlightChartsData.olvDonutData.datasets[0].data[indDon] : null;
                            // const currentMediaArr = flightsMediaArr.find(mediaArr => mediaArr.flight === flight);
                            return findFlightArr.length !== 0 && `ФЛАЙТ ${currentFlightNumber}\n  Reach ${findValueView(valReach, 'roundReach')} %,\n @f ${currentFrequency},\n ${findValueView(valBudg, 'roundBudget')} ₽`;
                          }
                        });
                        // const findCurrentValue = currentValueArr.find(cv=> cv.indexOf(`ФЛАЙТ ${item.number}`)>-1)
                        // return findCurrentValue;
                        return currentValueArr[item.currentNumber - 1];
                      };
                      return (
                        <tr key={i}>
                          <td style={item.media === 'ИТОГО:' ? { backgroundColor: '#738fa2', border: '3px solid white', fontSize: '10px' } : { backgroundColor: '#e3e9ec', border: '3px solid white', fontSize: '10px' }}>{item.Publisher}</td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь4')}
                          </td>

                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь1')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь2')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь3')}
                          </td>
                          <td style={{ border: '3px solid white', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь4')}
                          </td>
                          <td style={{ width: '40px' }} colSpan='1'></td>
                          <td style={{ border: '3px solid white', whiteSpace: 'preWrap', fontSize: '10px', backgroundColor: '#738fa2', color: 'white' }} colSpan='1'>
                            {findBudgetValueByMonth(item.Publisher)} <br />
                            <span style={{ color: '#738fa2' }}>{item.Publisher}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <table className={s.resultTable}>
                <div className={s.redLine}></div>
                <thead className={s.thead} ref={headResult}>
                  <tr>
                    <th className={s.thResult} style={{ backgroundColor: 'white' }}>
                      Медиа
                    </th>
                    <th className={s.thResult}>Янв.</th>
                    <th className={s.thResult}>Янв.</th>
                    <th className={s.thResult}>Янв.</th>
                    <th className={s.thResult}>Янв.</th>

                    <th className={s.thResult}>Фев.</th>
                    <th className={s.thResult}>Фев.</th>
                    <th className={s.thResult}>Фев.</th>
                    <th className={s.thResult}>Фев.</th>

                    <th className={s.thResult}>Мар.</th>
                    <th className={s.thResult}>Мар.</th>
                    <th className={s.thResult}>Мар.</th>
                    <th className={s.thResult}>Мар.</th>

                    <th className={s.thResult}>Апр.</th>
                    <th className={s.thResult}>Апр.</th>
                    <th className={s.thResult}>Апр.</th>
                    <th className={s.thResult}>Апр.</th>

                    <th className={s.thResult}>Май</th>
                    <th className={s.thResult}>Май</th>
                    <th className={s.thResult}>Май</th>
                    <th className={s.thResult}>Май</th>

                    <th className={s.thResult}>Июн.</th>
                    <th className={s.thResult}>Июн.</th>
                    <th className={s.thResult}>Июн.</th>
                    <th className={s.thResult}>Июн.</th>

                    <th className={s.thResult}>Июл.</th>
                    <th className={s.thResult}>Июл.</th>
                    <th className={s.thResult}>Июл.</th>
                    <th className={s.thResult}>Июл.</th>

                    <th className={s.thResult}>Авг.</th>
                    <th className={s.thResult}>Авг.</th>
                    <th className={s.thResult}>Авг.</th>
                    <th className={s.thResult}>Авг.</th>

                    <th className={s.thResult}>Сен.</th>
                    <th className={s.thResult}>Сен.</th>
                    <th className={s.thResult}>Сен.</th>
                    <th className={s.thResult}>Сен.</th>

                    <th className={s.thResult}>Окт.</th>
                    <th className={s.thResult}>Окт.</th>
                    <th className={s.thResult}>Окт.</th>
                    <th className={s.thResult}>Окт.</th>

                    <th className={s.thResult}>Ноя.</th>
                    <th className={s.thResult}>Ноя.</th>
                    <th className={s.thResult}>Ноя.</th>
                    <th className={s.thResult}>Ноя.</th>

                    <th className={s.thResult}>Дек.</th>
                    <th className={s.thResult}>Дек.</th>
                    <th className={s.thResult}>Дек.</th>
                    <th className={s.thResult}>Дек.</th>
                    <th style={{ width: '40px' }}></th>
                    <th className={s.thResult} style={{ backgroundColor: 'white' }}>
                      Бюджет
                    </th>
                  </tr>
                </thead>
                <tbody id='body' ref={bodyResult}>
                  {totalArr &&
                    totalArr.map((item, i) => {
                      const findBudgetValueByMonth = media => {
                        return `${findValueView(sumBudgets, 'roundBudget')} ₽`;
                      };
                      const findMonthValue = (media, month) => {
                        const findFlightArr = [];
                        flightsAndPeriodsArrWithCurrentNumber
                          .sort((a, b) => a.currentFlightNumber - b.currentFlightNumber)
                          .sort((a, b) => a.current - b.current)
                          .map(f => {
                            // // flightsAndPeriodsArr.map(f => {
                            const currentFlightsNumber = currentFlightsNumberArr.find(fl => fl.id === f.flight) && currentFlightsNumberArr.find(fl => fl.id === f.flight).currentNumber;

                            const findMonth = f.monthArr.find(ma => ma === month);
                            findMonth ? findFlightArr.splice(currentFlightsNumber - 1, 0, f.flight) : findFlightArr.push(0);
                            return f;
                          });
                        const currentValueArr = findFlightArr.map(flight => {
                          if (flight === 0) {
                            return '';
                          } else {
                            const currentFlightNumber = flightArr.find(fl => fl.id === flight) && flightArr.find(fl => fl.id === flight).flight;
                            const startDate = flightsPeriod.find(item => item.flight === flight) && flightsPeriod.find(item => item.flight === flight).startDate;
                            const finishDate = flightsPeriod.find(item => item.flight === flight) && flightsPeriod.find(item => item.flight === flight).finishDate;
                            const frequency = flightsFrequencyArr.find(item => item.flight === flight) && flightsFrequencyArr.find(item => item.flight === flight).frequency;
                            const budgetPOST = flightsChartsData.find(item => item.flight === flight)
                              ? flightsChartsData
                                  .find(item => item.flight === flight)
                                  .olvDonutData.datasets[0].data.reduce(function (a, b) {
                                    return +a + +b;
                                  })
                              : '';
                            const currentFlightsChartData = flightsChartsData.find(item => item.flight === flight);
                            const reachGeneralArr = currentFlightsChartData && currentFlightsChartData.olvDistribution_data.datasets[0].data;
                            const frequencyNumber = getFreqNumber(frequency) - 1;
                            const reachGeneral = reachGeneralArr && reachGeneralArr[frequencyNumber];
                            return findFlightArr.length !== 0 && ` ФЛАЙТ ${currentFlightNumber} \n ${moment.utc(startDate).format('DD.MM')}-${moment.utc(finishDate).format('DD.MM')}\n Reach ${findValueView(reachGeneral, 'roundReach')} %\n @f ${frequency},\n ${findValueView(budgetPOST, 'roundBudget')} ₽`;
                          }
                        });
                        const findCurrentValue = currentValueArr.find(cv => cv.indexOf(`ФЛАЙТ ${item.number}`) > -1);
                        return findCurrentValue;
                        // return currentValueArr[item.number - 1]
                      };
                      return (
                        <tr key={i}>
                          <td style={item.Publisher === 'ИТОГО:' ? { backgroundColor: '#D6DCE5', border: '3px solid #D6DCE5', fontSize: '20px', fontWeight: '700' } : { backgroundColor: '#D6DCE5', border: '3px solid #D6DCE5', fontSize: '10px' }}>{item.Publisher}</td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Январь4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Февраль4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Март4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Апрель4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Май4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июнь4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Июль4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Август4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Сентябрь4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Октябрь4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Ноябрь4')}
                          </td>

                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь1')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь2')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь3')}
                          </td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'pre', fontSize: '10px' }} colSpan='1'>
                            {findMonthValue(item.Publisher, 'Декабрь4')}
                          </td>
                          <td style={{ width: '40px', borderWidth: '3px', borderStyle: 'solid', borderLeftColor: '#D6DCE5', borderTopColor: '#D6DCE5', borderBottomColor: '#D6DCE5', borderRightColor: '#D6DCE5' }} colSpan='1'></td>
                          <td style={{ border: '3px solid #D6DCE5', whiteSpace: 'preWrap', fontSize: '10px', backgroundColor: 'white', color: 'black' }} colSpan='1'>
                            {findBudgetValueByMonth(item.Publisher)} <br />
                            <span style={{ color: 'white' }}>{item.Publisher}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <StepButton onClick={onClickExportPPTFlow} isDisabled={false}>
              <div className={s.downloadBtnTextWrapper}>
                <img src={download} alt='download' style={{ width: '30px', height: '30px', marginRight: '5px' }} />
                <div>
                  <div>Выгрузить </div>
                  <div> флоу в PPT </div>
                </div>
              </div>
            </StepButton>
          </div>
          {isExportPPTFlow && <ExportPPTFlow selectedMediaAllFlightsWithCurrentNumber={selectedMediaAllFlightsWithCurrentNumber} currentMediaNumberArr={currentMediaNumberArr} flightsChartsData={flightsChartsData} flightsPeriod={flightsPeriod} flightsFrequencyArr={flightsFrequencyArr} flightArr={flightArr} flightsTableParametersArr={flightsTableParametersArr} />}
        </div>
      </div>
    </>
  );
});
