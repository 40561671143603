import React from 'react';
import s from './InputNumber.module.css';

export const InputNumber = React.memo(props => {
  const { value, onChange, borderTitle, location, ...restProps } = props;
  const findInputStyle = () => {
    if (location === 'optimization') {
      return s.input_Optimization;
    } else if (location === 'optimizationTable') {
      return s.input_Optimization_Table;
    } else {
      return s.input;
    }
  };
  return (
    <div className={s.inputWrapper}>
      <input type={'number'} min='1' step='1' onChange={onChange} className={findInputStyle()} {...restProps} value={value} />
      {borderTitle && <div className={s.blockTitle}>{borderTitle}</div>}
    </div>
  );
});
