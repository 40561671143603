import React, { useEffect } from 'react';
import s from './OptComparisonChart.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { round, findMaxMinOhvat } from '../../../../helpers/chart_helpers';
import { setMillion } from '../../../../helpers/common_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

export const OptComparisonChart = React.memo(props => {
  const { optComparisonChart_data } = props;
  const optic_reachArr = optComparisonChart_data.datasets && optComparisonChart_data.datasets[0].data;

  const maxValueArr = optComparisonChart_data.datasets && optComparisonChart_data.datasets.filter(item => item.type === 'bar');
  const maxValue =
    maxValueArr &&
    maxValueArr.reduce(function (accumulator, currentValue) {
      return accumulator + +currentValue.data[8];
    }, 0);
  const visibleMaxValue = 5;
  const maxValuePr = (maxValue / 100) * visibleMaxValue; //вычисление процентов

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: 'true',
        formatter: function (value) {
          if (+value > maxValuePr) {
            const changedValue = setMillion(value);
            return changedValue;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: '14px',
            },
          },
        },
        anchor: 'centre',
        align: 'centre',
        color: 'white',
      },
      legend: {
        display: false,
        position: 'bottom',
        align: 'centre',
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
      },
    },
    scales: {
      x: {
        stacked: true,
      },

      y: {
        stacked: true,
        title: {
          display: true,
          align: 'start',
          text: 'Доля в медиамиксе (млн)',
          color: 'grey',
          font: {
            size: 12.5,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },

        ticks: {
          callback: function (val, index) {
            return round(setMillion(val), 1);
          },
        },
      },
      ohvat: {
        min: findMaxMinOhvat(optic_reachArr)[0],
        max: findMaxMinOhvat(optic_reachArr)[1],
        position: 'right',
        stacked: true,
        grid: { lineWidth: 0 },
        title: {
          display: true,
          align: 'end',
          text: 'Совокупный охват',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
    },
  };
  useEffect(() => {
    optComparisonChart_data.datasets && optComparisonChart_data.datasets.map(item => (document.getElementById(`${item.label}+2`).style.backgroundColor = item.backgroundColor));
  }, [optComparisonChart_data]);

  return (
    <div>
      <div className={s.wrapper}>
        <Bar data={optComparisonChart_data} options={options} width={1000} height={200} />
      </div>
      <div className={s.lenendsBox}>
        {optComparisonChart_data.datasets &&
          optComparisonChart_data.datasets.map(item => (
            <div className={s.legendWrapper} key={item.label}>
              <div id={`${item.label}+2`} className={item.label === 'Охват' ? s.ohvatLegend : s.legend}></div> <span>{item.label}</span>
            </div>
          ))}
      </div>
    </div>
  );
});
