import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './LtvAudience.module.css';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import { setLtv, setIsInputDataChanged, setIsInputDataPart1Changed } from '../../../../../bll/inputParameters_reducer';
import { setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const LtvAudience = React.memo(props => {
  const dispatch = useDispatch();
  const ltv = useSelector(state => state.input.ltv);

  const handleOnChange = selectedName => {
    selectedName === 'no' ? dispatch(setLtv(1)) : dispatch(setLtv(0));
    dispatch(setIsInputDataPart1Changed(true));
    dispatch(setIsInputDataChanged(true));
    dispatch(setIsChartsUpload(false));
  };
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ТЕЛЕСМОТРЕНИЕ</div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('yes');
            }}
            checked={ltv === 0 ? true : false}
            label={'Все'}
            id={'yes'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('no');
            }}
            checked={ltv === 1 ? true : false}
            label={'Только LTV'}
            id={'no'}
          />
        </div>
      </div>
    </div>
  );
});
