import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { HomePage } from './ui/pages/HomePage/HomePage';
import { Step0Page } from './ui/pages/Step0Page/Step0Page';
import { Step2Page } from './ui/pages/Step2Page/Step2Page';
import { Step3Page } from './ui/pages/Step3Page/Step3Page';
import { Step4Page } from './ui/pages/Step4Page/Step4Page';
import { InstructionPage } from './ui/pages/InstructionPage/InstructionPage';
import { Step2SeveralFlightsPage } from './ui/pages/Step2SeveralFlightsPage/Step2SeveralFlightsPage';
import { Step3SeveralFlightsPage } from './ui/pages/Step3SeveralFlightsPage/Step3SeveralFlightsPage';
import { Step4SeveralFlightsPage } from './ui/pages/Step4SeveralFlightsPage/Step4SeveralFlightsPage';
import { Step5SeveralFlightsPage } from './ui/pages/Step5SeveralFlightsPage/Step5SeveralFlightsPage';
import { Step6SeveralFlightsPage } from './ui/pages/Step6SeveralFlightsPage/Step6SeveralFlightsPage';
import { Progress } from './ui/components/common/Progress/Progress';
import { Warning } from './ui/components/common/Warning/Warning';

export const App = () => {
  const [errorWarning, setErrorWarning] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const status = useSelector(state => state.app.status);
  const error = useSelector(state => state.app.error);

  useEffect(() => {
    error !== null && setErrorWarning(true);
  }, [error]);

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem('app'));
  //   const maxData = data && data.timestamp + 86400000;
  //   if (Date.now() < maxData) {
  //     dispatch(setInitialized(true));
  //   } else if (Date.now() > maxData) {
  //     dispatch(setInitialized(false));
  //     localStorage.removeItem('app');
  //   }
  // }, [dispatch]);

  return (
    <div className='App'>
      {status === 'loading' && <Progress />}
      <Warning text={error} warningVisible={errorWarning} setWarningVisible={setErrorWarning} />
      <Switch>
        <Route exact path={'/'} render={() => <HomePage />} />
        <Route exact path={'/step0'} render={() => <Step0Page />} />
        <Route exact path={'/step2'} render={() => <Step2Page />} />
        <Route exact path={'/step3'} render={() => <Step3Page />} />
        <Route exact path={'/step4'} render={() => <Step4Page />} />
        <Route exact path={'/instruction'} render={() => <InstructionPage />} />
        <Route exact path={'/flightsStep2'} render={() => <Step2SeveralFlightsPage />} />
        <Route exact path={'/flightsStep3/:flightId?'} render={() => <Step3SeveralFlightsPage />} />
        <Route exact path={'/flightsStep4'} render={() => <Step4SeveralFlightsPage />} />
        <Route exact path={'/flightsStep5/:flightId?'} render={() => <Step5SeveralFlightsPage />} />
        <Route exact path={'/flightsStep6'} render={() => <Step6SeveralFlightsPage />} />
        <Route
          path='/stageLogin'
          component={() => {
            window.location.href = 'https%3A%2F%2Fsso.unseco.ru%2Fadfs%2Foauth2%2Fauthorize%2F%3Fresponse_type%3Dcode%26client_id%3D5f477699-0ab3-4157-a1da-f43518508072%26resource%3D5f477699-0ab3-4157-a1da-f43518508072%26redirect_uri%3Dhttp%3A%2F%2Fstage.videotron.mediainstinctgroup.ru%2Fcallback%26state%3DL2dldF9pcC8%3D%26scope%3Dopenid';
          }}
        />
        <Route
          path='/mainLogin'
          component={() => {
            window.location.href = 'https%3A%2F%2Fsso.unseco.ru%2Fadfs%2Foauth2%2Fauthorize%2F%3Fresponse_type%3Dcode%26client_id%3D5f477699-0ab3-4157-a1da-f43518508072%26resource%3D5f477699-0ab3-4157-a1da-f43518508072%26redirect_uri%3Dhttp%3A%2F%2Fvideotron.mediainstinctgroup.ru%2Fcallback%26state%3DL2dldF9pcC8%3D%26scope%3Dopenid';
          }}
        />
        <Route
          path='/localLogin'
          component={() => {
            window.location.href = 'https%3A%2F%2Fsso.unseco.ru%2Fadfs%2Foauth2%2Fauthorize%2F%3Fresponse_type%3Dcode%26client_id%3D5f477699-0ab3-4157-a1da-f43518508072%26resource%3D5f477699-0ab3-4157-a1da-f43518508072%26redirect_uri%3Dhttp%3A%2F%2Flocalhost%3A3000%2Fcallback%26state%3DL2dldF9pcC8%3D%26scope%3Dopenid';
            // window.location.href = 'https://sso.unseco.ru/adfs/oauth2/authorize/?response_type=code&client_id=5f477699-0ab3-4157-a1da-f43518508072&resource=5f477699-0ab3-4157-a1da-f43518508072&redirect_uri=http%3A%2F%2Fstage.videotron.mediainstinctgroup.ru%2Foauth2%2Fcallback&state=L2dldF9pcC8%3D&scope=openid';
          }}
        />
        <Route
          path='/logout'
          component={() => {
            window.location.href = 'https://sso.unseco.ru/adfs/oauth2/logout';
          }}
        />

        <Route path={'/404'} render={() => <h1>404: PAGE NOT FOUND</h1>} />
        <Redirect from={'*'} to={'/'}></Redirect>
      </Switch>
    </div>
  );
};
