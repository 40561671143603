const SET_GENDER = 'SET_GENDER';
const SET_INCOME = 'SET_INCOME';
const SET_AGE_MIN = 'SET_AGE_MIN';
const SET_AGE_MAX = 'SET_AGE_MAX';
const SET_LTV = 'SET_LTV';
const SET_PRESENCE_OF_TV_FLIGHT = 'SET_PRESENCE_OF_TV_FLIGHT';
const SET_GEO = 'SET_GEO';
const SET_IS_INPUT_DATA_CHANGED = 'SET_IS_INPUT_DATA_CHANGED';
const SET_IS_INPUT_DATA_PART1_CHANGED = 'SET_IS_INPUT_DATA_PART1_CHANGED';
const SET_CBU = 'SET_CBU';
const SET_IS_CBU_UPLOAD = 'SET_IS_CBU_UPLOAD';
const SET_TV_RF = 'SET_TV_RF';
const SET_POST_DATA = 'SET_POST_DATA';
const SET_ISRESTRICTIONS = 'SET_ISRESTRICTIONS';

const initialState = {
  gender: [
    { id: 1, isChecked: false, gender: 'WOMEN' },
    { id: 2, isChecked: false, gender: 'MEN' },
    { id: 3, isChecked: true, gender: 'ALL' },
  ],
  income: [
    { id: 1, isChecked: false, income: 'A' },
    { id: 2, isChecked: true, income: 'B' },
    { id: 3, isChecked: true, income: 'C' },
  ],
  geo: [
    { id: 1, name: 'NAT', isChecked: true, geo: 'NAT', population: 146171015 },
    { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow', population: 12655050 },
    { id: 3, name: 'Petersburg', isChecked: false, geo: 'Petersburg', population: 5384342 },
    { id: 4, name: 'cityХ', isChecked: false, geo: 'cityХ', population: 0 },
  ],
  agemin: 18,
  agemax: 45,
  ltv: 0,
  presenceOfTvFlight: 0,
  isInputDataChanged: false,
  isInputDataPart1Changed: true,
  isCBUUpload: 0,
  cbu: null,
  tv_rf: [],
  postData: [{}],
  isRestrictions: 1,
};

export const inputParameters_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GENDER:
      return { ...state, gender: action.gender };
    case SET_AGE_MIN:
      return { ...state, agemin: action.agemin };
    case SET_AGE_MAX:
      return { ...state, agemax: action.agemax };
    case SET_INCOME:
      return { ...state, income: action.income };
    case SET_LTV:
      return { ...state, ltv: action.ltv };
    case SET_PRESENCE_OF_TV_FLIGHT:
      return { ...state, presenceOfTvFlight: action.presenceOfTvFlight };
    case SET_GEO:
      return { ...state, geo: action.geo };
    case SET_IS_INPUT_DATA_CHANGED:
      return { ...state, isInputDataChanged: action.isInputDataChanged };
    case SET_IS_INPUT_DATA_PART1_CHANGED:
      return { ...state, isInputDataPart1Changed: action.isInputDataPart1Changed };
    case SET_CBU:
      return { ...state, cbu: action.cbu };
    case SET_IS_CBU_UPLOAD:
      return { ...state, isCBUUpload: action.isCBUUpload };
    case SET_TV_RF:
      return { ...state, tv_rf: action.tv_rf };
    case SET_POST_DATA:
      return { ...state, postData: action.postData };
    case SET_ISRESTRICTIONS:
      return { ...state, isRestrictions: action.isRestrictions };
    default:
      return state;
  }
};

// action creators
export const setGender = gender => ({ type: SET_GENDER, gender });
export const setIncome = income => ({ type: SET_INCOME, income });
export const setAgemin = agemin => ({ type: SET_AGE_MIN, agemin });
export const setAgemax = agemax => ({ type: SET_AGE_MAX, agemax });
export const setLtv = ltv => ({ type: SET_LTV, ltv });
export const setPresenceOfTvFlight = presenceOfTvFlight => ({ type: SET_PRESENCE_OF_TV_FLIGHT, presenceOfTvFlight });
export const setGeo = geo => ({ type: SET_GEO, geo });
export const setIsInputDataChanged = isInputDataChanged => ({ type: SET_IS_INPUT_DATA_CHANGED, isInputDataChanged });
export const setIsInputDataPart1Changed = isInputDataPart1Changed => ({ type: SET_IS_INPUT_DATA_PART1_CHANGED, isInputDataPart1Changed });
export const setCbu = cbu => ({ type: SET_CBU, cbu });
export const setIsCBUUpload = isCBUUpload => ({ type: SET_IS_CBU_UPLOAD, isCBUUpload });
export const setTv_rf = tv_rf => ({ type: SET_TV_RF, tv_rf });
export const setPostData = postData => ({ type: SET_POST_DATA, postData });
export const setIsRestrictions = isRestrictions => ({ type: SET_ISRESTRICTIONS, isRestrictions });