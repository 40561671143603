import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Gender.module.css';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import { setGender, setIsInputDataChanged, setIsInputDataPart1Changed } from '../../../../../bll/inputParameters_reducer';
import { setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const Gender = React.memo(props => {
  const dispatch = useDispatch();
  const gender = useSelector(state => state.input.gender);

  const handleOnChange = selectedName => {
    const updatedGenderState = gender.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = true;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.gender === selectedName ? selectedItem : otherItem;
    });
    dispatch(setGender(updatedGenderState));
    dispatch(setIsInputDataPart1Changed(true));
    dispatch(setIsInputDataChanged(true));
    dispatch(setIsChartsUpload(false));
  };

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ПОЛ</div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('ALL');
            }}
            checked={gender[2].isChecked}
            label={'М и Ж'}
            id={'ALL'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('WOMEN');
            }}
            checked={gender[0].isChecked}
            label={'Ж'}
            id={'WOMEN'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('MEN');
            }}
            checked={gender[1].isChecked}
            label={'М'}
            id={'MEN'}
          />
        </div>
      </div>
    </div>
  );
});
