import React from 'react';
import { useSelector } from 'react-redux';
import s from './PageHeaderFlights.module.css';
import blackStar from '../../../assets/images/image17.png';
import { makeGeoTitle, makeIncomeTitle, makeGenderTitle } from '../../../helpers/common_helpers';

export const PageHeaderFlights = React.memo(props => {
  const { flight, pageHeaderTitle, pageHeaderNumber, withRedTitle } = props;

  const flightsGenderArr = useSelector(state => state.flights.flightsGenderArr);
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight); 
  const gender = currentGenderArr.gender

  const flightsIncomeArr = useSelector(state => state.flights.flightsIncomeArr);
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const income = currentFlightsIncomeArr.income;

  const flightsGeoArr = useSelector(state => state.flights.flightsGeoArr);
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const geo = currentFlightsGeoArr.geo;

  const flightsAgeArr = useSelector(state => state.flights.flightsAgeArr);
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const agemin = currentAge.agemin;
  const agemax = currentAge.agemax;

  const flightsLtvArr = useSelector(state => state.flights.flightsLtvArr);
  const currentLtv= flightsLtvArr.find(item => item.flight === flight);
  const ltv = currentLtv.ltv;
  const ltvPost = ltv === 1 ? 'LTV' : null;

  return (
    <div className={s.pageBarContent}>
      <div className={s.titleWrapper}>
        <span className={s.pageHeaderNumber}>{pageHeaderNumber}</span>
        {pageHeaderTitle}
        {withRedTitle && (
          <span className={s.redText}>
            {' '}
            {makeGenderTitle(gender)} {agemin}-{agemax} {makeIncomeTitle(income)} {ltvPost} {makeGeoTitle(geo)}
          </span>
        )}
      </div>
      <div className={s.blackStarWrapper}>
        <img src={blackStar} alt='blackStar' style={{ width: '250px', height: '66px', display: 'block' }} />
      </div>
    </div>
  );
});
