import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { StartButton } from '../../common/StartButton/StartButton';
import { round, getFreqNumber } from '../../../../helpers/chart_helpers';
import { makeGeoTitle_From_PostData, makePresenceOfTvFlight_From_PostData, makeLtv_From_PostData } from '../../../../helpers/common_helpers';
import save from '../../../../assets/images/saveNew.png';
import { variablesOLV_CBU_CPRP_Arr, freqStrArr, variablesOptSplitArr, variables, variablesOptDistrArr } from '../../../../assets/data/data';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const SaveXLSFlightsOlv_Tv = props => {
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsPostData = useSelector(state => state.flights.flightsPostData);
  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const dataFromXL_Flights = useSelector(state => state.flights_charts.dataFromXL_Flights);
  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);
  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const flightArr = useSelector(state => state.flights.flightArr);
  const sheet1Arr = [];

  const getDataForExportXLSPart1_DataFromXL = () => {
    const dataSetDonut_dataFromXL = dataFromXL_Flights
      .filter(i => i.Media1)
      .map(i => {
        return {
          flight: i.flight,
          name: i.Media1,
          budget: i.Budget,
        };
      });
    const dataSetTA_dataFromXL = dataFromXL_Flights.filter(i => i.agemin);
    const dataSetPublishers_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher);
    const dataSetTotal_dataFromXL = dataFromXL_Flights.filter(i => i.total_Budget);
    const dataSetRfNPlus_dataFromXL = dataFromXL_Flights.filter(i => i['OLVReachN+']);
    const dataSetRfN_dataFromXL = dataFromXL_Flights.filter(i => i['OLVReachN']);
    const dataSet_Optimization_data_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher_Opt);
    const dataSet_restrictions_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher_Restr);
    const dataSet_Input_OLV_Budgets_dataFromXL = dataFromXL_Flights.filter(i => i.budget_OlvCbuCprpChart);
    const dataSetContr_dataFromXL = dataFromXL_Flights
      .filter(i => i.MainMedia)
      .map(i => {
        return {
          Freq: i.Freq,
          MainMedia: i.MainMedia,
          Publisher: i.PublisherContr,
          Reach: i.Reach,
          flight: i.flight,
        };
      });
    const dataSetOLV_CBU_CPRP_dataFromXL = dataFromXL_Flights.filter(i => i.Variable_OLV_CBU_CPRP);
    const DATASET_TV_OPT_DISTR_dataFromXL = dataFromXL_Flights.filter(i => i.TV_OPT_DISTR);
    const DATASET_TV_OPT_SPL_dataFromXL = dataFromXL_Flights.filter(i => i.TV_OPT_SPL);
    const DATASET_TV_INCREASE_dataFromXL = dataFromXL_Flights.filter(i => i.TV_INCREASE);
    const DATASET_RF_TV_OLV_RfNPlus_dataFromXL = dataFromXL_Flights.filter(i => i.RF_TV_OLV).filter(i => i.RF === 'rfPlus');
    const DATASET_RF_TV_OLV_RfN_dataFromXL = dataFromXL_Flights.filter(i => i.RF_TV_OLV).filter(i => i.RF === 'rfClose');
    const DATASET_CBU_dataFromXL = dataFromXL_Flights.filter(i => i.TRP_Cbu);

    return [dataSetTA_dataFromXL, dataSetTotal_dataFromXL, dataSetPublishers_dataFromXL, dataSetDonut_dataFromXL, dataSetRfNPlus_dataFromXL, dataSetRfN_dataFromXL, dataSet_Optimization_data_dataFromXL, dataSet_restrictions_dataFromXL, dataSet_Input_OLV_Budgets_dataFromXL, dataSetContr_dataFromXL, dataSetOLV_CBU_CPRP_dataFromXL, DATASET_TV_OPT_DISTR_dataFromXL, DATASET_TV_OPT_SPL_dataFromXL, DATASET_TV_INCREASE_dataFromXL, DATASET_RF_TV_OLV_RfNPlus_dataFromXL, DATASET_RF_TV_OLV_RfN_dataFromXL, DATASET_CBU_dataFromXL];
  };

  const dataSetTA_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[0];
  const dataSetTotal_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[1];
  const dataSetPublishers_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[2];
  const dataSetDonut_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[3];
  const dataSetRfNPlus_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[4];
  const dataSetRfN_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[5];
  const dataSet_Optimization_data_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[6];
  const dataSet_restrictions_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[7];
  const dataSet_Input_OLV_Budgets_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[8];
  const dataSetContr_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[9];
  const dataSetOLV_CBU_CPRP_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[10];
  const DATASET_TV_OPT_DISTR_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[11];
  const DATASET_TV_OPT_SPL_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[12];
  const DATASET_TV_INCREASE_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[13];
  const DATASET_RF_TV_OLV_RfNPlus_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[14];
  const DATASET_RF_TV_OLV_RfN_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[15];
  const DATASET_CBU_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[16];

  const getDataForExportXLSPart1_DataFromChartsData = () => {
    const dataSetTA = flightsPostData;
    const dataSetPublishers = [];
    const dataSetDonut = [];
    const dataSetRfNPlus = [];
    const dataSetRfN = [];
    const dataSet_Optimization_data = [];
    const dataSet_restrictions = [];
    const dataSet_Input_OLV_Budgets = [];
    const dataSetContr = [];
    const dataSetOLV_CBU_CPRP = [];
    const dataSetTotal = [];

    const DATASET_TV_OPT_DISTR = [];
    const DATASET_TV_OPT_SPL = [];
    const DATASET_TV_INCREASE = [];
    const DATASET_RF_TV_OLV_RfNPlus = [];
    const DATASET_RF_TV_OLV_RfN = [];
    const DATASET_CBU = [];

    flightsChartsData.map(item => {
      const currentFlightsTableParameters = flightsTableParametersArr.find(fl => fl.flight === item.flight);
      const publishers = currentFlightsTableParameters.publishers;

      const DONUT = publishers.filter(item => item.isChecked === 1);
      const donutMedia = DONUT.map(item => item.Publisher);
      const INCR = item.chartsData.incr;
      const incrPublNameArr = Object.keys(INCR);
      const Item = INCR[Object.keys(INCR)[0]][1];
      const pubNames = Object.keys(Item);
      const DATASETCONTR_ITEM = [];
      freqStrArr.map(item => {
        for (var i = 0; i < incrPublNameArr.length; i++) {
          for (var a = 0; a < pubNames.length; a++) {
            DATASETCONTR_ITEM.push(item + '*' + incrPublNameArr[i] + '/' + pubNames[a]);
          }
        }
        return item;
      });

      DATASETCONTR_ITEM.map(i => {
        const INCR_ITEM = INCR[i.slice(i.lastIndexOf('*') + 1, i.lastIndexOf('/'))][+i.substring(0, 2)];
        dataSetContr.push({
          flight: item.flight,
          MainMedia: i.slice(i.lastIndexOf('*') + 1, i.lastIndexOf('/')),
          Freq: +i.substring(0, 2),
          Publisher: i.slice(i.lastIndexOf('/') + 1),
          Reach: INCR_ITEM[i.slice(i.lastIndexOf('/') + 1)],
        });
        return i;
      });

      const RF = item.chartsData.RF;
      DONUT.map(i => {
        dataSetDonut.push({
          flight: item.flight,
          name: i.Publisher,
          budget: i.Budget,
        });
        return i;
      });

      dataSetRfNPlus.push({
        flight: item.flight,
        'OLVReachN+': 'OLVReachN+',
        1: RF['Total_media_rf_n+'][0],
        2: RF['Total_media_rf_n+'][1],
        3: RF['Total_media_rf_n+'][2],
        4: RF['Total_media_rf_n+'][3],
        5: RF['Total_media_rf_n+'][4],
        6: RF['Total_media_rf_n+'][5],
        7: RF['Total_media_rf_n+'][6],
        8: RF['Total_media_rf_n+'][7],
        9: RF['Total_media_rf_n+'][8],
        10: RF['Total_media_rf_n+'][9],
        11: RF['Total_media_rf_n+'][10],
        12: RF['Total_media_rf_n+'][11],
        13: RF['Total_media_rf_n+'][12],
        14: RF['Total_media_rf_n+'][13],
        15: RF['Total_media_rf_n+'][14],
        16: RF['Total_media_rf_n+'][15],
        17: RF['Total_media_rf_n+'][16],
        18: RF['Total_media_rf_n+'][17],
        19: RF['Total_media_rf_n+'][18],
      });

      dataSetRfN.push({
        flight: item.flight,
        OLVReachN: 'OLVReachN',
        1: RF['Total_media_rf_n'][0],
        2: RF['Total_media_rf_n'][1],
        3: RF['Total_media_rf_n'][2],
        4: RF['Total_media_rf_n'][3],
        5: RF['Total_media_rf_n'][4],
        6: RF['Total_media_rf_n'][5],
        7: RF['Total_media_rf_n'][6],
        8: RF['Total_media_rf_n'][7],
        9: RF['Total_media_rf_n'][8],
        10: RF['Total_media_rf_n'][9],
        11: RF['Total_media_rf_n'][10],
        12: RF['Total_media_rf_n'][11],
        13: RF['Total_media_rf_n'][12],
        14: RF['Total_media_rf_n'][13],
        15: RF['Total_media_rf_n'][14],
        16: RF['Total_media_rf_n'][15],
        17: RF['Total_media_rf_n'][16],
        18: RF['Total_media_rf_n'][17],
        19: RF['Total_media_rf_n'][18],
      });

      const currentBudget_OLV_TvIncreaseAggregateCoverageChart = item.budgets_TvIncreaseAggregateCoverageChart !== null ? item.budgets_TvIncreaseAggregateCoverageChart.olvBudget : null;
      const currentBudget_TV_TvIncreaseAggregateCoverageChart = item.budgets_TvIncreaseAggregateCoverageChart !== null ? item.budgets_TvIncreaseAggregateCoverageChart.tvBudget : null;
      dataSet_Input_OLV_Budgets.push({
        flight: item.flight,
        budget_OlvCbuCprpChart: item.budget_OlvCbuCprpChart,
        budget_OLV_TvIncreaseAggregateCoverageChart: currentBudget_OLV_TvIncreaseAggregateCoverageChart,
        budget_TvDistributionChart: item.budget_TvDistributionChart,
        budget_TV_TvIncreaseAggregateCoverageChart: currentBudget_TV_TvIncreaseAggregateCoverageChart,
      });

      const OLV_CBU_CPRP = item.chartsData.OLV_CBU_CPRP;
      variablesOLV_CBU_CPRP_Arr.map(i => {
        const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP[i.substring(2)][+i.substring(0, 2)];
        dataSetOLV_CBU_CPRP.push({
          flight: item.flight,
          Variable_OLV_CBU_CPRP: i.substring(2),
          Freq: +i.substring(0, 2),
          [Object.keys(OLV_CBU_CPRP_ITEM)[0]]: Object.values(OLV_CBU_CPRP_ITEM)[0],
          [Object.keys(OLV_CBU_CPRP_ITEM)[1]]: Object.values(OLV_CBU_CPRP_ITEM)[1],
          [Object.keys(OLV_CBU_CPRP_ITEM)[2]]: Object.values(OLV_CBU_CPRP_ITEM)[2],
          [Object.keys(OLV_CBU_CPRP_ITEM)[3]]: Object.values(OLV_CBU_CPRP_ITEM)[3],
          [Object.keys(OLV_CBU_CPRP_ITEM)[4]]: Object.values(OLV_CBU_CPRP_ITEM)[4],
          [Object.keys(OLV_CBU_CPRP_ITEM)[5]]: Object.values(OLV_CBU_CPRP_ITEM)[5],
          [Object.keys(OLV_CBU_CPRP_ITEM)[6]]: Object.values(OLV_CBU_CPRP_ITEM)[6],
          [Object.keys(OLV_CBU_CPRP_ITEM)[7]]: Object.values(OLV_CBU_CPRP_ITEM)[7],
          [Object.keys(OLV_CBU_CPRP_ITEM)[8]]: Object.values(OLV_CBU_CPRP_ITEM)[8],
          [Object.keys(OLV_CBU_CPRP_ITEM)[9]]: Object.values(OLV_CBU_CPRP_ITEM)[9],
          [Object.keys(OLV_CBU_CPRP_ITEM)[10]]: Object.values(OLV_CBU_CPRP_ITEM)[10],
          [Object.keys(OLV_CBU_CPRP_ITEM)[11]]: Object.values(OLV_CBU_CPRP_ITEM)[11],
          [Object.keys(OLV_CBU_CPRP_ITEM)[12]]: Object.values(OLV_CBU_CPRP_ITEM)[12],
          [Object.keys(OLV_CBU_CPRP_ITEM)[13]]: Object.values(OLV_CBU_CPRP_ITEM)[13],
          [Object.keys(OLV_CBU_CPRP_ITEM)[14]]: Object.values(OLV_CBU_CPRP_ITEM)[14],
          [Object.keys(OLV_CBU_CPRP_ITEM)[15]]: Object.values(OLV_CBU_CPRP_ITEM)[15],
          [Object.keys(OLV_CBU_CPRP_ITEM)[16]]: Object.values(OLV_CBU_CPRP_ITEM)[16],
          [Object.keys(OLV_CBU_CPRP_ITEM)[17]]: Object.values(OLV_CBU_CPRP_ITEM)[17],
          [Object.keys(OLV_CBU_CPRP_ITEM)[18]]: Object.values(OLV_CBU_CPRP_ITEM)[18],
          [Object.keys(OLV_CBU_CPRP_ITEM)[19]]: Object.values(OLV_CBU_CPRP_ITEM)[19],
        });
        return i;
      });
      const currentFlightsCbuArr = flightsCbuArr.find(fl => fl.flight === item.flight);
      const isCBUUpload = currentFlightsCbuArr.isCBUUpload;
      const сbu = currentFlightsCbuArr.cbu;

      const take_TV_DATA = () => {
        сbu.map(i => {
          DATASET_CBU.push({
            flight: item.flight,
            TRP_Cbu: i[0],
            R1: i[1],
            R2: i[2],
            R3: i[3],
            R4: i[4],
            R5: i[5],
            R6: i[6],
            R7: i[7],
            R8: i[8],
            R9: i[9],
            R10: i[10],
            R11: i[11],
            R12: i[12],
            R13: i[13],
            R14: i[14],
            R15: i[15],
            R16: i[16],
            R17: i[17],
            R18: i[18],
            R19: i[19],
            R20: i[20],
          });
          return i;
        });

        const RF_TV_OLV = item.chartsData_TV_OLV_RFdistr2;
        variables.map(variable => {
          DATASET_RF_TV_OLV_RfNPlus.push({
            flight: item.flight,
            RF_TV_OLV: 'RF_TV_OLV',
            Variable: variable,
            RF: 'rfPlus',
            1: RF_TV_OLV[variable]['rfPlus'][0],
            2: RF_TV_OLV[variable]['rfPlus'][1],
            3: RF_TV_OLV[variable]['rfPlus'][2],
            4: RF_TV_OLV[variable]['rfPlus'][3],
            5: RF_TV_OLV[variable]['rfPlus'][4],
            6: RF_TV_OLV[variable]['rfPlus'][5],
            7: RF_TV_OLV[variable]['rfPlus'][6],
            8: RF_TV_OLV[variable]['rfPlus'][7],
            9: RF_TV_OLV[variable]['rfPlus'][8],
            10: RF_TV_OLV[variable]['rfPlus'][9],
            11: RF_TV_OLV[variable]['rfPlus'][10],
            12: RF_TV_OLV[variable]['rfPlus'][11],
            13: RF_TV_OLV[variable]['rfPlus'][12],
            14: RF_TV_OLV[variable]['rfPlus'][13],
            15: RF_TV_OLV[variable]['rfPlus'][14],
            16: RF_TV_OLV[variable]['rfPlus'][15],
            17: RF_TV_OLV[variable]['rfPlus'][16],
            18: RF_TV_OLV[variable]['rfPlus'][17],
            19: RF_TV_OLV[variable]['rfPlus'][18],
          });
          return variable;
        });

        variables.map(variable => {
          DATASET_RF_TV_OLV_RfN.push({
            flight: item.flight,
            RF_TV_OLV: 'RF_TV_OLV',
            Variable: variable,
            RF: 'rfClose',
            1: RF_TV_OLV[variable]['rfClose'][0],
            2: RF_TV_OLV[variable]['rfClose'][1],
            3: RF_TV_OLV[variable]['rfClose'][2],
            4: RF_TV_OLV[variable]['rfClose'][3],
            5: RF_TV_OLV[variable]['rfClose'][4],
            6: RF_TV_OLV[variable]['rfClose'][5],
            7: RF_TV_OLV[variable]['rfClose'][6],
            8: RF_TV_OLV[variable]['rfClose'][7],
            9: RF_TV_OLV[variable]['rfClose'][8],
            10: RF_TV_OLV[variable]['rfClose'][9],
            11: RF_TV_OLV[variable]['rfClose'][10],
            12: RF_TV_OLV[variable]['rfClose'][11],
            13: RF_TV_OLV[variable]['rfClose'][12],
            14: RF_TV_OLV[variable]['rfClose'][13],
            15: RF_TV_OLV[variable]['rfClose'][14],
            16: RF_TV_OLV[variable]['rfClose'][15],
            17: RF_TV_OLV[variable]['rfClose'][16],
            18: RF_TV_OLV[variable]['rfClose'][17],
            19: RF_TV_OLV[variable]['rfClose'][18],
          });
          return variable;
        });
        const TV_INCREASE = item.chartsData_TV_OLV_20_view;
        variablesOptSplitArr.map(i => {
          const TV_INCREASE_ITEM = TV_INCREASE[+i.substring(0, 2)];
          const TV_INCREASE_ITEM_var = TV_INCREASE_ITEM[i.substring(2)];
          DATASET_TV_INCREASE.push({
            flight: item.flight,
            TV_INCREASE: 'TV_INCREASE',
            Freq: +i.substring(0, 2),
            Variable: i.substring(2),
            [Object.keys(TV_INCREASE_ITEM_var)[0]]: Object.values(TV_INCREASE_ITEM_var)[0],
            [Object.keys(TV_INCREASE_ITEM_var)[1]]: Object.values(TV_INCREASE_ITEM_var)[1],
            [Object.keys(TV_INCREASE_ITEM_var)[2]]: Object.values(TV_INCREASE_ITEM_var)[2],
            [Object.keys(TV_INCREASE_ITEM_var)[3]]: Object.values(TV_INCREASE_ITEM_var)[3],
            [Object.keys(TV_INCREASE_ITEM_var)[4]]: Object.values(TV_INCREASE_ITEM_var)[4],
            [Object.keys(TV_INCREASE_ITEM_var)[5]]: Object.values(TV_INCREASE_ITEM_var)[5],
            [Object.keys(TV_INCREASE_ITEM_var)[6]]: Object.values(TV_INCREASE_ITEM_var)[6],
            [Object.keys(TV_INCREASE_ITEM_var)[7]]: Object.values(TV_INCREASE_ITEM_var)[7],
            [Object.keys(TV_INCREASE_ITEM_var)[8]]: Object.values(TV_INCREASE_ITEM_var)[8],
            [Object.keys(TV_INCREASE_ITEM_var)[9]]: Object.values(TV_INCREASE_ITEM_var)[9],
            [Object.keys(TV_INCREASE_ITEM_var)[10]]: Object.values(TV_INCREASE_ITEM_var)[10],
            [Object.keys(TV_INCREASE_ITEM_var)[11]]: Object.values(TV_INCREASE_ITEM_var)[11],
            [Object.keys(TV_INCREASE_ITEM_var)[12]]: Object.values(TV_INCREASE_ITEM_var)[12],
            [Object.keys(TV_INCREASE_ITEM_var)[13]]: Object.values(TV_INCREASE_ITEM_var)[13],
            [Object.keys(TV_INCREASE_ITEM_var)[14]]: Object.values(TV_INCREASE_ITEM_var)[14],
            [Object.keys(TV_INCREASE_ITEM_var)[15]]: Object.values(TV_INCREASE_ITEM_var)[15],
            [Object.keys(TV_INCREASE_ITEM_var)[16]]: Object.values(TV_INCREASE_ITEM_var)[16],
            [Object.keys(TV_INCREASE_ITEM_var)[17]]: Object.values(TV_INCREASE_ITEM_var)[17],
            [Object.keys(TV_INCREASE_ITEM_var)[18]]: Object.values(TV_INCREASE_ITEM_var)[18],
            [Object.keys(TV_INCREASE_ITEM_var)[19]]: Object.values(TV_INCREASE_ITEM_var)[19],
            [Object.keys(TV_INCREASE_ITEM_var)[20]]: Object.values(TV_INCREASE_ITEM_var)[20],
          });
          return i;
        });

        const TV_OPT_DISTR = item.chartsData.tv_olv_data.opt_bud;
        variablesOptDistrArr.map(i => {
          const TV_OPT_DISTR_ITEM = TV_OPT_DISTR[+i.substring(0, 2)];
          const TV_OPT_DISTR_LABELS = Object.values(TV_OPT_DISTR_ITEM['full_bud']).map(i => round(i));
          const TV_OPT_DISTR_ITEM_var = TV_OPT_DISTR_ITEM[i.substring(2)];
          DATASET_TV_OPT_DISTR.push({
            flight: item.flight,
            TV_OPT_DISTR: 'TV_OPT_DISTR',
            Freq: +i.substring(0, 2),
            Variable: i.substring(2),
            [TV_OPT_DISTR_LABELS[0]]: Object.values(TV_OPT_DISTR_ITEM_var)[0],
            [TV_OPT_DISTR_LABELS[1]]: Object.values(TV_OPT_DISTR_ITEM_var)[1],
            [TV_OPT_DISTR_LABELS[2]]: Object.values(TV_OPT_DISTR_ITEM_var)[2],
            [TV_OPT_DISTR_LABELS[3]]: Object.values(TV_OPT_DISTR_ITEM_var)[3],
            [TV_OPT_DISTR_LABELS[4]]: Object.values(TV_OPT_DISTR_ITEM_var)[4],
            [TV_OPT_DISTR_LABELS[5]]: Object.values(TV_OPT_DISTR_ITEM_var)[5],
            [TV_OPT_DISTR_LABELS[6]]: Object.values(TV_OPT_DISTR_ITEM_var)[6],
            [TV_OPT_DISTR_LABELS[7]]: Object.values(TV_OPT_DISTR_ITEM_var)[7],
            [TV_OPT_DISTR_LABELS[8]]: Object.values(TV_OPT_DISTR_ITEM_var)[8],
            [TV_OPT_DISTR_LABELS[9]]: Object.values(TV_OPT_DISTR_ITEM_var)[9],
            [TV_OPT_DISTR_LABELS[10]]: Object.values(TV_OPT_DISTR_ITEM_var)[10],
          });
          return i;
        });

        const TV_OPT_SPL = item.chartsData.tv_olv_data.opt_view;
        variablesOptSplitArr.map(i => {
          const TV_OPT_SPL_ITEM = TV_OPT_SPL[+i.substring(0, 2)];
          const TV_OPT_SPL_ITEM_var = TV_OPT_SPL_ITEM[i.substring(2)];
          DATASET_TV_OPT_SPL.push({
            flight: item.flight,
            TV_OPT_SPL: 'TV_OPT_SPL',
            Freq: +i.substring(0, 2),
            Variable: i.substring(2),
            [Object.keys(TV_OPT_SPL_ITEM_var)[0]]: Object.values(TV_OPT_SPL_ITEM_var)[0],
            [Object.keys(TV_OPT_SPL_ITEM_var)[1]]: Object.values(TV_OPT_SPL_ITEM_var)[1],
            [Object.keys(TV_OPT_SPL_ITEM_var)[2]]: Object.values(TV_OPT_SPL_ITEM_var)[2],
            [Object.keys(TV_OPT_SPL_ITEM_var)[3]]: Object.values(TV_OPT_SPL_ITEM_var)[3],
            [Object.keys(TV_OPT_SPL_ITEM_var)[4]]: Object.values(TV_OPT_SPL_ITEM_var)[4],
            [Object.keys(TV_OPT_SPL_ITEM_var)[5]]: Object.values(TV_OPT_SPL_ITEM_var)[5],
            [Object.keys(TV_OPT_SPL_ITEM_var)[6]]: Object.values(TV_OPT_SPL_ITEM_var)[6],
            [Object.keys(TV_OPT_SPL_ITEM_var)[7]]: Object.values(TV_OPT_SPL_ITEM_var)[7],
            [Object.keys(TV_OPT_SPL_ITEM_var)[8]]: Object.values(TV_OPT_SPL_ITEM_var)[8],
            [Object.keys(TV_OPT_SPL_ITEM_var)[9]]: Object.values(TV_OPT_SPL_ITEM_var)[9],
            [Object.keys(TV_OPT_SPL_ITEM_var)[10]]: Object.values(TV_OPT_SPL_ITEM_var)[10],
            [Object.keys(TV_OPT_SPL_ITEM_var)[11]]: Object.values(TV_OPT_SPL_ITEM_var)[11],
            [Object.keys(TV_OPT_SPL_ITEM_var)[12]]: Object.values(TV_OPT_SPL_ITEM_var)[12],
            [Object.keys(TV_OPT_SPL_ITEM_var)[13]]: Object.values(TV_OPT_SPL_ITEM_var)[13],
            [Object.keys(TV_OPT_SPL_ITEM_var)[14]]: Object.values(TV_OPT_SPL_ITEM_var)[14],
            [Object.keys(TV_OPT_SPL_ITEM_var)[15]]: Object.values(TV_OPT_SPL_ITEM_var)[15],
            [Object.keys(TV_OPT_SPL_ITEM_var)[16]]: Object.values(TV_OPT_SPL_ITEM_var)[16],
            [Object.keys(TV_OPT_SPL_ITEM_var)[17]]: Object.values(TV_OPT_SPL_ITEM_var)[17],
            [Object.keys(TV_OPT_SPL_ITEM_var)[18]]: Object.values(TV_OPT_SPL_ITEM_var)[18],
            [Object.keys(TV_OPT_SPL_ITEM_var)[19]]: Object.values(TV_OPT_SPL_ITEM_var)[19],
            [Object.keys(TV_OPT_SPL_ITEM_var)[20]]: Object.values(TV_OPT_SPL_ITEM_var)[20],
            [Object.keys(TV_OPT_SPL_ITEM_var)[21]]: Object.values(TV_OPT_SPL_ITEM_var)[21],
            [Object.keys(TV_OPT_SPL_ITEM_var)[22]]: Object.values(TV_OPT_SPL_ITEM_var)[22],
            [Object.keys(TV_OPT_SPL_ITEM_var)[23]]: Object.values(TV_OPT_SPL_ITEM_var)[23],
            [Object.keys(TV_OPT_SPL_ITEM_var)[24]]: Object.values(TV_OPT_SPL_ITEM_var)[24],
            [Object.keys(TV_OPT_SPL_ITEM_var)[25]]: Object.values(TV_OPT_SPL_ITEM_var)[25],
            [Object.keys(TV_OPT_SPL_ITEM_var)[26]]: Object.values(TV_OPT_SPL_ITEM_var)[26],
            [Object.keys(TV_OPT_SPL_ITEM_var)[27]]: Object.values(TV_OPT_SPL_ITEM_var)[27],
            [Object.keys(TV_OPT_SPL_ITEM_var)[28]]: Object.values(TV_OPT_SPL_ITEM_var)[28],
            [Object.keys(TV_OPT_SPL_ITEM_var)[29]]: Object.values(TV_OPT_SPL_ITEM_var)[29],
            [Object.keys(TV_OPT_SPL_ITEM_var)[30]]: Object.values(TV_OPT_SPL_ITEM_var)[30],
            [Object.keys(TV_OPT_SPL_ITEM_var)[31]]: Object.values(TV_OPT_SPL_ITEM_var)[31],
            [Object.keys(TV_OPT_SPL_ITEM_var)[32]]: Object.values(TV_OPT_SPL_ITEM_var)[32],
            [Object.keys(TV_OPT_SPL_ITEM_var)[33]]: Object.values(TV_OPT_SPL_ITEM_var)[33],
            [Object.keys(TV_OPT_SPL_ITEM_var)[34]]: Object.values(TV_OPT_SPL_ITEM_var)[34],
            [Object.keys(TV_OPT_SPL_ITEM_var)[35]]: Object.values(TV_OPT_SPL_ITEM_var)[35],
            [Object.keys(TV_OPT_SPL_ITEM_var)[36]]: Object.values(TV_OPT_SPL_ITEM_var)[36],
            [Object.keys(TV_OPT_SPL_ITEM_var)[37]]: Object.values(TV_OPT_SPL_ITEM_var)[37],
            [Object.keys(TV_OPT_SPL_ITEM_var)[38]]: Object.values(TV_OPT_SPL_ITEM_var)[38],
            [Object.keys(TV_OPT_SPL_ITEM_var)[39]]: Object.values(TV_OPT_SPL_ITEM_var)[39],
            [Object.keys(TV_OPT_SPL_ITEM_var)[40]]: Object.values(TV_OPT_SPL_ITEM_var)[40],
            [Object.keys(TV_OPT_SPL_ITEM_var)[41]]: Object.values(TV_OPT_SPL_ITEM_var)[41],
            [Object.keys(TV_OPT_SPL_ITEM_var)[42]]: Object.values(TV_OPT_SPL_ITEM_var)[42],
            [Object.keys(TV_OPT_SPL_ITEM_var)[43]]: Object.values(TV_OPT_SPL_ITEM_var)[43],
            [Object.keys(TV_OPT_SPL_ITEM_var)[44]]: Object.values(TV_OPT_SPL_ITEM_var)[44],
            [Object.keys(TV_OPT_SPL_ITEM_var)[45]]: Object.values(TV_OPT_SPL_ITEM_var)[45],
            [Object.keys(TV_OPT_SPL_ITEM_var)[46]]: Object.values(TV_OPT_SPL_ITEM_var)[46],
            [Object.keys(TV_OPT_SPL_ITEM_var)[47]]: Object.values(TV_OPT_SPL_ITEM_var)[47],
            [Object.keys(TV_OPT_SPL_ITEM_var)[48]]: Object.values(TV_OPT_SPL_ITEM_var)[48],
            [Object.keys(TV_OPT_SPL_ITEM_var)[49]]: Object.values(TV_OPT_SPL_ITEM_var)[49],
            [Object.keys(TV_OPT_SPL_ITEM_var)[50]]: Object.values(TV_OPT_SPL_ITEM_var)[50],
            [Object.keys(TV_OPT_SPL_ITEM_var)[51]]: Object.values(TV_OPT_SPL_ITEM_var)[51],
            [Object.keys(TV_OPT_SPL_ITEM_var)[52]]: Object.values(TV_OPT_SPL_ITEM_var)[52],
            [Object.keys(TV_OPT_SPL_ITEM_var)[53]]: Object.values(TV_OPT_SPL_ITEM_var)[53],
            [Object.keys(TV_OPT_SPL_ITEM_var)[54]]: Object.values(TV_OPT_SPL_ITEM_var)[54],
            [Object.keys(TV_OPT_SPL_ITEM_var)[55]]: Object.values(TV_OPT_SPL_ITEM_var)[55],
            [Object.keys(TV_OPT_SPL_ITEM_var)[56]]: Object.values(TV_OPT_SPL_ITEM_var)[56],
            [Object.keys(TV_OPT_SPL_ITEM_var)[57]]: Object.values(TV_OPT_SPL_ITEM_var)[57],
            [Object.keys(TV_OPT_SPL_ITEM_var)[58]]: Object.values(TV_OPT_SPL_ITEM_var)[58],
            [Object.keys(TV_OPT_SPL_ITEM_var)[59]]: Object.values(TV_OPT_SPL_ITEM_var)[59],
            [Object.keys(TV_OPT_SPL_ITEM_var)[60]]: Object.values(TV_OPT_SPL_ITEM_var)[60],
            [Object.keys(TV_OPT_SPL_ITEM_var)[61]]: Object.values(TV_OPT_SPL_ITEM_var)[61],
            [Object.keys(TV_OPT_SPL_ITEM_var)[62]]: Object.values(TV_OPT_SPL_ITEM_var)[62],
            [Object.keys(TV_OPT_SPL_ITEM_var)[63]]: Object.values(TV_OPT_SPL_ITEM_var)[63],
            [Object.keys(TV_OPT_SPL_ITEM_var)[64]]: Object.values(TV_OPT_SPL_ITEM_var)[64],
            [Object.keys(TV_OPT_SPL_ITEM_var)[65]]: Object.values(TV_OPT_SPL_ITEM_var)[65],
            [Object.keys(TV_OPT_SPL_ITEM_var)[66]]: Object.values(TV_OPT_SPL_ITEM_var)[66],
            [Object.keys(TV_OPT_SPL_ITEM_var)[67]]: Object.values(TV_OPT_SPL_ITEM_var)[67],
            [Object.keys(TV_OPT_SPL_ITEM_var)[68]]: Object.values(TV_OPT_SPL_ITEM_var)[68],
            [Object.keys(TV_OPT_SPL_ITEM_var)[69]]: Object.values(TV_OPT_SPL_ITEM_var)[69],
            [Object.keys(TV_OPT_SPL_ITEM_var)[70]]: Object.values(TV_OPT_SPL_ITEM_var)[70],
            [Object.keys(TV_OPT_SPL_ITEM_var)[71]]: Object.values(TV_OPT_SPL_ITEM_var)[71],
            [Object.keys(TV_OPT_SPL_ITEM_var)[72]]: Object.values(TV_OPT_SPL_ITEM_var)[72],
            [Object.keys(TV_OPT_SPL_ITEM_var)[73]]: Object.values(TV_OPT_SPL_ITEM_var)[73],
            [Object.keys(TV_OPT_SPL_ITEM_var)[74]]: Object.values(TV_OPT_SPL_ITEM_var)[74],
            [Object.keys(TV_OPT_SPL_ITEM_var)[75]]: Object.values(TV_OPT_SPL_ITEM_var)[75],
            [Object.keys(TV_OPT_SPL_ITEM_var)[76]]: Object.values(TV_OPT_SPL_ITEM_var)[76],
            [Object.keys(TV_OPT_SPL_ITEM_var)[77]]: Object.values(TV_OPT_SPL_ITEM_var)[77],
            [Object.keys(TV_OPT_SPL_ITEM_var)[78]]: Object.values(TV_OPT_SPL_ITEM_var)[78],
            [Object.keys(TV_OPT_SPL_ITEM_var)[79]]: Object.values(TV_OPT_SPL_ITEM_var)[79],
            [Object.keys(TV_OPT_SPL_ITEM_var)[80]]: Object.values(TV_OPT_SPL_ITEM_var)[80],
            [Object.keys(TV_OPT_SPL_ITEM_var)[81]]: Object.values(TV_OPT_SPL_ITEM_var)[81],
            [Object.keys(TV_OPT_SPL_ITEM_var)[82]]: Object.values(TV_OPT_SPL_ITEM_var)[82],
            [Object.keys(TV_OPT_SPL_ITEM_var)[83]]: Object.values(TV_OPT_SPL_ITEM_var)[83],
            [Object.keys(TV_OPT_SPL_ITEM_var)[84]]: Object.values(TV_OPT_SPL_ITEM_var)[84],
            [Object.keys(TV_OPT_SPL_ITEM_var)[85]]: Object.values(TV_OPT_SPL_ITEM_var)[85],
            [Object.keys(TV_OPT_SPL_ITEM_var)[86]]: Object.values(TV_OPT_SPL_ITEM_var)[86],
            [Object.keys(TV_OPT_SPL_ITEM_var)[87]]: Object.values(TV_OPT_SPL_ITEM_var)[87],
            [Object.keys(TV_OPT_SPL_ITEM_var)[88]]: Object.values(TV_OPT_SPL_ITEM_var)[88],
            [Object.keys(TV_OPT_SPL_ITEM_var)[89]]: Object.values(TV_OPT_SPL_ITEM_var)[89],
            [Object.keys(TV_OPT_SPL_ITEM_var)[90]]: Object.values(TV_OPT_SPL_ITEM_var)[90],
            [Object.keys(TV_OPT_SPL_ITEM_var)[91]]: Object.values(TV_OPT_SPL_ITEM_var)[91],
            [Object.keys(TV_OPT_SPL_ITEM_var)[92]]: Object.values(TV_OPT_SPL_ITEM_var)[92],
            [Object.keys(TV_OPT_SPL_ITEM_var)[93]]: Object.values(TV_OPT_SPL_ITEM_var)[93],
            [Object.keys(TV_OPT_SPL_ITEM_var)[94]]: Object.values(TV_OPT_SPL_ITEM_var)[94],
            [Object.keys(TV_OPT_SPL_ITEM_var)[95]]: Object.values(TV_OPT_SPL_ITEM_var)[95],
            [Object.keys(TV_OPT_SPL_ITEM_var)[96]]: Object.values(TV_OPT_SPL_ITEM_var)[96],
            [Object.keys(TV_OPT_SPL_ITEM_var)[97]]: Object.values(TV_OPT_SPL_ITEM_var)[97],
            [Object.keys(TV_OPT_SPL_ITEM_var)[98]]: Object.values(TV_OPT_SPL_ITEM_var)[98],
            [Object.keys(TV_OPT_SPL_ITEM_var)[99]]: Object.values(TV_OPT_SPL_ITEM_var)[99],
            [Object.keys(TV_OPT_SPL_ITEM_var)[100]]: Object.values(TV_OPT_SPL_ITEM_var)[100],
          });
          return i;
        });

        return [DATASET_TV_OPT_DISTR, DATASET_TV_OPT_SPL, DATASET_TV_INCREASE, DATASET_RF_TV_OLV_RfNPlus, DATASET_RF_TV_OLV_RfN, DATASET_CBU];
      };
      isCBUUpload === 1 && take_TV_DATA();
    });

    flightsTableParametersArr.map(item => {
      item.publishers.map(i => {
        dataSetPublishers.push({
          flight: item.flight,
          Budget: i.Budget,
          CPM: i.CPM,
          Days: i.Days,
          DefaultImpressions: i.DefaultImpressions,
          FrequencyLimit: i.FrequencyLimit,
          Impressions: i.Impressions,
          OtherTargeting: i.OtherTargeting,
          Publisher: i.Publisher,
          TRP: i.TRP,
          TargetingSocDem: i.TargetingSocDem,
          Tsa_percent: i.Tsa_percent,
          Tsa_thousand: i.Tsa_thousand,
          isChecked: i.isChecked,
        });
        return i;
      });
      item.total.map(i => {
        dataSetTotal.push({
          flight: item.flight,
          total_Budget: i.total_Budget,
          total_Impressions: i.total_Impressions,
          total_CPM: i.total_CPM,
          total_Tsa_thousand: i.total_Tsa_thousand,
          total_Tsa_percent: i.total_Tsa_percent,
          total_TRP: i.total_TRP,
        });
        return i;
      });
      return {
        item,
      };
    });
    flightsOptimizationArr.map(i => {
      Object.keys(i.optimizationData).map(it => {
        dataSet_Optimization_data.push({
          flight: i.flight,
          Publisher_Opt: it,
          CPM_Opt: i.optimizationData[it].CPM,
          Days_Opt: i.optimizationData[it].Days,
          FrequencyLimit_Opt: i.optimizationData[it].FrequencyLimit,
          Impressions_Opt: i.optimizationData[it].Impressions,
          max_OTS_Opt: i.optimizationData[it].max_OTS,
          targ_Opt: i.optimizationData[it].targ,
          budget_Opt: i.optimizationData[it].budget ? i.optimizationData[it].budget : null,
        });
        return i;
      });
      i.restrictions.map(i => {
        dataSet_restrictions.push({
          Publisher_Restr: i.Publisher,
          min: i.min === null ? 0 : i.min,
          max: i.max === null ? 0 : i.max,
        });
        return i;
      });
      return i;
    });
    return [dataSetTA, dataSetTotal, dataSetPublishers, dataSetDonut, dataSetRfNPlus, dataSetRfN, dataSet_Optimization_data, dataSet_restrictions, dataSet_Input_OLV_Budgets, dataSetContr, dataSetOLV_CBU_CPRP, DATASET_TV_OPT_DISTR, DATASET_TV_OPT_SPL, DATASET_TV_INCREASE, DATASET_RF_TV_OLV_RfNPlus, DATASET_RF_TV_OLV_RfN, DATASET_CBU];
  };

  const dataSetTA = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[0];
  const dataSetTotal = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[1];
  const dataSetPublishers = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[2];
  const dataSetDonut = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[3];
  const dataSetRfNPlus = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[4];
  const dataSetRfN = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[5];
  const dataSet_Optimization_data = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[6];
  const dataSet_restrictions = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[7];
  const dataSet_Input_OLV_Budgets = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[8];
  const dataSetContr = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[9];
  const dataSetOLV_CBU_CPRP = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[10];
  const DATASET_TV_OPT_DISTR = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[11];
  const DATASET_TV_OPT_SPL = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[12];
  const DATASET_TV_INCREASE = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[13];
  const DATASET_RF_TV_OLV_RfNPlus = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[14];
  const DATASET_RF_TV_OLV_RfN = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[15];
  const DATASET_CBU = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[16];

  for (const flight of flightsChartsData) {
    const FLIGHT = flight.flight;
    const TA = flightsUpload ? `${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).gender} ${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).agemin}-${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).agemax} ${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).income}` : `${dataSetTA.find(i => i.flight === flight.flight).gender} ${dataSetTA.find(i => i.flight === flight.flight).agemin}-${dataSetTA.find(i => i.flight === flight.flight).agemax} ${dataSetTA.find(i => i.flight === flight.flight).income}`;
    const Geo = flightsUpload ? makeGeoTitle_From_PostData(dataSetTA_dataFromXL.find(i => i.flight === flight.flight).geo) : makeGeoTitle_From_PostData(dataSetTA.find(i => i.flight === flight.flight).geo);
    const Universe = flightsUpload ? dataSetTA_dataFromXL.find(i => i.flight === flight.flight).universe : dataSetTA.find(i => i.flight === flight.flight).universe;
    const Share = flightsUpload ? dataSetTA_dataFromXL.find(i => i.flight === flight.flight).share : dataSetTA.find(i => i.flight === flight.flight).share;
    const PresenceOfTvFlight = flightsUpload ? makePresenceOfTvFlight_From_PostData(dataSetTA_dataFromXL.find(i => i.flight === flight.flight).presenceOfTvFlight) : makePresenceOfTvFlight_From_PostData(dataSetTA.find(i => i.flight === flight.flight).presenceOfTvFlight);
    const Ltv = flightsUpload ? makeLtv_From_PostData(dataSetTA_dataFromXL.find(i => i.flight === flight.flight).ltv) : makeLtv_From_PostData(dataSetTA.find(i => i.flight === flight.flight).ltv);
    const freqAll = flight.freqAll;
    const freqPost = freqAll && getFreqNumber(freqAll);
    const mainMedia = flight.mainMedia;
    const FLIGHT_NUMBER = flightArr.find(i => i.id === flight.flight) && flightArr.find(i => i.id === flight.flight).flight;
    const isCBUUpload = flightsCbuArr.find(i => i.flight === flight.flight) && flightsCbuArr.find(i => i.flight === flight.flight).isCBUUpload;

    const chooseDataSetTotal = () => {
      if (flightsUpload) {
        return dataSetTotal_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetTotal.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDataSetPublishers = () => {
      if (flightsUpload) {
        return dataSetPublishers_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetPublishers.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDATASETCONTR = () => {
      if (flightsUpload) {
        return dataSetContr_dataFromXL.filter(i => i.flight === flight.flight).filter(i => i.MainMedia === mainMedia && i.Freq === freqPost);
      } else {
        return dataSetContr.filter(i => i.flight === flight.flight).filter(i => i.MainMedia === mainMedia && i.Freq === freqPost);
      }
    };
    const choosedataSetDonut = () => {
      if (flightsUpload) {
        return dataSetDonut_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetDonut.filter(i => i.flight === flight.flight);
      }
    };
    const choosedataSetRfN = () => {
      if (flightsUpload) {
        return dataSetRfN_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetRfN.filter(i => i.flight === flight.flight);
      }
    };
    const choosedataSetRfNPlus = () => {
      if (flightsUpload) {
        return dataSetRfNPlus_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetRfNPlus.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDATASETOLV_CBU_CPRP = () => {
      if (flightsUpload) {
        return dataSetOLV_CBU_CPRP_dataFromXL.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      } else {
        return dataSetOLV_CBU_CPRP.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      }
    };
    const chooseDATASET_RF_TV_OLV_RfN = () => {
      if (flightsUpload) {
        return DATASET_RF_TV_OLV_RfN_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return DATASET_RF_TV_OLV_RfN.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDATASET_RF_TV_OLV_RfNPlus = () => {
      if (flightsUpload) {
        return DATASET_RF_TV_OLV_RfNPlus_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return DATASET_RF_TV_OLV_RfNPlus.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDATASET_TV_OPT_SPL = () => {
      if (flightsUpload) {
        return DATASET_TV_OPT_SPL_dataFromXL.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      } else {
        return DATASET_TV_OPT_SPL.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      }
    };
    const chooseDATASET_TV_OPT_DISTR = () => {
      if (flightsUpload) {
        return DATASET_TV_OPT_DISTR_dataFromXL.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      } else {
        return DATASET_TV_OPT_DISTR.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      }
    };
    const chooseDATASET_DATASET_TV_INCREASE = () => {
      if (flightsUpload) {
        return DATASET_TV_INCREASE_dataFromXL.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      } else {
        return DATASET_TV_INCREASE.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      }
    };

    const chooseDataSetTotal_call_result = chooseDataSetTotal();
    const chooseDATASETOLV_CBU_CPRP_call_result = chooseDATASETOLV_CBU_CPRP();
    const chooseDATASET_TV_OPT_SPL_call_result = chooseDATASET_TV_OPT_SPL();
    const chooseDATASET_TV_OPT_DISTR_call_result = chooseDATASET_TV_OPT_DISTR();
    const chooseDATASET_DATASET_TV_INCREASE_call_result = chooseDATASET_DATASET_TV_INCREASE();

    const Publishers_Sheet1 = chooseDataSetPublishers().map((item, i) => {
      return [
        {
          value: String(item.Publisher),
          style: {
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: item.Budget,
          style: {
            numFmt: '#,##0 ₽',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Impressions,
          style: {
            numFmt: '#,##0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.CPM,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.TargetingSocDem === 1 ? 'Да' : 'Нет',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.OtherTargeting === 1 ? 'Да' : 'Нет',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Days,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: item.FrequencyLimit,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Tsa_percent / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Tsa_thousand,
          style: {
            numFmt: '0.0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.TRP,
          style: {
            numFmt: '0.0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const Contribution_Sheet1 = chooseDATASETCONTR().map((item, i) => {
      return [
        {
          value: String(item.Publisher),
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Reach / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const Donut_Sheet1 = choosedataSetDonut().map((item, i) => {
      return [
        {
          value: String(item.name),
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.budget,
          style: {
            numFmt: '#,##0 ₽',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const RfN_Sheet1 = choosedataSetRfN().map((item, i) => {
      return [
        {
          value: 'Закрытые часоты',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: 'olv',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[1] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[2] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[3] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const RfNPlus_Sheet1 = choosedataSetRfNPlus().map((item, i) => {
      return [
        {
          value: 'Открытые частоты',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: 'olv',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[1] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[2] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[3] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const OLV_CBU_CPRP_sheet1 = chooseDATASETOLV_CBU_CPRP().map((item, i) => {
      return [
        {
          value: item.Variable_OLV_CBU_CPRP,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Freq,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[3] !== 'null' ? Object.values(item)[3] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[4] !== 'null' ? Object.values(item)[4] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[5] !== 'null' ? Object.values(item)[5] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[6] !== 'null' ? Object.values(item)[6] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[7] !== 'null' ? Object.values(item)[7] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[8] !== 'null' ? Object.values(item)[8] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[9] !== 'null' ? Object.values(item)[9] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[10] !== 'null' ? Object.values(item)[10] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[11] !== 'null' ? Object.values(item)[11] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[12] !== 'null' ? Object.values(item)[12] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[13] !== 'null' ? Object.values(item)[13] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[14] !== 'null' ? Object.values(item)[14] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[15] !== 'null' ? Object.values(item)[15] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[16] !== 'null' ? Object.values(item)[16] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[17] !== 'null' ? Object.values(item)[17] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[18] !== 'null' ? Object.values(item)[18] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[19] !== 'null' ? Object.values(item)[19] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[20] !== 'null' ? Object.values(item)[20] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[21] !== 'null' ? Object.values(item)[21] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[22] !== 'null' ? Object.values(item)[22] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const RF_TV_OLV_RfN_Sheet1 = chooseDATASET_RF_TV_OLV_RfN().map((item, i) => {
      return [
        {
          value: 'RF_TV_OLV',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: 'N',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[1] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[2] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[3] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const RF_TV_OLV_RfNPlus_Sheet1 = chooseDATASET_RF_TV_OLV_RfNPlus().map((item, i) => {
      return [
        {
          value: 'RF_TV_OLV',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: 'N+',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[1] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[2] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[3] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const TV_OPT_SPL_sheet1 = chooseDATASET_TV_OPT_SPL_call_result.map((item, i) => {
      return [
        {
          value: 'TV_OPT_SPL',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Freq,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: Object.values(item)[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[20] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[21] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[22] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[23] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[24] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[25] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[26] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[27] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[28] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[29] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[30] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[31] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[32] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[33] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[34] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[35] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[36] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[37] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[38] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[39] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[40] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[41] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[42] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[43] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[44] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[45] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[46] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[47] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[48] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[49] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[50] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[51] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[52] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[53] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[54] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[55] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[56] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[57] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[58] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[59] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[60] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[61] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[62] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[63] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[64] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[65] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[66] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[67] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[68] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[69] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[70] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[71] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[72] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[73] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[74] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[75] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[76] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[77] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[78] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[79] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[80] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[81] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[82] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[83] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[84] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[85] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[86] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[87] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[88] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[89] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[90] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[91] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[92] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[93] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[94] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[95] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[96] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[97] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[98] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[99] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[100] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[101] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[102] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[103] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[104] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const TV_OPT_DISTR_sheet1 = chooseDATASET_TV_OPT_DISTR_call_result.map((item, i) => {
      return [
        {
          value: 'TV_OPT_DISTR',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Freq,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[0] : Object.values(item)[0] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[1] : Object.values(item)[1] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[2] : Object.values(item)[2] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[3] : Object.values(item)[3] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[4] : Object.values(item)[4] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[5] : Object.values(item)[5] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[6] : Object.values(item)[6] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[7] : Object.values(item)[7] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[8] : Object.values(item)[8] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[9] : Object.values(item)[9] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[10] : Object.values(item)[10] / 100,
          style: {
            numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const TV_INCREASE_sheet1 = chooseDATASET_DATASET_TV_INCREASE_call_result.map((item, i) => {
      return [
        {
          value: 'TV_INCREASE',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Freq,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Variable,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: Object.values(item)[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[20] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[21] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[22] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[23] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[24] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });

    sheet1Arr.push([
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: `Флайт ${FLIGHT_NUMBER}`,
            width: { wpx: 280 },
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `Целевая аудитория (флайт ${FLIGHT})`,
            width: { wpx: 280 },
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            title: String(TA),
            width: { wpx: 170 },
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: [
          [
            {
              value: 'Гео',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: String(Geo),
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          [
            {
              value: 'OLV бюджет',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Budget,
              style: {
                numFmt: '#,##0 ₽',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          [
            {
              value: 'Universe, OOO',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: Universe,
              style: {
                numFmt: '0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          [
            {
              value: 'Share',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: Share / 100,
              style: {
                numFmt: '0%',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],

          [
            {
              value: 'Наличие TV флайта',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: PresenceOfTvFlight,
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],

          [
            {
              value: 'LTV',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: Ltv,
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],

          [
            {
              value: 'Заданная эффективная частота в инструменте',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: freqAll,
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
        ],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: 'Площадка',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Бюджет',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Показы',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'CPM',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Таргет (soc dem)',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Таргет (other)',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Период (дни)',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Частота в месяц',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'ЦА %',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'ЦА тыс',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'TRP',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: Publishers_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: -1,
        columns: [],
        data: [
          [
            {
              value: 'Total',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Budget,
              style: {
                numFmt: '#,##0 ₽',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Impressions,
              style: {
                numFmt: '#,##0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },

            {
              value: chooseDataSetTotal_call_result[0].total_CPM,
              style: {
                numFmt: '0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Tsa_percent,
              style: {
                numFmt: '0.0%',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Tsa_thousand,
              style: {
                numFmt: '0.0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_TRP,
              style: {
                numFmt: '0.0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
        ],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: 'графики в цифрах: ',
            width: { wpx: 280 },
            style: { alignment: { wrapText: false, horizontal: 'center', vertical: 'top' } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: 'OLV only 1й - 4й графики из Videotron',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `1. Вклад каждой площадки в совокупный охват на частоте ${freqAll}`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Основное медиа',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },

              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: mainMedia,
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Площадка',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Охват',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: Contribution_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `2. Распределение бюджета`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Площадка',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Бюджет',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: Donut_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `3. Распределение Reach/Frequency OLV`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: '',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '1',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '2',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '3',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '4',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '5',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '6',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '7',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '8',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '9',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '10',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '11',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '12',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '14',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '14',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '15',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '16',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '17',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '18',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '19',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: RfN_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: -1,
        columns: [],
        data: RfNPlus_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `4. OLV: CBU & CPRP на частоте ${freqAll}`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Variable_OLV_CBU_CPRP',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Freq',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[3].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[4].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[5].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[6].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[7].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[8].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[9].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[10].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[11].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[12].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[13].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[14].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[15].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[16].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[17].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[18].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[19].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[20].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[21].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[22].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: OLV_CBU_CPRP_sheet1,
      },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 1,
            columns: [
              {
                title: 'OLV+TV 5й-8й графики из Videotron',
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                },
              },
            ],
            data: [],
          }
        : {
            xSteps: 0,
            ySteps: 1,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 1,
            columns: [
              {
                title: `5. Распределение Reach / Frequency TV & OLV `,
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                },
              },
            ],
            data: [],
          }
        : {
            xSteps: 0,
            ySteps: 1,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 0,
            columns: [
              {
                title: 'RF_TV_OLV',
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'RF',
                width: { wpx: 170 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Variable',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '1',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '2',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '3',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '4',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '5',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '6',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '7',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '8',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '9',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '10',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '11',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '12',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '14',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '14',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '15',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '16',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '17',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '18',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: '19',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            data: RF_TV_OLV_RfN_Sheet1,
          }
        : {
            xSteps: 0,
            ySteps: 0,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: -1,
            columns: [],
            data: RF_TV_OLV_RfNPlus_Sheet1,
          }
        : {
            xSteps: 0,
            ySteps: -1,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 1,
            columns: [
              {
                title: `6. Оптимальный сплит бюджета TV & OLV`,
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                },
              },
            ],
            data: [],
          }
        : {
            xSteps: 0,
            ySteps: 1,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 0,
            columns: [
              {
                title: 'TV_OPT_SPL',
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Freq',
                width: { wpx: 170 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Variable',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[4].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[5].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[6].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[7].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[8].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[9].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[10].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[11].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[12].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[13].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[14].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[15].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[16].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[17].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[18].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[19].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[20].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[21].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[22].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[23].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[24].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[25].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[26].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[27].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[28].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[29].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[30].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[31].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[32].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[33].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[34].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[35].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[36].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[37].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[38].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[39].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[40].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[41].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[42].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[43].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[44].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[45].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[46].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[47].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[48].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[49].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[50].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[51].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[52].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[53].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[54].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[55].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[56].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[57].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[58].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[59].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[60].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[61].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[62].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[63].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[64].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[65].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[66].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[67].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[68].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[69].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[70].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[71].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[72].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[73].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[74].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[75].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[76].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[77].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[78].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[79].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[80].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[81].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[82].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[83].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[84].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[85].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[86].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[87].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[88].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[89].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[90].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[91].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[92].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[93].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[94].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[95].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[96].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[97].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[98].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[99].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[100].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[101].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[102].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[103].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[104].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            data: TV_OPT_SPL_sheet1,
          }
        : {
            xSteps: 0,
            ySteps: 0,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 1,
            columns: [
              {
                title: `7. Оптимальное распределение бюджета для достижения максимального совокупного охвата при нефиксированных TV и OLV бюджетах`,
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                },
              },
            ],
            data: [],
          }
        : {
            xSteps: 0,
            ySteps: 1,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 0,
            columns: [
              {
                title: 'TV_OPT_DISTR',
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Freq',
                width: { wpx: 170 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Variable',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[0]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[1]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[2]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[3]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[4]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[5]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[6]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[7]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[8]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[9]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[10]} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            data: TV_OPT_DISTR_sheet1,
          }
        : {
            xSteps: 0,
            ySteps: 0,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 1,
            columns: [
              {
                title: `8. Рост совокупного охвата TV & OLV при фиксированном бюджете TV и нефиксированном бюджете OLV`,
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                },
              },
            ],
            data: [],
          }
        : {
            xSteps: 0,
            ySteps: 1,
            columns: [],
            data: [],
          },
      isCBUUpload === 1
        ? {
            xSteps: 0,
            ySteps: 0,
            columns: [
              {
                title: 'TV_INCREASE',
                width: { wpx: 280 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Freq',
                width: { wpx: 170 },
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: 'Variable',
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[4].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[5].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[6].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[7].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[8].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[9].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[10].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[11].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[12].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[13].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[14].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[15].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[16].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[17].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[18].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[19].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[20].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[21].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[22].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[23].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[24].toLocaleString()} ₽`,
                style: {
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            data: TV_INCREASE_sheet1,
          }
        : {
            xSteps: 0,
            ySteps: 0,
            columns: [],
            data: [],
          },
    ]);
  }
  return (
    <div>
      <ExcelFile
        element={
          <StartButton view={'red'}>
            <img src={save} alt='save' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

            <div>
              <div>Сохранить </div>
              <div> проект </div>
            </div>
          </StartButton>
        }
        filename='save_videotron_flights'
      >
        {sheet1Arr.map((data, index) => {
          return <ExcelSheet key={index} dataSet={data} name={'Сводные данные ' + `(${data[0].columns[0].title})`} />;
        })}
        <ExcelSheet data={flightsUpload ? dataSetPublishers_dataFromXL : dataSetPublishers} name='Площадки'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher' value='Publisher' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='Budget' value='Budget' />
          <ExcelColumn label='CPM' value='CPM' />
          <ExcelColumn label='Days' value='Days' />
          <ExcelColumn label='DefaultImpressions' value='DefaultImpressions' />
          <ExcelColumn label='FrequencyLimit' value='FrequencyLimit' />
          <ExcelColumn label='Impressions' value='Impressions' />
          <ExcelColumn label='OtherTargeting' value='OtherTargeting' />
          <ExcelColumn label='TRP' value='TRP' />
          <ExcelColumn label='TargetingSocDem' value='TargetingSocDem' />
          <ExcelColumn label='Tsa_percent' value='Tsa_percent' />
          <ExcelColumn label='Tsa_thousand' value='Tsa_thousand' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetOLV_CBU_CPRP_dataFromXL : dataSetOLV_CBU_CPRP} name='OLV_CBU_CPRP'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Variable_OLV_CBU_CPRP' value='Variable_OLV_CBU_CPRP' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(dataSetOLV_CBU_CPRP[0])[3]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(dataSetOLV_CBU_CPRP[0])[3]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(dataSetOLV_CBU_CPRP[0])[4]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(dataSetOLV_CBU_CPRP[0])[4]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(dataSetOLV_CBU_CPRP[0])[5]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(dataSetOLV_CBU_CPRP[0])[5]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(dataSetOLV_CBU_CPRP[0])[6]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(dataSetOLV_CBU_CPRP[0])[6]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(dataSetOLV_CBU_CPRP[0])[7]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(dataSetOLV_CBU_CPRP[0])[7]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(dataSetOLV_CBU_CPRP[0])[8]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(dataSetOLV_CBU_CPRP[0])[8]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(dataSetOLV_CBU_CPRP[0])[9]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(dataSetOLV_CBU_CPRP[0])[9]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(dataSetOLV_CBU_CPRP[0])[10]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(dataSetOLV_CBU_CPRP[0])[10]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(dataSetOLV_CBU_CPRP[0])[11]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(dataSetOLV_CBU_CPRP[0])[11]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(dataSetOLV_CBU_CPRP[0])[12]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(dataSetOLV_CBU_CPRP[0])[12]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(dataSetOLV_CBU_CPRP[0])[13]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(dataSetOLV_CBU_CPRP[0])[13]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(dataSetOLV_CBU_CPRP[0])[14]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(dataSetOLV_CBU_CPRP[0])[14]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(dataSetOLV_CBU_CPRP[0])[15]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(dataSetOLV_CBU_CPRP[0])[15]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(dataSetOLV_CBU_CPRP[0])[16]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(dataSetOLV_CBU_CPRP[0])[16]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(dataSetOLV_CBU_CPRP[0])[17]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(dataSetOLV_CBU_CPRP[0])[17]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(dataSetOLV_CBU_CPRP[0])[18]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(dataSetOLV_CBU_CPRP[0])[18]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(dataSetOLV_CBU_CPRP[0])[19]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(dataSetOLV_CBU_CPRP[0])[19]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(dataSetOLV_CBU_CPRP[0])[20]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(dataSetOLV_CBU_CPRP[0])[20]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(dataSetOLV_CBU_CPRP[0])[21]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(dataSetOLV_CBU_CPRP[0])[21]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[22] : Object.keys(dataSetOLV_CBU_CPRP[0])[22]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[22] : Object.keys(dataSetOLV_CBU_CPRP[0])[22]} />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetContr_dataFromXL : dataSetContr} name='Reach_build_OLV'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='MainMedia' value='MainMedia' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='PublisherContr' value='Publisher' />
          <ExcelColumn label='Reach' value='Reach' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? DATASET_RF_TV_OLV_RfNPlus_dataFromXL : DATASET_RF_TV_OLV_RfNPlus} name='RF_TV_OLV(n+)'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='RF_TV_OLV' value='RF_TV_OLV' />
          <ExcelColumn label='RF' value='RF' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? DATASET_RF_TV_OLV_RfN_dataFromXL : DATASET_RF_TV_OLV_RfN} name='RF_TV_OLV(n)'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='RF_TV_OLV' value='RF_TV_OLV' />
          <ExcelColumn label='RF' value='RF' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL : DATASET_TV_OPT_SPL} name='TV_OLV_Split'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='TV_OPT_SPL' value='TV_OPT_SPL' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[4] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[4]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[4] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[4]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[5] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[5]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[5] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[5]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[6] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[6]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[6] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[6]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[7] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[7]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[7] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[7]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[8] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[8]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[8] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[8]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[9] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[9]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[9] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[9]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[10] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[10]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[10] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[10]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[11] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[11]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[11] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[11]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[12] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[12]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[12] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[12]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[13] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[13]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[13] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[13]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[14] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[14]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[14] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[14]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[15] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[15]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[15] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[15]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[16] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[16]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[16] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[16]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[17] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[17]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[17] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[17]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[18] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[18]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[18] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[18]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[19] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[19]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[19] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[19]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[20] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[20]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[20] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[20]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[21] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[21]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[21] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[21]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[22] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[22]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[22] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[22]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[23] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[23]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[23] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[23]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[24] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[24]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[24] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[24]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[25] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[25]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[25] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[25]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[26] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[26]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[26] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[26]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[27] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[27]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[27] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[27]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[28] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[28]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[28] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[28]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[29] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[29]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[29] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[29]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[30] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[30]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[30] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[30]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[31] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[31]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[31] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[31]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[32] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[32]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[32] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[32]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[33] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[33]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[33] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[33]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[34] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[34]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[34] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[34]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[35] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[35]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[35] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[35]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[36] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[36]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[36] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[36]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[37] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[37]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[37] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[37]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[38] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[38]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[38] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[38]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[39] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[39]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[39] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[39]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[40] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[40]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[40] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[40]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[41] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[41]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[41] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[41]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[42] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[42]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[42] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[42]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[43] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[43]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[43] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[43]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[44] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[44]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[44] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[44]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[45] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[45]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[45] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[45]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[46] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[46]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[46] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[46]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[47] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[47]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[47] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[47]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[48] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[48]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[48] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[48]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[49] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[49]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[49] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[49]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[50] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[50]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[50] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[50]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[51] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[51]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[51] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[51]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[52] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[52]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[52] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[52]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[53] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[53]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[53] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[53]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[54] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[54]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[54] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[54]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[55] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[55]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[55] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[55]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[56] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[56]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[56] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[56]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[57] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[57]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[57] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[57]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[58] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[58]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[58] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[58]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[59] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[59]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[59] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[59]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[60] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[60]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[60] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[60]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[61] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[61]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[61] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[61]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[62] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[62]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[62] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[62]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[63] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[63]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[63] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[63]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[64] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[64]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[64] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[64]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[65] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[65]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[65] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[65]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[66] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[66]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[66] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[66]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[67] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[67]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[67] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[67]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[68] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[68]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[68] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[68]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[69] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[69]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[69] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[69]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[70] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[70]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[70] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[70]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[71] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[71]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[71] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[71]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[72] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[72]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[72] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[72]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[73] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[73]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[73] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[73]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[74] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[74]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[74] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[74]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[75] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[75]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[75] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[75]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[76] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[76]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[76] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[76]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[77] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[77]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[77] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[77]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[78] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[78]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[78] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[78]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[79] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[79]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[79] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[79]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[80] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[80]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[80] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[80]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[81] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[81]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[81] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[81]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[82] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[82]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[82] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[82]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[83] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[83]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[83] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[83]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[84] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[84]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[84] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[84]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[85] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[85]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[85] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[85]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[86] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[86]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[86] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[86]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[87] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[87]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[87] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[87]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[88] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[88]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[88] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[88]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[89] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[89]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[89] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[89]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[90] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[90]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[90] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[90]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[91] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[91]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[91] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[91]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[92] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[92]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[92] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[92]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[93] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[93]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[93] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[93]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[94] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[94]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[94] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[94]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[95] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[95]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[95] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[95]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[96] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[96]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[96] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[96]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[97] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[97]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[97] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[97]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[98] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[98]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[98] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[98]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[99] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[99]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[99] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[99]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[100] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[100]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[100] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[100]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[101] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[101]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[101] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[101]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[102] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[102]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[102] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[102]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[103] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[103]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[103] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[103]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[104] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[104]} value={flightsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[104] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[104]} />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL : DATASET_TV_OPT_DISTR} name='TV_OLV_Opt_split'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='TV_OPT_DISTR' value='TV_OPT_DISTR' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[0] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[0]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[0] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[0]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[1] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[1]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[1] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[1]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[2] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[2]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[2] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[2]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[3] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[3]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[3] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[3]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[4] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[4]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[4] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[4]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[5] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[5]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[5] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[5]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[6] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[6]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[6] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[6]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[7] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[7]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[7] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[7]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[8] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[8]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[8] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[8]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[9] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[9]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[9] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[9]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[10] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[10]} value={flightsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[10] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[10]} />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? DATASET_TV_INCREASE_dataFromXL : DATASET_TV_INCREASE} name='OLV_Add_TV'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='TV_INCREASE' value='TV_INCREASE' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[4] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[4]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[4] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[4]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[5] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[5]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[5] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[5]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[6] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[6]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[6] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[6]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[7] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[7]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[7] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[7]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[8] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[8]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[8] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[8]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[9] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[9]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[9] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[9]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[10] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[10]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[10] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[10]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[11] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[11]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[11] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[11]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[12] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[12]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[12] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[12]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[13] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[13]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[13] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[13]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[14] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[14]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[14] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[14]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[15] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[15]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[15] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[15]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[16] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[16]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[16] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[16]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[17] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[17]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[17] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[17]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[18] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[18]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[18] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[18]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[19] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[19]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[19] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[19]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[20] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[20]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[20] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[20]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[21] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[21]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[21] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[21]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[22] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[22]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[22] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[22]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[23] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[23]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[23] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[23]} />
          <ExcelColumn label={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[24] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[24]} value={flightsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[24] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[24]} />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetTA_dataFromXL : dataSetTA} name='Вводные данные'>
          <ExcelColumn label='flightNumber' value='flightNumber' />
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='gender' value='gender' />
          <ExcelColumn label='agemin' value='agemin' />
          <ExcelColumn label='agemax' value='agemax' />
          <ExcelColumn label='income' value='income' />
          <ExcelColumn label='geo' value='geo' />
          <ExcelColumn label='currentFrequency' value='currentFrequency' />
          <ExcelColumn label='period' value='period' />
          <ExcelColumn label='startDate' value='startDate' />
          <ExcelColumn label='finishDate' value='finishDate' />
          <ExcelColumn label='geoPopulation' value='geoPopulation' />
          <ExcelColumn label='ltv' value='ltv' />
          <ExcelColumn label='presenceOfTvFlight' value='presenceOfTvFlight' />
          <ExcelColumn label='isCBUUpload' value='isCBUUpload' />
          <ExcelColumn label='isOptimization' value='isOptimization' />
          <ExcelColumn label='isRestrictions' value='isRestrictions' />
          <ExcelColumn label='isSupplierBudgetLimits' value='isSupplierBudgetLimits' />
          <ExcelColumn label='freq' value='freq' />
          <ExcelColumn label='flightCPP' value='flightCPP' />
          <ExcelColumn label='flightBudgetTV' value='flightBudgetTV' />
          <ExcelColumn label='budget' value='budget' />
          <ExcelColumn label='share' value='share' />
          <ExcelColumn label='universe' value='universe' />
          <ExcelColumn label='impChangesNotPossible' value='impChangesNotPossible' />
          <ExcelColumn label='crmChangesNotPossible' value='crmChangesNotPossible' />
          <ExcelColumn label='bgtChangesNotPossible' value='bgtChangesNotPossible' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? DATASET_CBU_dataFromXL : DATASET_CBU} name='Cbu'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='TRP_Cbu' value='TRP_Cbu' />
          <ExcelColumn label='R1' value='R1' />
          <ExcelColumn label='R2' value='R2' />
          <ExcelColumn label='R3' value='R3' />
          <ExcelColumn label='R4' value='R4' />
          <ExcelColumn label='R5' value='R5' />
          <ExcelColumn label='R6' value='R6' />
          <ExcelColumn label='R7' value='R7' />
          <ExcelColumn label='R8' value='R8' />
          <ExcelColumn label='R9' value='R9' />
          <ExcelColumn label='R10' value='R10' />
          <ExcelColumn label='R11' value='R11' />
          <ExcelColumn label='R12' value='R12' />
          <ExcelColumn label='R13' value='R13' />
          <ExcelColumn label='R14' value='R14' />
          <ExcelColumn label='R15' value='R15' />
          <ExcelColumn label='R16' value='R16' />
          <ExcelColumn label='R17' value='R17' />
          <ExcelColumn label='R18' value='R18' />
          <ExcelColumn label='R19' value='R19' />
          <ExcelColumn label='R20' value='R20' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_Optimization_data_dataFromXL : dataSet_Optimization_data} name='optimizationData'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher_Opt' value='Publisher_Opt' />
          <ExcelColumn label='CPM_Opt' value='CPM_Opt' />
          <ExcelColumn label='Days_Opt' value='Days_Opt' />
          <ExcelColumn label='FrequencyLimit_Opt' value='FrequencyLimit_Opt' />
          <ExcelColumn label='Impressions_Opt' value='Impressions_Opt' />
          <ExcelColumn label='max_OTS_Opt' value='max_OTS_Opt' />
          <ExcelColumn label='targ_Opt' value='targ_Opt' />
          <ExcelColumn label='budget_Opt' value='budget_Opt' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_restrictions_dataFromXL : dataSet_restrictions} name='restrictions'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher_Restr' value='Publisher_Restr' />
          <ExcelColumn label='min' value='min' />
          <ExcelColumn label='max' value='max' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetTotal_dataFromXL : dataSetTotal} name='Total'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='total_Budget' value='total_Budget' />
          <ExcelColumn label='total_Impressions' value='total_Impressions' />
          <ExcelColumn label='total_CPM' value='total_CPM' />
          <ExcelColumn label='total_Tsa_thousand' value='total_Tsa_thousand' />
          <ExcelColumn label='total_Tsa_percent' value='total_Tsa_percent' />
          <ExcelColumn label='total_TRP' value='total_TRP' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetDonut_dataFromXL : dataSetDonut} name='Распределение бюджета'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Media1' value='name' />
          <ExcelColumn label='Budget' value='budget' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetRfNPlus_dataFromXL : dataSetRfNPlus} name='Распределение охвата(N+)'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='OLVReachN+' value='OLVReachN+' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetRfN_dataFromXL : dataSetRfN} name='Распределение охвата(N)'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='OLVReachN' value='OLVReachN' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_Input_OLV_Budgets_dataFromXL : dataSet_Input_OLV_Budgets} name='Input_Budgets'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='budget_OlvCbuCprpChart' value='budget_OlvCbuCprpChart' />
          <ExcelColumn label='budget_OLV_TvIncreaseAggregateCoverageChart' value='budget_OLV_TvIncreaseAggregateCoverageChart' />
          <ExcelColumn label='budget_TvDistributionChart' value='budget_TvDistributionChart' />
          <ExcelColumn label='budget_TV_TvIncreaseAggregateCoverageChart' value='budget_TV_TvIncreaseAggregateCoverageChart' />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};
