import React from 'react';
import s from './DelimiterOpt.module.css';
import redStarSmall from '../../../../assets/images/RedStarSmall.png';
import redLineSmall from '../../../../assets/images/RedLineSmall.png';

export const DelimiterOpt = React.memo(props => {
  const { title } = props;

  return (
    <div className={s.delimiterWrapper}>
      <div className={s.delimiterImgs}>
        <img src={redStarSmall} alt='redStarSmall' style={{ width: '55px', height: '55px', display: 'block' }} />
        <img src={redLineSmall} alt='redLineSmall' style={{ width: '95%', height: '4px' }} />
      </div>
      <div className={s.delimiterTitle}>{title}</div>
    </div>
  );
});
