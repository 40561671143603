import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../common/Select/Select';
import { setFlightsGenderArr, setFlightsInputDataChangedArr, setFlightsIntroductoryArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsGender = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsGenderArr = useSelector(state => state.flights.flightsGenderArr);
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight); 
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const convertGender = gender => {
    if (gender === 'WOMEN') {
      return 'Ж';
    } else if (gender === 'MEN') {
      return 'М';
    } else if (gender === 'ALL') {
      return 'М и Ж';
    }
  };
  const currentGender = currentGenderArr.gender.find(i => i.isChecked === true) ? convertGender(currentGenderArr.gender.find(i => i.isChecked === true).gender) : '';

  const findGender = selectItem => {
    if (selectItem === 'Ж') {
      return 'WOMEN';
    } else if (selectItem === 'М') {
      return 'MEN';
    } else if (selectItem === 'М и Ж') {
      return 'ALL';
    }
  };
  const handleOnChange = (selectedName, flight) => {
    const selectItemName = findGender(selectedName);
    const updatedStateCurrentGender = currentGenderArr.gender.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      return i.gender === selectItemName ? selectedItem : otherItem;
    });

    const updatedStateFlightsGenderArr = flightsGenderArr.map(item => {
      const newItem = { ...item };
      newItem.gender = updatedStateCurrentGender;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsGenderArr(updatedStateFlightsGenderArr));
     const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));

    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return (
    <Select isDisabled={isDisabled} name={flight} value={currentGender} onChange={handleOnChange} items={['М и Ж', 'Ж', 'М']} location={'severalFlights'} />
  );
});
