import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsTable.module.css';
import { FlightsPeriod } from '../FlightsInputParam/FlightsPeriod/FlightsPeriod';
import { Accordion } from '../common/Accordion/Accordion';
import { FlightsCPPValue } from '../FlightsInputParam/FlightsCPPValue/FlightsCPPValue';
import { FlightsBudgetTVValue } from '../FlightsInputParam/FlightsBudgetTVValue/FlightsBudgetTVValue';
import { FlightsLtvAudience } from '../FlightsInputParam/FlightsLtvAudience/FlightsLtvAudience';
import { FlightsPresenceOfTv } from '../FlightsInputParam/FlightsPresenceOfTv/FlightsPresenceOfTv'
import { FlightsGender } from '../FlightsInputParam/FlightsGender/FlightsGender';
import { FlightsAge } from '../FlightsInputParam/FlightsAge/FlightsAge';
import { FlightsIncome } from '../FlightsInputParam/FlightsIncome/FlightsIncome';
import { FlightsGeo } from '../FlightsInputParam/FlightsGeo/FlightsGeo';
import { FlightsDownloadCbu } from '../FlightsInputParam/FlightsDownloadCbu/FlightsDownloadCbu';
import { FlightsIntroductory } from '../FlightsInputParam/FlightsIntroductory/FlightsIntroductory';
import { FlightsFrequency } from '../FlightsInputParam/FlightsFrequency/FlightsFrequency';
import { StartButton } from '../common/StartButton/StartButton';
import { setNextValueFlightArr } from '../../../helpers/flightTable_helpers';
import { convertIncome, convertFlightsGeo } from '../../../helpers/common_helpers'
import { setFlightsPeriod, setFlightArr, setFlightsFrequencyArr, setFlightsInputDataChangedArr, setFlightsSomeInputDataChanged, setFlightsLtvArr, setFlightsPresenceOfTvArr, setFlightsGenderArr, setFlightsAgeArr, setFlightsIncomeArr, setFlightsGeoArr, setFlightsCbuArr, setFlightsIntroductoryArr, setFlightsTableParametersArr, setFlightsOptimizationArr } from '../../../bll/flights_reducer';
import add from '../../../assets/images/add.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export const FlightsTable = React.memo(props => {

  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod); 
  const flightsAgeArr = useSelector(state => state.flights.flightsAgeArr);
  const flightsIncomeArr = useSelector(state => state.flights.flightsIncomeArr);
  const flightsGeoArr = useSelector(state => state.flights.flightsGeoArr);
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsLtvArr = useSelector(state => state.flights.flightsLtvArr);
  const flightsGenderArr = useSelector(state => state.flights.flightsGenderArr);
  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);

  const dispatch = useDispatch();

  const addFlight = () => {
    const arrNum = flightArr.map(item => item.flight);
    const newFlightNumber = flightArr.length === 0 ? 1 : setNextValueFlightArr(arrNum);
    const newFlightId = Math.random();
    const newFlightArr = [...flightArr, { flight: newFlightNumber, id: newFlightId }];

    const newFlightsPeriod = [
      ...flightsPeriod,
      {
        flight: newFlightId,
        period: 0,
        startDate: 0,
        finishDate: 0,
      },
    ];
    const newFlightsFrequencyArr = [
      ...flightsFrequencyArr,
      {
        flight: newFlightId,
        frequency: '',
      },
    ];
    const newFlightsLtvArr = [
      ...flightsLtvArr,
      {
        flight: newFlightId,
        ltv: 0
      },
    ];
    const newFlightsPresenceOfTvArr = [
      ...flightsPresenceOfTvArr,
      {
        flight: newFlightId,
        presenceOfTvFlight: 0,
        flightCPP: 0,
        flightBudgetTV: 0
      },
    ];
    const newFlightsGenderArr  = [
      ...flightsGenderArr,
      {
        flight: newFlightId,
        gender: [
          { id: 1, isChecked: false, gender: 'WOMEN' },
          { id: 2, isChecked: false, gender: 'MEN' },
          { id: 3, isChecked: true, gender: 'ALL' },
        ],
      },
    ];
    const newflightsAgeArr = [
      ...flightsAgeArr,
      {
        flight: newFlightId,
        agemin: 18,
        agemax: 45,
      },
    ];
    const newFlightsIncomeArr = [
      ...flightsIncomeArr,
      {
        flight: newFlightId,
        income: [
          { id: 1, isChecked: false, income: 'A' },
          { id: 2, isChecked: true, income: 'B' },
          { id: 3, isChecked: true, income: 'C' },
        ],
      },
    ];
    const newFlightsGeoArr= [
      ...flightsGeoArr,
      {
        flight: newFlightId,
        geo: [
          { id: 1, name: 'NAT', isChecked: true, geo: 'NAT', population: 146171015 },
          { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow', population: 12655050 },
          { id: 3, name: 'Petersburg', isChecked: false, geo: 'Petersburg', population: 5384342 },
          { id: 4, name: 'cityХ', isChecked: false, geo: 'cityХ', population: 0 },
        ],
      },
    ];
    const newFlightsCbuArr= [
      ...flightsCbuArr,
      {
        flight: newFlightId,
        isCBUUpload: 0,
        cbu: null
      },
    ];
    const newFlightsIntroductoryArr= [
      ...flightsIntroductoryArr,
      {
        flight: newFlightId,
        introductory: 0
      },
    ];
    const newFlightsTableParametersArr= [
      ...flightsTableParametersArr,
      {
        flight: newFlightId,
        publishers: [],
        impChangesNotPossible: 0,
        bgtChangesNotPossible: 0,
        crmChangesNotPossible: 1,
        isCalculated: false,
        universe: 0,
        share: 0,
        total: [{ total_Budget: 0, total_Impressions: 0, total_CPM: 0, total_Tsa_thousand: 0, total_Tsa_percent: 0, total_TRP: 0 }],
      },
    ];
    const newFlightsOptimizationArr= [
      ...flightsOptimizationArr,
      {
        flight: newFlightId,
        budget: 10000000,
        freq: '1+',
        restrictions: [{ Publisher: '', min: null, max: null }],
        workers: -1,
        isSupplierBudgetLimits: 0,
        optimizationData: {},
        isOptimization: -1,
      },
    ];
    const newFlightsInputDataChangedArr= [
      ...flightsInputDataChangedArr,
      {
        flight: newFlightId,
        flightsInputDataChanged: true
      },
    ];
    dispatch(setFlightArr(newFlightArr));
    dispatch(setFlightsPeriod(newFlightsPeriod));
    dispatch(setFlightsFrequencyArr(newFlightsFrequencyArr));
    dispatch(setFlightsLtvArr(newFlightsLtvArr));
    dispatch(setFlightsPresenceOfTvArr(newFlightsPresenceOfTvArr));
    dispatch(setFlightsGenderArr(newFlightsGenderArr));
    dispatch(setFlightsAgeArr(newflightsAgeArr));
    dispatch(setFlightsIncomeArr(newFlightsIncomeArr));
    dispatch(setFlightsGeoArr(newFlightsGeoArr));
    dispatch(setFlightsCbuArr(newFlightsCbuArr));
    dispatch(setFlightsIntroductoryArr(newFlightsIntroductoryArr));
    dispatch(setFlightsTableParametersArr(newFlightsTableParametersArr));
    dispatch(setFlightsOptimizationArr(newFlightsOptimizationArr));
    dispatch(setFlightsInputDataChangedArr(newFlightsInputDataChangedArr))
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  const deleteFlight = (flight, flightId) => {
    const newFlightArr = flightArr.filter(n => n.flight !== flight);
    const indexArr = [];
    for (let i = 1; i < newFlightArr.length + 1; i++) {
      indexArr.push(i);
    }
    newFlightArr.map((item, i) => {
      item.flight = indexArr[i];
      return item;
    });
    const newFlightsPeriod = flightsPeriod.filter(i => i.flight !== flightId);
    const newFlightsFrequencyArr = flightsFrequencyArr.filter(i => i.flight !== flightId); 
    const newFlightsLtvArr = flightsLtvArr.filter(i => i.flight !== flightId)
    const newFlightsPresenceOfTvArr = flightsPresenceOfTvArr.filter(i => i.flight !== flightId)
    const newFlightsGenderArr  = flightsGenderArr.filter(i => i.flight !== flightId)
    const newflightsAgeArr = flightsAgeArr.filter(i => i.flight !== flightId)
    const newFlightsIncomeArr = flightsIncomeArr.filter(i => i.flight !== flightId)
    const newFlightsGeoArr= flightsGeoArr.filter(i => i.flight !== flightId)
    const newFlightsCbuArr= flightsCbuArr.filter(i => i.flight !== flightId)
    const newFlightsIntroductoryArr= flightsIntroductoryArr.filter(i => i.flight !== flightId)
    const newFlightsTableParametersArr= flightsTableParametersArr.filter(i => i.flight !== flightId)
    const newFlightsOptimizationArr= flightsOptimizationArr.filter(i => i.flight !== flightId)
    const newFlightsInputDataChangedArr= flightsInputDataChangedArr.filter(i => i.flight !== flightId)
    dispatch(setFlightArr(newFlightArr));
    dispatch(setFlightsPeriod(newFlightsPeriod));
    dispatch(setFlightsFrequencyArr(newFlightsFrequencyArr));
    dispatch(setFlightsLtvArr(newFlightsLtvArr));
    dispatch(setFlightsPresenceOfTvArr(newFlightsPresenceOfTvArr));
    dispatch(setFlightsGenderArr(newFlightsGenderArr));
    dispatch(setFlightsAgeArr(newflightsAgeArr));
    dispatch(setFlightsIncomeArr(newFlightsIncomeArr));
    dispatch(setFlightsGeoArr(newFlightsGeoArr));
    dispatch(setFlightsCbuArr(newFlightsCbuArr));
    dispatch(setFlightsIntroductoryArr(newFlightsIntroductoryArr));
    dispatch(setFlightsTableParametersArr(newFlightsTableParametersArr));
    dispatch(setFlightsOptimizationArr(newFlightsOptimizationArr));
    dispatch(setFlightsInputDataChangedArr(newFlightsInputDataChangedArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        <div className={s.title}>ПЕРИОД</div>
        <div className={s.title}>ПОЛ</div>
        <div className={s.title}>ВОЗРАСТ</div>
        <div className={s.title}>ДОХОД & LTV</div>
        <div className={s.title}>ГЕО</div>
        <div className={s.title}>
          <div>ВВОДНЫЕ</div> <div>OLV</div>
        </div>
         <div className={s.title}>
          <div>ЧАСТОТА</div> <div>НА ФЛАЙТ</div>
        </div>
        <div className={s.title}>
          <div>НАЛИЧИЕ ТВ</div> <div> & CBU</div>
        </div>  
        <div className={s.title}>
          <div>БЮДЖЕТ ТВ</div> <div> & CPP TA</div>
        </div>      
      </div>
      <div className={s.addBtnWrapper}>
      <StartButton onClick={addFlight} view={'add'}>
        <div className={s.icon}>
          <img src={add} alt='add' style={{ width: '20px', height: '20px' }} />
        </div>
        <div className={s.titleBtn}>
          <div>Добавить </div>
          <div> флайт </div>
        </div>
      </StartButton>
    </div>
      <div className={s.blockContent}>
        {flightArr.map(flight => {
          const currentPeriod = flightsPeriod.find(item => item.flight === flight.id);

          const currentAge = flightsAgeArr.find(item => item.flight === flight.id);
          const currentAgemin = currentAge.agemin
          const currentAgemax = currentAge.agemax
          const currentLtv = flightsLtvArr.find(item => item.flight === flight.id).ltv;
          const ltvPOST = currentLtv ===1 ? '+ LTV' : ''

          const currentIncome = `${convertIncome(flightsIncomeArr.find(item => item.flight === flight.id).income)} ${ltvPOST}`;
          const currentGeo = flightsGeoArr.find(item => item.flight === flight.id);
          const geoPOST = convertFlightsGeo(currentGeo.geo.find(item=> item.isChecked===true).geo, currentGeo)

          const currentIsCBUUpload = flightsCbuArr.find(item => item.flight === flight.id).isCBUUpload;
          const currentPresenceOfTvFlight = flightsPresenceOfTvArr.find(item => item.flight === flight.id).presenceOfTvFlight;
          const currentFlightBudgetTV = flightsPresenceOfTvArr.find(item => item.flight === flight.id).flightBudgetTV;
          const currentFlightCPP = flightsPresenceOfTvArr.find(item => item.flight === flight.id).flightCPP;

          const findCBUSelectValue = () => {
            if (currentPresenceOfTvFlight===0) {
              return 'Нет'
            } else if (currentPresenceOfTvFlight===1 && currentIsCBUUpload === 1 ) {
              return 'Да'
            } else if (currentPresenceOfTvFlight===1 && currentIsCBUUpload === 0) {
              return ''
            }
          }
          const findCPPSelectValue = () => {
            if (currentPresenceOfTvFlight===0) {
              return 'Не нужны'
            } else if (currentPresenceOfTvFlight===1 && (currentFlightBudgetTV === 0 || currentFlightCPP === 0) ) {
              return ''
            } else if (currentPresenceOfTvFlight===1 && currentFlightBudgetTV !== 0 || currentFlightCPP !== 0) {
              return 'Указаны'
            }
          }
          const currentFlightsIntroductory= flightsIntroductoryArr.find(item => item.flight === flight.id).introductory;
          return (
            <div className={s.flightBlock} key={flight.id}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={s.flight}>ФЛАЙТ {flight.flight}</div>
                <button
                  className={s.deleteBtn}
                  onClick={() => {
                    deleteFlight(flight.flight, flight.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size='2x' style={{ color: '#c4c4c4' }} />
                </button>
              </div>
              <div className={s.selectBlock}>                
                <div>
                  <Accordion flight={flight.id} isDisabled={false} selectValue={currentPeriod.startDate === 0 ? '' :`${moment.utc(currentPeriod.startDate).format('DD.MM')}-${moment.utc(currentPeriod.finishDate).format('DD.MM')}`}>
                    <FlightsPeriod isDisabled={false} flight={flight.id} />
                  </Accordion>
                </div>                
                 <div>                  
                  <FlightsGender flight={flight.id} isDisabled={false} />                
                </div>
                <div>
                  <Accordion flight={flight.id} isDisabled={false} selectValue={`${currentAgemin}- ${currentAgemax}`}>
                    <FlightsAge flight={flight.id} />
                  </Accordion>
                </div>
                <div>
                  <Accordion flight={flight.id} isDisabled={false} selectValue={currentIncome}>
                    <FlightsIncome flight={flight.id} />
                    <FlightsLtvAudience flight={flight.id} isDisabled={false} />    
                  </Accordion>
                </div>
                <div>
                  <Accordion flight={flight.id} isDisabled={false} selectValue={geoPOST}>
                    <FlightsGeo flight={flight.id} />
                  </Accordion>
                </div>
                <div>
                  <Accordion flight={flight.id} isDisabled={false} selectValue={currentFlightsIntroductory === 1 ? 'Указаны' : ''}>
                    <FlightsIntroductory flight={flight.id} />
                  </Accordion>
                </div>     
               <div>
                  <FlightsFrequency isDisabled={false} flight={flight.id} />
                </div>
                <div>     
                  <Accordion flight={flight.id} isDisabled={false} selectValue={findCBUSelectValue()}>
                    <FlightsPresenceOfTv flight={flight.id} isDisabled={false} /> 
                    <FlightsDownloadCbu flight={flight.id} />
                  </Accordion>                                     
                </div>  
                <div>
                  <Accordion flight={flight.id} isDisabled={currentIsCBUUpload === 0 ? true : false} selectValue={findCPPSelectValue()}>
                    <div className={s.flightCPPWrapper}>
                      <div>
                         CPP TA
                          <FlightsCPPValue isDisabled={currentFlightCPP !==0 ? false : true} flight={flight.id}/>
                      </div>   
                      <div>
                         Бюджет ТВ
                         <FlightsBudgetTVValue isDisabled={currentFlightCPP !==0 ? false : true} flight={flight.id} />  
                      </div>
                                       
                    </div>                   
                  </Accordion>
                </div>             
                           
              </div>
            </div>
          );
        })}      
      </div>
    </div>
  );
});
