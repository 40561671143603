import React from 'react';
import s from './FlightsLtvAudience.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { setFlightsLtvArr, setFlightsInputDataChangedArr, setFlightsSomeInputDataChanged, setFlightsIntroductoryArr } from '../../../../bll/flights_reducer';

export const FlightsLtvAudience = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();
  const flightsLtvArr = useSelector(state => state.flights.flightsLtvArr);
  const currentLtv= flightsLtvArr.find(item => item.flight === flight);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const handleOnChange = (e, flight) => {
    const updatedStateflightsLtvArr = flightsLtvArr.map(item => {
      const newItem = { ...item };
      newItem.ltv =  e.currentTarget.checked === true ? 1 : 0
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsLtvArr(updatedStateflightsLtvArr));
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    dispatch(setFlightsSomeInputDataChanged(true))
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
  };


  return (
    <div className={s.checkboxWrapper}>
    <Checkbox
      onChangeChecked={(e) => {
        handleOnChange(e, flight);
      }}
      checked={currentLtv.ltv === 1 ? true : false}
      label={'LTV'}
      id={'LTV'}
    />
  </div>   
  );
});
