import React from 'react';
import pptxgen from 'pptxgenjs';
import moment from 'moment';
import colorStar from '../../../assets/images/Picture1.png';
import { findValueView } from '../../../helpers/common_helpers';
import { findFlightByPeriod } from '../../../helpers/flightTable_helpers';

export const ExportPPTFlow = React.memo(props => {
  const { selectedMediaAllFlightsWithCurrentNumber, currentMediaNumberArr, flightsTableParametersArr, flightsChartsData, flightsPeriod, flightsFrequencyArr, flightArr } = props;

  let pptx = new pptxgen();
  let slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  let arrRows = [];
  let flightsRows = []

  arrRows.push([
    { text: 'Площадка', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true } },
    { text: 'Янв.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Фев.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Мар.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Апр.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Май', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Июн.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Июл.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Авг.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Сен.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Окт.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Ноя.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Дек.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Бюджет', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true } },
  ]);

  selectedMediaAllFlightsWithCurrentNumber &&
  selectedMediaAllFlightsWithCurrentNumber.map((item, i) => {
      const findBudgetValueByMonth = media => {
          const monthBudgetsArr = [];
          flightsChartsData.map(chartData => {
            const indDon = chartData.olvDonutData.labels.indexOf(media);
            const valBudg = indDon > -1 ? chartData.olvDonutData.datasets[0].data[indDon] : null;
            monthBudgetsArr.push(valBudg); 

            return chartData;
          });

          const sumMonthBudgetsArr = monthBudgetsArr.reduce(function (accumulator, currentValue) {
            return accumulator + +currentValue;
          }, 0);
          return `${findValueView(sumMonthBudgetsArr, 'roundBudget')} ₽\n ${media}`;
        }

        const findMonthValue = (media, month) => {
          const flightsAndPeriodsArr = findFlightByPeriod(flightsPeriod, flightsTableParametersArr, flightArr);

          const findFlightArr = [];
          flightsAndPeriodsArr.sort((a, b) => a.currentFlightNumber -b.currentFlightNumber).map(f => {
            const currentMediaNumber = currentMediaNumberArr.find(fl=> fl.currentFlightNumber===f.currentFlightNumber && fl.media===media) && currentMediaNumberArr.find(fl=> fl.currentFlightNumber===f.currentFlightNumber && fl.media===media ).currentNumber                                    
            const findMonth = f.monthArr.find(ma => ma === month);
            const findMedia = f.mediaArr.find(me => me.Publisher === media);
            (findMonth && findMedia )? findFlightArr.splice(currentMediaNumber-1, 0, f.flight) :  findFlightArr.push(0) 
            return f;
          });
          const currentValueArr = findFlightArr.map(flight => {
            if(flight === 0 ) {
              return ''
            } else {
            const currentFlightNumber = flightArr.find(fl => fl.id === flight) && flightArr.find(fl => fl.id === flight).flight;
            const currentFrequency = flightsFrequencyArr.find(frequency => frequency.flight === flight).frequency;
            const currentFlightChartsData = flightsChartsData.find(chartsData => chartsData.flight === flight);
            const indContr = currentFlightChartsData && currentFlightChartsData.olvContributionOfEachMedia_data.labels.indexOf(media);
            const valReach = indContr > -1 ? currentFlightChartsData && currentFlightChartsData.olvContributionOfEachMedia_data.datasets[0].data[indContr] : null
            const indDon = currentFlightChartsData && currentFlightChartsData.olvDonutData.labels.indexOf(media);
            const valBudg = indDon > -1 ? currentFlightChartsData && currentFlightChartsData.olvDonutData.datasets[0].data[indDon] : null;
          
            return findFlightArr.length !== 0 && `ФЛАЙТ ${currentFlightNumber}\n Reach ${findValueView(valReach, 'roundReach')} %,\n @f ${currentFrequency},\n ${findValueView(valBudg, 'roundBudget')} ₽` ;
            }
          });
          // const findCurrentValue = currentValueArr.find(cv=> cv.indexOf(`ФЛАЙТ ${item.number}`)>-1)
          // return findCurrentValue;
          return currentValueArr[item.currentNumber - 1]
      };

      const arr = [
        { text: item.Publisher, options: { fill: 'e3e9ec', fontSize: 8, rowspan: 1 } },
        { text: findMonthValue(item.Publisher, 'Январь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Январь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Январь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Январь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Февраль1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Февраль2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Февраль3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Февраль4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Март1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Март2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Март3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Март4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Апрель1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Апрель2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Апрель3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Апрель4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Май1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Май2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Май3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Май4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Июнь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Июнь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Июнь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Июнь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Июль1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Июль2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Июль3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Июль4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Август1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Август2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Август3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Август4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Сентябрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Сентябрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Сентябрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Сентябрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Октябрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Октябрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Октябрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Октябрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Ноябрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Ноябрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Ноябрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Ноябрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findMonthValue(item.Publisher, 'Декабрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Декабрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Декабрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },
        { text: findMonthValue(item.Publisher, 'Декабрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff',  margin:[3,4.5,3,4.5] } },

        { text: findBudgetValueByMonth(item.Publisher), options: { colspan: 1, fill: '738fa2', fontSize: 8, rowspan: 1 },
      }
      ];
      const findnewArr = () => {
        let newArr = [...arr];
          const  rowWithZeroBudget = arr.find(a => a.text && a.text.indexOf(',\n 0 ₽') > -1)

        for (var b = 0; b < arr.length; b++) {
          if (arr[b].text && arr.filter(l => l.text === arr[b].text).length > 1) {
            const r = arr[b].text !== '' && arr.filter(l => l.text === arr[b].text);
            arr.find(a => a.text === r[0].text).options.colspan = r.length;
            for (var c = 1; c < r.length; c++) {
              newArr = newArr.filter(ar => ar !== r[c]);
            }
          }
        }    
        const emptyRowArr =[]    
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].text && arr[n].text !== '') {
            arr[n].options.fill = 'd1e2ed';
          }
        }
        return [newArr,emptyRowArr,rowWithZeroBudget] ;
      };
      !findnewArr()[2] && arrRows.push(findnewArr()[0]);      
      return item;
    });
  slide.addText(`VideoTron`, { x: '11%', y: '5%', w: 5.5, h: 0.75, fontSize: 14 });
  slide.addImage({ y: '8%', w: '100%', h: '8%', data: colorStar });
  slide.addText(`Флоу для спланированных флайтов на год`, { x: 2, y: -0.5, w: 9, h: 3.2, color: '#080808', fontSize: 23, bold: true });

  flightsRows.push(flightArr.map((flight, i) => {    
    const startDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).startDate;
    const finishDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).finishDate;
    const frequency = flightsFrequencyArr.find(item => item.flight === flight.id) && flightsFrequencyArr.find(item => item.flight === flight.id).frequency;
    const budgetPOST =
      flightsChartsData.find(item => item.flight === flight.id) &&
      flightsChartsData
        .find(item => item.flight === flight.id)
        .olvDonutData.datasets[0].data.reduce(function (a, b) {
          return +a + +b;
        });
    const coveragePOST = flightsChartsData.find(item => item.flight === flight.id) && 
    flightsChartsData.find(item => item.flight === flight.id).olvContributionOfEachMedia_data.datasets[0]
    .data.reduce(function (a, b) {
      return +a + +b;
    }); 
  return  { text: `ФЛАЙТ ${flight.flight}\n ${moment.utc(startDate).format('DD.MM')}-${moment.utc(finishDate).format('DD.MM')}\n Reach ${findValueView(coveragePOST, 'roundReach')} % @f ${frequency}\n ${findValueView(budgetPOST, 'roundBudget')} ₽`,
   options: { valign: 'middle', fontSize: 8, bold: true }}  
}))  

  slide.addTable(flightsRows, {
    x: 0.5,
    y: 1.5,
    w: 9,   
    border: { color: 'CFCFCF' },
    autoPage: true,
    autoPageRepeatHeader: true,
    verbose: false,
  });


  slide.addTable(arrRows, {
    x: 0.5,
    y: 2.5,
    w: 9,
    colW: [1, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.7],  
    border: { color: 'CFCFCF' },
    autoPage: true,    
    autoPageRepeatHeader: true,
    autoPageLineWeight: 0.5,
    autoPageCharWeight: -1.5,
    verbose: true,
  });

  pptx.writeFile({ fileName: 'videotron_flow.pptx' });

  return <div></div>;
});
