import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import s from './HomePage.module.css';
import { Header } from '../../components/Header/Header';
import { Step } from '../../components/Step/Step';
import { StartButton } from '../../../ui/components/common/StartButton/StartButton';
import { DownloadButton } from '../../components/common/DownloadButton/DownloadButton';
import { useHistory } from 'react-router-dom';
import newPr from '../../../assets/images/newPr.png';
import edit from '../../../assets/images/edit.png';
import pres from '../../../assets/images/pres.png';
import arrow from '../../../assets/images/arrow.png';
import { setDataFromXL, setIsChartsUpload, updateCharts } from '../../../bll/charts_reducer';
import { updateFlightsCharts, setDataFromXL_Flights, setFlightsUpload } from '../../../bll/flights_charts_reducer';
import { setError } from '../../../bll/app_reducer';
import * as XLSX from 'xlsx';
import { getStartIp } from '../../../bll/app_reducer';
import Presentation from '../../../assets/files/VideoTron.pptx';

export const HomePage = React.memo(() => {
  const [downloadBtnsVisible, setDownloadBtnsVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClickStartButton = () => {
    // history.push('/step2');
    history.push('/step0');
  };
  const fileRef = React.createRef();
  const fileRefFlights = React.createRef();

  const onClickUploadProject = () => {
    fileRef.current.click();
  };
  const onClickUploadProjectFlights = () => {
    fileRefFlights.current.click();
  };
  const onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }

        dispatch(setDataFromXL(data));
        dispatch(updateCharts(data));
        dispatch(setIsChartsUpload(true));
        history.push('/step4');
      } catch (error) {
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };
  const onImportExcelFlights = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }
        dispatch(updateFlightsCharts(data));
        dispatch(setDataFromXL_Flights(data));
        dispatch(setFlightsUpload(true));

        history.push('/flightsStep4');
      } catch (e) {}
    };
    fileReader.readAsBinaryString(files[0]);
  };
  useEffect(() => {
    dispatch(getStartIp(history));
  }, []);
  return (
    <div className={s.homeWrapper}>
      <Header />
      <div className={s.howItWorksBlock}>
        <h1>Как это работает</h1>
        <div className={s.steps}>
          <Step stepNumber={1} stepTitle={'Указываем вводные параметры'} stepDescription={'Система предложит задать\n настройки всех параметров,\n которые будут учитываться в\n расчетах'} />
          <Step stepNumber={2} stepTitle={'Отмечаем наличие или отсутствие TV флайта'} stepDescription={'Вы можете загрузить файл CBU\n для TV флайта, если необходимо\n рассчитать совокупные данные\n для TV и OLV'} />
          <Step stepNumber={3} stepTitle={'Получаем рекомендации'} stepDescription={'На базе вводных о ЦА и\n параметрах флайта\n производится расчет\n показателей'} />
        </div>
      </div>
      <div className={s.startNewProjectBtnWrapper}>
        <a className={s.link} href={Presentation} download='VideoTron.pptx' style={{ marginRight: '50px' }}>
          <StartButton onClick={() => {}}>
            <div className={s.icon}>
              <img src={pres} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div className={s.redTitle}>Скачать</div>
              <div>
                презентацию <br /> о софте
              </div>
            </div>
          </StartButton>
        </a>

        <div style={{ marginRight: '50px' }}>
          <StartButton onClick={onClickStartButton}>
            <div className={s.icon}>
              <img src={newPr} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div className={s.redTitle}>Создать</div>
              <div>новый проект</div>
            </div>
          </StartButton>
        </div>
        <div className={s.downloadBtnWrapper}>
          <StartButton
            //  onClick={onClickUploadProject}
            onClick={() => {
              setDownloadBtnsVisible(prevState => !prevState);
            }}
          >
            <div className={s.icon}>
              <img src={edit} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div className={s.redTitle}>Загрузить</div>
              <div>сохраненный проект</div>
            </div>
          </StartButton>
          <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
          <input type='file' accept='.xlsx, .xls' onChange={onImportExcelFlights} ref={fileRefFlights} style={{ display: 'none' }} />
          {downloadBtnsVisible && (
            <div className={s.downloadBtnsBlock}>
              <div className={s.forSeveralFlightsBtn}>
                <DownloadButton onClick={onClickUploadProjectFlights} title={'buttonTitle'}>
                  <div>
                    <div>
                      Медиа микс <span className={s.redTitle}>для нескольких</span>
                    </div>
                    <div>
                      <span className={s.redTitle}>флайтов</span> в течение одного года
                    </div>
                  </div>
                  <img src={arrow} alt='download' style={{ width: '20px', height: '40px', marginLeft: '10px' }} />
                </DownloadButton>
              </div>

              <div className={s.forOneFlightBtn}>
                <DownloadButton onClick={onClickUploadProject} title={'buttonTitle'}>
                  <div>
                    <div>
                      Медиа микс <span className={s.redTitle}>для одного флайта</span>
                    </div>
                  </div>
                  <img src={arrow} alt='download' style={{ width: '20px', height: '40px', marginLeft: '10px' }} />
                </DownloadButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
