import React, { useState, useEffect } from 'react';
import './FlightsPeriod.css';
import { useDispatch, useSelector } from 'react-redux';
import { setFlightsPeriod, setFlightsSomeInputDataChanged, setFlightsIntroductoryArr, setFlightsInputDataChangedArr  } from '../../../../bll/flights_reducer';
import { numberOfDaysBetweenDates } from '../../../../helpers/flightTable_helpers';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const FlightsPeriod = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod);
  const currentPeriod = flightsPeriod.find(item => item.flight === flight);
  const [value, setValue] = useState(null);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const onChangeDate = date => {
    setValue(date);
    const days = numberOfDaysBetweenDates(new Date(date[0]), new Date(date[1]));
    const updatedStateFlightsPeriod = flightsPeriod.map(item => {
      const newItem = { ...item };
      newItem.period = +days;
      newItem.startDate = String(date[0]);
      newItem.finishDate = String(date[1]);
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsPeriod(updatedStateFlightsPeriod));
    dispatch(setFlightsSomeInputDataChanged(true))
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
  };
  useEffect(() => {
    currentPeriod.startDate !== 0 && setValue([new Date(currentPeriod.startDate), new Date(currentPeriod.finishDate)]);
  }, [currentPeriod.finishDate, currentPeriod.startDate, flightsPeriod]);
  return <Calendar onChange={onChangeDate} value={value} returnValue={'range'} selectRange={true} next2Label={''} prev2Label={''} />;
});
