import React from 'react';
import s from './Delimiter.module.css';
import redStarSmall from '../../../../assets/images/RedStarSmall.png';
import redLineSmall from '../../../../assets/images/RedLineSmall.png';

export const Delimiter = React.memo(props => {
  const { title } = props;

  return (
    <div className={s.delimiterWrapper}>
      <img src={redStarSmall} alt='redStarSmall' style={{ width: '55px', height: '55px', display: 'block' }} />
      <div className={s.delimiterTitle}>{title}</div>
      <img src={redLineSmall} alt='redLineSmall' style={{ width: '50%', height: '4px' }} />
    </div>
  );
});
