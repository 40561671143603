import React, { useState } from 'react';
import s from './EditableSpan.module.css';
import { findValueView, round } from '../../../../helpers/common_helpers';

export const EditableSpan = React.memo(props => {
  const { value, onChange, name, variable, location, borderTitle } = props;
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(value);
  const activateEditMode = () => {
    setEditMode(true);
    setTitle(round(value));
  };
  const disactivateEditMode = () => {
    setEditMode(false);
    onChange(title, name, variable);
  };
  const onChangeHandler = (e, prev) => {
    setTitle(+e.currentTarget.value);
  };

  const findSpanClassName = () => {
    if (location === 'Cpp') {
      return s.spanCpp;
    } else if (location === 'PopulationCityX') {
      return s.spanPopulationCityX;
    } else if (location === 'budget') {
      return s.spanBudget;
    } else if (location === 'totalOptBudget') {
      return s.spanTotalOptBudget;
    } else if (location === 'optimizationTable') {
      return s.spanOptBudgetLimits;
    } else {
      return s.span;
    }
  };
  const findInputClassName = () => {
    if (location === 'Cpp') {
      return s.inputCpp;
    } else if (location === 'PopulationCityX') {
      return s.inputPopulationCityX;
    } else if (location === 'budget') {
      return s.inputBudget;
    } else if (location === 'totalOptBudget') {
      return s.inputTotalOptBudget;
    } else if (location === 'optimizationTable') {
      return s.inputOptBudgetLimits;
    } else {
      return s.input;
    }
  };
  return editMode ? (
    <div className={s.inputWrapper}>
      <input name={name} type='number' min='0' step='1' onChange={onChangeHandler} autoFocus onFocus={e => e.currentTarget.select()} onBlur={disactivateEditMode} value={title} className={findInputClassName()} />
      {borderTitle && <div className={s.blockTitle}>{borderTitle}</div>}
    </div>
  ) : (
    <div className={s.inputWrapper}>
      <span style={{ cursor: 'pointer' }} onClick={activateEditMode} className={findSpanClassName()}>
        {findValueView(value, variable)}
      </span>
      {borderTitle && <div className={s.blockTitle}>{borderTitle}</div>}
    </div>
  );
});
