import React, { useEffect } from 'react';
import s from './TvOptimalDistributionChart.module.css';
import { Line } from 'react-chartjs-2';
import { round, getFreqNumber } from '../../../../helpers/chart_helpers';
import { setMillion } from '../../../../helpers/common_helpers';

export const TvOptimalDistributionChart = React.memo(props => {
  const { chartsData, tvOptimalDistributionChart_data, freqAll, dataFromXL, isChartsUpload } = props;

  const takeDataForTooltip_dataFromXL = () => {
    const freqPost = getFreqNumber(freqAll);
    const TV_OPT_DISTR_DataFromXL = dataFromXL.filter(item => item.TV_OPT_DISTR && item.Freq === freqPost);
    const TV_OPT_DISTR_olv_bud_DataFromXL = TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'olv_bud')[0];
    const TV_OPT_DISTR_tv_bud_DataFromXL = TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv_bud')[0];
    const TV_OPT_DISTR_full_bud_DataFromXL = TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'full_bud')[0];
    return [TV_OPT_DISTR_olv_bud_DataFromXL, TV_OPT_DISTR_tv_bud_DataFromXL, TV_OPT_DISTR_full_bud_DataFromXL];
  };

  const takeDataForTooltip_chartsData = () => {
    const freqPost = getFreqNumber(freqAll);
    const TV_OPT_DISTR = chartsData.tv_olv_data.opt_bud[freqPost];
    const TV_OPT_DISTR_olv_bud = TV_OPT_DISTR.olv_bud;
    const TV_OPT_DISTR_tv_bud = TV_OPT_DISTR.tv_bud;
    const TV_OPT_DISTR_full_bud = TV_OPT_DISTR.full_bud;
    return [TV_OPT_DISTR_olv_bud, TV_OPT_DISTR_tv_bud, TV_OPT_DISTR_full_bud];
  };

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        boxHeight: 1,
        // bodyAlign: 'right',
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
          title: function (TooltipItem) {
            return '';
          },
          afterBody: function (TooltipItem) {
            const labelsArr = isChartsUpload ? Object.keys(takeDataForTooltip_dataFromXL()[0]) : Object.keys(takeDataForTooltip_chartsData()[0]);
            const currentLabelInd = tvOptimalDistributionChart_data.labels.indexOf(String(TooltipItem[0].label));
            const currentValue = labelsArr[currentLabelInd];
            const findCurrentValue_ChartsData = labelsArr.find(item => item.indexOf(currentValue) > -1);
            const findCurrentValue_dataFromXL = labelsArr.find(item => item.indexOf(currentValue) > -1);
            return [`Опт. бюджет OLV: ${isChartsUpload ? setMillion(takeDataForTooltip_dataFromXL()[0][findCurrentValue_dataFromXL]) : setMillion(takeDataForTooltip_chartsData()[0][findCurrentValue_ChartsData])} млн`, `Опт. бюджет TV: ${isChartsUpload ? setMillion(takeDataForTooltip_dataFromXL()[1][findCurrentValue_dataFromXL]) : setMillion(takeDataForTooltip_chartsData()[1][findCurrentValue_ChartsData])} млн`, `Общий бюджет: ${isChartsUpload ? setMillion(takeDataForTooltip_dataFromXL()[2][findCurrentValue_dataFromXL]) : setMillion(takeDataForTooltip_chartsData()[2][findCurrentValue_ChartsData])} млн`];
          },
        },
      },
    },

    scales: {
      y: {
        title: {
          display: true,
          align: 'start',
          text: 'Reach %',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },

        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
      x: {
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет (млн. руб)',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },
    },
  };
  useEffect(() => {
    tvOptimalDistributionChart_data.datasets && tvOptimalDistributionChart_data.datasets.map(item => (document.getElementById(`${item.label}+4`).style.backgroundColor = item.borderColor));
  }, [tvOptimalDistributionChart_data]);

  return (
    <div>
      <div style={{ position: 'relative' }} className={s.wrapper}>
        <Line width={300} height={220} data={tvOptimalDistributionChart_data} options={options} />
      </div>

      <div id='4' className={`${s.legendWrapperNew} ${s.audioLegend}`}>
        <div id='OLV+4' className={s.legend}></div>
      </div>
      <div id='5' className={`${s.legendWrapperNew} ${s.radioLegend}`}>
        <div id='TV+4' className={s.legend}></div>
      </div>
      <div id='6' className={`${s.legendWrapperNew} ${s.audioRadioLegend}`}>
        <div id='TV + OLV+4' className={s.legend}></div>
      </div>
    </div>
  );
});
