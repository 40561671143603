import { API } from '../dal/api';
import { getFreqNumber } from '../helpers/chart_helpers';
import { setStatus, setError } from './app_reducer';

const SET_BUDGET = 'SET_BUDGET';
const SET_FREQ = 'SET_FREQ';
const SET_RESTRICTIONS = 'SET_RESTRICTIONS';
const SET_WORKERS = 'SET_WORKERS';
const SET_IS_SUPPLIER_BUDGET_LIMITS = 'SET_IS_SUPPLIER_BUDGET_LIMITS';
const SET_OPTIMIZATION_DATA = 'SET_OPTIMIZATION_DATA';
const SET_IS_OPTIMIZATION = 'SET_IS_OPTIMIZATION';

const initialState = {
  budget: 10000000,
  freq: '1+',
  restrictions: [{ Publisher: '', min: null, max: null }],
  workers: -1,
  isSupplierBudgetLimits: 0,
  optimizationData: {},
  isOptimization: -1,
};

export const optimization_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUDGET:
      return { ...state, budget: action.budget };
    case SET_FREQ:
      return { ...state, freq: action.freq };
    case SET_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    case SET_WORKERS:
      return { ...state, workers: action.workers };
    case SET_IS_SUPPLIER_BUDGET_LIMITS:
      return { ...state, isSupplierBudgetLimits: action.isSupplierBudgetLimits };
    case SET_OPTIMIZATION_DATA:
      return { ...state, optimizationData: action.optimizationData };
    case SET_IS_OPTIMIZATION:
      return { ...state, isOptimization: action.isOptimization };
    default:
      return state;
  }
};

// action creators
export const setBudget = budget => ({ type: SET_BUDGET, budget });
export const setFreq = freq => ({ type: SET_FREQ, freq });
export const setRestrictions = restrictions => ({ type: SET_RESTRICTIONS, restrictions });
export const setWorkers = workers => ({ type: SET_WORKERS, workers });
export const setIsSupplierBudgetLimits = isSupplierBudgetLimits => ({ type: SET_IS_SUPPLIER_BUDGET_LIMITS, isSupplierBudgetLimits });
export const setOptimizationData = optimizationData => ({ type: SET_OPTIMIZATION_DATA, optimizationData });
export const setIsOptimization = isOptimization => ({ type: SET_IS_OPTIMIZATION, isOptimization });

export const getOptimization = () => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const income = getState().input.income;
  const isRestrictions = getState().input.isRestrictions;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const budget = getState().opt.budget;
  const freq = getState().opt.freq;
  const freqPost = getFreqNumber(freq);
  const restrictions = getState().opt.restrictions;
  const restrictionsPOST = Object.assign({}, ...restrictions.map(n => ({ [n.Publisher]: { min: n.min, max: n.max } })));
  const workers = getState().opt.workers;
  const geo = getState().input.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const publishers = getState().table.publishers;
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));

  try {
    const response = await API.post_get_opt({
      agemax: agemax,
      agemin: agemin,
      budget: budget,
      freq: freqPost,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      restrictions: restrictionsPOST,
      workers: workers,
      geo: geoPost,
      ots_mult: isRestrictions
    });
    const onePr = budget / 100;
    const publWithBudgArr_withoutSmall = Object.values(response.data)
      .filter(item => item.budget)
      .filter(p => p.budget > onePr);
    const budgArr = publWithBudgArr_withoutSmall.map(publ => publ.budget);
    const budgSum = budgArr.reduce(function (a, b) {
      return +a + +b;
    });
    const n = budget / budgSum;
    for (const publ in response.data) {
      if (response.data[publ].budget && response.data[publ].budget > onePr) {
        response.data[publ].budget = response.data[publ].budget * n;
      } else if (response.data[publ].budget && response.data[publ].budget < onePr) {
        response.data[publ].budget = 0;
      }
    }
    dispatch(setOptimizationData(response.data));
  } catch (error) {
    dispatch(setError(error.message));
  }

  dispatch(setStatus('success'));
};
