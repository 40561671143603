import { COLORS } from '../assets/styles/colors/colors';

export const colorsArr = [COLORS.DigitalAlliance, COLORS.GPM, COLORS.IVI, COLORS.myTarget, COLORS.NativeRoll, COLORS.qBid, COLORS.RuTube, COLORS.SberSeller, COLORS.TikTok, COLORS.VK, COLORS.Yandex];

function removePlus(str) {
  return +str.substring(0, str.indexOf('+'));
}
export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
export function setMillion(labelValue) {
  return Math.abs(Number(labelValue)) / 1.0e6;
}
export function getMaxOfArray(numArray) {
  return Math.max.apply(null, numArray);
}

export function getMaxValueOfArray(numArray) {
  return Math.ceil(Math.max.apply(null, numArray));
}
export function getMinValueOfArray(numArray) {
  return Math.floor(Math.min.apply(null, numArray));
}
export const getFreqNumber = freq => {
  const freqPost = removePlus(freq);
  return freqPost;
};
export const findName = (name, arr) => {
  const findName = arr.find(arr => arr[name]);
  return findName;
};
export function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export const findMaxMinOhvat = numArray => {
  const min = getMinValueOfArray(numArray);
  const max = getMaxValueOfArray(numArray);
  const difference = ((max - min) / 100) * 10;
  const minOhvat = min - difference;
  const maxOhvat = max + difference;
  return [minOhvat, maxOhvat];
};
export function getMaxValueOfArrayNotRound(numArray) {
  return Math.max.apply(null, numArray);
}

export const removeByAttr = function (arr, attr) {
  var i = arr.length;
  while (i--) {
    if (arr[i] && arr[i].hasOwnProperty(attr)) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
