import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { RedButton } from '../common/RedButton/RedButton';
import { makeGeoTitle_From_PostData, makeLtv_From_PostData, makePresenceOfTvFlight_From_PostData } from '../../../helpers/common_helpers';
import { getFreqNumber, round } from '../../../helpers/chart_helpers';
import { variablesOptDistrArr, variablesOptSplitArr, variablesOLV_CBU_CPRP_Arr, freqStrArr, variables } from '../../../assets/data/data';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const SaveXLSOlvTv = React.memo(props => {
  const { chartsData, chartsData_TV_OLV_RFdistr2, chartsData_TV_OLV_20_view } = props;
  const postData = useSelector(state => state.input.postData);
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const dataFromXL = useSelector(state => state.charts.dataFromXL);
  const publishers = useSelector(state => state.table.publishers);
  const total = useSelector(state => state.table.total);
  const isCBUUpload = useSelector(state => state.input.isCBUUpload);
  const cbu = useSelector(state => state.input.cbu);
  const mainMedia = useSelector(state => state.change.mainMedia);
  const freqAll = useSelector(state => state.change.freqAll);
  const freqPost = freqAll && getFreqNumber(freqAll);
  const optimizationData = useSelector(state => state.opt.optimizationData);
  const restrictions = useSelector(state => state.opt.restrictions);
  const budget_OlvCbuCprpChart = useSelector(state => state.change.budget_OlvCbuCprpChart);
  const budget_OLV_TvIncreaseAggregateCoverageChart = useSelector(state => state.change.budgets_TvIncreaseAggregateCoverageChart.olvBudget);
  const budget_TvDistributionChart = useSelector(state => state.change.budget_TvDistributionChart);
  const budget_TV_TvIncreaseAggregateCoverageChart = useSelector(state => state.change.budgets_TvIncreaseAggregateCoverageChart.tvBudget);

  const getDataForSaveXLS_DataFromXL = dataFromXL => {
    const updatedInputDataArr = dataFromXL.filter(item => item.agemax);
    const dataSetInput_dataFromXL = updatedInputDataArr;

    const dataSet_Optimization_data_dataFromXL = dataFromXL.filter(item => item.Publisher_Opt);
    const dataSet_restrictions_dataFromXL = dataFromXL.filter(item => item.Publisher_Restr);

    const dataSet_Input_OLV_Budgets_dataFromXL = dataFromXL.filter(item => item.budget_OlvCbuCprpChart);

    const updatedPublishers = dataFromXL.filter(item => item.Publisher);
    const dataSetPublishers_dataFromXL = updatedPublishers;

    const updatedTotal = dataFromXL.filter(item => item.total_Budget);
    const dataSetTotal_dataFromXL = updatedTotal;

    const DONUT_DataFromXL = dataFromXL.filter(item => item.PublisherDonut);
    const dataSetDonut_dataFromXL = DONUT_DataFromXL.map((item, i) => {
      return {
        Publisher: item.PublisherDonut,
        Budget: item.Budget,
      };
    });

    const INCR_DataFromXL = dataFromXL.filter(item => item.MainMedia);
    const makeArrayMediaContributionMainMediaName = arr => {
      let newArr = [];
      arr.map(item => {
        !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
        return item;
      });
      return newArr;
    };
    const incrPublNameArr = makeArrayMediaContributionMainMediaName(INCR_DataFromXL);
    const Item = INCR_DataFromXL.filter(item => item.MainMedia === incrPublNameArr[0] && item.Freq === 1);
    const pubNames = Item.map(item => item.PublisherContr);

    const DATASETCONTR_ITEM = [];
    freqStrArr.map(item => {
      for (var i = 0; i < incrPublNameArr.length; i++) {
        for (var a = 0; a < pubNames.length; a++) {
          DATASETCONTR_ITEM.push(item + '*' + incrPublNameArr[i] + '/' + pubNames[a]);
        }
      }
      return item;
    });

    const DATASETCONTR_dataFromXL = DATASETCONTR_ITEM.map(item => {
      const INCR_ITEM = INCR_DataFromXL.filter(i => i.MainMedia === item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/')) && i.Freq === +item.substring(0, 2));

      return {
        MainMedia: item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/')),
        Freq: +item.substring(0, 2),
        Publisher: item.slice(item.lastIndexOf('/') + 1),
        Reach: INCR_ITEM.find(el => el.PublisherContr === item.slice(item.lastIndexOf('/') + 1)) && INCR_ITEM.find(el => el.PublisherContr === item.slice(item.lastIndexOf('/') + 1)).Reach,
      };
    });
    const dataSetRfNPlus_dataFromXL = dataFromXL.filter(item => item['OLVReachN+']);
    const dataSetRfN_dataFromXL = dataFromXL.filter(item => item['OLVReachN']);

    const OLV_CBU_CPRP_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP);
    const DATASETOLV_CBU_CPRP_dataFromXL = variablesOLV_CBU_CPRP_Arr.map(item => {
      const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP_DataFromXL.filter(el => el.Variable_OLV_CBU_CPRP === item.substring(2) && el.Freq === +item.substring(0, 2));
      return {
        Variable_OLV_CBU_CPRP: item.substring(2),
        Freq: +item.substring(0, 2),
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[2]]: Object.values(OLV_CBU_CPRP_ITEM[0])[2],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[3]]: Object.values(OLV_CBU_CPRP_ITEM[0])[3],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[4]]: Object.values(OLV_CBU_CPRP_ITEM[0])[4],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[5]]: Object.values(OLV_CBU_CPRP_ITEM[0])[5],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[6]]: Object.values(OLV_CBU_CPRP_ITEM[0])[6],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[7]]: Object.values(OLV_CBU_CPRP_ITEM[0])[7],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[8]]: Object.values(OLV_CBU_CPRP_ITEM[0])[8],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[9]]: Object.values(OLV_CBU_CPRP_ITEM[0])[9],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[10]]: Object.values(OLV_CBU_CPRP_ITEM[0])[10],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[11]]: Object.values(OLV_CBU_CPRP_ITEM[0])[11],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[12]]: Object.values(OLV_CBU_CPRP_ITEM[0])[12],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[13]]: Object.values(OLV_CBU_CPRP_ITEM[0])[13],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[14]]: Object.values(OLV_CBU_CPRP_ITEM[0])[14],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[15]]: Object.values(OLV_CBU_CPRP_ITEM[0])[15],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[16]]: Object.values(OLV_CBU_CPRP_ITEM[0])[16],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[17]]: Object.values(OLV_CBU_CPRP_ITEM[0])[17],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[18]]: Object.values(OLV_CBU_CPRP_ITEM[0])[18],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[19]]: Object.values(OLV_CBU_CPRP_ITEM[0])[19],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[20]]: Object.values(OLV_CBU_CPRP_ITEM[0])[20],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[21]]: Object.values(OLV_CBU_CPRP_ITEM[0])[21],
      };
    });

    const dataSet_OPT_INVEST_dataFromXL = [];
    const dataSet_Reach_OPT_INVEST_dataFromXL = [];
    const dataSet_OPT_COMPARISON_dataFromXL = [];
    const dataSet_Reach_OPT_COMPARISON_dataFromXL = [];

    const take_TV_DATA_DataFromXL = () => {
      const TV_OPT_SPL_dataFromXL = dataFromXL.filter(item => item.TV_OPT_SPL);
      const DATASET_TV_OPT_SPL_dataFromXL = variablesOptSplitArr.map(item => {
        const TV_OPT_SPL_ITEM_dataFromXL = TV_OPT_SPL_dataFromXL.filter(rf => rf.Freq === +item.substring(0, 2));
        const TV_OPT_SPL_ITEM_var = TV_OPT_SPL_ITEM_dataFromXL.filter(variables => variables.Variable === item.substring(2));
        return {
          TV_OPT_SPL: 'TV_OPT_SPL',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[3]]: Object.values(TV_OPT_SPL_ITEM_var[0])[3],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[4]]: Object.values(TV_OPT_SPL_ITEM_var[0])[4],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[5]]: Object.values(TV_OPT_SPL_ITEM_var[0])[5],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[6]]: Object.values(TV_OPT_SPL_ITEM_var[0])[6],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[7]]: Object.values(TV_OPT_SPL_ITEM_var[0])[7],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[8]]: Object.values(TV_OPT_SPL_ITEM_var[0])[8],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[9]]: Object.values(TV_OPT_SPL_ITEM_var[0])[9],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[10]]: Object.values(TV_OPT_SPL_ITEM_var[0])[10],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[11]]: Object.values(TV_OPT_SPL_ITEM_var[0])[11],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[12]]: Object.values(TV_OPT_SPL_ITEM_var[0])[12],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[13]]: Object.values(TV_OPT_SPL_ITEM_var[0])[13],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[14]]: Object.values(TV_OPT_SPL_ITEM_var[0])[14],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[15]]: Object.values(TV_OPT_SPL_ITEM_var[0])[15],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[16]]: Object.values(TV_OPT_SPL_ITEM_var[0])[16],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[17]]: Object.values(TV_OPT_SPL_ITEM_var[0])[17],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[18]]: Object.values(TV_OPT_SPL_ITEM_var[0])[18],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[19]]: Object.values(TV_OPT_SPL_ITEM_var[0])[19],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[20]]: Object.values(TV_OPT_SPL_ITEM_var[0])[20],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[21]]: Object.values(TV_OPT_SPL_ITEM_var[0])[21],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[22]]: Object.values(TV_OPT_SPL_ITEM_var[0])[22],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[23]]: Object.values(TV_OPT_SPL_ITEM_var[0])[23],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[24]]: Object.values(TV_OPT_SPL_ITEM_var[0])[24],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[25]]: Object.values(TV_OPT_SPL_ITEM_var[0])[25],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[26]]: Object.values(TV_OPT_SPL_ITEM_var[0])[26],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[27]]: Object.values(TV_OPT_SPL_ITEM_var[0])[27],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[28]]: Object.values(TV_OPT_SPL_ITEM_var[0])[28],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[29]]: Object.values(TV_OPT_SPL_ITEM_var[0])[29],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[30]]: Object.values(TV_OPT_SPL_ITEM_var[0])[30],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[31]]: Object.values(TV_OPT_SPL_ITEM_var[0])[31],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[32]]: Object.values(TV_OPT_SPL_ITEM_var[0])[32],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[33]]: Object.values(TV_OPT_SPL_ITEM_var[0])[33],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[34]]: Object.values(TV_OPT_SPL_ITEM_var[0])[34],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[35]]: Object.values(TV_OPT_SPL_ITEM_var[0])[35],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[36]]: Object.values(TV_OPT_SPL_ITEM_var[0])[36],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[37]]: Object.values(TV_OPT_SPL_ITEM_var[0])[37],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[38]]: Object.values(TV_OPT_SPL_ITEM_var[0])[38],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[39]]: Object.values(TV_OPT_SPL_ITEM_var[0])[39],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[40]]: Object.values(TV_OPT_SPL_ITEM_var[0])[40],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[41]]: Object.values(TV_OPT_SPL_ITEM_var[0])[41],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[42]]: Object.values(TV_OPT_SPL_ITEM_var[0])[42],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[43]]: Object.values(TV_OPT_SPL_ITEM_var[0])[43],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[44]]: Object.values(TV_OPT_SPL_ITEM_var[0])[44],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[45]]: Object.values(TV_OPT_SPL_ITEM_var[0])[45],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[46]]: Object.values(TV_OPT_SPL_ITEM_var[0])[46],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[47]]: Object.values(TV_OPT_SPL_ITEM_var[0])[47],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[48]]: Object.values(TV_OPT_SPL_ITEM_var[0])[48],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[49]]: Object.values(TV_OPT_SPL_ITEM_var[0])[49],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[50]]: Object.values(TV_OPT_SPL_ITEM_var[0])[50],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[51]]: Object.values(TV_OPT_SPL_ITEM_var[0])[51],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[52]]: Object.values(TV_OPT_SPL_ITEM_var[0])[52],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[53]]: Object.values(TV_OPT_SPL_ITEM_var[0])[53],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[54]]: Object.values(TV_OPT_SPL_ITEM_var[0])[54],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[55]]: Object.values(TV_OPT_SPL_ITEM_var[0])[55],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[56]]: Object.values(TV_OPT_SPL_ITEM_var[0])[56],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[57]]: Object.values(TV_OPT_SPL_ITEM_var[0])[57],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[58]]: Object.values(TV_OPT_SPL_ITEM_var[0])[58],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[59]]: Object.values(TV_OPT_SPL_ITEM_var[0])[59],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[60]]: Object.values(TV_OPT_SPL_ITEM_var[0])[60],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[61]]: Object.values(TV_OPT_SPL_ITEM_var[0])[61],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[62]]: Object.values(TV_OPT_SPL_ITEM_var[0])[62],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[63]]: Object.values(TV_OPT_SPL_ITEM_var[0])[63],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[64]]: Object.values(TV_OPT_SPL_ITEM_var[0])[64],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[65]]: Object.values(TV_OPT_SPL_ITEM_var[0])[65],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[66]]: Object.values(TV_OPT_SPL_ITEM_var[0])[66],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[67]]: Object.values(TV_OPT_SPL_ITEM_var[0])[67],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[68]]: Object.values(TV_OPT_SPL_ITEM_var[0])[68],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[69]]: Object.values(TV_OPT_SPL_ITEM_var[0])[69],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[70]]: Object.values(TV_OPT_SPL_ITEM_var[0])[70],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[71]]: Object.values(TV_OPT_SPL_ITEM_var[0])[71],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[72]]: Object.values(TV_OPT_SPL_ITEM_var[0])[72],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[73]]: Object.values(TV_OPT_SPL_ITEM_var[0])[73],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[74]]: Object.values(TV_OPT_SPL_ITEM_var[0])[74],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[75]]: Object.values(TV_OPT_SPL_ITEM_var[0])[75],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[76]]: Object.values(TV_OPT_SPL_ITEM_var[0])[76],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[77]]: Object.values(TV_OPT_SPL_ITEM_var[0])[77],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[78]]: Object.values(TV_OPT_SPL_ITEM_var[0])[78],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[79]]: Object.values(TV_OPT_SPL_ITEM_var[0])[79],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[80]]: Object.values(TV_OPT_SPL_ITEM_var[0])[80],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[81]]: Object.values(TV_OPT_SPL_ITEM_var[0])[81],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[82]]: Object.values(TV_OPT_SPL_ITEM_var[0])[82],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[83]]: Object.values(TV_OPT_SPL_ITEM_var[0])[83],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[84]]: Object.values(TV_OPT_SPL_ITEM_var[0])[84],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[85]]: Object.values(TV_OPT_SPL_ITEM_var[0])[85],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[86]]: Object.values(TV_OPT_SPL_ITEM_var[0])[86],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[87]]: Object.values(TV_OPT_SPL_ITEM_var[0])[87],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[88]]: Object.values(TV_OPT_SPL_ITEM_var[0])[88],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[89]]: Object.values(TV_OPT_SPL_ITEM_var[0])[89],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[90]]: Object.values(TV_OPT_SPL_ITEM_var[0])[90],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[91]]: Object.values(TV_OPT_SPL_ITEM_var[0])[91],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[92]]: Object.values(TV_OPT_SPL_ITEM_var[0])[92],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[93]]: Object.values(TV_OPT_SPL_ITEM_var[0])[93],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[94]]: Object.values(TV_OPT_SPL_ITEM_var[0])[94],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[95]]: Object.values(TV_OPT_SPL_ITEM_var[0])[95],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[96]]: Object.values(TV_OPT_SPL_ITEM_var[0])[96],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[97]]: Object.values(TV_OPT_SPL_ITEM_var[0])[97],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[98]]: Object.values(TV_OPT_SPL_ITEM_var[0])[98],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[99]]: Object.values(TV_OPT_SPL_ITEM_var[0])[99],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[100]]: Object.values(TV_OPT_SPL_ITEM_var[0])[100],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[101]]: Object.values(TV_OPT_SPL_ITEM_var[0])[101],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[102]]: Object.values(TV_OPT_SPL_ITEM_var[0])[102],
          [Object.keys(TV_OPT_SPL_ITEM_var[0])[103]]: Object.values(TV_OPT_SPL_ITEM_var[0])[103],
        };
      });

      const TV_OPT_DISTR_dataFromXL = dataFromXL.filter(item => item.TV_OPT_DISTR);
      const DATASET_TV_OPT_DISTR_dataFromXL = variablesOptDistrArr.map(item => {
        const TV_OPT_DISTR_ITEM_dataFromXL = TV_OPT_DISTR_dataFromXL.filter(rf => rf.Freq === +item.substring(0, 2));
        const TV_OPT_DISTR_LABELS = TV_OPT_DISTR_ITEM_dataFromXL.filter(variables => variables.Variable === 'full_bud');
                const TV_OPT_DISTR_ITEM_var = TV_OPT_DISTR_ITEM_dataFromXL.filter(variables => variables.Variable === item.substring(2));
        return {
          TV_OPT_DISTR: 'TV_OPT_DISTR',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[0])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[0],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[1])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[1],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[2])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[2],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[3])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[3],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[4])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[4],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[5])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[5],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[6])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[6],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[7])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[7],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[8])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[8],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[9])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[9],
          [round(Object.values(TV_OPT_DISTR_LABELS[0])[10])]: Object.values(TV_OPT_DISTR_ITEM_var[0])[10],
        };
      });

      const TV_INCREASE_dataFromXL = dataFromXL.filter(item => item.TV_INCREASE);
      const DATASET_TV_INCREASE_dataFromXL = variablesOptSplitArr.map(item => {
        const TV_INCREASE_ITEM_dataFromXL = TV_INCREASE_dataFromXL.filter(rf => rf.Freq === +item.substring(0, 2));
        const TV_INCREASE_ITEM_var = TV_INCREASE_ITEM_dataFromXL.filter(variables => variables.Variable === item.substring(2));
        return {
          TV_INCREASE: 'TV_INCREASE',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [Object.keys(TV_INCREASE_ITEM_var[0])[3]]: Object.values(TV_INCREASE_ITEM_var[0])[3],
          [Object.keys(TV_INCREASE_ITEM_var[0])[4]]: Object.values(TV_INCREASE_ITEM_var[0])[4],
          [Object.keys(TV_INCREASE_ITEM_var[0])[5]]: Object.values(TV_INCREASE_ITEM_var[0])[5],
          [Object.keys(TV_INCREASE_ITEM_var[0])[6]]: Object.values(TV_INCREASE_ITEM_var[0])[6],
          [Object.keys(TV_INCREASE_ITEM_var[0])[7]]: Object.values(TV_INCREASE_ITEM_var[0])[7],
          [Object.keys(TV_INCREASE_ITEM_var[0])[8]]: Object.values(TV_INCREASE_ITEM_var[0])[8],
          [Object.keys(TV_INCREASE_ITEM_var[0])[9]]: Object.values(TV_INCREASE_ITEM_var[0])[9],
          [Object.keys(TV_INCREASE_ITEM_var[0])[10]]: Object.values(TV_INCREASE_ITEM_var[0])[10],
          [Object.keys(TV_INCREASE_ITEM_var[0])[11]]: Object.values(TV_INCREASE_ITEM_var[0])[11],
          [Object.keys(TV_INCREASE_ITEM_var[0])[12]]: Object.values(TV_INCREASE_ITEM_var[0])[12],
          [Object.keys(TV_INCREASE_ITEM_var[0])[13]]: Object.values(TV_INCREASE_ITEM_var[0])[13],
          [Object.keys(TV_INCREASE_ITEM_var[0])[14]]: Object.values(TV_INCREASE_ITEM_var[0])[14],
          [Object.keys(TV_INCREASE_ITEM_var[0])[15]]: Object.values(TV_INCREASE_ITEM_var[0])[15],
          [Object.keys(TV_INCREASE_ITEM_var[0])[16]]: Object.values(TV_INCREASE_ITEM_var[0])[16],
          [Object.keys(TV_INCREASE_ITEM_var[0])[17]]: Object.values(TV_INCREASE_ITEM_var[0])[17],
          [Object.keys(TV_INCREASE_ITEM_var[0])[18]]: Object.values(TV_INCREASE_ITEM_var[0])[18],
          [Object.keys(TV_INCREASE_ITEM_var[0])[19]]: Object.values(TV_INCREASE_ITEM_var[0])[19],
          [Object.keys(TV_INCREASE_ITEM_var[0])[20]]: Object.values(TV_INCREASE_ITEM_var[0])[20],
          [Object.keys(TV_INCREASE_ITEM_var[0])[21]]: Object.values(TV_INCREASE_ITEM_var[0])[21],
          [Object.keys(TV_INCREASE_ITEM_var[0])[22]]: Object.values(TV_INCREASE_ITEM_var[0])[22],
          [Object.keys(TV_INCREASE_ITEM_var[0])[23]]: Object.values(TV_INCREASE_ITEM_var[0])[23],
        };
      });

      const RF_TV_OLV_dataFromXL = dataFromXL.filter(item => item.RF_TV_OLV);
      const DATASET_RF_TV_OLV_RfNPlus_dataFromXL = variables.map(variable => {
        const RF_ITEM = RF_TV_OLV_dataFromXL.find(rf => rf.Variable === variable && rf.RF === 'rfPlus');

        return {
          RF_TV_OLV: 'RF_TV_OLV',
          Variable: variable,
          RF: 'rfPlus',
          1: RF_ITEM[1],
          2: RF_ITEM[2],
          3: RF_ITEM[3],
          4: RF_ITEM[4],
          5: RF_ITEM[5],
          6: RF_ITEM[6],
          7: RF_ITEM[7],
          8: RF_ITEM[8],
          9: RF_ITEM[9],
          10: RF_ITEM[10],
          11: RF_ITEM[11],
          12: RF_ITEM[12],
          13: RF_ITEM[13],
          14: RF_ITEM[14],
          15: RF_ITEM[15],
          16: RF_ITEM[16],
          17: RF_ITEM[17],
          18: RF_ITEM[18],
          19: RF_ITEM[19],
        };
      });

      const DATASET_RF_TV_OLV_RfN_dataFromXL = variables.map(variable => {
        const RF_ITEM = RF_TV_OLV_dataFromXL.find(rf => rf.Variable === variable && rf.RF === 'rfClose');
        return {
          RF_TV_OLV: 'RF_TV_OLV',
          Variable: variable,
          RF: 'rfClose',
          1: RF_ITEM[1],
          2: RF_ITEM[2],
          3: RF_ITEM[3],
          4: RF_ITEM[4],
          5: RF_ITEM[5],
          6: RF_ITEM[6],
          7: RF_ITEM[7],
          8: RF_ITEM[8],
          9: RF_ITEM[9],
          10: RF_ITEM[10],
          11: RF_ITEM[11],
          12: RF_ITEM[12],
          13: RF_ITEM[13],
          14: RF_ITEM[14],
          15: RF_ITEM[15],
          16: RF_ITEM[16],
          17: RF_ITEM[17],
          18: RF_ITEM[18],
          19: RF_ITEM[19],
        };
      });

      const DATASET_CBU_dataFromXL = dataFromXL.filter(item => item.TRP_Cbu);

      return [DATASET_TV_OPT_DISTR_dataFromXL, DATASET_TV_OPT_SPL_dataFromXL, DATASET_TV_INCREASE_dataFromXL, DATASET_RF_TV_OLV_RfNPlus_dataFromXL, DATASET_RF_TV_OLV_RfN_dataFromXL, DATASET_CBU_dataFromXL];
    };
    const take_TV_DATA_DataFromXL_call_result = isCBUUpload === 1 && take_TV_DATA_DataFromXL();
    const DATASET_TV_OPT_DISTR_dataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL_call_result[0] : [];
    const DATASET_TV_OPT_SPL_dataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL_call_result[1] : [];
    const DATASET_TV_INCREASE_dataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL_call_result[2] : [];
    const DATASET_RF_TV_OLV_RfNPlus_dataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL_call_result[3] : [];
    const DATASET_RF_TV_OLV_RfN_dataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL_call_result[4] : [];
    const DATASET_CBU_dataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL_call_result[5] : [];

    return [dataSetInput_dataFromXL, dataSetPublishers_dataFromXL, dataSetDonut_dataFromXL, DATASETCONTR_dataFromXL, dataSetRfNPlus_dataFromXL, dataSetRfN_dataFromXL, DATASETOLV_CBU_CPRP_dataFromXL, dataSetTotal_dataFromXL, dataSet_OPT_INVEST_dataFromXL, dataSet_Reach_OPT_INVEST_dataFromXL, dataSet_OPT_COMPARISON_dataFromXL, dataSet_Reach_OPT_COMPARISON_dataFromXL, DATASET_TV_OPT_DISTR_dataFromXL, DATASET_TV_OPT_SPL_dataFromXL, DATASET_TV_INCREASE_dataFromXL, DATASET_RF_TV_OLV_RfNPlus_dataFromXL, DATASET_RF_TV_OLV_RfN_dataFromXL, DATASET_CBU_dataFromXL, dataSet_Input_OLV_Budgets_dataFromXL, dataSet_Optimization_data_dataFromXL, dataSet_restrictions_dataFromXL];
  };
  const getDataForSaveXLS_DataFromXL_call_result = isChartsUpload && getDataForSaveXLS_DataFromXL(dataFromXL);
  const dataSetInput_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[0];
  const dataSetPublishers_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[1];
  const dataSetDonut_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[2];
  const DATASETCONTR_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[3];
  const dataSetRfNPlus_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[4];
  const dataSetRfN_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[5];
  const DATASETOLV_CBU_CPRP_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[6];
  const dataSetTotal_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[7];
  const DATASET_TV_OPT_DISTR_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[12];
  const DATASET_TV_OPT_SPL_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[13];
  const DATASET_TV_INCREASE_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[14];
  const DATASET_RF_TV_OLV_RfNPlus_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[15];
  const DATASET_RF_TV_OLV_RfN_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[16];
  const DATASET_CBU_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[17];
  const dataSet_Input_OLV_Budgets_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[18];
  const dataSet_Optimization_data_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[19];
  const dataSet_restrictions_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[20];

  const getDataForSaveXLS_DataFromChartsData = chartsData => {
    const dataSet_Optimization_data = Object.keys(optimizationData).map((item, i) => {
      return {
        Publisher_Opt: item,
        CPM_Opt: optimizationData[item].CPM,
        Days_Opt: optimizationData[item].Days,
        FrequencyLimit_Opt: optimizationData[item].FrequencyLimit,
        Impressions_Opt: optimizationData[item].Impressions,
        max_OTS_Opt: optimizationData[item].max_OTS,
        targ_Opt: optimizationData[item].targ,
        budget_Opt: optimizationData[item].budget ? optimizationData[item].budget : null,
      };
    });
    const dataSet_restrictions = restrictions.map((item, i) => {
      return {
        Publisher_Restr: item.Publisher,
        min: item.min === null ? 0 : item.min,
        max: item.max === null ? 0 : item.max,
      };
    });

    const dataSet_Input_OLV_Budgets = [
      {
        budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
        budget_OLV_TvIncreaseAggregateCoverageChart: budget_OLV_TvIncreaseAggregateCoverageChart,
        budget_TvDistributionChart: budget_TvDistributionChart,
        budget_TV_TvIncreaseAggregateCoverageChart: budget_TV_TvIncreaseAggregateCoverageChart,
      },
    ];

    const dataSetInput = postData;
    const dataSetPublishers = publishers;
    const dataSetTotal = total;

    const DONUT = publishers.filter(item => item.isChecked === 1);
    const dataSetDonut = DONUT.map((item, i) => {
      return {
        Publisher: item.Publisher,
        Budget: item.Budget,
      };
    });

    const INCR = chartsData.incr;
    const incrPublNameArr = Object.keys(INCR);
    const Item = INCR[Object.keys(INCR)[0]][1];
    const pubNames = Object.keys(Item);
    const DATASETCONTR_ITEM = [];
    freqStrArr.map(item => {
      for (var i = 0; i < incrPublNameArr.length; i++) {
        for (var a = 0; a < pubNames.length; a++) {
          DATASETCONTR_ITEM.push(item + '*' + incrPublNameArr[i] + '/' + pubNames[a]);
        }
      }
      return item;
    });

    const DATASETCONTR = DATASETCONTR_ITEM.map(item => {
      const INCR_ITEM = INCR[item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/'))][+item.substring(0, 2)];

      return {
        MainMedia: item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/')),
        Freq: +item.substring(0, 2),
        Publisher: item.slice(item.lastIndexOf('/') + 1),
        Reach: INCR_ITEM[item.slice(item.lastIndexOf('/') + 1)],
      };
    });
    const RF = chartsData.RF;
    const dataSetRfNPlus = [
      {
        'OLVReachN+': 'OLVReachN+',
        1: RF['Total_media_rf_n+'][0],
        2: RF['Total_media_rf_n+'][1],
        3: RF['Total_media_rf_n+'][2],
        4: RF['Total_media_rf_n+'][3],
        5: RF['Total_media_rf_n+'][4],
        6: RF['Total_media_rf_n+'][5],
        7: RF['Total_media_rf_n+'][6],
        8: RF['Total_media_rf_n+'][7],
        9: RF['Total_media_rf_n+'][8],
        10: RF['Total_media_rf_n+'][9],
        11: RF['Total_media_rf_n+'][10],
        12: RF['Total_media_rf_n+'][11],
        13: RF['Total_media_rf_n+'][12],
        14: RF['Total_media_rf_n+'][13],
        15: RF['Total_media_rf_n+'][14],
        16: RF['Total_media_rf_n+'][15],
        17: RF['Total_media_rf_n+'][16],
        18: RF['Total_media_rf_n+'][17],
        19: RF['Total_media_rf_n+'][18],
      },
    ];
    const dataSetRfN = [
      {
        OLVReachN: 'OLVReachN',
        1: RF['Total_media_rf_n'][0],
        2: RF['Total_media_rf_n'][1],
        3: RF['Total_media_rf_n'][2],
        4: RF['Total_media_rf_n'][3],
        5: RF['Total_media_rf_n'][4],
        6: RF['Total_media_rf_n'][5],
        7: RF['Total_media_rf_n'][6],
        8: RF['Total_media_rf_n'][7],
        9: RF['Total_media_rf_n'][8],
        10: RF['Total_media_rf_n'][9],
        11: RF['Total_media_rf_n'][10],
        12: RF['Total_media_rf_n'][11],
        13: RF['Total_media_rf_n'][12],
        14: RF['Total_media_rf_n'][13],
        15: RF['Total_media_rf_n'][14],
        16: RF['Total_media_rf_n'][15],
        17: RF['Total_media_rf_n'][16],
        18: RF['Total_media_rf_n'][17],
        19: RF['Total_media_rf_n'][18],
      },
    ];

    const OLV_CBU_CPRP = chartsData.OLV_CBU_CPRP;
    const DATASETOLV_CBU_CPRP = variablesOLV_CBU_CPRP_Arr.map(item => {
      const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP[item.substring(2)][+item.substring(0, 2)];
      return {
        Variable_OLV_CBU_CPRP: item.substring(2),
        Freq: +item.substring(0, 2),
        [Object.keys(OLV_CBU_CPRP_ITEM)[0]]: Object.values(OLV_CBU_CPRP_ITEM)[0],
        [Object.keys(OLV_CBU_CPRP_ITEM)[1]]: Object.values(OLV_CBU_CPRP_ITEM)[1],
        [Object.keys(OLV_CBU_CPRP_ITEM)[2]]: Object.values(OLV_CBU_CPRP_ITEM)[2],
        [Object.keys(OLV_CBU_CPRP_ITEM)[3]]: Object.values(OLV_CBU_CPRP_ITEM)[3],
        [Object.keys(OLV_CBU_CPRP_ITEM)[4]]: Object.values(OLV_CBU_CPRP_ITEM)[4],
        [Object.keys(OLV_CBU_CPRP_ITEM)[5]]: Object.values(OLV_CBU_CPRP_ITEM)[5],
        [Object.keys(OLV_CBU_CPRP_ITEM)[6]]: Object.values(OLV_CBU_CPRP_ITEM)[6],
        [Object.keys(OLV_CBU_CPRP_ITEM)[7]]: Object.values(OLV_CBU_CPRP_ITEM)[7],
        [Object.keys(OLV_CBU_CPRP_ITEM)[8]]: Object.values(OLV_CBU_CPRP_ITEM)[8],
        [Object.keys(OLV_CBU_CPRP_ITEM)[9]]: Object.values(OLV_CBU_CPRP_ITEM)[9],
        [Object.keys(OLV_CBU_CPRP_ITEM)[10]]: Object.values(OLV_CBU_CPRP_ITEM)[10],
        [Object.keys(OLV_CBU_CPRP_ITEM)[11]]: Object.values(OLV_CBU_CPRP_ITEM)[11],
        [Object.keys(OLV_CBU_CPRP_ITEM)[12]]: Object.values(OLV_CBU_CPRP_ITEM)[12],
        [Object.keys(OLV_CBU_CPRP_ITEM)[13]]: Object.values(OLV_CBU_CPRP_ITEM)[13],
        [Object.keys(OLV_CBU_CPRP_ITEM)[14]]: Object.values(OLV_CBU_CPRP_ITEM)[14],
        [Object.keys(OLV_CBU_CPRP_ITEM)[15]]: Object.values(OLV_CBU_CPRP_ITEM)[15],
        [Object.keys(OLV_CBU_CPRP_ITEM)[16]]: Object.values(OLV_CBU_CPRP_ITEM)[16],
        [Object.keys(OLV_CBU_CPRP_ITEM)[17]]: Object.values(OLV_CBU_CPRP_ITEM)[17],
        [Object.keys(OLV_CBU_CPRP_ITEM)[18]]: Object.values(OLV_CBU_CPRP_ITEM)[18],
        [Object.keys(OLV_CBU_CPRP_ITEM)[19]]: Object.values(OLV_CBU_CPRP_ITEM)[19],
      };
    });

    const dataSet_OPT_INVEST = [];
    const dataSet_Reach_OPT_INVEST = [];
    const dataSet_OPT_COMPARISON = [];
    const dataSet_Reach_OPT_COMPARISON = [];

    const take_TV_DATA = () => {
      const DATASET_CBU = cbu.map(item => {
        return {
          TRP_Cbu: item[0],
          R1: item[1],
          R2: item[2],
          R3: item[3],
          R4: item[4],
          R5: item[5],
          R6: item[6],
          R7: item[7],
          R8: item[8],
          R9: item[9],
          R10: item[10],
          R11: item[11],
          R12: item[12],
          R13: item[13],
          R14: item[14],
          R15: item[15],
          R16: item[16],
          R17: item[17],
          R18: item[18],
          R19: item[19],
          R20: item[20],
        };
      });

      const RF_TV_OLV = chartsData_TV_OLV_RFdistr2;
      const DATASET_RF_TV_OLV_RfNPlus = variables.map(variable => {
        return {
          RF_TV_OLV: 'RF_TV_OLV',
          Variable: variable,
          RF: 'rfPlus',
          1: RF_TV_OLV[variable]['rfPlus'][0],
          2: RF_TV_OLV[variable]['rfPlus'][1],
          3: RF_TV_OLV[variable]['rfPlus'][2],
          4: RF_TV_OLV[variable]['rfPlus'][3],
          5: RF_TV_OLV[variable]['rfPlus'][4],
          6: RF_TV_OLV[variable]['rfPlus'][5],
          7: RF_TV_OLV[variable]['rfPlus'][6],
          8: RF_TV_OLV[variable]['rfPlus'][7],
          9: RF_TV_OLV[variable]['rfPlus'][8],
          10: RF_TV_OLV[variable]['rfPlus'][9],
          11: RF_TV_OLV[variable]['rfPlus'][10],
          12: RF_TV_OLV[variable]['rfPlus'][11],
          13: RF_TV_OLV[variable]['rfPlus'][12],
          14: RF_TV_OLV[variable]['rfPlus'][13],
          15: RF_TV_OLV[variable]['rfPlus'][14],
          16: RF_TV_OLV[variable]['rfPlus'][15],
          17: RF_TV_OLV[variable]['rfPlus'][16],
          18: RF_TV_OLV[variable]['rfPlus'][17],
          19: RF_TV_OLV[variable]['rfPlus'][18],
        };
      });

      const DATASET_RF_TV_OLV_RfN = variables.map(variable => {
        return {
          RF_TV_OLV: 'RF_TV_OLV',
          Variable: variable,
          RF: 'rfClose',
          1: RF_TV_OLV[variable]['rfClose'][0],
          2: RF_TV_OLV[variable]['rfClose'][1],
          3: RF_TV_OLV[variable]['rfClose'][2],
          4: RF_TV_OLV[variable]['rfClose'][3],
          5: RF_TV_OLV[variable]['rfClose'][4],
          6: RF_TV_OLV[variable]['rfClose'][5],
          7: RF_TV_OLV[variable]['rfClose'][6],
          8: RF_TV_OLV[variable]['rfClose'][7],
          9: RF_TV_OLV[variable]['rfClose'][8],
          10: RF_TV_OLV[variable]['rfClose'][9],
          11: RF_TV_OLV[variable]['rfClose'][10],
          12: RF_TV_OLV[variable]['rfClose'][11],
          13: RF_TV_OLV[variable]['rfClose'][12],
          14: RF_TV_OLV[variable]['rfClose'][13],
          15: RF_TV_OLV[variable]['rfClose'][14],
          16: RF_TV_OLV[variable]['rfClose'][15],
          17: RF_TV_OLV[variable]['rfClose'][16],
          18: RF_TV_OLV[variable]['rfClose'][17],
          19: RF_TV_OLV[variable]['rfClose'][18],
        };
      });
      const TV_INCREASE = chartsData_TV_OLV_20_view;
      const DATASET_TV_INCREASE = variablesOptSplitArr.map(item => {
        const TV_INCREASE_ITEM = TV_INCREASE[+item.substring(0, 2)];
        const TV_INCREASE_ITEM_var = TV_INCREASE_ITEM[item.substring(2)];
        return {
          TV_INCREASE: 'TV_INCREASE',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [Object.keys(TV_INCREASE_ITEM_var)[0]]: Object.values(TV_INCREASE_ITEM_var)[0],
          [Object.keys(TV_INCREASE_ITEM_var)[1]]: Object.values(TV_INCREASE_ITEM_var)[1],
          [Object.keys(TV_INCREASE_ITEM_var)[2]]: Object.values(TV_INCREASE_ITEM_var)[2],
          [Object.keys(TV_INCREASE_ITEM_var)[3]]: Object.values(TV_INCREASE_ITEM_var)[3],
          [Object.keys(TV_INCREASE_ITEM_var)[4]]: Object.values(TV_INCREASE_ITEM_var)[4],
          [Object.keys(TV_INCREASE_ITEM_var)[5]]: Object.values(TV_INCREASE_ITEM_var)[5],
          [Object.keys(TV_INCREASE_ITEM_var)[6]]: Object.values(TV_INCREASE_ITEM_var)[6],
          [Object.keys(TV_INCREASE_ITEM_var)[7]]: Object.values(TV_INCREASE_ITEM_var)[7],
          [Object.keys(TV_INCREASE_ITEM_var)[8]]: Object.values(TV_INCREASE_ITEM_var)[8],
          [Object.keys(TV_INCREASE_ITEM_var)[9]]: Object.values(TV_INCREASE_ITEM_var)[9],
          [Object.keys(TV_INCREASE_ITEM_var)[10]]: Object.values(TV_INCREASE_ITEM_var)[10],
          [Object.keys(TV_INCREASE_ITEM_var)[11]]: Object.values(TV_INCREASE_ITEM_var)[11],
          [Object.keys(TV_INCREASE_ITEM_var)[12]]: Object.values(TV_INCREASE_ITEM_var)[12],
          [Object.keys(TV_INCREASE_ITEM_var)[13]]: Object.values(TV_INCREASE_ITEM_var)[13],
          [Object.keys(TV_INCREASE_ITEM_var)[14]]: Object.values(TV_INCREASE_ITEM_var)[14],
          [Object.keys(TV_INCREASE_ITEM_var)[15]]: Object.values(TV_INCREASE_ITEM_var)[15],
          [Object.keys(TV_INCREASE_ITEM_var)[16]]: Object.values(TV_INCREASE_ITEM_var)[16],
          [Object.keys(TV_INCREASE_ITEM_var)[17]]: Object.values(TV_INCREASE_ITEM_var)[17],
          [Object.keys(TV_INCREASE_ITEM_var)[18]]: Object.values(TV_INCREASE_ITEM_var)[18],
          [Object.keys(TV_INCREASE_ITEM_var)[19]]: Object.values(TV_INCREASE_ITEM_var)[19],
          [Object.keys(TV_INCREASE_ITEM_var)[20]]: Object.values(TV_INCREASE_ITEM_var)[20],
        };
      });

      const TV_OPT_DISTR = chartsData.tv_olv_data.opt_bud;
      const DATASET_TV_OPT_DISTR = variablesOptDistrArr.map(item => {
        const TV_OPT_DISTR_ITEM = TV_OPT_DISTR[+item.substring(0, 2)];
        const TV_OPT_DISTR_LABELS = Object.values(TV_OPT_DISTR_ITEM['full_bud']).map(item => round(item));
        const TV_OPT_DISTR_ITEM_var = TV_OPT_DISTR_ITEM[item.substring(2)];
        return {
          TV_OPT_DISTR: 'TV_OPT_DISTR',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [TV_OPT_DISTR_LABELS[0]]: Object.values(TV_OPT_DISTR_ITEM_var)[0],
          [TV_OPT_DISTR_LABELS[1]]: Object.values(TV_OPT_DISTR_ITEM_var)[1],
          [TV_OPT_DISTR_LABELS[2]]: Object.values(TV_OPT_DISTR_ITEM_var)[2],
          [TV_OPT_DISTR_LABELS[3]]: Object.values(TV_OPT_DISTR_ITEM_var)[3],
          [TV_OPT_DISTR_LABELS[4]]: Object.values(TV_OPT_DISTR_ITEM_var)[4],
          [TV_OPT_DISTR_LABELS[5]]: Object.values(TV_OPT_DISTR_ITEM_var)[5],
          [TV_OPT_DISTR_LABELS[6]]: Object.values(TV_OPT_DISTR_ITEM_var)[6],
          [TV_OPT_DISTR_LABELS[7]]: Object.values(TV_OPT_DISTR_ITEM_var)[7],
          [TV_OPT_DISTR_LABELS[8]]: Object.values(TV_OPT_DISTR_ITEM_var)[8],
          [TV_OPT_DISTR_LABELS[9]]: Object.values(TV_OPT_DISTR_ITEM_var)[9],
          [TV_OPT_DISTR_LABELS[10]]: Object.values(TV_OPT_DISTR_ITEM_var)[10],
        };
      });

      const TV_OPT_SPL = chartsData.tv_olv_data.opt_view;
      const DATASET_TV_OPT_SPL = variablesOptSplitArr.map(item => {
        const TV_OPT_SPL_ITEM = TV_OPT_SPL[+item.substring(0, 2)];
        const TV_OPT_SPL_ITEM_var = TV_OPT_SPL_ITEM[item.substring(2)];
        return {
          TV_OPT_SPL: 'TV_OPT_SPL',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [Object.keys(TV_OPT_SPL_ITEM_var)[0]]: Object.values(TV_OPT_SPL_ITEM_var)[0],
          [Object.keys(TV_OPT_SPL_ITEM_var)[1]]: Object.values(TV_OPT_SPL_ITEM_var)[1],
          [Object.keys(TV_OPT_SPL_ITEM_var)[2]]: Object.values(TV_OPT_SPL_ITEM_var)[2],
          [Object.keys(TV_OPT_SPL_ITEM_var)[3]]: Object.values(TV_OPT_SPL_ITEM_var)[3],
          [Object.keys(TV_OPT_SPL_ITEM_var)[4]]: Object.values(TV_OPT_SPL_ITEM_var)[4],
          [Object.keys(TV_OPT_SPL_ITEM_var)[5]]: Object.values(TV_OPT_SPL_ITEM_var)[5],
          [Object.keys(TV_OPT_SPL_ITEM_var)[6]]: Object.values(TV_OPT_SPL_ITEM_var)[6],
          [Object.keys(TV_OPT_SPL_ITEM_var)[7]]: Object.values(TV_OPT_SPL_ITEM_var)[7],
          [Object.keys(TV_OPT_SPL_ITEM_var)[8]]: Object.values(TV_OPT_SPL_ITEM_var)[8],
          [Object.keys(TV_OPT_SPL_ITEM_var)[9]]: Object.values(TV_OPT_SPL_ITEM_var)[9],
          [Object.keys(TV_OPT_SPL_ITEM_var)[10]]: Object.values(TV_OPT_SPL_ITEM_var)[10],
          [Object.keys(TV_OPT_SPL_ITEM_var)[11]]: Object.values(TV_OPT_SPL_ITEM_var)[11],
          [Object.keys(TV_OPT_SPL_ITEM_var)[12]]: Object.values(TV_OPT_SPL_ITEM_var)[12],
          [Object.keys(TV_OPT_SPL_ITEM_var)[13]]: Object.values(TV_OPT_SPL_ITEM_var)[13],
          [Object.keys(TV_OPT_SPL_ITEM_var)[14]]: Object.values(TV_OPT_SPL_ITEM_var)[14],
          [Object.keys(TV_OPT_SPL_ITEM_var)[15]]: Object.values(TV_OPT_SPL_ITEM_var)[15],
          [Object.keys(TV_OPT_SPL_ITEM_var)[16]]: Object.values(TV_OPT_SPL_ITEM_var)[16],
          [Object.keys(TV_OPT_SPL_ITEM_var)[17]]: Object.values(TV_OPT_SPL_ITEM_var)[17],
          [Object.keys(TV_OPT_SPL_ITEM_var)[18]]: Object.values(TV_OPT_SPL_ITEM_var)[18],
          [Object.keys(TV_OPT_SPL_ITEM_var)[19]]: Object.values(TV_OPT_SPL_ITEM_var)[19],
          [Object.keys(TV_OPT_SPL_ITEM_var)[20]]: Object.values(TV_OPT_SPL_ITEM_var)[20],
          [Object.keys(TV_OPT_SPL_ITEM_var)[21]]: Object.values(TV_OPT_SPL_ITEM_var)[21],
          [Object.keys(TV_OPT_SPL_ITEM_var)[22]]: Object.values(TV_OPT_SPL_ITEM_var)[22],
          [Object.keys(TV_OPT_SPL_ITEM_var)[23]]: Object.values(TV_OPT_SPL_ITEM_var)[23],
          [Object.keys(TV_OPT_SPL_ITEM_var)[24]]: Object.values(TV_OPT_SPL_ITEM_var)[24],
          [Object.keys(TV_OPT_SPL_ITEM_var)[25]]: Object.values(TV_OPT_SPL_ITEM_var)[25],
          [Object.keys(TV_OPT_SPL_ITEM_var)[26]]: Object.values(TV_OPT_SPL_ITEM_var)[26],
          [Object.keys(TV_OPT_SPL_ITEM_var)[27]]: Object.values(TV_OPT_SPL_ITEM_var)[27],
          [Object.keys(TV_OPT_SPL_ITEM_var)[28]]: Object.values(TV_OPT_SPL_ITEM_var)[28],
          [Object.keys(TV_OPT_SPL_ITEM_var)[29]]: Object.values(TV_OPT_SPL_ITEM_var)[29],
          [Object.keys(TV_OPT_SPL_ITEM_var)[30]]: Object.values(TV_OPT_SPL_ITEM_var)[30],
          [Object.keys(TV_OPT_SPL_ITEM_var)[31]]: Object.values(TV_OPT_SPL_ITEM_var)[31],
          [Object.keys(TV_OPT_SPL_ITEM_var)[32]]: Object.values(TV_OPT_SPL_ITEM_var)[32],
          [Object.keys(TV_OPT_SPL_ITEM_var)[33]]: Object.values(TV_OPT_SPL_ITEM_var)[33],
          [Object.keys(TV_OPT_SPL_ITEM_var)[34]]: Object.values(TV_OPT_SPL_ITEM_var)[34],
          [Object.keys(TV_OPT_SPL_ITEM_var)[35]]: Object.values(TV_OPT_SPL_ITEM_var)[35],
          [Object.keys(TV_OPT_SPL_ITEM_var)[36]]: Object.values(TV_OPT_SPL_ITEM_var)[36],
          [Object.keys(TV_OPT_SPL_ITEM_var)[37]]: Object.values(TV_OPT_SPL_ITEM_var)[37],
          [Object.keys(TV_OPT_SPL_ITEM_var)[38]]: Object.values(TV_OPT_SPL_ITEM_var)[38],
          [Object.keys(TV_OPT_SPL_ITEM_var)[39]]: Object.values(TV_OPT_SPL_ITEM_var)[39],
          [Object.keys(TV_OPT_SPL_ITEM_var)[40]]: Object.values(TV_OPT_SPL_ITEM_var)[40],
          [Object.keys(TV_OPT_SPL_ITEM_var)[41]]: Object.values(TV_OPT_SPL_ITEM_var)[41],
          [Object.keys(TV_OPT_SPL_ITEM_var)[42]]: Object.values(TV_OPT_SPL_ITEM_var)[42],
          [Object.keys(TV_OPT_SPL_ITEM_var)[43]]: Object.values(TV_OPT_SPL_ITEM_var)[43],
          [Object.keys(TV_OPT_SPL_ITEM_var)[44]]: Object.values(TV_OPT_SPL_ITEM_var)[44],
          [Object.keys(TV_OPT_SPL_ITEM_var)[45]]: Object.values(TV_OPT_SPL_ITEM_var)[45],
          [Object.keys(TV_OPT_SPL_ITEM_var)[46]]: Object.values(TV_OPT_SPL_ITEM_var)[46],
          [Object.keys(TV_OPT_SPL_ITEM_var)[47]]: Object.values(TV_OPT_SPL_ITEM_var)[47],
          [Object.keys(TV_OPT_SPL_ITEM_var)[48]]: Object.values(TV_OPT_SPL_ITEM_var)[48],
          [Object.keys(TV_OPT_SPL_ITEM_var)[49]]: Object.values(TV_OPT_SPL_ITEM_var)[49],
          [Object.keys(TV_OPT_SPL_ITEM_var)[50]]: Object.values(TV_OPT_SPL_ITEM_var)[50],
          [Object.keys(TV_OPT_SPL_ITEM_var)[51]]: Object.values(TV_OPT_SPL_ITEM_var)[51],
          [Object.keys(TV_OPT_SPL_ITEM_var)[52]]: Object.values(TV_OPT_SPL_ITEM_var)[52],
          [Object.keys(TV_OPT_SPL_ITEM_var)[53]]: Object.values(TV_OPT_SPL_ITEM_var)[53],
          [Object.keys(TV_OPT_SPL_ITEM_var)[54]]: Object.values(TV_OPT_SPL_ITEM_var)[54],
          [Object.keys(TV_OPT_SPL_ITEM_var)[55]]: Object.values(TV_OPT_SPL_ITEM_var)[55],
          [Object.keys(TV_OPT_SPL_ITEM_var)[56]]: Object.values(TV_OPT_SPL_ITEM_var)[56],
          [Object.keys(TV_OPT_SPL_ITEM_var)[57]]: Object.values(TV_OPT_SPL_ITEM_var)[57],
          [Object.keys(TV_OPT_SPL_ITEM_var)[58]]: Object.values(TV_OPT_SPL_ITEM_var)[58],
          [Object.keys(TV_OPT_SPL_ITEM_var)[59]]: Object.values(TV_OPT_SPL_ITEM_var)[59],
          [Object.keys(TV_OPT_SPL_ITEM_var)[60]]: Object.values(TV_OPT_SPL_ITEM_var)[60],
          [Object.keys(TV_OPT_SPL_ITEM_var)[61]]: Object.values(TV_OPT_SPL_ITEM_var)[61],
          [Object.keys(TV_OPT_SPL_ITEM_var)[62]]: Object.values(TV_OPT_SPL_ITEM_var)[62],
          [Object.keys(TV_OPT_SPL_ITEM_var)[63]]: Object.values(TV_OPT_SPL_ITEM_var)[63],
          [Object.keys(TV_OPT_SPL_ITEM_var)[64]]: Object.values(TV_OPT_SPL_ITEM_var)[64],
          [Object.keys(TV_OPT_SPL_ITEM_var)[65]]: Object.values(TV_OPT_SPL_ITEM_var)[65],
          [Object.keys(TV_OPT_SPL_ITEM_var)[66]]: Object.values(TV_OPT_SPL_ITEM_var)[66],
          [Object.keys(TV_OPT_SPL_ITEM_var)[67]]: Object.values(TV_OPT_SPL_ITEM_var)[67],
          [Object.keys(TV_OPT_SPL_ITEM_var)[68]]: Object.values(TV_OPT_SPL_ITEM_var)[68],
          [Object.keys(TV_OPT_SPL_ITEM_var)[69]]: Object.values(TV_OPT_SPL_ITEM_var)[69],
          [Object.keys(TV_OPT_SPL_ITEM_var)[70]]: Object.values(TV_OPT_SPL_ITEM_var)[70],
          [Object.keys(TV_OPT_SPL_ITEM_var)[71]]: Object.values(TV_OPT_SPL_ITEM_var)[71],
          [Object.keys(TV_OPT_SPL_ITEM_var)[72]]: Object.values(TV_OPT_SPL_ITEM_var)[72],
          [Object.keys(TV_OPT_SPL_ITEM_var)[73]]: Object.values(TV_OPT_SPL_ITEM_var)[73],
          [Object.keys(TV_OPT_SPL_ITEM_var)[74]]: Object.values(TV_OPT_SPL_ITEM_var)[74],
          [Object.keys(TV_OPT_SPL_ITEM_var)[75]]: Object.values(TV_OPT_SPL_ITEM_var)[75],
          [Object.keys(TV_OPT_SPL_ITEM_var)[76]]: Object.values(TV_OPT_SPL_ITEM_var)[76],
          [Object.keys(TV_OPT_SPL_ITEM_var)[77]]: Object.values(TV_OPT_SPL_ITEM_var)[77],
          [Object.keys(TV_OPT_SPL_ITEM_var)[78]]: Object.values(TV_OPT_SPL_ITEM_var)[78],
          [Object.keys(TV_OPT_SPL_ITEM_var)[79]]: Object.values(TV_OPT_SPL_ITEM_var)[79],
          [Object.keys(TV_OPT_SPL_ITEM_var)[80]]: Object.values(TV_OPT_SPL_ITEM_var)[80],
          [Object.keys(TV_OPT_SPL_ITEM_var)[81]]: Object.values(TV_OPT_SPL_ITEM_var)[81],
          [Object.keys(TV_OPT_SPL_ITEM_var)[82]]: Object.values(TV_OPT_SPL_ITEM_var)[82],
          [Object.keys(TV_OPT_SPL_ITEM_var)[83]]: Object.values(TV_OPT_SPL_ITEM_var)[83],
          [Object.keys(TV_OPT_SPL_ITEM_var)[84]]: Object.values(TV_OPT_SPL_ITEM_var)[84],
          [Object.keys(TV_OPT_SPL_ITEM_var)[85]]: Object.values(TV_OPT_SPL_ITEM_var)[85],
          [Object.keys(TV_OPT_SPL_ITEM_var)[86]]: Object.values(TV_OPT_SPL_ITEM_var)[86],
          [Object.keys(TV_OPT_SPL_ITEM_var)[87]]: Object.values(TV_OPT_SPL_ITEM_var)[87],
          [Object.keys(TV_OPT_SPL_ITEM_var)[88]]: Object.values(TV_OPT_SPL_ITEM_var)[88],
          [Object.keys(TV_OPT_SPL_ITEM_var)[89]]: Object.values(TV_OPT_SPL_ITEM_var)[89],
          [Object.keys(TV_OPT_SPL_ITEM_var)[90]]: Object.values(TV_OPT_SPL_ITEM_var)[90],
          [Object.keys(TV_OPT_SPL_ITEM_var)[91]]: Object.values(TV_OPT_SPL_ITEM_var)[91],
          [Object.keys(TV_OPT_SPL_ITEM_var)[92]]: Object.values(TV_OPT_SPL_ITEM_var)[92],
          [Object.keys(TV_OPT_SPL_ITEM_var)[93]]: Object.values(TV_OPT_SPL_ITEM_var)[93],
          [Object.keys(TV_OPT_SPL_ITEM_var)[94]]: Object.values(TV_OPT_SPL_ITEM_var)[94],
          [Object.keys(TV_OPT_SPL_ITEM_var)[95]]: Object.values(TV_OPT_SPL_ITEM_var)[95],
          [Object.keys(TV_OPT_SPL_ITEM_var)[96]]: Object.values(TV_OPT_SPL_ITEM_var)[96],
          [Object.keys(TV_OPT_SPL_ITEM_var)[97]]: Object.values(TV_OPT_SPL_ITEM_var)[97],
          [Object.keys(TV_OPT_SPL_ITEM_var)[98]]: Object.values(TV_OPT_SPL_ITEM_var)[98],
          [Object.keys(TV_OPT_SPL_ITEM_var)[99]]: Object.values(TV_OPT_SPL_ITEM_var)[99],
          [Object.keys(TV_OPT_SPL_ITEM_var)[100]]: Object.values(TV_OPT_SPL_ITEM_var)[100],
        };
      });

      return [DATASET_TV_OPT_DISTR, DATASET_TV_OPT_SPL, DATASET_TV_INCREASE, DATASET_RF_TV_OLV_RfNPlus, DATASET_RF_TV_OLV_RfN, DATASET_CBU];
    };
    const take_TV_DATA_call_result = isCBUUpload === 1 && take_TV_DATA();
    const DATASET_TV_OPT_DISTR = isCBUUpload === 1 ? take_TV_DATA_call_result[0] : [];
    const DATASET_TV_OPT_SPL = isCBUUpload === 1 ? take_TV_DATA_call_result[1] : [];
    const DATASET_TV_INCREASE = isCBUUpload === 1 ? take_TV_DATA_call_result[2] : [];
    const DATASET_RF_TV_OLV_RfNPlus = isCBUUpload === 1 ? take_TV_DATA_call_result[3] : [];
    const DATASET_RF_TV_OLV_RfN = isCBUUpload === 1 ? take_TV_DATA_call_result[4] : [];
    const DATASET_CBU = isCBUUpload === 1 ? take_TV_DATA_call_result[5] : [];

    return [dataSetInput, dataSetPublishers, dataSetDonut, DATASETCONTR, dataSetRfNPlus, dataSetRfN, DATASETOLV_CBU_CPRP, dataSetTotal, dataSet_OPT_INVEST, dataSet_Reach_OPT_INVEST, dataSet_OPT_COMPARISON, dataSet_Reach_OPT_COMPARISON, DATASET_TV_OPT_DISTR, DATASET_TV_OPT_SPL, DATASET_TV_INCREASE, DATASET_RF_TV_OLV_RfNPlus, DATASET_RF_TV_OLV_RfN, DATASET_CBU, dataSet_Input_OLV_Budgets, dataSet_Optimization_data, dataSet_restrictions];
  };
  const getDataForSaveXLS_DataFromChartsData_call_result = !isChartsUpload && getDataForSaveXLS_DataFromChartsData(chartsData);
  const dataSetInput = getDataForSaveXLS_DataFromChartsData_call_result[0];
  const dataSetPublishers = getDataForSaveXLS_DataFromChartsData_call_result[1];
  const dataSetDonut = getDataForSaveXLS_DataFromChartsData_call_result[2];
  const DATASETCONTR = getDataForSaveXLS_DataFromChartsData_call_result[3];
  const dataSetRfNPlus = getDataForSaveXLS_DataFromChartsData_call_result[4];
  const dataSetRfN = getDataForSaveXLS_DataFromChartsData_call_result[5];
  const DATASETOLV_CBU_CPRP = getDataForSaveXLS_DataFromChartsData_call_result[6];
  const dataSetTotal = getDataForSaveXLS_DataFromChartsData_call_result[7];
  const DATASET_TV_OPT_DISTR = getDataForSaveXLS_DataFromChartsData_call_result[12];
  const DATASET_TV_OPT_SPL = getDataForSaveXLS_DataFromChartsData_call_result[13];
  const DATASET_TV_INCREASE = getDataForSaveXLS_DataFromChartsData_call_result[14];
  const DATASET_RF_TV_OLV_RfNPlus = getDataForSaveXLS_DataFromChartsData_call_result[15];
  const DATASET_RF_TV_OLV_RfN = getDataForSaveXLS_DataFromChartsData_call_result[16];
  const DATASET_CBU = getDataForSaveXLS_DataFromChartsData_call_result[17];
  const dataSet_Input_OLV_Budgets = getDataForSaveXLS_DataFromChartsData_call_result[18];
  const dataSet_Optimization_data = getDataForSaveXLS_DataFromChartsData_call_result[19];
  const dataSet_restrictions = getDataForSaveXLS_DataFromChartsData_call_result[20];

  const TA = isChartsUpload ? `${dataSetInput_dataFromXL[0].gender} ${dataSetInput_dataFromXL[0].agemin}-${dataSetInput_dataFromXL[0].agemax} ${dataSetInput_dataFromXL[0].income}` : `${dataSetInput[0].gender} ${dataSetInput[0].agemin}-${dataSetInput[0].agemax} ${dataSetInput[0].income}`;
  const Geo = isChartsUpload ? makeGeoTitle_From_PostData(dataSetInput_dataFromXL[0].geo) : makeGeoTitle_From_PostData(dataSetInput[0].geo);
  const Universe = isChartsUpload ? dataSetInput_dataFromXL[0].universe : dataSetInput[0].universe;
  const Share = isChartsUpload ? dataSetInput_dataFromXL[0].share : dataSetInput[0].share;
  const PresenceOfTvFlight = isChartsUpload ? makePresenceOfTvFlight_From_PostData(dataSetInput_dataFromXL[0].presenceOfTvFlight) : makePresenceOfTvFlight_From_PostData(dataSetInput[0].presenceOfTvFlight);
  const Ltv = isChartsUpload ? makeLtv_From_PostData(dataSetInput_dataFromXL[0].ltv) : makeLtv_From_PostData(dataSetInput[0].ltv);

  const chooseDataSetTotal = () => {
    if (isChartsUpload) {
      return dataSetTotal_dataFromXL;
    } else {
      return dataSetTotal;
    }
  };
  const chooseDataSetPublishers = () => {
    if (isChartsUpload) {
      return dataSetPublishers_dataFromXL;
    } else {
      return dataSetPublishers;
    }
  };
  const chooseDATASETCONTR = () => {
    if (isChartsUpload) {
      return DATASETCONTR_dataFromXL.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    } else {
      return DATASETCONTR.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    }
  };
  const choosedataSetDonut = () => {
    if (isChartsUpload) {
      return dataSetDonut_dataFromXL;
    } else {
      return dataSetDonut;
    }
  };
  const choosedataSetRfN = () => {
    if (isChartsUpload) {
      return dataSetRfN_dataFromXL;
    } else {
      return dataSetRfN;
    }
  };
  const choosedataSetRfNPlus = () => {
    if (isChartsUpload) {
      return dataSetRfNPlus_dataFromXL;
    } else {
      return dataSetRfNPlus;
    }
  };
  const chooseDATASETOLV_CBU_CPRP = () => {
    if (isChartsUpload) {
      return DATASETOLV_CBU_CPRP_dataFromXL.filter(item => item.Freq === freqPost);
    } else {
      return DATASETOLV_CBU_CPRP.filter(item => item.Freq === freqPost);
    }
  };
  const chooseDATASET_RF_TV_OLV_RfN = () => {
    if (isChartsUpload) {
      return DATASET_RF_TV_OLV_RfN_dataFromXL;
    } else {
      return DATASET_RF_TV_OLV_RfN;
    }
  };
  const chooseDATASET_RF_TV_OLV_RfNPlus = () => {
    if (isChartsUpload) {
      return DATASET_RF_TV_OLV_RfNPlus_dataFromXL;
    } else {
      return DATASET_RF_TV_OLV_RfNPlus;
    }
  };
  const chooseDATASET_TV_OPT_SPL = () => {
    if (isChartsUpload) {
      return DATASET_TV_OPT_SPL_dataFromXL.filter(item => item.Freq === freqPost);
    } else {
      return DATASET_TV_OPT_SPL.filter(item => item.Freq === freqPost);
    }
  };

  const chooseDATASET_TV_OPT_DISTR = () => {
    if (isChartsUpload) {
      return DATASET_TV_OPT_DISTR_dataFromXL.filter(item => item.Freq === freqPost);
    } else {
      return DATASET_TV_OPT_DISTR.filter(item => item.Freq === freqPost);
    }
  };
  const chooseDATASET_DATASET_TV_INCREASE = () => {
    if (isChartsUpload) {
      return DATASET_TV_INCREASE_dataFromXL.filter(item => item.Freq === freqPost);
    } else {
      return DATASET_TV_INCREASE.filter(item => item.Freq === freqPost);
    }
  };
  const chooseDataSetTotal_call_result = chooseDataSetTotal();
  const chooseDATASETOLV_CBU_CPRP_call_result = chooseDATASETOLV_CBU_CPRP();
  const chooseDATASET_TV_OPT_SPL_call_result = chooseDATASET_TV_OPT_SPL();
  const chooseDATASET_TV_OPT_DISTR_call_result = chooseDATASET_TV_OPT_DISTR();
  const chooseDATASET_DATASET_TV_INCREASE_call_result = chooseDATASET_DATASET_TV_INCREASE();

  const Publishers_Sheet1 = chooseDataSetPublishers().map((item, i) => {
    return [
      {
        value: String(item.Publisher),
        style: {
          fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },

      {
        value: item.Budget,
        style: {
          numFmt: '#,##0 ₽',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Impressions,
        style: {
          numFmt: '#,##0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.CPM,
        style: {
          numFmt: '0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.TargetingSocDem === 1 ? 'Да' : 'Нет',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.OtherTargeting === 1 ? 'Да' : 'Нет',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Days,
        style: {
          numFmt: '0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },

      {
        value: item.FrequencyLimit,
        style: {
          numFmt: '0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Tsa_percent / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Tsa_thousand,
        style: {
          numFmt: '0.0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.TRP,
        style: {
          numFmt: '0.0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });
  const Contribution_Sheet1 = chooseDATASETCONTR().map((item, i) => {
    return [
      {
        value: String(item.Publisher),
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Reach / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const Donut_Sheet1 = choosedataSetDonut().map((item, i) => {
    return [
      {
        value: String(item.Publisher),
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Budget,
        style: {
          numFmt: '#,##0 ₽',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const RfN_Sheet1 = choosedataSetRfN().map((item, i) => {
    return [
      {
        value: 'Закрытые часоты',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: 'olv',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[1] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[2] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const RfNPlus_Sheet1 = choosedataSetRfNPlus().map((item, i) => {
    return [
      {
        value: 'Открытые частоты',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: 'olv',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[1] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[2] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const OLV_CBU_CPRP_sheet1 = chooseDATASETOLV_CBU_CPRP().map((item, i) => {
    return [
      {
        value: item.Variable_OLV_CBU_CPRP,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Freq,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[2] !== 'null' ? Object.values(item)[2] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[3] !== 'null' ? Object.values(item)[3] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[4] !== 'null' ? Object.values(item)[4] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[5] !== 'null' ? Object.values(item)[5] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[6] !== 'null' ? Object.values(item)[6] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[7] !== 'null' ? Object.values(item)[7] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[8] !== 'null' ? Object.values(item)[8] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[9] !== 'null' ? Object.values(item)[9] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[10] !== 'null' ? Object.values(item)[10] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[11] !== 'null' ? Object.values(item)[11] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[12] !== 'null' ? Object.values(item)[12] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[13] !== 'null' ? Object.values(item)[13] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[14] !== 'null' ? Object.values(item)[14] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[15] !== 'null' ? Object.values(item)[15] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[16] !== 'null' ? Object.values(item)[16] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[17] !== 'null' ? Object.values(item)[17] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[18] !== 'null' ? Object.values(item)[18] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[19] !== 'null' ? Object.values(item)[19] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[20] !== 'null' ? Object.values(item)[20] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[21] !== 'null' ? Object.values(item)[21] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const RF_TV_OLV_RfN_Sheet1 = chooseDATASET_RF_TV_OLV_RfN().map((item, i) => {
    return [
      {
        value: 'RF_TV_OLV',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: 'N',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[1] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[2] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const RF_TV_OLV_RfNPlus_Sheet1 = chooseDATASET_RF_TV_OLV_RfNPlus().map((item, i) => {
    return [
      {
        value: 'RF_TV_OLV',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: 'N+',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[1] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[2] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const TV_OPT_SPL_sheet1 = chooseDATASET_TV_OPT_SPL_call_result.map((item, i) => {
    return [
      {
        value: 'TV_OPT_SPL',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Freq,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },

      {
        value: Object.values(item)[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[20] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[21] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[22] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[23] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[24] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[25] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[26] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[27] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[28] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[29] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[30] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[31] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[32] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[33] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[34] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[35] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[36] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[37] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[38] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[39] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[40] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[41] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[42] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[43] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[44] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[45] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[46] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[47] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[48] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[49] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[50] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[51] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[52] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[53] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[54] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[55] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[56] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[57] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[58] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[59] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[60] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[61] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[62] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[63] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[64] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[65] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[66] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[67] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[68] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[69] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[70] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[71] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[72] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[73] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[74] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[75] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[76] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[77] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[78] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[79] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[80] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[81] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[82] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[83] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[84] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[85] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[86] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[87] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[88] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[89] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[90] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[91] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[92] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[93] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[94] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[95] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[96] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[97] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[98] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[99] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[100] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[101] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[102] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[103] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });
  const TV_OPT_DISTR_sheet1 = chooseDATASET_TV_OPT_DISTR_call_result.map((item, i) => {
    return [
      {
        value: 'TV_OPT_DISTR',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Freq,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[0] : Object.values(item)[0] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[1] : Object.values(item)[1] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[2] : Object.values(item)[2] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[3] : Object.values(item)[3] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[4] : Object.values(item)[4] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[5] : Object.values(item)[5] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[6] : Object.values(item)[6] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[7] : Object.values(item)[7] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[8] : Object.values(item)[8] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[9] : Object.values(item)[9] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable.indexOf('bud') > -1 ? Object.values(item)[10] : Object.values(item)[10] / 100,
        style: {
          numFmt: item.Variable.indexOf('bud') > -1 ? '#,##0 ₽' : '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });
  const TV_INCREASE_sheet1 = chooseDATASET_DATASET_TV_INCREASE_call_result.map((item, i) => {
    return [
      {
        value: 'TV_INCREASE',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Freq,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Variable,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },

      {
        value: Object.values(item)[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[20] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[21] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[22] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[23] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const sheet1 = [
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'Целевая аудитория',
          width: { wpx: 280 },
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          title: String(TA),
          width: { wpx: 170 },
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            font: { bold: true },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [
        [
          {
            value: 'Гео',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: String(Geo),
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'OLV бюджет',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Budget,
            style: {
              numFmt: '#,##0 ₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Universe, OOO',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Universe,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Share',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Share / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],

        [
          {
            value: 'Наличие TV флайта',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: PresenceOfTvFlight,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],

        [
          {
            value: 'LTV',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Ltv,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],

        [
          {
            value: 'Заданная эффективная частота в инструменте',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: freqAll,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'Площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Бюджет',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Показы',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'CPM',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Таргет (soc dem)',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Таргет (other)',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Период (дни)',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Частота в месяц',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'ЦА %',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'ЦА тыс',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'TRP',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: Publishers_Sheet1,
    },

    {
      xSteps: 0,
      ySteps: -1,
      columns: [],
      data: [
        [
          {
            value: 'Total',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Budget,
            style: {
              numFmt: '#,##0 ₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Impressions,
            style: {
              numFmt: '#,##0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: chooseDataSetTotal_call_result[0].total_CPM,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Tsa_percent,
            style: {
              numFmt: '0.0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Tsa_thousand,
            style: {
              numFmt: '0.0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_TRP,
            style: {
              numFmt: '0.0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'графики в цифрах: ',
          width: { wpx: 280 },
          style: { alignment: { wrapText: false, horizontal: 'center', vertical: 'top' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'OLV only 1й - 4й графики из Videotron',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `1. Вклад каждой площадки в совокупный охват на частоте ${freqAll}`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Основная площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },

            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: mainMedia,
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: Contribution_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `2. Распределение бюджета`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Бюджет',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: Donut_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `3. Распределение Reach/Frequency OLV`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: '',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '1',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '2',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '3',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '4',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '5',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '6',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '7',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '8',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '9',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '10',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '11',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '12',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '14',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '14',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '15',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '16',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '17',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '18',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '19',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: RfN_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: -1,
      columns: [],
      data: RfNPlus_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `4. OLV: CBU & CPRP на частоте ${freqAll}`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Variable_OLV_CBU_CPRP',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Freq',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[2].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[3].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[4].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[5].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[6].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[7].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[8].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[9].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[10].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[11].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[12].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[13].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[14].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[15].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[16].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[17].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[18].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[19].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[20].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[21].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: OLV_CBU_CPRP_sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'OLV+TV 5й-8й графики из Videotron',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `5. Распределение Reach / Frequency TV & OLV `,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'RF_TV_OLV',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'RF',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Variable',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '1',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '2',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '3',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '4',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '5',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '6',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '7',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '8',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '9',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '10',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '11',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '12',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '14',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '14',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '15',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '16',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '17',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '18',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '19',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: RF_TV_OLV_RfN_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: -1,
      columns: [],
      data: RF_TV_OLV_RfNPlus_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `6. Оптимальный сплит бюджета TV & OLV`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'TV_OPT_SPL',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Freq',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Variable',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[3].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[4].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[5].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[6].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[7].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[8].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[9].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[10].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[11].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[12].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[13].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[14].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[15].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[16].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[17].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[18].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[19].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[20].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[21].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[22].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[23].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[24].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[25].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[26].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[27].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[28].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[29].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[30].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[31].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[32].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[33].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[34].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[35].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[36].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[37].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[38].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[39].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[40].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[41].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[42].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[43].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[44].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[45].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[46].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[47].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[48].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[49].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[50].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[51].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[52].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[53].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[54].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[55].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[56].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[57].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[58].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[59].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[60].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[61].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[62].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[63].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[64].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[65].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[66].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[67].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[68].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[69].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[70].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[71].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[72].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[73].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[74].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[75].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[76].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[77].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[78].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[79].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[80].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[81].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[82].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[83].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[84].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[85].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[86].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[87].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[88].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[89].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[90].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[91].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[92].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[93].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[94].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[95].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[96].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[97].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[98].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[99].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[100].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[101].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[102].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_SPL_call_result[0] && `${+Object.keys(chooseDATASET_TV_OPT_SPL_call_result[0])[103].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: TV_OPT_SPL_sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `7. Оптимальное распределение бюджета для достижения максимального совокупного охвата при нефиксированных TV и OLV бюджетах`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'TV_OPT_DISTR',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Freq',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Variable',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[0]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[1]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[2]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[3]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[4]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[5]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[6]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[7]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[8]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[9]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_TV_OPT_DISTR_call_result[5] && `${Object.values(chooseDATASET_TV_OPT_DISTR_call_result[5])[10]} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: TV_OPT_DISTR_sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `8. Рост совокупного охвата TV & OLV при фиксированном бюджете TV и нефиксированном бюджете OLV`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'TV_INCREASE',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Freq',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Variable',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[3].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[4].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[5].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[6].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[7].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[8].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[9].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[10].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[11].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[12].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[13].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[14].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[15].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[16].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[17].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[18].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[19].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[20].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[21].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[22].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASET_DATASET_TV_INCREASE_call_result[0] && `${+Object.keys(chooseDATASET_DATASET_TV_INCREASE_call_result[0])[23].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: TV_INCREASE_sheet1,
    },
  ];
  return (
    <div>
      <ExcelFile element={<RedButton title={'Сохранить\n проект xls'} />} filename='save_videotron_olv_charts'>
        <ExcelSheet dataSet={sheet1} name='Сводные данные'></ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetPublishers_dataFromXL : dataSetPublishers} name='Площадки'>
          <ExcelColumn label='Publisher' value='Publisher' />
          <ExcelColumn label='Budget' value='Budget' />
          <ExcelColumn label='CPM' value='CPM' />
          <ExcelColumn label='Days' value='Days' />
          <ExcelColumn label='FrequencyLimit' value='FrequencyLimit' />
          <ExcelColumn label='DefaultImpressions' value='DefaultImpressions' />
          <ExcelColumn label='Impressions' value='Impressions' />
          <ExcelColumn label='OtherTargeting' value='OtherTargeting' />
          <ExcelColumn label='TRP' value='TRP' />
          <ExcelColumn label='TargetingSocDem' value='TargetingSocDem' />
          <ExcelColumn label='Tsa_percent' value='Tsa_percent' />
          <ExcelColumn label='Tsa_thousand' value='Tsa_thousand' />
          <ExcelColumn label='isChecked' value='isChecked' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASETOLV_CBU_CPRP_dataFromXL : DATASETOLV_CBU_CPRP} name='OLV_CBU_CPRP'>
          <ExcelColumn label='Variable_OLV_CBU_CPRP' value='Variable_OLV_CBU_CPRP' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[2] : Object.keys(DATASETOLV_CBU_CPRP[0])[2]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[2] : Object.keys(DATASETOLV_CBU_CPRP[0])[2]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(DATASETOLV_CBU_CPRP[0])[3]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(DATASETOLV_CBU_CPRP[0])[3]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(DATASETOLV_CBU_CPRP[0])[4]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(DATASETOLV_CBU_CPRP[0])[4]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(DATASETOLV_CBU_CPRP[0])[5]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(DATASETOLV_CBU_CPRP[0])[5]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(DATASETOLV_CBU_CPRP[0])[6]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(DATASETOLV_CBU_CPRP[0])[6]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(DATASETOLV_CBU_CPRP[0])[7]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(DATASETOLV_CBU_CPRP[0])[7]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(DATASETOLV_CBU_CPRP[0])[8]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(DATASETOLV_CBU_CPRP[0])[8]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(DATASETOLV_CBU_CPRP[0])[9]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(DATASETOLV_CBU_CPRP[0])[9]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(DATASETOLV_CBU_CPRP[0])[10]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(DATASETOLV_CBU_CPRP[0])[10]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(DATASETOLV_CBU_CPRP[0])[11]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(DATASETOLV_CBU_CPRP[0])[11]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(DATASETOLV_CBU_CPRP[0])[12]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(DATASETOLV_CBU_CPRP[0])[12]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(DATASETOLV_CBU_CPRP[0])[13]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(DATASETOLV_CBU_CPRP[0])[13]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(DATASETOLV_CBU_CPRP[0])[14]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(DATASETOLV_CBU_CPRP[0])[14]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(DATASETOLV_CBU_CPRP[0])[15]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(DATASETOLV_CBU_CPRP[0])[15]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(DATASETOLV_CBU_CPRP[0])[16]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(DATASETOLV_CBU_CPRP[0])[16]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(DATASETOLV_CBU_CPRP[0])[17]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(DATASETOLV_CBU_CPRP[0])[17]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(DATASETOLV_CBU_CPRP[0])[18]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(DATASETOLV_CBU_CPRP[0])[18]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(DATASETOLV_CBU_CPRP[0])[19]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(DATASETOLV_CBU_CPRP[0])[19]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(DATASETOLV_CBU_CPRP[0])[20]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(DATASETOLV_CBU_CPRP[0])[20]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(DATASETOLV_CBU_CPRP[0])[21]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(DATASETOLV_CBU_CPRP[0])[21]} />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASETCONTR_dataFromXL : DATASETCONTR} name='Reach_build_OLV'>
          <ExcelColumn label='MainMedia' value='MainMedia' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='PublisherContr' value='Publisher' />
          <ExcelColumn label='Reach' value='Reach' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASET_RF_TV_OLV_RfNPlus_dataFromXL : DATASET_RF_TV_OLV_RfNPlus} name='RF_TV_OLV(n+)'>
          <ExcelColumn label='RF_TV_OLV' value='RF_TV_OLV' />
          <ExcelColumn label='RF' value='RF' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASET_RF_TV_OLV_RfN_dataFromXL : DATASET_RF_TV_OLV_RfN} name='RF_TV_OLV(n)'>
          <ExcelColumn label='RF_TV_OLV' value='RF_TV_OLV' />
          <ExcelColumn label='RF' value='RF' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL : DATASET_TV_OPT_SPL} name='TV_OLV_Split'>
          <ExcelColumn label='TV_OPT_SPL' value='TV_OPT_SPL' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[3] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[3]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[3] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[3]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[4] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[4]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[4] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[4]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[5] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[5]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[5] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[5]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[6] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[6]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[6] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[6]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[7] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[7]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[7] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[7]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[8] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[8]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[8] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[8]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[9] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[9]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[9] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[9]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[10] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[10]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[10] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[10]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[11] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[11]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[11] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[11]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[12] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[12]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[12] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[12]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[13] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[13]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[13] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[13]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[14] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[14]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[14] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[14]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[15] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[15]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[15] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[15]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[16] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[16]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[16] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[16]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[17] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[17]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[17] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[17]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[18] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[18]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[18] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[18]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[19] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[19]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[19] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[19]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[20] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[20]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[20] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[20]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[21] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[21]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[21] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[21]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[22] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[22]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[22] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[22]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[23] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[23]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[23] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[23]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[24] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[24]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[24] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[24]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[25] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[25]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[25] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[25]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[26] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[26]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[26] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[26]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[27] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[27]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[27] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[27]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[28] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[28]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[28] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[28]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[29] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[29]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[29] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[29]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[30] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[30]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[30] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[30]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[31] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[31]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[31] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[31]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[32] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[32]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[32] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[32]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[33] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[33]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[33] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[33]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[34] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[34]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[34] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[34]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[35] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[35]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[35] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[35]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[36] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[36]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[36] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[36]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[37] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[37]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[37] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[37]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[38] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[38]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[38] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[38]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[39] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[39]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[39] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[39]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[40] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[40]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[40] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[40]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[41] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[41]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[41] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[41]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[42] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[42]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[42] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[42]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[43] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[43]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[43] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[43]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[44] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[44]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[44] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[44]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[45] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[45]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[45] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[45]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[46] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[46]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[46] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[46]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[47] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[47]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[47] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[47]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[48] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[48]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[48] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[48]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[49] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[49]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[49] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[49]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[50] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[50]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[50] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[50]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[51] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[51]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[51] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[51]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[52] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[52]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[52] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[52]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[53] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[53]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[53] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[53]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[54] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[54]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[54] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[54]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[55] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[55]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[55] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[55]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[56] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[56]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[56] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[56]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[57] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[57]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[57] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[57]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[58] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[58]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[58] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[58]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[59] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[59]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[59] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[59]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[60] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[60]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[60] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[60]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[61] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[61]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[61] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[61]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[62] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[62]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[62] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[62]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[63] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[63]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[63] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[63]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[64] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[64]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[64] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[64]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[65] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[65]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[65] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[65]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[66] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[66]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[66] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[66]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[67] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[67]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[67] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[67]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[68] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[68]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[68] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[68]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[69] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[69]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[69] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[69]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[70] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[70]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[70] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[70]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[71] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[71]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[71] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[71]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[72] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[72]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[72] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[72]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[73] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[73]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[73] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[73]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[74] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[74]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[74] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[74]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[75] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[75]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[75] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[75]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[76] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[76]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[76] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[76]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[77] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[77]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[77] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[77]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[78] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[78]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[78] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[78]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[79] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[79]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[79] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[79]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[80] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[80]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[80] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[80]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[81] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[81]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[81] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[81]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[82] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[82]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[82] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[82]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[83] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[83]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[83] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[83]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[84] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[84]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[84] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[84]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[85] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[85]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[85] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[85]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[86] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[86]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[86] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[86]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[87] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[87]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[87] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[87]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[88] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[88]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[88] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[88]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[89] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[89]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[89] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[89]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[90] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[90]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[90] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[90]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[91] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[91]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[91] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[91]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[92] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[92]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[92] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[92]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[93] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[93]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[93] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[93]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[94] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[94]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[94] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[94]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[95] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[95]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[95] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[95]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[96] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[96]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[96] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[96]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[97] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[97]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[97] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[97]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[98] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[98]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[98] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[98]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[99] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[99]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[99] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[99]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[100] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[100]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[100] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[100]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[101] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[101]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[101] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[101]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[102] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[102]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[102] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[102]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[103] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[103]} value={isChartsUpload ? DATASET_TV_OPT_SPL_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_SPL_dataFromXL[0])[103] : DATASET_TV_OPT_SPL.length > 0 && Object.keys(DATASET_TV_OPT_SPL[0])[103]} />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL : DATASET_TV_OPT_DISTR} name='TV_OLV_Opt_split'>
          <ExcelColumn label='TV_OPT_DISTR' value='TV_OPT_DISTR' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[0] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[0]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[0] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[0]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[1] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[1]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[1] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[1]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[2] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[2]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[2] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[2]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[3] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[3]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[3] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[3]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[4] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[4]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[4] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[4]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[5] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[5]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[5] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[5]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[6] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[6]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[6] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[6]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[7] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[7]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[7] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[7]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[8] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[8]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[8] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[8]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[9] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[9]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[9] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[9]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[10] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[10]} value={isChartsUpload ? DATASET_TV_OPT_DISTR_dataFromXL.length > 0 && Object.keys(DATASET_TV_OPT_DISTR_dataFromXL[0])[10] : DATASET_TV_OPT_DISTR.length > 0 && Object.keys(DATASET_TV_OPT_DISTR[0])[10]} />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL : DATASET_TV_INCREASE} name='OLV_Add_TV'>
          <ExcelColumn label='TV_INCREASE' value='TV_INCREASE' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='Variable' value='Variable' />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[3] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[3]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[3] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[3]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[4] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[4]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[4] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[4]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[5] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[5]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[5] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[5]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[6] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[6]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[6] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[6]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[7] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[7]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[7] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[7]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[8] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[8]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[8] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[8]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[9] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[9]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[9] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[9]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[10] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[10]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[10] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[10]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[11] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[11]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[11] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[11]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[12] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[12]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[12] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[12]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[13] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[13]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[13] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[13]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[14] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[14]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[14] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[14]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[15] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[15]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[15] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[15]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[16] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[16]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[16] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[16]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[17] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[17]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[17] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[17]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[18] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[18]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[18] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[18]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[19] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[19]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[19] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[19]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[20] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[20]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[20] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[20]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[21] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[21]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[21] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[21]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[22] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[22]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[22] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[22]} />
          <ExcelColumn label={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[23] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[23]} value={isChartsUpload ? DATASET_TV_INCREASE_dataFromXL.length > 0 && Object.keys(DATASET_TV_INCREASE_dataFromXL[0])[23] : DATASET_TV_INCREASE.length > 0 && Object.keys(DATASET_TV_INCREASE[0])[23]} />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetInput_dataFromXL : dataSetInput} name='Вводные данные' hideElement={true}>
          <ExcelColumn label='agemax' value='agemax' />
          <ExcelColumn label='agemin' value='agemin' />
          <ExcelColumn label='gender' value='gender' />
          <ExcelColumn label='income' value='income' />
          <ExcelColumn label='ltv' value='ltv' />
          <ExcelColumn label='geo' value='geo' />
          <ExcelColumn label='geoPopulation' value='geoPopulation' />
          <ExcelColumn label='presenceOfTvFlight' value='presenceOfTvFlight' />
          <ExcelColumn label='isCBUUpload' value='isCBUUpload' />
          <ExcelColumn label='cpp' value='cpp' />
          <ExcelColumn label='universe' value='universe' />
          <ExcelColumn label='share' value='share' />
          <ExcelColumn label='isOptimization' value='isOptimization' />
          <ExcelColumn label='isRestrictions' value='isRestrictions' />
          <ExcelColumn label='budget' value='budget' />
          <ExcelColumn label='freq' value='freq' />
          <ExcelColumn label='isSupplierBudgetLimits' value='isSupplierBudgetLimits' />
          <ExcelColumn label='impChangesNotPossible' value='impChangesNotPossible' />
          <ExcelColumn label='bgtChangesNotPossible' value='bgtChangesNotPossible' />
          <ExcelColumn label='crmChangesNotPossible' value='crmChangesNotPossible' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASET_CBU_dataFromXL : DATASET_CBU} name='Cbu'>
          <ExcelColumn label='TRP_Cbu' value='TRP_Cbu' />
          <ExcelColumn label='R1' value='R1' />
          <ExcelColumn label='R2' value='R2' />
          <ExcelColumn label='R3' value='R3' />
          <ExcelColumn label='R4' value='R4' />
          <ExcelColumn label='R5' value='R5' />
          <ExcelColumn label='R6' value='R6' />
          <ExcelColumn label='R7' value='R7' />
          <ExcelColumn label='R8' value='R8' />
          <ExcelColumn label='R9' value='R9' />
          <ExcelColumn label='R10' value='R10' />
          <ExcelColumn label='R11' value='R11' />
          <ExcelColumn label='R12' value='R12' />
          <ExcelColumn label='R13' value='R13' />
          <ExcelColumn label='R14' value='R14' />
          <ExcelColumn label='R15' value='R15' />
          <ExcelColumn label='R16' value='R16' />
          <ExcelColumn label='R17' value='R17' />
          <ExcelColumn label='R18' value='R18' />
          <ExcelColumn label='R19' value='R19' />
          <ExcelColumn label='R20' value='R20' />
        </ExcelSheet>

        <ExcelSheet data={isChartsUpload ? dataSet_Optimization_data_dataFromXL : dataSet_Optimization_data} name='optimizationData'>
          <ExcelColumn label='Publisher_Opt' value='Publisher_Opt' />
          <ExcelColumn label='CPM_Opt' value='CPM_Opt' />
          <ExcelColumn label='Days_Opt' value='Days_Opt' />
          <ExcelColumn label='FrequencyLimit_Opt' value='FrequencyLimit_Opt' />
          <ExcelColumn label='Impressions_Opt' value='Impressions_Opt' />
          <ExcelColumn label='max_OTS_Opt' value='max_OTS_Opt' />
          <ExcelColumn label='targ_Opt' value='targ_Opt' />
          <ExcelColumn label='budget_Opt' value='budget_Opt' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSet_restrictions_dataFromXL : dataSet_restrictions} name='restrictions'>
          <ExcelColumn label='Publisher_Restr' value='Publisher_Restr' />
          <ExcelColumn label='min' value='min' />
          <ExcelColumn label='max' value='max' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetTotal_dataFromXL : dataSetTotal} name='Total'>
          <ExcelColumn label='total_Budget' value='total_Budget' />
          <ExcelColumn label='total_Impressions' value='total_Impressions' />
          <ExcelColumn label='total_CPM' value='total_CPM' />
          <ExcelColumn label='total_Tsa_thousand' value='total_Tsa_thousand' />
          <ExcelColumn label='total_Tsa_percent' value='total_Tsa_percent' />
          <ExcelColumn label='total_TRP' value='total_TRP' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetDonut_dataFromXL : dataSetDonut} name='Распределение бюджета'>
          <ExcelColumn label='PublisherDonut' value='Publisher' />
          <ExcelColumn label='Budget' value='Budget' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetRfNPlus_dataFromXL : dataSetRfNPlus} name='Распределение охвата(N+)'>
          <ExcelColumn label='OLVReachN+' value='OLVReachN+' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetRfN_dataFromXL : dataSetRfN} name='Распределение охвата(N)'>
          <ExcelColumn label='OLVReachN' value='OLVReachN' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>

        <ExcelSheet data={isChartsUpload ? dataSet_Input_OLV_Budgets_dataFromXL : dataSet_Input_OLV_Budgets} name='Input_Budgets'>
          <ExcelColumn label='budget_OlvCbuCprpChart' value='budget_OlvCbuCprpChart' />
          <ExcelColumn label='budget_OLV_TvIncreaseAggregateCoverageChart' value='budget_OLV_TvIncreaseAggregateCoverageChart' />
          <ExcelColumn label='budget_TvDistributionChart' value='budget_TvDistributionChart' />
          <ExcelColumn label='budget_TV_TvIncreaseAggregateCoverageChart' value='budget_TV_TvIncreaseAggregateCoverageChart' />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
});
