import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './PresenceOfTvFlight.module.css';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import { setPresenceOfTvFlight, setIsInputDataChanged, setIsInputDataPart1Changed, setIsCBUUpload, setCbu, setTv_rf } from '../../../../../bll/inputParameters_reducer';
import { setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const PresenceOfTvFlight = React.memo(props => {
  const { setTvTrue_cbuDownload } = props;
  const dispatch = useDispatch();
  const presenceOfTvFlight = useSelector(state => state.input.presenceOfTvFlight);
  const cbu = useSelector(state => state.input.cbu);

  const handleOnChange = selectedName => {
    selectedName === 'tvFlightYes' ? dispatch(setPresenceOfTvFlight(1)) : dispatch(setPresenceOfTvFlight(0));
    selectedName === 'tvFlightNo' && dispatch(setIsCBUUpload(0));
    selectedName === 'tvFlightNo' && dispatch(setCbu(null));
    selectedName === 'tvFlightNo' && dispatch(setTv_rf([]));
    dispatch(setIsInputDataPart1Changed(true));
    dispatch(setIsInputDataChanged(true));
    dispatch(setIsChartsUpload(false));
  };
  useEffect(() => {
    (presenceOfTvFlight === 1 && cbu !== null) || (presenceOfTvFlight === 0 && cbu === null) ? setTvTrue_cbuDownload(true) : setTvTrue_cbuDownload(false);
  }, [cbu, presenceOfTvFlight, setTvTrue_cbuDownload]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        НАЛИЧИЕ TV <br /> ФЛАЙТА
      </div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('tvFlightYes');
            }}
            checked={presenceOfTvFlight === 1 ? true : false}
            label={'Да'}
            id={'tvFlightYes'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('tvFlightNo');
            }}
            checked={presenceOfTvFlight === 0 ? true : false}
            label={'Нет'}
            id={'tvFlightNo'}
          />
        </div>
      </div>
    </div>
  );
});
