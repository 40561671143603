export const COLORS = {
  DigitalAlliance: '#767171',
  GPM: '#4BA2DD',
  IVI: '#2A5378',
  myTarget: '#573755',
  NativeRoll: '#8497B0',
  qBid: '#67677B',
  RuTube: '#8C588A',
  SberSeller: '#007A37',
  TikTok: '#43434F',
  VK: '#2380BF',
  Yandex: '#C47E3E',
  red: '#e10500',
  blueGray: '#356686',
  gray: '#738fa2',
  lightGray: '#afbcc5',
  veryLightGray: '#e1e1e6',
  veryDarkGray: '#0d2e44',
  blue: '#284d64',
  skyBlue: '#a2c4da',
  white: '#fff',
  darkGreen: '#425757',
  lightGreen: '#89bbbb',
  lightPink: '#f7caad',
  pink: '#a56d76',
  green: '#008000',
  purple: '#800080',
  orange: '#caa96b',
  violet: '#360136',
  yellow: '#5c5c19',
  darkBlue: '#060679',
  darkRed: '#630707',
  darkOrange: '#caa96b8f',
  darkPurple: '#8000805d',
  darkViolet: '#2b032b',
};
