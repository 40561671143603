import React from 'react';
import s from './DownloadCBUButton.module.css';

export const DownloadCBUButton = React.memo(props => {
  const { onClick, children, isDisabled } = props;
  return (
    <div>
      <button className={s.downloadButton} onClick={onClick} disabled={isDisabled ? true : false}>
        {children}
      </button>
    </div>
  );
});
