import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../common/Select/Select';
import { setFlightsPresenceOfTvArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsBudgetTVValue = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const currentPresenceOfTv = flightsPresenceOfTvArr.find(item => item.flight === flight);
  const cpp = currentPresenceOfTv.flightCPP;

  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const currentCbuArr = flightsCbuArr.find(item => item.flight === flight);
  const cbu = currentCbuArr.cbu;
  const badgetTV = currentPresenceOfTv.flightBudgetTV;

  const findTV_budget_values = () => {
    return cbu.map(item => (item[0] * cpp).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 '));
  };
  const findTV_budget_value_view = value => {
    return ` ${value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} ₽`;
  };
  const onChangeFlightsBudgetTVValue = newValue => {
    const newValueNumber = newValue.replace(/\s/g, '');
    // const newValueNumber =  newValue.replace(/\D+/g, "")
    const updatedStateFlightsPresenceOfTvArr = flightsPresenceOfTvArr.map(item => {
      const newItem = { ...item };
      newItem.flightBudgetTV = +newValueNumber;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsPresenceOfTvArr(updatedStateFlightsPresenceOfTvArr));
    dispatch(setFlightsSomeInputDataChanged(true));
  };

  return <Select isDisabled={isDisabled} name={'flightBudgetTV'} value={findTV_budget_value_view(badgetTV)} onChange={onChangeFlightsBudgetTVValue} items={findTV_budget_values()} location={'severalFlightsBudget'} />;
});
