import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsIncome.module.css';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { setFlightsIncomeArr, setFlightsInputDataChangedArr, setFlightsIntroductoryArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsIncome = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();

  const flightsIncomeArr = useSelector(state => state.flights.flightsIncomeArr);
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const handleOnChange = (income, flight) => {
    const updatedIncome = currentFlightsIncomeArr.income.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = !selectedItem.isChecked;
      return i.income=== income ? selectedItem : i;
    });

    const updatedFlightsIncomeArr = flightsIncomeArr.map(item => {
      const newItem = { ...item };
      newItem.income = updatedIncome;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIncomeArr(updatedFlightsIncomeArr));
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return (   
    <div className={s.blockContent}>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('A', flight);
        }}
        checked={currentFlightsIncomeArr.income[0].isChecked}
        label={'A'}
        id={'A'}
      />
    </div>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('B', flight);
        }}
        checked={currentFlightsIncomeArr.income[1].isChecked}
        label={'B'}
        id={'B'}
      />
    </div>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('C', flight);
        }}
        checked={currentFlightsIncomeArr.income[2].isChecked}
        label={'C'}
        id={'C'}
      />
    </div>
  </div>
  );
});
