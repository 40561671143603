export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
export function setMillion(labelValue) {
  return (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2);
}
export function setTus(labelValue) {
  return (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2);
}

function addZero(countZero) {
  var text = '1';
  while (text.length < countZero + 1) text = text + '0';
  return +text;
}

export const roundDependingOnTheOrder = value => {
  if (value < 1000) {
    return 0;
  } else {
    const poryadok = Math.round(Math.log10(value), 0) - 3;
    const f = poryadok=== 0 ? addZero(3) : addZero(poryadok)
    const result = Math.round(value / f) * f;
    return result;
   
  }
};
export const findValueView = (value, variable) => {
  const value_withSpace_WithoutZero = round(value, 1).toLocaleString();
  const valuePopulationCityX = round(value).toLocaleString();
  const value_percent = round(value) + ' %';
  const value_TSA_percent = round(value, 1).toLocaleString() + ' %';
  const roundSetMillionValue = round(setMillion(value), 2);
  const value_withSpace = round(value).toLocaleString();
  const value_withSpace_TRP = round(value, 1).toLocaleString();
  const value_millions = `${roundSetMillionValue.toLocaleString()} ${'M'}`;
  const value_Ruble = `${round(value).toLocaleString()} ${'₽'}`;
  const roundSetTusValue = round(setTus(value));
  const value_Budget = `${round(value).toLocaleString()} ${'₽'}`;

  if (variable === 'Impressions') {
    return value_millions;
  } else if (variable === 'Budget') {
    return roundSetTusValue === 0 ? `0 ${'₽'}` : value_Budget;
  } else if (variable === 'Tsa_thousand') {
    return value_withSpace_WithoutZero;
  } else if (variable === 'Tsa_percent') {
    return value_TSA_percent;
  } else if (variable === 'Cpp') {
    return value_Ruble;
  } else if (variable === 'Share') {
    return value_percent;
  } else if (variable === 'Universe') {
    return value_withSpace;
  } else if (variable === 'TRP') {
    return value_withSpace_TRP;
  } else if (variable === 'CPM') {
    return round(value);
  } else if (variable === 'PopulationCityX' || variable === 'min' || variable === 'max') {
    return valuePopulationCityX;
  } else if (variable === 'roundBudget') {
    return valuePopulationCityX 
  }  else if (variable === 'roundReach') {
   return value_withSpace_WithoutZero
  } else if (variable === 'flightBudget') {
    return value_Budget
  } else {
    return value;
  }
};

export const makeValueMillions = value => {
  const roundSetMillionValue = round(setMillion(value), 2);
  const value_millions = `${roundSetMillionValue.toLocaleString()} ${'M'}`;
  return value_millions;
};
export const makeBudgetValue = value => {
  // const roundSetTusValue = round(setTus(value));
  const roundValue = round(value);
  const value_Budget = `${roundValue.toLocaleString()} ${'₽'}`;
  // return roundSetTusValue === 0 ? `0 ${'₽'}` : value_Budget;
  return value_Budget;
};

export const makeGeoTitle = geo => {
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const geoPopulation = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population.toLocaleString();
  if (geoPost === 'NAT') {
    return '(Россия)';
  } else if (geoPost === 'cityХ') {
    return `(Город, ${geoPopulation})`;
  } else if (geoPost === 'Moscow') {
    return '(Москва)';
  } else if (geoPost === 'Petersburg') {
    return '(Петербург)';
  }
};

export const makeGeoTitlePPT = geo => {
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  if (geoPost === 'NAT') {
    return 'Россия';
  } else if (geoPost === 'cityХ') {
    return 'Город';
  } else if (geoPost === 'Moscow') {
    return 'Москва';
  } else if (geoPost === 'Petersburg') {
    return 'Петербург';
  }
};

export const makeIncomeTitle = income => {
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  if (incomePost === 'ABC') {
    return null;
  } else {
    return incomePost;
  }
};
export const convertIncome = income => {
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  return incomePost;
};
export const makeGenderTitle = gender => {
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  if (genderPost === 'ALL') {
    return 'ВСЕ';
  } else if (genderPost === 'WOMEN') {
    return 'Ж';
  } else if (genderPost === 'MEN') {
    return 'М';
  }
};

export const makeGeoTitle_From_PostData = geoString => {
  if (geoString === 'NAT') {
    return 'Россия';
  } else if (geoString === 'cityХ') {
    return 'Город';
  } else if (geoString === 'Moscow') {
    return 'Москва';
  } else if (geoString === 'Petersburg') {
    return 'Петербург';
  }
};
export const convertFlightsGeo = (geoString, currentGeoArr) => {
  if (geoString === 'NAT') {
    return 'Россия';
  } else if (geoString === 'cityХ') {
    return currentGeoArr.geo[3].population.toLocaleString()
  } else if (geoString === 'Moscow') {
    return 'Москва';
  } else if (geoString === 'Petersburg') {
    return 'Петербург';
  }
};

export const makeLtv_From_PostData = ltvNumber => {
  if (ltvNumber === 0) {
    return 'Нет';
  } else if (ltvNumber === 1) {
    return 'Да';
  }
};

export const makePresenceOfTvFlight_From_PostData = presenceOfTvFlightNumber => {
  if (presenceOfTvFlightNumber === 0) {
    return 'Нет';
  } else if (presenceOfTvFlightNumber === 1) {
    return 'Да';
  }
};
