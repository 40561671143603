import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepButton } from '../../common/StepButton/StepButton';
import { useHistory } from 'react-router-dom';
import { setStartDataFlights } from '../../../../bll/flights_reducer'

export const FlightsIntroductory = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const currentInputDataChanged = flightsInputDataChangedArr.find(item => item.flight === flight) && flightsInputDataChangedArr.find(item => item.flight === flight).flightsInputDataChanged
 
  const onClickSetOptions = () => {
    history.push(`/flightsStep3/${flight}`);
    currentInputDataChanged && dispatch(setStartDataFlights(flight))
  }
  return (
    <StepButton onClick={onClickSetOptions} title={'Задать\n параметры'} isDisabled={false} />
  );
});
