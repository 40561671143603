import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './PublishersChoiceTable.module.css';
import { Checkbox } from '../common/Checkbox/Checkbox';
import { EditableSpan } from '../common/EditableSpan/EditableSpan';
import { Span } from '../common/Span/Span';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { setPublishers, setFinalData, setImpChangesNotPossible, setCrmChangesNotPossible, setBgtChangesNotPossible, setTotal } from '../../../bll/publishersTable_reducer';
import { findValueView } from '../../../helpers/common_helpers';
import { setIsOptimization, setOptimizationData, setRestrictions } from '../../../bll/optimization_reducer';
import { setIsChartsUpload } from '../../../bll/charts_reducer';
import { setIsInputDataChanged } from '../../../bll/inputParameters_reducer';
import { TableWarning } from '../common/TableWarning/TableWarning';

export const PublishersChoiceTable = React.memo(props => {
  const { setTotalBudgetZero } = props;
  const [PublWithBigImpressionsArr, setPublWithBigImpressionsArr] = useState([]);
  const [maxCapacityExceededWarning, setMaxCapacityExceededWarning] = useState(false);
  console.log(maxCapacityExceededWarning);
  const dispatch = useDispatch();
  const publishers = useSelector(state => state.table.publishers);
  const total = useSelector(state => state.table.total);
  const impChangesNotPossible = useSelector(state => state.table.impChangesNotPossible);
  const bgtChangesNotPossible = useSelector(state => state.table.bgtChangesNotPossible);
  const crmChangesNotPossible = useSelector(state => state.table.crmChangesNotPossible);
  const universe = useSelector(state => state.table.universe);

  const selectedPublArr = publishers.filter(item => item.isChecked === 1);
  const totalImpressions = selectedPublArr.length > 0 ? selectedPublArr.map(item => item.Impressions).reduce((a, b) => +a + +b) : 0;
  const totalBudget = selectedPublArr.length > 0 ? selectedPublArr.map(item => item.Budget).reduce((a, b) => +a + +b) : 0;
  const totalCpm = totalBudget > 0 ? (totalBudget / totalImpressions) * 1000 : 0;

  const handleOnChange = mediaName => {
    const updatedCheckedState = publishers.map(item => {
      const newItem = { ...item };
      newItem.isChecked = newItem.isChecked === 0 ? 1 : 0;
      return item.Publisher === mediaName ? newItem : item;
    });

    dispatch(setPublishers(updatedCheckedState));
    dispatch(setFinalData());
    dispatch(setIsOptimization(-1));
    dispatch(setOptimizationData({}));
    dispatch(setIsInputDataChanged(true));
    dispatch(setIsChartsUpload(false));

    const checkedPublishersArr = updatedCheckedState.filter(item => item.isChecked === 1);
    const newRestrictions = checkedPublishersArr.map(item => ({ Publisher: item.Publisher, min: null, max: null }));
    dispatch(setRestrictions(newRestrictions));
  };
  const onChangeTableValue = (newValue, mediaName, variable) => {
    if (variable === 'Budget') {
      const updatedCheckedState = publishers.map(item => {
        const newItem = { ...item };
        newItem[variable] = newValue;
        if (impChangesNotPossible === 1) {
          newItem['CPM'] = (newValue / newItem['Impressions']) * 1000;
        } else if (crmChangesNotPossible === 1) {
          newItem['Impressions'] = (newValue / newItem['CPM']) * 1000;
        }
        return item.Publisher === mediaName ? newItem : item;
      });
      dispatch(setPublishers(updatedCheckedState));
      dispatch(setFinalData());
      dispatch(setIsOptimization(-1));
      dispatch(setOptimizationData({}));
      dispatch(setIsInputDataChanged(true));
      dispatch(setIsChartsUpload(false));
    } else if (variable === 'Impressions') {
      const updatedCheckedState = publishers.map(item => {
        const newItem = { ...item };
        newItem[variable] = newValue;
        if (bgtChangesNotPossible === 1) {
          newItem['CPM'] = (newItem['Budget'] / newValue) * 1000;
        } else if (crmChangesNotPossible === 1) {
          newItem['Budget'] = (newValue * newItem['CPM']) / 1000;
        }
        return item.Publisher === mediaName ? newItem : item;
      });
      dispatch(setPublishers(updatedCheckedState));
      dispatch(setFinalData());
      dispatch(setIsOptimization(-1));
      dispatch(setOptimizationData({}));
      dispatch(setIsInputDataChanged(true));
      dispatch(setIsChartsUpload(false));
    } else if (variable === 'CPM') {
      const updatedCheckedState = publishers.map(item => {
        const newItem = { ...item };
        newItem[variable] = newValue;
        if (impChangesNotPossible === 1) {
          newItem['Budget'] = (newItem['Impressions'] * newValue) / 1000;
        } else if (bgtChangesNotPossible === 1) {
          newItem['Impressions'] = (newItem['Budget'] / newValue) * 1000;
        }
        return item.Publisher === mediaName ? newItem : item;
      });
      dispatch(setPublishers(updatedCheckedState));
      dispatch(setFinalData());
      dispatch(setIsOptimization(-1));
      dispatch(setOptimizationData({}));
      dispatch(setIsInputDataChanged(true));
      dispatch(setIsChartsUpload(false));
    } else {
      const updatedCheckedState = publishers.map(item => {
        const newItem = { ...item };
        newItem[variable] = newValue;
        return item.Publisher === mediaName ? newItem : item;
      });
      dispatch(setPublishers(updatedCheckedState));
      dispatch(setFinalData());
      dispatch(setIsOptimization(-1));
      dispatch(setOptimizationData({}));
      dispatch(setIsInputDataChanged(true));
      dispatch(setIsChartsUpload(false));
    }
  };

  const onChangeTargetCheckbox = (e, target, mediaName) => {
    const updatedState = publishers.map(item => {
      const newItem = { ...item };
      if (target === 'soc') {
        newItem.TargetingSocDem = e.currentTarget.checked ? 1 : 0;
      } else if (target === 'dr') {
        newItem.OtherTargeting = e.currentTarget.checked ? 1 : 0;
      }
      return item.Publisher === mediaName ? newItem : item;
    });
    dispatch(setPublishers(updatedState));
    dispatch(setFinalData());
    dispatch(setIsOptimization(-1));
    dispatch(setOptimizationData({}));
    dispatch(setIsInputDataChanged(true));
    dispatch(setIsChartsUpload(false));
  };
  const onClickImpLock = () => {
    dispatch(setImpChangesNotPossible(1));
    dispatch(setCrmChangesNotPossible(0));
    dispatch(setBgtChangesNotPossible(0));
  };
  const onClickCrmLock = () => {
    dispatch(setCrmChangesNotPossible(1));
    dispatch(setImpChangesNotPossible(0));
    dispatch(setBgtChangesNotPossible(0));
  };
  const onClickBgtLock = () => {
    dispatch(setBgtChangesNotPossible(1));
    dispatch(setImpChangesNotPossible(0));
    dispatch(setCrmChangesNotPossible(0));
  };

  useEffect(() => {
    totalBudget && totalBudget !== 0 ? setTotalBudgetZero(false) : setTotalBudgetZero(true);
  }, [setTotalBudgetZero, totalBudget]);
  useEffect(() => {
    const PublWittBigImpressionsArr = [];
    publishers.map(item => {
      item.Impressions > item.DefaultImpressions && PublWittBigImpressionsArr.push(item.Publisher);
      return item;
    });
    setPublWithBigImpressionsArr(PublWittBigImpressionsArr);
    PublWittBigImpressionsArr.length > 0 && setMaxCapacityExceededWarning(true);
  }, [publishers]);

  useEffect(() => {
    const newTotal = [{ total_Budget: totalBudget, total_Impressions: totalImpressions, total_CPM: totalCpm, total_Tsa_thousand: total[0].total_Tsa_percent * universe, total_Tsa_percent: total[0].total_Tsa_percent, total_TRP: total[0].total_TRP }];
    dispatch(setTotal(newTotal));
  }, [publishers]);
  return (
    <div className={s.blockWrapper}>
      {PublWithBigImpressionsArr.length > 0 && (
        <div className={s.tableWarningWrapper}>
          <TableWarning text={`Превышена максимальная емкость площадки ${PublWithBigImpressionsArr.map(item => ` ${item}`)}, рекомендуем снизить бюджет или увеличить период размещения`} setWarningVisible={setMaxCapacityExceededWarning} setPublWithBigImpressionsArr={setPublWithBigImpressionsArr} />
        </div>
      )}

      <div className={s.blockTitleMedia}>ПЛОЩАДКА</div>
      <div className={s.blockTitleBgt}>
        <FontAwesomeIcon icon={faLock} style={bgtChangesNotPossible === 1 ? { color: 'rgb(225, 5, 0)', marginRight: '3px' } : { marginRight: '3px' }} onClick={onClickBgtLock} />
        БЮДЖЕТ <br /> (руб.)
      </div>
      <div className={s.blockTitleImpress}>
        <FontAwesomeIcon icon={faLock} style={impChangesNotPossible === 1 ? { color: 'rgb(225, 5, 0)', marginRight: '3px' } : { marginRight: '3px' }} onClick={onClickImpLock} />
        ПОКАЗЫ
      </div>
      <div className={s.blockTitleCPM}>
        <FontAwesomeIcon icon={faLock} style={crmChangesNotPossible === 1 ? { color: 'rgb(225, 5, 0)', marginRight: '3px' } : { marginRight: '3px' }} onClick={onClickCrmLock} />
        CPM
      </div>
      <div className={s.blockTitleDays}>
        ПЕРИОД <br /> (ДНИ)
      </div>
      <div className={s.blockTitleFreq}>
        ОГРАНИЧЕНИЕ <br />
        ЧАСТОТЫ В МЕСЯЦ
      </div>
      <div className={s.blockTitleTsaPr}>ЦА %</div>
      <div className={s.blockTitleTsaTh}>ЦА тыс.</div>
      <div className={s.blockTitleTRP}>TRP</div>
      <div className={s.blockTitleTargetSoc}>
        ТАРГЕТ
        <br /> (soc dem)
      </div>
      <div className={s.blockTitleTargetDr}>
        ТАРГЕТ
        <br /> (other)
      </div>

      <div className={s.blockContent}>
        {publishers &&
          publishers.map(publisher => {
            return (
              <div className={publisher.isChecked === 1 ? s.item : `${s.item} ${s.text_muted}`} key={publisher.Publisher}>
                <div className={publisher.isChecked === 1 ? s.checkboxWrapper : `${s.checkboxWrapper} ${s.text_muted_for_checkbox}`}>
                  <Checkbox
                    onChangeChecked={() => {
                      handleOnChange(publisher.Publisher);
                    }}
                    checked={publisher.isChecked === 1 ? true : false}
                    label={publisher.Publisher}
                    id={`${publisher.Publisher}+${publisher.Publisher}`}
                  />
                </div>
                <div className={bgtChangesNotPossible === 1 ? `${s.editableSpanWrapper} ${s.isBlocked}` : s.editableSpanWrapper}>
                  <EditableSpan value={publisher.Budget} name={publisher.Publisher} onChange={onChangeTableValue} variable={'Budget'} />
                </div>
                <div className={impChangesNotPossible === 1 ? `${s.editableSpanWrapper} ${s.isBlocked}` : s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <EditableSpan value={publisher.Impressions} name={publisher.Publisher} onChange={onChangeTableValue} variable={'Impressions'} />
                </div>
                <div className={crmChangesNotPossible === 1 ? `${s.editableSpanWrapper} ${s.isBlocked}` : s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <EditableSpan value={publisher.CPM} name={publisher.Publisher} onChange={onChangeTableValue} variable={'CPM'} />
                </div>

                <div className={s.targetCheckboxWrapper} style={{ marginLeft: '30px', marginRight: '5px' }}>
                  <Checkbox
                    onChangeChecked={e => {
                      onChangeTargetCheckbox(e, 'soc', publisher.Publisher);
                    }}
                    checked={publisher.TargetingSocDem === 1 ? true : false}
                    label={''}
                    id={`targetSoc+ ${publisher.Publisher}`}
                    disabled={publisher.TargetingSocDem === 1 && publisher.isChecked === 0 ? true : false}
                  />
                </div>

                <div className={s.targetCheckboxWrapper} style={{ marginLeft: '30px', marginRight: '5px' }}>
                  <Checkbox
                    onChangeChecked={e => {
                      onChangeTargetCheckbox(e, 'dr', publisher.Publisher);
                    }}
                    checked={publisher.OtherTargeting === 1 ? true : false}
                    label={''}
                    id={`targetDr+ ${publisher.Publisher}`}
                    disabled={publisher.OtherTargeting === 1 && publisher.isChecked === 0 ? true : false}
                  />
                </div>

                <div className={s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <EditableSpan value={publisher.Days} name={publisher.Publisher} onChange={onChangeTableValue} variable={'Days'} />
                </div>
                <div className={s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <EditableSpan value={publisher.FrequencyLimit} name={publisher.Publisher} onChange={onChangeTableValue} variable={'FrequencyLimit'} />
                </div>
                <div className={s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <Span value={publisher.Tsa_percent} variable={'Tsa_percent'} />
                </div>
                <div className={s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <Span value={(publisher.Tsa_percent * universe) / 100} variable={'Tsa_thousand'} />
                </div>
                <div className={s.editableSpanWrapper} style={{ marginLeft: '8px' }}>
                  <Span value={publisher.TRP} variable={'TRP'} />
                </div>
              </div>
            );
          })}

        <div className={s.totalWrapper}>
          <div style={{ width: '92px', marginLeft: '15px', paddingLeft: '5px' }}>TOTAL</div>
          <div style={{ marginLeft: '5px', marginTop: '5px' }}>
            <div className={s.totalCell}>{findValueView(totalBudget, 'Budget')}</div>
          </div>
          <div style={{ marginLeft: '8px', marginTop: '5px' }}>
            <div className={s.totalCell}>{findValueView(totalImpressions, 'Impressions')}</div>
          </div>
          <div style={{ marginLeft: '8px', marginTop: '5px' }}>
            <div className={s.totalCell}>{findValueView(totalCpm, 'CPM')}</div>
          </div>
          <div style={{ marginLeft: '441px', marginTop: '5px' }}>
            <div className={s.totalCell}>{findValueView(total[0].total_Tsa_percent * 100, 'Tsa_percent')}</div>
          </div>
          <div style={{ marginLeft: '8px', marginTop: '5px' }}>
            <div className={s.totalCell}>{findValueView((total[0].total_Tsa_percent * 100 * universe) / 100, 'Tsa_thousand')}</div>
          </div>
          <div style={{ marginLeft: '8px', marginTop: '5px' }}>
            <div className={s.totalCell}>{findValueView(total[0].total_TRP, 'TRP')}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
