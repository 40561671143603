export const variablesOptDistrArr = [
  '01olv',
  '01tv',
  '01tv_olv',
  '01olv_bud',
  '01tv_bud',
  '01full_bud',
  '02olv',
  '02tv',
  '02tv_olv',
  '02olv_bud',
  '02tv_bud',
  '02full_bud',
  '03olv',
  '03tv',
  '03tv_olv',
  '03olv_bud',
  '03tv_bud',
  '03full_bud',
  '04olv',
  '04tv',
  '04tv_olv',
  '04olv_bud',
  '04tv_bud',
  '04full_bud',
  '05olv',
  '05tv',
  '05tv_olv',
  '05olv_bud',
  '05tv_bud',
  '05full_bud',
  '06olv',
  '06tv',
  '06tv_olv',
  '06olv_bud',
  '06tv_bud',
  '06full_bud',
  '07olv',
  '07tv',
  '07tv_olv',
  '07olv_bud',
  '07tv_bud',
  '07full_bud',
  '08olv',
  '08tv',
  '08tv_olv',
  '08olv_bud',
  '08tv_bud',
  '08full_bud',
  '09olv',
  '09tv',
  '09tv_olv',
  '09olv_bud',
  '09tv_bud',
  '09full_bud',
  '10olv',
  '10tv',
  '10tv_olv',
  '10olv_bud',
  '10tv_bud',
  '10full_bud',
  '11olv',
  '11tv',
  '11tv_olv',
  '11olv_bud',
  '11tv_bud',
  '11full_bud',
  '12olv',
  '12tv',
  '12tv_olv',
  '12olv_bud',
  '12tv_bud',
  '12full_bud',
  '13olv',
  '13tv',
  '13tv_olv',
  '13olv_bud',
  '13tv_bud',
  '13full_bud',
  '14olv',
  '14tv',
  '14tv_olv',
  '14olv_bud',
  '14tv_bud',
  '14full_bud',
  '15olv',
  '15tv',
  '15tv_olv',
  '15olv_bud',
  '15tv_bud',
  '15full_bud',
  '16olv',
  '16tv',
  '16tv_olv',
  '16olv_bud',
  '16tv_bud',
  '16full_bud',
  '17olv',
  '17tv',
  '17tv_olv',
  '17olv_bud',
  '17tv_bud',
  '17full_bud',
  '18olv',
  '18tv',
  '18tv_olv',
  '18olv_bud',
  '18tv_bud',
  '18full_bud',
  '19olv',
  '19tv',
  '19tv_olv',
  '19olv_bud',
  '19tv_bud',
  '19full_bud',
];

export const variablesOptSplitArr = ['01olv', '01tv', '01tv_olv', '02olv', '02tv', '02tv_olv', '03olv', '03tv', '03tv_olv', '04olv', '04tv', '04tv_olv', '05olv', '05tv', '05tv_olv', '06olv', '06tv', '06tv_olv', '07olv', '07tv', '07tv_olv', '08olv', '08tv', '08tv_olv', '09olv', '09tv', '09tv_olv', '10olv', '10tv', '10tv_olv', '11olv', '11tv', '11tv_olv', '12olv', '12tv', '12tv_olv', '13olv', '13tv', '13tv_olv', '14olv', '14tv', '14tv_olv', '15olv', '15tv', '15tv_olv', '16olv', '16tv', '16tv_olv', '17olv', '17tv', '17tv_olv', '18olv', '18tv', '18tv_olv', '19olv', '19tv', '19tv_olv'];

export const variablesOLV_CBU_CPRP_Arr = ['01reach', '01incr_reach', '01cprp', '02reach', '02incr_reach', '02cprp', '03reach', '03incr_reach', '03cprp', '04reach', '04incr_reach', '04cprp', '05reach', '05incr_reach', '05cprp', '06reach', '06incr_reach', '06cprp', '07reach', '07incr_reach', '07cprp', '08reach', '08incr_reach', '08cprp', '09reach', '09incr_reach', '09cprp', '10reach', '10incr_reach', '10cprp', '11reach', '11incr_reach', '11cprp', '12reach', '12incr_reach', '12cprp', '13reach', '13incr_reach', '13cprp', '14reach', '14incr_reach', '14cprp', '15reach', '15incr_reach', '15cprp', '16reach', '16incr_reach', '16cprp', '17reach', '17incr_reach', '17cprp', '18reach', '18incr_reach', '18cprp', '19reach', '19incr_reach', '19cprp'];

export const freqStrArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];

export const variables = ['olv', 'tv', 'tv_olv'];
