import React from 'react';
import s from './TableWarning.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const TableWarning = React.memo(props => {
  const { text, setWarningVisible, setPublWithBigImpressionsArr } = props;
  const onCloseWarning = () => {
    setWarningVisible(false);
    setPublWithBigImpressionsArr([]);
  };
  return (
    <div className={s.warningCard}>
      {text}
      <div className={s.closeButtonWrapper}>
        <FontAwesomeIcon icon={faTimes} onClick={onCloseWarning} />
      </div>
    </div>
  );
});
