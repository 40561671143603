import React from 'react';
import s from './TableButton.module.css';

export const TableButton = React.memo(props => {
  const { onClick, redTitle, blackTitle, isDisabled, children } = props;
  return (
    <div>
      <button className={s.tableButton} onClick={onClick} disabled={isDisabled ? true : false}>
        <div className={s.icon}>{children}</div>
        <div className={s.title}>
          <div className={isDisabled ? s.redTitleDisabled : s.redTitle}>{redTitle}</div>
          <div>{blackTitle}</div>
        </div>
      </button>
    </div>
  );
});
