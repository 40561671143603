import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step5SeveralFlightsPage.module.css';
import { PageHeaderFlights } from '../../components/PageHeaderFlights/PageHeaderFlights';
import { RedButton } from '../../components/common/RedButton/RedButton';
import { Select } from '../../components/common/Select/Select';
import { Delimiter } from '../../components/common/Delimiter/Delimiter';
import { DelimiterOpt } from '../../components/common/DelimiterOpt/DelimiterOpt';
import { useHistory, useParams } from 'react-router-dom';
import { findValueView } from '../../../helpers/common_helpers';
import { OlvDoughnutChart } from '../../components/Charts/OlvDoughnutChart/OlvDoughnutChart';
import { OlvContributionOfEachMedia } from '../../components/Charts/OlvContributionOfEachMedia/OlvContributionOfEachMedia';
import { OlvDistributionChart } from '../../components/Charts/OlvDistributionChart/OlvDistributionChart';
import { OlvCbuCprpChart } from '../../components/Charts/OlvCbuCprpChart/OlvCbuCprpChart';
import { TvDistributionChart } from '../../components/Charts/TvDistributionChart/TvDistributionChart';
import { TvIncreaseAggregateCoverageChart } from '../../components/Charts/TvIncreaseAggregateCoverageChart/TvIncreaseAggregateCoverageChart';
import { ToggleSwitch } from '../../components/common/ToggleSwitch/TiggleSwitch';
import { OptInvestmentChart } from '../../components/Charts/OptInvestmentChart/OptInvestmentChart';
import { OptComparisonChart } from '../../components/Charts/OptComparisonChart/OptComparisonChart';
import { EditableSpan } from '../../components/common/EditableSpan/EditableSpan';
import { changeFlightOlvDistributionChart, changeFlightOlvContributionOfEachMedia, changeFlightOlvCbuCprpChart, changeFlightChartsOnChangeFreqAll, changeFlightTvDistributionChart, changeFlightTvIncreaseAggregateCoverageChart } from '../../../bll/flights_charts_reducer';
import { getStartIp } from '../../../bll/app_reducer';

export const Step5SeveralFlightsPage = React.memo(props => {
  const history = useHistory();
  const currentFlight = +useParams().flightId;
  const dispatch = useDispatch();

  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === currentFlight);
  const mainMedia = currentFlightChartsData.mainMedia;
  const mainMediaArr = currentFlightChartsData.mainMediaArr;
  const N_OLV = currentFlightChartsData.N_OLV;
  const N_TV = currentFlightChartsData.N_TV;
  const freqAll = currentFlightChartsData.freqAll;
  const budget_OlvCbuCprpChart = currentFlightChartsData.budget_OlvCbuCprpChart;
  const budgets_TvIncreaseAggregateCoverageChart = currentFlightChartsData.budgets_TvIncreaseAggregateCoverageChart;

  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const currentFlightsTableParameters = flightsTableParametersArr.find(item => item.flight === currentFlight);
  const universe = currentFlightsTableParameters.universe;
  const share = currentFlightsTableParameters.share;

  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const currentFlightsPresenceOfTvArr = flightsPresenceOfTvArr.find(item => item.flight === currentFlight);
  const cpp = currentFlightsPresenceOfTvArr.flightCPP;
  const flightBudgetTV = currentFlightsPresenceOfTvArr.flightBudgetTV;

  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);
  const currentOptimization = flightsOptimizationArr.find(item => item.flight === currentFlight);
  const isOptimization = currentOptimization.isOptimization;
  const freq = currentOptimization.freq;

  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const currentFlightsCbuArr = flightsCbuArr.find(item => item.flight === currentFlight);
  const isCBUUpload = currentFlightsCbuArr.isCBUUpload;
  const cbu = currentFlightsCbuArr.cbu;

  const olvCbuCprpChartData = currentFlightChartsData.olvCbuCprpChartData;
  const olvContributionOfEachMedia_data = currentFlightChartsData.olvContributionOfEachMedia_data;
  const olvDistribution_data = currentFlightChartsData.olvDistribution_data;
  const olvDonutData = currentFlightChartsData.olvDonutData;
  const optComparisonChart_data = currentFlightChartsData.optComparisonChart_data;
  const optInvestmentChart_data = currentFlightChartsData.optInvestmentChart_data;
  const tvDistributionChart_data = currentFlightChartsData.tvDistributionChart_data;
  const tvIncreaseAggregateCoverageChart_data = currentFlightChartsData.tvIncreaseAggregateCoverageChart_data;

  const findTV_budget_values = () => {
    return cbu.map(item => (item[0] * cpp).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 '));
  };
  const findTV_budget_value_view = value => {
    return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  };

  const onClick_OLV_ToggleSwitch = e => {
    const currentN_OLV = e.currentTarget.checked ? 1 : 0;
    dispatch(changeFlightOlvDistributionChart(currentN_OLV, currentFlight));
  };
  const onClick_TV_ToggleSwitch = e => {
    const currentN_TV = e.currentTarget.checked ? 1 : 0;
    dispatch(changeFlightTvDistributionChart(currentN_TV, currentFlight));
  };
  const onClickBackButton = () => {
    history.push('/flightsStep4');
    flightArr.map(item => {
      const currentFrequency = flightsFrequencyArr.find(fr => fr.flight === item.id).frequency;
      const currentFreqAll = flightsChartsData && flightsChartsData.find(flData => flData.flight === item.id).freqAll;
      currentFrequency !== currentFreqAll && dispatch(changeFlightChartsOnChangeFreqAll(currentFrequency, item.id));
      return item;
    });
  };
  const onChangeFreqAll = selectItem => {
    dispatch(changeFlightChartsOnChangeFreqAll(selectItem, currentFlight));
  };
  const onChange_budget_OlvCbuCprpChart = newValue => {
    dispatch(changeFlightOlvCbuCprpChart(newValue, currentFlight));
  };
  const onChangeMainMedia = selectItem => {
    dispatch(changeFlightOlvContributionOfEachMedia(selectItem, currentFlight));
  };
  const onChange_olv_budget_TvIncreaseAggregateCoverageChart = newValue => {
    dispatch(changeFlightTvIncreaseAggregateCoverageChart(flightBudgetTV, newValue, currentFlight));
  };

  useEffect(() => {
    dispatch(getStartIp(history));
  }, []);
  return (
    <div className={s.step4Wrapper}>
      <div className={s.fixedBlock}>
        <PageHeaderFlights flight={currentFlight} pageHeaderTitle={'Результат'} pageHeaderNumber={'4.'} withRedTitle />
        <div className={s.step4Content}>
          <div className={s.buttonsWrapper}>
            <div className={s.leftPartWrapper}>
              <RedButton onClick={onClickBackButton} title={'Вернуться назад'} />
            </div>
            <div className={s.rightPartWrapper}>
              <div className={s.frequencyWrapper}>
                <div>
                  Частота, на которую <br /> смотрим результаты
                </div>
                {freqAll !== null && <Select value={freqAll} onChange={onChangeFreqAll} items={['1 +', '2 +', '3 +', '4 +', '5 +', '6 +', '7 +', '8 +', '9 +', '10 +', '11 +', '12 +', '13 +', '14 +', '15 +', '16 +', '17 +', '18 +', '19 +']} />}
              </div>
              <div className={s.universeWrapper}>
                <div>Universe: {findValueView(universe, 'Universe')}</div>
                <div>Share: {findValueView(share, 'Share')} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.chartsWrapper}>
        <Delimiter title={'OLV'} />

        <div className={s.twoChartsWrapper}>
          <div className={s.oneChartWrapper}>
            <h4 className={s.chartTitle}>Вклад каждой площадки в совокупный охват на частоте {freqAll}</h4>
            <div className={s.chooseMainMediaBlockWrapper}>
              <p className={s.selectTitle}>Основное медиа: </p>
              {mainMedia !== null && (
                <div className={s.selectWrapper}>
                  <Select value={mainMedia} onChange={onChangeMainMedia} items={mainMediaArr} location={'contr'} />
                </div>
              )}
            </div>
            <OlvContributionOfEachMedia olvContributionOfEachMedia_data={olvContributionOfEachMedia_data} />
          </div>
          <div className={s.oneChartWrapper}>
            <h4 className={s.chartTitle}>Распределение бюджета по площадкам (млн. ₽)</h4>
            <OlvDoughnutChart olvDonutData={olvDonutData} />
          </div>
        </div>

        <div className={s.twoChartsWrapper}>
          <div className={s.oneChartWrapper}>
            <div className={s.toggleSwitchWrapper}>
              <h4 className={s.chartTitle}>Распределение Reach / Frequency (OLV)</h4>
              <ToggleSwitch
                onChangeChecked={e => {
                  onClick_OLV_ToggleSwitch(e);
                }}
                checked={N_OLV === 1 ? true : false}
                labelStart={'N+'}
                labelEnd={'N'}
                id={'toggle_switch_distribution'}
              />
            </div>

            <OlvDistributionChart olvDistribution_data={olvDistribution_data} />
          </div>
          <div className={s.oneChartWrapper}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={s.chartTitle}>OLV: CBU & CPRP на частоте {freqAll}</h4>
              <EditableSpan value={budget_OlvCbuCprpChart} name={'budget_OlvCbuCprpChart'} onChange={onChange_budget_OlvCbuCprpChart} variable={'PopulationCityX'} location={'budget'} borderTitle={'OLV Бюджет'} />
            </div>

            <OlvCbuCprpChart olvCbuCprpChartData={olvCbuCprpChartData} freqAll={freqAll} />
          </div>
        </div>

        {isOptimization > -1 && (
          <>
            <DelimiterOpt title={`OLV на частоту ${freq} после использования функции “Оптимизировать OLV бюджет” на шаге №3`} />

            <div className={s.twoChartsWrapper}>
              <div className={s.oneChartWrapper}>
                <h4 className={s.chartTitle}>Влияние прироста инвестиций в рекламу на микс площадок и совокупный охват (на заданную частоту на шаге #3 при использовании функции “Оптимизировать OLV бюджет”)</h4>
                <OptInvestmentChart optInvestmentChart_data={optInvestmentChart_data} />
              </div>
              <div className={s.oneChartWrapper}>
                <h4 className={s.chartTitle}>Сравнение различных миксов площадок на идентичный бюджет и их влияние на совокупный охват (на заданную частоту на шаге #3 при использовании функции “Оптимизировать OLV бюджет”)</h4>
                <OptComparisonChart optComparisonChart_data={optComparisonChart_data} />
              </div>
            </div>
          </>
        )}

        {isCBUUpload === 1 && (
          <>
            <Delimiter title={'TV & OLV'} />
            <div className={s.twoChartsWrapper}>
              <div className={s.oneChartWrapper}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4 className={s.chartTitle}>Распределение Reach / Frequency (TV & OLV)</h4>
                  <div style={{ display: 'flex' }}>
                    <Select value={findTV_budget_value_view(flightBudgetTV)} onChange={() => {}} items={findTV_budget_values()} borderTitle={'TV Бюджет'} location={'chart'} isDisabled={true} />
                    <ToggleSwitch
                      onChangeChecked={e => {
                        onClick_TV_ToggleSwitch(e);
                      }}
                      checked={N_TV === 1 ? true : false}
                      labelStart={'N+'}
                      labelEnd={'N'}
                      id={'toggle_switch_TvDistributionChart'}
                    />
                  </div>
                </div>

                <TvDistributionChart tvDistributionChart_data={tvDistributionChart_data} />
              </div>
              <div className={s.oneChartWrapper} style={{ position: 'relative' }}>
                <h4 className={s.chartTitle}>Рост совокупного охвата TV & OLV при фиксированном бюджете TV и нефиксированном бюджете OLV</h4>
                <div style={{ display: 'flex', justifyContent: 'end', position: 'absolute', top: '10%', left: '50%' }}>
                  <Select value={findTV_budget_value_view(flightBudgetTV)} onChange={() => {}} items={findTV_budget_values()} borderTitle={'TV Бюджет'} location={'chart'} isDisabled={true} />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <EditableSpan value={budgets_TvIncreaseAggregateCoverageChart.olvBudget} name={'budgets_TvIncreaseAggregateCoverageChart.olvBudget'} onChange={onChange_olv_budget_TvIncreaseAggregateCoverageChart} variable={'PopulationCityX'} location={'budget'} borderTitle={'OLV Бюджет'} />
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <TvIncreaseAggregateCoverageChart tvIncreaseAggregateCoverageChart_data={tvIncreaseAggregateCoverageChart_data} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
