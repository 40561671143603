import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsAge.module.css';
import { InputNumber } from '../../common/InputNumber/InputNumber';
import { setFlightsAgeArr, setFlightsInputDataChangedArr, setFlightsIntroductoryArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsAge = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();

  const flightsAgeArr = useSelector(state => state.flights.flightsAgeArr);
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const onChangeAgemin = (e, flight) => {
    const updatedflightsAgeArr = flightsAgeArr.map(item => {
      const newItem = { ...item };
      newItem.agemin = +e.currentTarget.value;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsAgeArr(updatedflightsAgeArr));
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };
  const onChangeAgemax = (e, flight) => {
    const updatedflightsAgeArr = flightsAgeArr.map(item => {
      const newItem = { ...item };
      newItem.agemax = +e.currentTarget.value;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsAgeArr(updatedflightsAgeArr));
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return (   
       <div className={s.blockContent}>
          <p className={s.p}>ОТ (ВКЛЮЧАЯ)</p>
          <InputNumber value={currentAge.agemin} onChange={(e)=>{onChangeAgemin(e, flight)}} />
          <p className={s.p}>ДО (ВКЛЮЧАЯ)</p>
          <InputNumber value={currentAge.agemax} onChange={(e)=> {onChangeAgemax(e, flight)}} />
      </div>  
  );
});
