import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsDownloadCbu.module.css';
import { DownloadCBUButton } from '../../common/DownloadCBUButton/DownloadCBUButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import SampleCBU from '../../../../assets/sample/cbu_input.xlsx';
import * as XLSX from 'xlsx';
import { setFlightsCbuArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';
import { setError } from '../../../../bll/app_reducer';

export const FlightsDownloadCbu = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();
  const fileRef = React.createRef();
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const presenceOfTvFlight = flightsPresenceOfTvArr.find(item => item.flight === flight).presenceOfTvFlight;
  const currentCbu = flightsCbuArr.find(item => item.flight === flight); 
  const isCBUUpload = currentCbu.isCBUUpload
  
  const onClickUploadCBU = () => {
    fileRef.current.click();
  };
  const onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data
              .concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
              .map(item => ({
                TRP: +item['TRP'],
                'R1+': +item['R1+'],
                'R2+': +item['R2+'],
                'R3+': +item['R3+'],
                'R4+': +item['R4+'],
                'R5+': +item['R5+'],
                'R6+': +item['R6+'],
                'R7+': +item['R7+'],
                'R8+': +item['R8+'],
                'R9+': +item['R9+'],
                'R10+': +item['R10+'],
                'R11+': +item['R11+'],
                'R12+': +item['R12+'],
                'R13+': +item['R13+'],
                'R14+': +item['R14+'],
                'R15+': +item['R15+'],
                'R16+': +item['R16+'],
                'R17+': +item['R17+'],
                'R18+': +item['R18+'],
                'R19+': +item['R19+'],
                'R20+': +item['R20+'],
              }))
              .map(item => Object.values(item));
          }
        }


        const updatedFlightsCbuArr = flightsCbuArr.map(item => {
          const newItem = { ...item };
          newItem.cbu = data;
          newItem.isCBUUpload = 1;
          return item.flight === flight ? newItem : item;
        });
        dispatch(setFlightsCbuArr(updatedFlightsCbuArr));
        dispatch(setFlightsSomeInputDataChanged(true));
      } catch (error) {
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };
  return (   
    <div className={s.blockContent}>
    <DownloadCBUButton onClick={onClickUploadCBU} isDisabled={presenceOfTvFlight === 0 ? true : false}>
      {isCBUUpload === 1 ? (
        <div style={{ color: 'green' }}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      ) : (
        <FontAwesomeIcon icon={faPlus} />
      )}
    </DownloadCBUButton>
    <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
    <div className={s.linkWrapper}>
      Образец CBU
      <br /> можно скачать
      <br />
      <a className={s.link} href={SampleCBU} download='cbu_input.xlsx'>
        здесь
      </a>
    </div>
  </div>
  );
});
