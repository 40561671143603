import React from 'react';
import { useSelector } from 'react-redux';
import pptxgen from 'pptxgenjs';
import { COLORS } from '../../../assets/styles/colors/colors';
import { findName } from '../../../helpers/chart_helpers';
import colorStar from '../../../assets/images/Picture1.png';
import grayBackground from '../../../assets/images/grayBackground.jpg';
import logoStar from '../../../assets/images/logoStar.png';

import olvBig from '../../../assets/images/olvBig2.png';
import opt from '../../../assets/images/opt2.png';
import optTv from '../../../assets/images/optTv.png';
import tv from '../../../assets/images/tv2.png';
import olv from '../../../assets/images/olv2.png';
import tvBig from '../../../assets/images/tvBig2.png';
import optBig from '../../../assets/images/optBig2.png';

export const ExportPPTFlights = React.memo(props => {
  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const dataFromXL_Flights = useSelector(state => state.flights_charts.dataFromXL_Flights);
  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);

  let pptx = new pptxgen();
  pptx.layout = 'LAYOUT_WIDE';
  pptx.layout = 'LAYOUT_WIDE';
  pptx.defineSlideMaster({
    title: 'MASTER_SLIDE',
    objects: [{ text: { text: 'VideoTron', options: { x: '11%', y: '7%', w: 5.5, h: 0.75 } } }, { image: { y: '8%', w: '100%', h: '8%', data: colorStar } }, { text: { text: 'Источник: https://videotron.mediainstinctgroup.ru', options: { x: 1, y: '90%', w: 5.5, h: 0.75, fontSize: 11 } } }],
    slideNumber: { x: '94%', y: '9%' },
  });
  let slide = pptx.addSlide({ masterName: 'MASTER' });
  slide.background = { path: grayBackground };
  slide.addImage({ path: logoStar, x: 1, y: 1, w: '16%', h: '11%' });
  slide.addText('Результат планирования', { x: '10%', y: '30%', w: '100%', h: 3.2, color: '#e10500', fontSize: 70, bold: true });
  slide.addText('VideoTron', { x: '70%', y: '60%', w: 6, h: 3.2, color: '#080808', fontSize: 45, bold: true });

  flightsChartsData.map(item => {
    const FLIGHT = flightArr.find(i => i.id === item.flight) && flightArr.find(i => i.id === item.flight).flight;
    const freqAll = item.freqAll;
    const ISOPTIMIZATION = flightsOptimizationArr.find(i => i.flight === item.flight) && flightsOptimizationArr.find(i => i.flight === item.flight).isOptimization;
    const ISCBUUPLOAD = flightsCbuArr.find(i => i.flight === item.flight) && flightsCbuArr.find(i => i.flight === item.flight).isCBUUpload;

    const olvDonutData = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).olvDonutData;
    const olvContributionOfEachMedia_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).olvContributionOfEachMedia_data;
    const olvDistribution_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).olvDistribution_data;
    const olvCbuCprpChartData = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).olvCbuCprpChartData;
    const optComparisonChart_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).optComparisonChart_data;
    const optInvestmentChart_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).optInvestmentChart_data;
    const tvDistributionChart_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).tvDistributionChart_data;
    const tvIncreaseAggregateCoverageChart_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).tvIncreaseAggregateCoverageChart_data;

    const colorsArray = item.colorsArray;
    const donutAllColorsArray = olvDonutData.labels.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const RFOpen = !flightsUpload ? item.chartsData.RF['Total_media_rf_n+'] : Object.values(dataFromXL_Flights.filter(i => i.flight === item.flight).filter(i => i['OLVReachN+'])[0]).slice(0, -3);
    const RFClose = !flightsUpload ? item.chartsData.RF['Total_media_rf_n'] : Object.values(dataFromXL_Flights.filter(i => i.flight === item.flight).filter(i => i['OLVReachN'])[0]).slice(0, -3);

    if (ISOPTIMIZATION === -1 && ISCBUUPLOAD === 0) {
      let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide1.addText(`Вклад каждой площадки в совокупный охват на частоте ${freqAll}`, { x: 2.5, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addText(`(Флайт ${FLIGHT})`, { x: '50%', y: 0.5, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide1.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide1.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsChartBar1 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendFontSize: 16,
        barDir: 'bar',
        catAxisOrientation: 'maxMin',
        catAxisLabelColor: '808080',
        barGrouping: 'stacked',
        dataLabelColor: 'FFFFFF',
        showValue: true,
        chartColors: [COLORS.red],
        valAxisLabelColor: '808080',
        dataLabelFormatCode: '0.00',

        showValAxisTitle: true,
        valAxisTitle: 'Охват ЦА, %',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'top',

        showCatAxisTitle: true,
        catAxisTitle: 'Площадки',
        catAxisTitleFontSize: 14,
      };
      let single = [
        {
          name: 'Conrtibution',
          labels: olvContributionOfEachMedia_data.labels,
          values: olvContributionOfEachMedia_data.datasets[0].data,
        },
      ];
      slide1.addChart(pptx.charts.BAR, single, optsChartBar1);

      let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      const dataChartPieStat = [
        {
          name: 'Pie',
          labels: olvDonutData.labels,
          values: olvDonutData.datasets[0].data,
        },
      ];
      slide2.addText(`Распределение бюджета по площадкам (Флайт ${FLIGHT})`, { x: 2.5, y: 0, w: 8, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide2.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide2.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide2.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsPie = {
        x: 2.5,
        y: 2,
        w: '60%',
        h: '60%',
        legendPos: 't',
        legendFontFace: 'Roboto',
        legendFontSize: 16,
        showLegend: true,
        showLeaderLines: true,
        legendColor: '808080',
        showPercent: false,

        showValue: true,
        chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        dataBorder: { pt: '1', color: 'F1F1F1' },
        dataLabelColor: 'FFFFFF',
        dataLabelFormatCode: '#,##0 ₽',
        dataLabelFontSize: 12,
        dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
      };
      slide2.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsPie);

      let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide3.addText(`Распределение Reach / Frequency (OLV) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide3.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide3.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide3.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let arrOlvDistribution_data = [
        {
          name: 'Открытые',
          labels: olvDistribution_data.labels,
          values: RFOpen,
        },
        {
          name: 'Закрытые',
          labels: olvDistribution_data.labels,
          values: RFClose,
        },
      ];
      let olvChart3 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.red, COLORS.veryDarkGray],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,

        showValAxisTitle: true,
        valAxisTitle: 'Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Частота',
        catAxisTitleFontSize: 14,
      };
      slide3.addChart(pptx.charts.BAR, arrOlvDistribution_data, olvChart3);

      let slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide4.addText(`OLV: CBU & CPRP на частоте ${freqAll} (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide4.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide4.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide4.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });

      let arrOlvCbuCprpChartData = [
        {
          name: 'Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[0].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'Incr.Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[1].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'CPRP',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[2].data.map(item => (item === 'null' ? null : item)),
        },
      ];

      let olvChart4 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',

        chartColors: [COLORS.red, COLORS.veryDarkGray, COLORS.gray],
        catAxisLabelColor: '808080',
        valAxisLabelColor: '808080',
        lineDataSymbol: 'none',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,
        valAxisMinVal: 0,
        catAxisMinVal: 0,
        valAxisMaxVal: 100,

        showValAxisTitle: true,
        valAxisTitle: 'Инкрементал Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Бюджет (млн. ₽)',
        catAxisTitleFontSize: 14,
      };

      slide4.addChart(pptx.charts.LINE, arrOlvCbuCprpChartData, olvChart4);
    } else if (ISOPTIMIZATION > -1 && ISCBUUPLOAD === 0) {
      let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide1.addText(`Вклад каждой площадки в совокупный охват на частоте ${freqAll}`, { x: 2.5, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addText(`(Флайт ${FLIGHT})`, { x: '50%', y: 0.5, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide1.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide1.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsChartBar1 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendFontSize: 16,
        barDir: 'bar',
        catAxisOrientation: 'maxMin',
        catAxisLabelColor: '808080',
        barGrouping: 'stacked',
        dataLabelColor: 'FFFFFF',
        showValue: true,
        chartColors: [COLORS.red],
        valAxisLabelColor: '808080',
        dataLabelFormatCode: '0.00',

        showValAxisTitle: true,
        valAxisTitle: 'Охват ЦА, %',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'top',

        showCatAxisTitle: true,
        catAxisTitle: 'Площадки',
        catAxisTitleFontSize: 14,
      };
      let single = [
        {
          name: 'Conrtibution',
          labels: olvContributionOfEachMedia_data.labels,
          values: olvContributionOfEachMedia_data.datasets[0].data,
        },
      ];
      slide1.addChart(pptx.charts.BAR, single, optsChartBar1);

      let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      const dataChartPieStat = [
        {
          name: 'Pie',
          labels: olvDonutData.labels,
          values: olvDonutData.datasets[0].data,
        },
      ];
      slide2.addText(`Распределение бюджета по площадкам (Флайт ${FLIGHT})`, { x: 2.5, y: 0, w: 8, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide2.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide2.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide2.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsPie = {
        x: 2.5,
        y: 2,
        w: '60%',
        h: '60%',
        legendPos: 't',
        legendFontFace: 'Roboto',
        legendFontSize: 16,
        showLegend: true,
        showLeaderLines: true,
        legendColor: '808080',
        showPercent: false,

        showValue: true,
        chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        dataBorder: { pt: '1', color: 'F1F1F1' },
        dataLabelColor: 'FFFFFF',
        dataLabelFormatCode: '#,##0 ₽',
        dataLabelFontSize: 12,
        dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
      };
      slide2.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsPie);

      let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide3.addText(`Распределение Reach / Frequency (OLV) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide3.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide3.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide3.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let arrOlvDistribution_data = [
        {
          name: 'Открытые',
          labels: olvDistribution_data.labels,
          values: RFOpen,
        },
        {
          name: 'Закрытые',
          labels: olvDistribution_data.labels,
          values: RFClose,
        },
      ];
      let olvChart3 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.red, COLORS.veryDarkGray],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,

        showValAxisTitle: true,
        valAxisTitle: 'Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Частота',
        catAxisTitleFontSize: 14,
      };
      slide3.addChart(pptx.charts.BAR, arrOlvDistribution_data, olvChart3);

      let slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide4.addText(`OLV: CBU & CPRP на частоте ${freqAll} (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide4.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide4.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide4.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });

      let arrOlvCbuCprpChartData = [
        {
          name: 'Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[0].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'Incr.Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[1].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'CPRP',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[2].data.map(item => (item === 'null' ? null : item)),
        },
      ];

      let olvChart4 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',

        chartColors: [COLORS.red, COLORS.veryDarkGray, COLORS.gray],
        catAxisLabelColor: '808080',
        valAxisLabelColor: '808080',
        lineDataSymbol: 'none',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,
        valAxisMinVal: 0,
        catAxisMinVal: 0,
        valAxisMaxVal: 100,

        showValAxisTitle: true,
        valAxisTitle: 'Инкрементал Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Бюджет (млн. ₽)',
        catAxisTitleFontSize: 14,
      };

      slide4.addChart(pptx.charts.LINE, arrOlvCbuCprpChartData, olvChart4);

      let slide5 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide5.addText(`Влияние прироста инвестиций в рекламу на микс площадок и совокупный охват (на заданную частоту в поле оптимизации бюджета ) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide5.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' }).addImage({ path: tv, x: -0.01, y: 5.03, w: '9%', h: '19%' });
      slide5.addImage({ path: optBig, x: -0.2999, y: 3.5, w: '15%', h: '26%' });

      function doChartSlide5() {
        let opts = {
          x: 1.5,
          y: 2.7,
          w: '80%',
          h: '50%',
          catGridLine: { style: 'none' },
          valGridLine: { style: 'none' },
          legendFontSize: 16,
          barDir: 'col',
          barGrouping: 'stacked',
          chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          legendPos: 'b',
          legendColor: '808080',
          showLegend: true,
          showValue: true,
          dataLabelColor: '000000',
          dataLabelFontSize: 12,
          catAxisLabelColor: '808080',
          valAxisLabelColor: '808080',
          dataLabelFormatCode: '0.00',

          valAxes: [
            {
              showValAxisTitle: true,
              valAxisTitle: 'Затраты на медиа (млн. руб)',
              valAxisTitleFontSize: 14,
              valAxisMinVal: 0,
              valAxisDisplayUnit: 'millions',
            },
            {
              showValAxisTitle: true,
              valAxisTitle: 'Совокупный охват (%)',
              valAxisTitleFontSize: 14,
              catAxisOrientation: 'maxMin',
              valAxisMajorUnit: 10,
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: '',
            },
            {
              catAxisHidden: true,
            },
          ],
        };
        const growthDataWithoutReach = optInvestmentChart_data.datasets.filter(data => data.type === 'bar');

        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: growthDataWithoutReach.map(item => ({
              name: item.label,
              labels: optInvestmentChart_data.labels,
              values: item.data.length === 9 ? item.data : item.data.slice(0, -1),
            })),
            options: {
              barGrouping: 'stacked',
            },
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'Охват',
                labels: optInvestmentChart_data.labels,
                values: optInvestmentChart_data.datasets.find(data => data.type === 'line').data,
              },
            ],
            options: {
              barGrouping: 'standard',
              chartColors: [COLORS.red],
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ];
        slide5.addChart(chartTypes, opts);
      }
      doChartSlide5();

      let slide6 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide6.addText(`Сравнение различных миксов площадок на идентичный бюджет и их влияние на совокупный охват(на заданную частоту в поле оптимизации бюджета) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide6.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' }).addImage({ path: tv, x: -0.01, y: 5.03, w: '9%', h: '19%' });
      slide6.addImage({ path: optBig, x: -0.2999, y: 3.5, w: '15%', h: '26%' });

      const comparisonDataWithoutReach = optComparisonChart_data.datasets.filter(data => data.type === 'bar');
      const maxValueComparisonDataWithoutReach =
        comparisonDataWithoutReach &&
        comparisonDataWithoutReach.reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue.data[8];
        }, 0);
      function doChartSlide6() {
        let opts = {
          x: 1.5,
          y: 2.7,
          w: '80%',
          h: '50%',
          catGridLine: { style: 'none' },
          valGridLine: { style: 'none' },
          legendFontSize: 16,
          barDir: 'col',
          barGrouping: 'stacked',
          chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          legendPos: 'b',
          legendColor: '808080',
          showLegend: true,
          showValue: true,
          dataLabelFormatCode: '0.00',
          dataLabelColor: '000000',
          dataLabelFontSize: 12,
          catAxisLabelColor: '808080',
          valAxisLabelColor: '808080',
          // catAxisLabelFormatCode: '0.00',
          valAxes: [
            {
              showValAxisTitle: true,
              valAxisTitle: 'Доля в медиамиксе (млн. руб)',
              valAxisTitleFontSize: 14,
              valAxisMaxVal: maxValueComparisonDataWithoutReach,
              valAxisMinVal: 0,
              valAxisDisplayUnit: 'millions',
            },
            {
              showValAxisTitle: true,
              valAxisTitle: 'Совокупный охват (%)',
              valAxisTitleFontSize: 14,
              catAxisOrientation: 'maxMin',
              valAxisMajorUnit: 10,
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: '',
            },
            {
              catAxisHidden: true,
            },
          ],
        };
        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: comparisonDataWithoutReach.map(item => ({
              name: item.label,
              labels: optComparisonChart_data.labels,
              values: item.data.length === 9 ? item.data : item.data.slice(0, -1),
            })),
            options: {
              barGrouping: 'stacked',
            },
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'Охват',
                labels: optComparisonChart_data.labels,
                values: optComparisonChart_data.datasets.find(data => data.type === 'line').data,
              },
            ],
            options: {
              barGrouping: 'standard',
              chartColors: [COLORS.red],
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ];
        slide6.addChart(chartTypes, opts);
      }
      doChartSlide6();
    } else if (ISOPTIMIZATION === -1 && ISCBUUPLOAD === 1) {
      let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide1.addText(`Вклад каждой площадки в совокупный охват на частоте ${freqAll}`, { x: 2.5, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addText(`(Флайт ${FLIGHT})`, { x: '50%', y: 0.5, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide1.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide1.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsChartBar1 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendFontSize: 16,
        barDir: 'bar',
        catAxisOrientation: 'maxMin',
        catAxisLabelColor: '808080',
        barGrouping: 'stacked',
        dataLabelColor: 'FFFFFF',
        showValue: true,
        chartColors: [COLORS.red],
        valAxisLabelColor: '808080',
        dataLabelFormatCode: '0.00',

        showValAxisTitle: true,
        valAxisTitle: 'Охват ЦА, %',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'top',

        showCatAxisTitle: true,
        catAxisTitle: 'Площадки',
        catAxisTitleFontSize: 14,
      };
      let single = [
        {
          name: 'Conrtibution',
          labels: olvContributionOfEachMedia_data.labels,
          values: olvContributionOfEachMedia_data.datasets[0].data,
        },
      ];
      slide1.addChart(pptx.charts.BAR, single, optsChartBar1);

      let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      const dataChartPieStat = [
        {
          name: 'Pie',
          labels: olvDonutData.labels,
          values: olvDonutData.datasets[0].data,
        },
      ];
      slide2.addText(`Распределение бюджета по площадкам (Флайт ${FLIGHT})`, { x: 2.5, y: 0, w: 8, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide2.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide2.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide2.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsPie = {
        x: 2.5,
        y: 2,
        w: '60%',
        h: '60%',
        legendPos: 't',
        legendFontFace: 'Roboto',
        legendFontSize: 16,
        showLegend: true,
        showLeaderLines: true,
        legendColor: '808080',
        showPercent: false,

        showValue: true,
        chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        dataBorder: { pt: '1', color: 'F1F1F1' },
        dataLabelColor: 'FFFFFF',
        dataLabelFormatCode: '#,##0 ₽',
        dataLabelFontSize: 12,
        dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
      };
      slide2.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsPie);

      let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide3.addText(`Распределение Reach / Frequency (OLV) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide3.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide3.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide3.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let arrOlvDistribution_data = [
        {
          name: 'Открытые',
          labels: olvDistribution_data.labels,
          values: RFOpen,
        },
        {
          name: 'Закрытые',
          labels: olvDistribution_data.labels,
          values: RFClose,
        },
      ];

      let olvChart3 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.red, COLORS.veryDarkGray],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,

        showValAxisTitle: true,
        valAxisTitle: 'Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Частота',
        catAxisTitleFontSize: 14,
      };
      slide3.addChart(pptx.charts.BAR, arrOlvDistribution_data, olvChart3);

      let slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide4.addText(`OLV: CBU & CPRP на частоте ${freqAll} (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide4.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide4.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide4.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });

      let arrOlvCbuCprpChartData = [
        {
          name: 'Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[0].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'Incr.Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[1].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'CPRP',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[2].data.map(item => (item === 'null' ? null : item)),
        },
      ];

      let olvChart4 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',

        chartColors: [COLORS.red, COLORS.veryDarkGray, COLORS.gray],
        catAxisLabelColor: '808080',
        valAxisLabelColor: '808080',
        lineDataSymbol: 'none',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,
        valAxisMinVal: 0,
        catAxisMinVal: 0,
        valAxisMaxVal: 100,

        showValAxisTitle: true,
        valAxisTitle: 'Инкрементал Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Бюджет (млн. ₽)',
        catAxisTitleFontSize: 14,
      };

      slide4.addChart(pptx.charts.LINE, arrOlvCbuCprpChartData, olvChart4);

      let slide7 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide7.addText(`Распределение Reach / Frequency (TV & OLV) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide7.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' });
      slide7.addImage({ path: optTv, x: -0.01, y: 3.75, w: '9%', h: '19%' });
      slide7.addImage({ path: tvBig, x: -0.2999, y: 4.75, w: '15%', h: '26%' });

      const openOrClose = tvDistributionChart_data.labels[0] === '1+' ? 'Открытые' : 'Закрытые';
      let arrTvDistributionChart_data = [
        {
          name: `${openOrClose} OLV`,
          labels: tvDistributionChart_data.labels,
          values: tvDistributionChart_data.datasets[0].data.length === 19 ? tvDistributionChart_data.datasets[0].data : tvDistributionChart_data.datasets[0].data.slice(0, -3),
        },
        {
          name: `${openOrClose} TV`,
          labels: tvDistributionChart_data.labels,
          values: tvDistributionChart_data.datasets[1].data.length === 19 ? tvDistributionChart_data.datasets[1].data : tvDistributionChart_data.datasets[1].data.slice(0, -3),
        },
        {
          name: `${openOrClose} TV-OLV`,
          labels: tvDistributionChart_data.labels,
          values: tvDistributionChart_data.datasets[2].data.length === 19 ? tvDistributionChart_data.datasets[2].data : tvDistributionChart_data.datasets[2].data.slice(0, -3),
        },
      ];
      let tvChart7 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.gray, COLORS.veryDarkGray, COLORS.red],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,

        showValAxisTitle: true,
        valAxisTitle: 'Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Частота',
        catAxisTitleFontSize: 14,
      };
      slide7.addChart(pptx.charts.BAR, arrTvDistributionChart_data, tvChart7);

      let slide10 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide10.addText(`Рост совокупного охвата TV & OLV  при фиксированном бюджете TV  и нефиксированном бюджете OLV (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide10.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' });
      slide10.addImage({ path: optTv, x: -0.01, y: 3.75, w: '9%', h: '19%' });
      slide10.addImage({ path: tvBig, x: -0.2999, y: 4.75, w: '15%', h: '26%' });

      const minValueTv = Math.max.apply(null, tvIncreaseAggregateCoverageChart_data.datasets[1].data) - 1;

      let arrTvIncreaseAggregateCoverageChart_data = [
        // {
        //   name: 'OLV',
        //   labels: tvIncreaseAggregateCoverageChart_data.labels,
        //   values: tvIncreaseAggregateCoverageChart_data.datasets[0].data,
        // },
        {
          name: 'TV',
          labels: tvIncreaseAggregateCoverageChart_data.labels,
          values: tvIncreaseAggregateCoverageChart_data.datasets[1].data,
        },
        {
          name: 'TV+OLV',
          labels: tvIncreaseAggregateCoverageChart_data.labels,
          values: tvIncreaseAggregateCoverageChart_data.datasets[2].data,
        },
      ];

      let tvChart10 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.veryDarkGray, COLORS.red],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,
        lineDataSymbol: 'none',

        showValAxisTitle: true,
        valAxisTitle: 'Совокупный охват TV & OLV (%)',
        valAxisTitleFontSize: 14,
        valAxisMinVal: minValueTv,

        showCatAxisTitle: true,
        catAxisTitle: 'OLV Бюджет (млн. руб)',
        catAxisTitleFontSize: 14,
      };

      slide10.addChart(pptx.charts.LINE, arrTvIncreaseAggregateCoverageChart_data, tvChart10);
    } else if (ISOPTIMIZATION > -1 && ISCBUUPLOAD === 1) {
      let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide1.addText(`Вклад каждой площадки в совокупный охват на частоте ${freqAll}`, { x: 2.5, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addText(`(Флайт ${FLIGHT})`, { x: '50%', y: 0.5, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide1.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide1.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide1.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsChartBar1 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendFontSize: 16,
        barDir: 'bar',
        catAxisOrientation: 'maxMin',
        catAxisLabelColor: '808080',
        barGrouping: 'stacked',
        dataLabelColor: 'FFFFFF',
        showValue: true,
        chartColors: [COLORS.red],
        valAxisLabelColor: '808080',
        dataLabelFormatCode: '0.00',

        showValAxisTitle: true,
        valAxisTitle: 'Охват ЦА, %',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'top',

        showCatAxisTitle: true,
        catAxisTitle: 'Площадки',
        catAxisTitleFontSize: 14,
      };
      let single = [
        {
          name: 'Conrtibution',
          labels: olvContributionOfEachMedia_data.labels,
          values: olvContributionOfEachMedia_data.datasets[0].data,
        },
      ];
      slide1.addChart(pptx.charts.BAR, single, optsChartBar1);

      let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      const dataChartPieStat = [
        {
          name: 'Pie',
          labels: olvDonutData.labels,
          values: olvDonutData.datasets[0].data,
        },
      ];
      slide2.addText(`Распределение бюджета по площадкам (Флайт ${FLIGHT})`, { x: 2.5, y: 0, w: 8, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide2.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide2.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide2.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let optsPie = {
        x: 2.5,
        y: 2,
        w: '60%',
        h: '60%',
        legendPos: 't',
        legendFontFace: 'Roboto',
        legendFontSize: 16,
        showLegend: true,
        showLeaderLines: true,
        legendColor: '808080',
        showPercent: false,

        showValue: true,
        chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        dataBorder: { pt: '1', color: 'F1F1F1' },
        dataLabelColor: 'FFFFFF',
        dataLabelFormatCode: '#,##0 ₽',
        dataLabelFontSize: 12,
        dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
      };
      slide2.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsPie);

      let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide3.addText(`Распределение Reach / Frequency (OLV) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide3.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide3.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide3.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });
      let arrOlvDistribution_data = [
        {
          name: 'Открытые',
          labels: olvDistribution_data.labels,
          values: RFOpen,
        },
        {
          name: 'Закрытые',
          labels: olvDistribution_data.labels,
          values: RFClose,
        },
      ];
      let olvChart3 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.red, COLORS.veryDarkGray],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,

        showValAxisTitle: true,
        valAxisTitle: 'Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Частота',
        catAxisTitleFontSize: 14,
      };
      slide3.addChart(pptx.charts.BAR, arrOlvDistribution_data, olvChart3);

      let slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide4.addText(`OLV: CBU & CPRP на частоте ${freqAll} (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide4.addImage({ path: tv, x: -0.01, y: 5.23, w: '9%', h: '19%' });
      slide4.addImage({ path: opt, x: -0.01, y: 4.03, w: '9%', h: '19%' });
      slide4.addImage({ path: olvBig, x: -0.2999, y: 2.5, w: '15%', h: '26%' });

      let arrOlvCbuCprpChartData = [
        {
          name: 'Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[0].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'Incr.Reach',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[1].data.map(item => (item === 'null' ? null : item)),
        },
        {
          name: 'CPRP',
          labels: olvCbuCprpChartData.labels,
          values: olvCbuCprpChartData.datasets[2].data.map(item => (item === 'null' ? null : item)),
        },
      ];

      let olvChart4 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',

        chartColors: [COLORS.red, COLORS.veryDarkGray, COLORS.gray],
        catAxisLabelColor: '808080',
        valAxisLabelColor: '808080',
        lineDataSymbol: 'none',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,
        valAxisMinVal: 0,
        catAxisMinVal: 0,
        valAxisMaxVal: 100,

        showValAxisTitle: true,
        valAxisTitle: 'Инкрементал Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Бюджет (млн. ₽)',
        catAxisTitleFontSize: 14,
      };

      slide4.addChart(pptx.charts.LINE, arrOlvCbuCprpChartData, olvChart4);

      let slide5 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide5.addText(`Влияние прироста инвестиций в рекламу на микс площадок и совокупный охват (на заданную частоту в поле оптимизации бюджета ) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide5.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' }).addImage({ path: tv, x: -0.01, y: 5.03, w: '9%', h: '19%' });
      slide5.addImage({ path: optBig, x: -0.2999, y: 3.5, w: '15%', h: '26%' });

      function doChartSlide5() {
        let opts = {
          x: 1.5,
          y: 2.7,
          w: '80%',
          h: '50%',
          catGridLine: { style: 'none' },
          valGridLine: { style: 'none' },
          legendFontSize: 16,
          barDir: 'col',
          barGrouping: 'stacked',
          chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          legendPos: 'b',
          legendColor: '808080',
          showLegend: true,
          showValue: true,
          dataLabelColor: '000000',
          dataLabelFontSize: 12,
          catAxisLabelColor: '808080',
          valAxisLabelColor: '808080',
          dataLabelFormatCode: '0.00',

          valAxes: [
            {
              showValAxisTitle: true,
              valAxisTitle: 'Затраты на медиа (млн. руб)',
              valAxisTitleFontSize: 14,
              valAxisMinVal: 0,
              valAxisDisplayUnit: 'millions',
            },
            {
              showValAxisTitle: true,
              valAxisTitle: 'Совокупный охват (%)',
              valAxisTitleFontSize: 14,
              catAxisOrientation: 'maxMin',
              valAxisMajorUnit: 10,
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: '',
            },
            {
              catAxisHidden: true,
            },
          ],
        };
        const growthDataWithoutReach = optInvestmentChart_data.datasets.filter(data => data.type === 'bar');

        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: growthDataWithoutReach.map(item => ({
              name: item.label,
              labels: optInvestmentChart_data.labels,
              values: item.data.length === 9 ? item.data : item.data.slice(0, -1),
            })),
            options: {
              barGrouping: 'stacked',
            },
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'Охват',
                labels: optInvestmentChart_data.labels,
                values: optInvestmentChart_data.datasets.find(data => data.type === 'line').data,
              },
            ],
            options: {
              barGrouping: 'standard',
              chartColors: [COLORS.red],
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ];
        slide5.addChart(chartTypes, opts);
      }
      doChartSlide5();

      let slide6 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide6.addText(`Сравнение различных миксов площадок на идентичный бюджет и их влияние на совокупный охват(на заданную частоту в поле оптимизации бюджета) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide6.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' }).addImage({ path: tv, x: -0.01, y: 5.03, w: '9%', h: '19%' });
      slide6.addImage({ path: optBig, x: -0.2999, y: 3.5, w: '15%', h: '26%' });

      const comparisonDataWithoutReach = optComparisonChart_data.datasets.filter(data => data.type === 'bar');
      const maxValueComparisonDataWithoutReach =
        comparisonDataWithoutReach &&
        comparisonDataWithoutReach.reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue.data[8];
        }, 0);
      function doChartSlide6() {
        let opts = {
          x: 1.5,
          y: 2.7,
          w: '80%',
          h: '50%',
          catGridLine: { style: 'none' },
          valGridLine: { style: 'none' },
          legendFontSize: 16,
          barDir: 'col',
          barGrouping: 'stacked',
          chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          legendPos: 'b',
          legendColor: '808080',
          showLegend: true,
          showValue: true,
          dataLabelFormatCode: '0.00',
          dataLabelColor: '000000',
          dataLabelFontSize: 12,
          catAxisLabelColor: '808080',
          valAxisLabelColor: '808080',
          // catAxisLabelFormatCode: '0.00',
          valAxes: [
            {
              showValAxisTitle: true,
              valAxisTitle: 'Доля в медиамиксе (млн. руб)',
              valAxisTitleFontSize: 14,
              valAxisMaxVal: maxValueComparisonDataWithoutReach,
              valAxisMinVal: 0,
              valAxisDisplayUnit: 'millions',
            },
            {
              showValAxisTitle: true,
              valAxisTitle: 'Совокупный охват (%)',
              valAxisTitleFontSize: 14,
              catAxisOrientation: 'maxMin',
              valAxisMajorUnit: 10,
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: '',
            },
            {
              catAxisHidden: true,
            },
          ],
        };
        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: comparisonDataWithoutReach.map(item => ({
              name: item.label,
              labels: optComparisonChart_data.labels,
              values: item.data.length === 9 ? item.data : item.data.slice(0, -1),
            })),
            options: {
              barGrouping: 'stacked',
            },
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'Охват',
                labels: optComparisonChart_data.labels,
                values: optComparisonChart_data.datasets.find(data => data.type === 'line').data,
              },
            ],
            options: {
              barGrouping: 'standard',
              chartColors: [COLORS.red],
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ];
        slide6.addChart(chartTypes, opts);
      }
      doChartSlide6();

      let slide7 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide7.addText(`Распределение Reach / Frequency (TV & OLV) (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide7.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' });
      slide7.addImage({ path: optTv, x: -0.01, y: 3.75, w: '9%', h: '19%' });
      slide7.addImage({ path: tvBig, x: -0.2999, y: 4.75, w: '15%', h: '26%' });

      const openOrClose = tvDistributionChart_data.labels[0] === '1+' ? 'Открытые' : 'Закрытые';
      let arrTvDistributionChart_data = [
        {
          name: `${openOrClose} OLV`,
          labels: tvDistributionChart_data.labels,
          values: tvDistributionChart_data.datasets[0].data.length === 19 ? tvDistributionChart_data.datasets[0].data : tvDistributionChart_data.datasets[0].data.slice(0, -3),
        },
        {
          name: `${openOrClose} TV`,
          labels: tvDistributionChart_data.labels,
          values: tvDistributionChart_data.datasets[1].data.length === 19 ? tvDistributionChart_data.datasets[1].data : tvDistributionChart_data.datasets[1].data.slice(0, -3),
        },
        {
          name: `${openOrClose} TV-OLV`,
          labels: tvDistributionChart_data.labels,
          values: tvDistributionChart_data.datasets[2].data.length === 19 ? tvDistributionChart_data.datasets[2].data : tvDistributionChart_data.datasets[2].data.slice(0, -3),
        },
      ];
      let tvChart7 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.gray, COLORS.veryDarkGray, COLORS.red],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,

        showValAxisTitle: true,
        valAxisTitle: 'Охват (%)',
        valAxisTitleFontSize: 14,
        valAxisTitleAlign: 'left',

        showCatAxisTitle: true,
        catAxisTitle: 'Частота',
        catAxisTitleFontSize: 14,
      };
      slide7.addChart(pptx.charts.BAR, arrTvDistributionChart_data, tvChart7);

      let slide10 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      slide10.addText(`Рост совокупного охвата TV & OLV  при фиксированном бюджете TV  и нефиксированном бюджете OLV (Флайт ${FLIGHT})`, { x: 2.2, y: 0, w: 9.5, h: 3.2, color: '#080808', fontSize: 25, bold: true });
      slide10.addImage({ path: olv, x: -0.01, y: 2.5, w: '9%', h: '19%' });
      slide10.addImage({ path: optTv, x: -0.01, y: 3.75, w: '9%', h: '19%' });
      slide10.addImage({ path: tvBig, x: -0.2999, y: 4.75, w: '15%', h: '26%' });

      const minValueTv = Math.max.apply(null, tvIncreaseAggregateCoverageChart_data.datasets[1].data) - 1;

      let arrTvIncreaseAggregateCoverageChart_data = [
        // {
        //   name: 'OLV',
        //   labels: tvIncreaseAggregateCoverageChart_data.labels,
        //   values: tvIncreaseAggregateCoverageChart_data.datasets[0].data,
        // },
        {
          name: 'TV',
          labels: tvIncreaseAggregateCoverageChart_data.labels,
          values: tvIncreaseAggregateCoverageChart_data.datasets[1].data,
        },
        {
          name: 'TV+OLV',
          labels: tvIncreaseAggregateCoverageChart_data.labels,
          values: tvIncreaseAggregateCoverageChart_data.datasets[2].data,
        },
      ];

      let tvChart10 = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Helvetica Neue',
        catAxisLabelFontSize: 14,
        catAxisOrientation: 'minMax',
        valAxisLabelColor: '808080',
        chartColors: [COLORS.veryDarkGray, COLORS.red],
        legendPos: 'b',
        legendFontSize: 16,
        legendColor: '808080',
        showLegend: true,
        lineDataSymbol: 'none',

        showValAxisTitle: true,
        valAxisTitle: 'Совокупный охват TV & OLV (%)',
        valAxisTitleFontSize: 14,
        valAxisMinVal: minValueTv,

        showCatAxisTitle: true,
        catAxisTitle: 'OLV Бюджет (млн. руб)',
        catAxisTitleFontSize: 14,
      };

      slide10.addChart(pptx.charts.LINE, arrTvIncreaseAggregateCoverageChart_data, tvChart10);
    }
  });
  pptx.writeFile({ fileName: 'videotron_flights.pptx' });

  return <div></div>;
});
