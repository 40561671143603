import React from 'react';
import s from './FlightsPresenceOfTv.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { setFlightsPresenceOfTvArr, setFlightsCbuArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsPresenceOfTv = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const currentPresenceOfTv= flightsPresenceOfTvArr.find(item => item.flight === flight);
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  

  const handleOnChange = (selectedName, flight) => {
    const updatedStateFlightsPresenceOfTvArr = flightsPresenceOfTvArr.map(item => {
      const newItem = { ...item };
      newItem.presenceOfTvFlight = selectedName === 'tvFlightsYes' ? 1 : 0
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsPresenceOfTvArr(updatedStateFlightsPresenceOfTvArr));

    const updatedFlightsCbuArr = flightsCbuArr.map(item => {
      const newItem = { ...item };
      newItem.isCBUUpload = 0;
      newItem.cbu = null
      return item.flight === flight ? newItem : item;
    });
    selectedName === 'tvFlightsNo' && dispatch(setFlightsCbuArr(updatedFlightsCbuArr));
    dispatch(setFlightsSomeInputDataChanged(true))
  };

  return (
    <div className={s.blockContent}>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('tvFlightsYes', flight);
        }}
        checked={currentPresenceOfTv.presenceOfTvFlight === 1 ? true : false}
        label={'Да'}
        id={'tvFlightsYes'}
      />
    </div>
    <div className={s.checkboxWrapper}>
      <Checkbox
        onChangeChecked={() => {
          handleOnChange('tvFlightsNo', flight);
        }}
        checked={currentPresenceOfTv.presenceOfTvFlight === 1 ? false : true}
        label={'Нет'}
        id={'tvFlightsNo'}
      />
    </div>
  </div>   
  );
});
