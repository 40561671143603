import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { RedButton } from '../common/RedButton/RedButton';
import { makeGeoTitle_From_PostData, makeLtv_From_PostData, makePresenceOfTvFlight_From_PostData } from '../../../helpers/common_helpers';
import { getFreqNumber } from '../../../helpers/chart_helpers';
import { variablesOLV_CBU_CPRP_Arr, freqStrArr } from '../../../assets/data/data';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const SaveXLSOlv = React.memo(props => {
  const { chartsData } = props;

  const postData = useSelector(state => state.input.postData);
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const dataFromXL = useSelector(state => state.charts.dataFromXL);
  const publishers = useSelector(state => state.table.publishers);
  const total = useSelector(state => state.table.total);
  const mainMedia = useSelector(state => state.change.mainMedia);
  const freqAll = useSelector(state => state.change.freqAll);
  const freqPost = freqAll && getFreqNumber(freqAll);
  const optimizationData = useSelector(state => state.opt.optimizationData);
  const restrictions = useSelector(state => state.opt.restrictions);
  const budget_OlvCbuCprpChart = useSelector(state => state.change.budget_OlvCbuCprpChart);
  const budget_OLV_TvIncreaseAggregateCoverageChart = useSelector(state => state.change.budgets_TvIncreaseAggregateCoverageChart.olvBudget);
  const budget_TvDistributionChart = useSelector(state => state.change.budget_TvDistributionChart);
  const budget_TV_TvIncreaseAggregateCoverageChart = useSelector(state => state.change.budgets_TvIncreaseAggregateCoverageChart.tvBudget);

  const getDataForSaveXLS_DataFromXL = dataFromXL => {
    const updatedInputDataArr = dataFromXL.filter(item => item.agemax);
    const dataSetInput_dataFromXL = updatedInputDataArr;

    const dataSet_Optimization_data_dataFromXL = dataFromXL.filter(item => item.Publisher_Opt);
    const dataSet_restrictions_dataFromXL = dataFromXL.filter(item => item.Publisher_Restr);

    const dataSet_Input_OLV_Budgets_dataFromXL = dataFromXL.filter(item => item.budget_OlvCbuCprpChart);

    const updatedPublishers = dataFromXL.filter(item => item.Publisher);
    const dataSetPublishers_dataFromXL = updatedPublishers;

    const updatedTotal = dataFromXL.filter(item => item.total_Budget);
    const dataSetTotal_dataFromXL = updatedTotal;

    const DONUT_DataFromXL = dataFromXL.filter(item => item.PublisherDonut);
    const dataSetDonut_dataFromXL = DONUT_DataFromXL.map((item, i) => {
      return {
        Publisher: item.PublisherDonut,
        Budget: item.Budget,
      };
    });

    const INCR_DataFromXL = dataFromXL.filter(item => item.MainMedia);
    const makeArrayMediaContributionMainMediaName = arr => {
      let newArr = [];
      arr.map(item => {
        !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
        return item;
      });
      return newArr;
    };
    const incrPublNameArr = makeArrayMediaContributionMainMediaName(INCR_DataFromXL);
    const Item = INCR_DataFromXL.filter(item => item.MainMedia === incrPublNameArr[0] && item.Freq === 1);
    const pubNames = Item.map(item => item.PublisherContr);

    const DATASETCONTR_ITEM = [];
    freqStrArr.map(item => {
      for (var i = 0; i < incrPublNameArr.length; i++) {
        for (var a = 0; a < pubNames.length; a++) {
          DATASETCONTR_ITEM.push(item + '*' + incrPublNameArr[i] + '/' + pubNames[a]);
        }
      }
      return item;
    });

    const DATASETCONTR_dataFromXL = DATASETCONTR_ITEM.map(item => {
      const INCR_ITEM = INCR_DataFromXL.filter(i => i.MainMedia === item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/')) && i.Freq === +item.substring(0, 2));

      return {
        MainMedia: item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/')),
        Freq: +item.substring(0, 2),
        Publisher: item.slice(item.lastIndexOf('/') + 1),
        Reach: INCR_ITEM.find(el => el.PublisherContr === item.slice(item.lastIndexOf('/') + 1)) && INCR_ITEM.find(el => el.PublisherContr === item.slice(item.lastIndexOf('/') + 1)).Reach,
      };
    });
    const dataSetRfNPlus_dataFromXL = dataFromXL.filter(item => item['OLVReachN+']);
    const dataSetRfN_dataFromXL = dataFromXL.filter(item => item['OLVReachN']);

    const OLV_CBU_CPRP_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP);
    const DATASETOLV_CBU_CPRP_dataFromXL = variablesOLV_CBU_CPRP_Arr.map(item => {
      const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP_DataFromXL.filter(el => el.Variable_OLV_CBU_CPRP === item.substring(2) && el.Freq === +item.substring(0, 2));
      return {
        Variable_OLV_CBU_CPRP: item.substring(2),
        Freq: +item.substring(0, 2),
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[2]]: Object.values(OLV_CBU_CPRP_ITEM[0])[2],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[3]]: Object.values(OLV_CBU_CPRP_ITEM[0])[3],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[4]]: Object.values(OLV_CBU_CPRP_ITEM[0])[4],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[5]]: Object.values(OLV_CBU_CPRP_ITEM[0])[5],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[6]]: Object.values(OLV_CBU_CPRP_ITEM[0])[6],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[7]]: Object.values(OLV_CBU_CPRP_ITEM[0])[7],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[8]]: Object.values(OLV_CBU_CPRP_ITEM[0])[8],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[9]]: Object.values(OLV_CBU_CPRP_ITEM[0])[9],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[10]]: Object.values(OLV_CBU_CPRP_ITEM[0])[10],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[11]]: Object.values(OLV_CBU_CPRP_ITEM[0])[11],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[12]]: Object.values(OLV_CBU_CPRP_ITEM[0])[12],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[13]]: Object.values(OLV_CBU_CPRP_ITEM[0])[13],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[14]]: Object.values(OLV_CBU_CPRP_ITEM[0])[14],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[15]]: Object.values(OLV_CBU_CPRP_ITEM[0])[15],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[16]]: Object.values(OLV_CBU_CPRP_ITEM[0])[16],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[17]]: Object.values(OLV_CBU_CPRP_ITEM[0])[17],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[18]]: Object.values(OLV_CBU_CPRP_ITEM[0])[18],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[19]]: Object.values(OLV_CBU_CPRP_ITEM[0])[19],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[20]]: Object.values(OLV_CBU_CPRP_ITEM[0])[20],
        [Object.keys(OLV_CBU_CPRP_ITEM[0])[21]]: Object.values(OLV_CBU_CPRP_ITEM[0])[21],
      };
    });

    const dataSet_OPT_INVEST_dataFromXL = [];
    const dataSet_Reach_OPT_INVEST_dataFromXL = [];
    const dataSet_OPT_COMPARISON_dataFromXL = [];
    const dataSet_Reach_OPT_COMPARISON_dataFromXL = [];

    const DATASET_TV_OPT_DISTR_dataFromXL = [];
    const DATASET_TV_OPT_SPL_dataFromXL = [];
    const DATASET_TV_INCREASE_dataFromXL = [];
    const DATASET_RF_TV_OLV_RfNPlus_dataFromXL = [];
    const DATASET_RF_TV_OLV_RfN_dataFromXL = [];
    const DATASET_CBU_dataFromXL = [];

    return [dataSetInput_dataFromXL, dataSetPublishers_dataFromXL, dataSetDonut_dataFromXL, DATASETCONTR_dataFromXL, dataSetRfNPlus_dataFromXL, dataSetRfN_dataFromXL, DATASETOLV_CBU_CPRP_dataFromXL, dataSetTotal_dataFromXL, dataSet_OPT_INVEST_dataFromXL, dataSet_Reach_OPT_INVEST_dataFromXL, dataSet_OPT_COMPARISON_dataFromXL, dataSet_Reach_OPT_COMPARISON_dataFromXL, DATASET_TV_OPT_DISTR_dataFromXL, DATASET_TV_OPT_SPL_dataFromXL, DATASET_TV_INCREASE_dataFromXL, DATASET_RF_TV_OLV_RfNPlus_dataFromXL, DATASET_RF_TV_OLV_RfN_dataFromXL, DATASET_CBU_dataFromXL, dataSet_Input_OLV_Budgets_dataFromXL, dataSet_Optimization_data_dataFromXL, dataSet_restrictions_dataFromXL];
  };

  const getDataForSaveXLS_DataFromXL_call_result = isChartsUpload && getDataForSaveXLS_DataFromXL(dataFromXL);
  const dataSetInput_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[0];
  const dataSetPublishers_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[1];
  const dataSetDonut_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[2];
  const DATASETCONTR_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[3];
  const dataSetRfNPlus_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[4];
  const dataSetRfN_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[5];
  const DATASETOLV_CBU_CPRP_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[6];
  const dataSetTotal_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[7];
  const dataSet_Input_OLV_Budgets_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[18];
  const dataSet_Optimization_data_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[19];
  const dataSet_restrictions_dataFromXL = getDataForSaveXLS_DataFromXL_call_result[20];

  const getDataForSaveXLS_DataFromChartsData = chartsData => {
    const dataSet_Optimization_data = Object.keys(optimizationData).map((item, i) => {
      return {
        Publisher_Opt: item,
        CPM_Opt: optimizationData[item].CPM,
        Days_Opt: optimizationData[item].Days,
        FrequencyLimit_Opt: optimizationData[item].FrequencyLimit,
        Impressions_Opt: optimizationData[item].Impressions,
        max_OTS_Opt: optimizationData[item].max_OTS,
        targ_Opt: optimizationData[item].targ,
        budget_Opt: optimizationData[item].budget ? optimizationData[item].budget : null,
      };
    });
    const dataSet_restrictions = restrictions.map((item, i) => {
      return {
        Publisher_Restr: item.Publisher,
        min: item.min === null ? 0 : item.min,
        max: item.max === null ? 0 : item.max,
      };
    });

    const dataSet_Input_OLV_Budgets = [
      {
        budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
        budget_OLV_TvIncreaseAggregateCoverageChart: budget_OLV_TvIncreaseAggregateCoverageChart,
        budget_TvDistributionChart: budget_TvDistributionChart,
        budget_TV_TvIncreaseAggregateCoverageChart: budget_TV_TvIncreaseAggregateCoverageChart,
      },
    ];

    const dataSetInput = postData;
    const dataSetPublishers = publishers;
    const dataSetTotal = total;

    const DONUT = publishers.filter(item => item.isChecked === 1);
    const dataSetDonut = DONUT.map((item, i) => {
      return {
        Publisher: item.Publisher,
        Budget: item.Budget,
      };
    });

    const INCR = chartsData.incr;
    const incrPublNameArr = Object.keys(INCR);
    const Item = INCR[Object.keys(INCR)[0]][1];
    const pubNames = Object.keys(Item);
    const DATASETCONTR_ITEM = [];
    freqStrArr.map(item => {
      for (var i = 0; i < incrPublNameArr.length; i++) {
        for (var a = 0; a < pubNames.length; a++) {
          DATASETCONTR_ITEM.push(item + '*' + incrPublNameArr[i] + '/' + pubNames[a]);
        }
      }
      return item;
    });

    const DATASETCONTR = DATASETCONTR_ITEM.map(item => {
      const INCR_ITEM = INCR[item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/'))][+item.substring(0, 2)];

      return {
        MainMedia: item.slice(item.lastIndexOf('*') + 1, item.lastIndexOf('/')),
        Freq: +item.substring(0, 2),
        Publisher: item.slice(item.lastIndexOf('/') + 1),
        Reach: INCR_ITEM[item.slice(item.lastIndexOf('/') + 1)],
      };
    });
    const RF = chartsData.RF;
    const dataSetRfNPlus = [
      {
        'OLVReachN+': 'OLVReachN+',
        1: RF['Total_media_rf_n+'][0],
        2: RF['Total_media_rf_n+'][1],
        3: RF['Total_media_rf_n+'][2],
        4: RF['Total_media_rf_n+'][3],
        5: RF['Total_media_rf_n+'][4],
        6: RF['Total_media_rf_n+'][5],
        7: RF['Total_media_rf_n+'][6],
        8: RF['Total_media_rf_n+'][7],
        9: RF['Total_media_rf_n+'][8],
        10: RF['Total_media_rf_n+'][9],
        11: RF['Total_media_rf_n+'][10],
        12: RF['Total_media_rf_n+'][11],
        13: RF['Total_media_rf_n+'][12],
        14: RF['Total_media_rf_n+'][13],
        15: RF['Total_media_rf_n+'][14],
        16: RF['Total_media_rf_n+'][15],
        17: RF['Total_media_rf_n+'][16],
        18: RF['Total_media_rf_n+'][17],
        19: RF['Total_media_rf_n+'][18],
      },
    ];
    const dataSetRfN = [
      {
        OLVReachN: 'OLVReachN',
        1: RF['Total_media_rf_n'][0],
        2: RF['Total_media_rf_n'][1],
        3: RF['Total_media_rf_n'][2],
        4: RF['Total_media_rf_n'][3],
        5: RF['Total_media_rf_n'][4],
        6: RF['Total_media_rf_n'][5],
        7: RF['Total_media_rf_n'][6],
        8: RF['Total_media_rf_n'][7],
        9: RF['Total_media_rf_n'][8],
        10: RF['Total_media_rf_n'][9],
        11: RF['Total_media_rf_n'][10],
        12: RF['Total_media_rf_n'][11],
        13: RF['Total_media_rf_n'][12],
        14: RF['Total_media_rf_n'][13],
        15: RF['Total_media_rf_n'][14],
        16: RF['Total_media_rf_n'][15],
        17: RF['Total_media_rf_n'][16],
        18: RF['Total_media_rf_n'][17],
        19: RF['Total_media_rf_n'][18],
      },
    ];

    const OLV_CBU_CPRP = chartsData.OLV_CBU_CPRP;
    const DATASETOLV_CBU_CPRP = variablesOLV_CBU_CPRP_Arr.map(item => {
      const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP[item.substring(2)][+item.substring(0, 2)];
      return {
        Variable_OLV_CBU_CPRP: item.substring(2),
        Freq: +item.substring(0, 2),
        [Object.keys(OLV_CBU_CPRP_ITEM)[0]]: Object.values(OLV_CBU_CPRP_ITEM)[0],
        [Object.keys(OLV_CBU_CPRP_ITEM)[1]]: Object.values(OLV_CBU_CPRP_ITEM)[1],
        [Object.keys(OLV_CBU_CPRP_ITEM)[2]]: Object.values(OLV_CBU_CPRP_ITEM)[2],
        [Object.keys(OLV_CBU_CPRP_ITEM)[3]]: Object.values(OLV_CBU_CPRP_ITEM)[3],
        [Object.keys(OLV_CBU_CPRP_ITEM)[4]]: Object.values(OLV_CBU_CPRP_ITEM)[4],
        [Object.keys(OLV_CBU_CPRP_ITEM)[5]]: Object.values(OLV_CBU_CPRP_ITEM)[5],
        [Object.keys(OLV_CBU_CPRP_ITEM)[6]]: Object.values(OLV_CBU_CPRP_ITEM)[6],
        [Object.keys(OLV_CBU_CPRP_ITEM)[7]]: Object.values(OLV_CBU_CPRP_ITEM)[7],
        [Object.keys(OLV_CBU_CPRP_ITEM)[8]]: Object.values(OLV_CBU_CPRP_ITEM)[8],
        [Object.keys(OLV_CBU_CPRP_ITEM)[9]]: Object.values(OLV_CBU_CPRP_ITEM)[9],
        [Object.keys(OLV_CBU_CPRP_ITEM)[10]]: Object.values(OLV_CBU_CPRP_ITEM)[10],
        [Object.keys(OLV_CBU_CPRP_ITEM)[11]]: Object.values(OLV_CBU_CPRP_ITEM)[11],
        [Object.keys(OLV_CBU_CPRP_ITEM)[12]]: Object.values(OLV_CBU_CPRP_ITEM)[12],
        [Object.keys(OLV_CBU_CPRP_ITEM)[13]]: Object.values(OLV_CBU_CPRP_ITEM)[13],
        [Object.keys(OLV_CBU_CPRP_ITEM)[14]]: Object.values(OLV_CBU_CPRP_ITEM)[14],
        [Object.keys(OLV_CBU_CPRP_ITEM)[15]]: Object.values(OLV_CBU_CPRP_ITEM)[15],
        [Object.keys(OLV_CBU_CPRP_ITEM)[16]]: Object.values(OLV_CBU_CPRP_ITEM)[16],
        [Object.keys(OLV_CBU_CPRP_ITEM)[17]]: Object.values(OLV_CBU_CPRP_ITEM)[17],
        [Object.keys(OLV_CBU_CPRP_ITEM)[18]]: Object.values(OLV_CBU_CPRP_ITEM)[18],
        [Object.keys(OLV_CBU_CPRP_ITEM)[19]]: Object.values(OLV_CBU_CPRP_ITEM)[19],
      };
    });

    const dataSet_OPT_INVEST = [];
    const dataSet_Reach_OPT_INVEST = [];
    const dataSet_OPT_COMPARISON = [];
    const dataSet_Reach_OPT_COMPARISON = [];

    const DATASET_TV_OPT_DISTR = [];
    const DATASET_TV_OPT_SPL = [];
    const DATASET_TV_INCREASE = [];
    const DATASET_RF_TV_OLV_RfNPlus = [];
    const DATASET_RF_TV_OLV_RfN = [];
    const DATASET_CBU = [];

    return [dataSetInput, dataSetPublishers, dataSetDonut, DATASETCONTR, dataSetRfNPlus, dataSetRfN, DATASETOLV_CBU_CPRP, dataSetTotal, dataSet_OPT_INVEST, dataSet_Reach_OPT_INVEST, dataSet_OPT_COMPARISON, dataSet_Reach_OPT_COMPARISON, DATASET_TV_OPT_DISTR, DATASET_TV_OPT_SPL, DATASET_TV_INCREASE, DATASET_RF_TV_OLV_RfNPlus, DATASET_RF_TV_OLV_RfN, DATASET_CBU, dataSet_Input_OLV_Budgets, dataSet_Optimization_data, dataSet_restrictions];
  };

  const getDataForSaveXLS_DataFromChartsData_call_result = !isChartsUpload && getDataForSaveXLS_DataFromChartsData(chartsData);

  const dataSetInput = getDataForSaveXLS_DataFromChartsData_call_result[0];
  const dataSetPublishers = getDataForSaveXLS_DataFromChartsData_call_result[1];
  const dataSetDonut = getDataForSaveXLS_DataFromChartsData_call_result[2];
  const DATASETCONTR = getDataForSaveXLS_DataFromChartsData_call_result[3];
  const dataSetRfNPlus = getDataForSaveXLS_DataFromChartsData_call_result[4];
  const dataSetRfN = getDataForSaveXLS_DataFromChartsData_call_result[5];
  const DATASETOLV_CBU_CPRP = getDataForSaveXLS_DataFromChartsData_call_result[6];
  const dataSetTotal = getDataForSaveXLS_DataFromChartsData_call_result[7];
  const dataSet_Input_OLV_Budgets = getDataForSaveXLS_DataFromChartsData_call_result[18];
  const dataSet_Optimization_data = getDataForSaveXLS_DataFromChartsData_call_result[19];
  const dataSet_restrictions = getDataForSaveXLS_DataFromChartsData_call_result[20];

  const TA = isChartsUpload ? `${dataSetInput_dataFromXL[0].gender} ${dataSetInput_dataFromXL[0].agemin}-${dataSetInput_dataFromXL[0].agemax} ${dataSetInput_dataFromXL[0].income}` : `${dataSetInput[0].gender} ${dataSetInput[0].agemin}-${dataSetInput[0].agemax} ${dataSetInput[0].income}`;
  const Geo = isChartsUpload ? makeGeoTitle_From_PostData(dataSetInput_dataFromXL[0].geo) : makeGeoTitle_From_PostData(dataSetInput[0].geo);
  const Universe = isChartsUpload ? dataSetInput_dataFromXL[0].universe : dataSetInput[0].universe;
  const Share = isChartsUpload ? dataSetInput_dataFromXL[0].share : dataSetInput[0].share;
  const PresenceOfTvFlight = isChartsUpload ? makePresenceOfTvFlight_From_PostData(dataSetInput_dataFromXL[0].presenceOfTvFlight) : makePresenceOfTvFlight_From_PostData(dataSetInput[0].presenceOfTvFlight);
  const Ltv = isChartsUpload ? makeLtv_From_PostData(dataSetInput_dataFromXL[0].ltv) : makeLtv_From_PostData(dataSetInput[0].ltv);

  const chooseDataSetTotal = () => {
    if (isChartsUpload) {
      return dataSetTotal_dataFromXL;
    } else {
      return dataSetTotal;
    }
  };
  const chooseDataSetPublishers = () => {
    if (isChartsUpload) {
      return dataSetPublishers_dataFromXL;
    } else {
      return dataSetPublishers;
    }
  };
  const chooseDATASETCONTR = () => {
    if (isChartsUpload) {
      return DATASETCONTR_dataFromXL.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    } else {
      return DATASETCONTR.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    }
  };
  const choosedataSetDonut = () => {
    if (isChartsUpload) {
      return dataSetDonut_dataFromXL;
    } else {
      return dataSetDonut;
    }
  };
  const choosedataSetRfN = () => {
    if (isChartsUpload) {
      return dataSetRfN_dataFromXL;
    } else {
      return dataSetRfN;
    }
  };
  const choosedataSetRfNPlus = () => {
    if (isChartsUpload) {
      return dataSetRfNPlus_dataFromXL;
    } else {
      return dataSetRfNPlus;
    }
  };
  const chooseDATASETOLV_CBU_CPRP = () => {
    if (isChartsUpload) {
      return DATASETOLV_CBU_CPRP_dataFromXL.filter(item => item.Freq === freqPost);
    } else {
      return DATASETOLV_CBU_CPRP.filter(item => item.Freq === freqPost);
    }
  };
  const chooseDataSetTotal_call_result = chooseDataSetTotal();
  const chooseDATASETOLV_CBU_CPRP_call_result = chooseDATASETOLV_CBU_CPRP();

  const Publishers_Sheet1 = chooseDataSetPublishers().map((item, i) => {
    return [
      {
        value: String(item.Publisher),
        style: {
          fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },

      {
        value: item.Budget,
        style: {
          numFmt: '#,##0 ₽',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Impressions,
        style: {
          numFmt: '#,##0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.CPM,
        style: {
          numFmt: '0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.TargetingSocDem === 1 ? 'Да' : 'Нет',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.OtherTargeting === 1 ? 'Да' : 'Нет',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Days,
        style: {
          numFmt: '0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },

      {
        value: item.FrequencyLimit,
        style: {
          numFmt: '0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Tsa_percent / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Tsa_thousand,
        style: {
          numFmt: '0.0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.TRP,
        style: {
          numFmt: '0.0',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });
  const Contribution_Sheet1 = chooseDATASETCONTR().map((item, i) => {
    return [
      {
        value: String(item.Publisher),
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Reach / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const Donut_Sheet1 = choosedataSetDonut().map((item, i) => {
    return [
      {
        value: String(item.Publisher),
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Budget,
        style: {
          numFmt: '#,##0 ₽',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const RfN_Sheet1 = choosedataSetRfN().map((item, i) => {
    return [
      {
        value: 'Закрытые часоты',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: 'olv',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[1] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[2] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const RfNPlus_Sheet1 = choosedataSetRfNPlus().map((item, i) => {
    return [
      {
        value: 'Открытые частоты',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: 'olv',
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[1] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[2] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[3] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[4] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[5] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[6] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[7] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[8] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[9] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[10] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[11] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[12] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[13] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[14] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[15] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[16] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[17] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[18] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item[19] / 100,
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const OLV_CBU_CPRP_sheet1 = chooseDATASETOLV_CBU_CPRP().map((item, i) => {
    return [
      {
        value: item.Variable_OLV_CBU_CPRP,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: item.Freq,
        style: {
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[2] !== 'null' ? Object.values(item)[2] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[3] !== 'null' ? Object.values(item)[3] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[4] !== 'null' ? Object.values(item)[4] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[5] !== 'null' ? Object.values(item)[5] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[6] !== 'null' ? Object.values(item)[6] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[7] !== 'null' ? Object.values(item)[7] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[8] !== 'null' ? Object.values(item)[8] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[9] !== 'null' ? Object.values(item)[9] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[10] !== 'null' ? Object.values(item)[10] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[11] !== 'null' ? Object.values(item)[11] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[12] !== 'null' ? Object.values(item)[12] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[13] !== 'null' ? Object.values(item)[13] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[14] !== 'null' ? Object.values(item)[14] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[15] !== 'null' ? Object.values(item)[15] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[16] !== 'null' ? Object.values(item)[16] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[17] !== 'null' ? Object.values(item)[17] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[18] !== 'null' ? Object.values(item)[18] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[19] !== 'null' ? Object.values(item)[19] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[20] !== 'null' ? Object.values(item)[20] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
      {
        value: Object.values(item)[21] !== 'null' ? Object.values(item)[21] / 100 : '',
        style: {
          numFmt: '0.0%',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
        },
      },
    ];
  });

  const sheet1 = [
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'Целевая аудитория',
          width: { wpx: 280 },
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          title: String(TA),
          width: { wpx: 170 },
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            font: { bold: true },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [
        [
          {
            value: 'Гео',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: String(Geo),
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'OLV бюджет',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Budget,
            style: {
              numFmt: '#,##0 ₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Universe, OOO',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Universe,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Share',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Share / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],

        [
          {
            value: 'Наличие TV флайта',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: PresenceOfTvFlight,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],

        [
          {
            value: 'LTV',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Ltv,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],

        [
          {
            value: 'Заданная эффективная частота в инструменте',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: freqAll,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'Площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Бюджет',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Показы',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'CPM',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Таргет (soc dem)',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Таргет (other)',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Период (дни)',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Частота в месяц',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'ЦА %',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'ЦА тыс',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'TRP',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: Publishers_Sheet1,
    },

    {
      xSteps: 0,
      ySteps: -1,
      columns: [],
      data: [
        [
          {
            value: 'Total',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Budget,
            style: {
              numFmt: '#,##0 ₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Impressions,
            style: {
              numFmt: '#,##0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: chooseDataSetTotal_call_result[0].total_CPM,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Tsa_percent,
            style: {
              numFmt: '0.0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_Tsa_thousand,
            style: {
              numFmt: '0.0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: chooseDataSetTotal_call_result[0].total_TRP,
            style: {
              numFmt: '0.0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'графики в цифрах: ',
          width: { wpx: 280 },
          style: { alignment: { wrapText: false, horizontal: 'center', vertical: 'top' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'OLV only 1й - 4й графики из Videotron',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `1. Вклад каждой площадки в совокупный охват на частоте ${freqAll}`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Основная площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },

            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: mainMedia,
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: Contribution_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `2. Распределение бюджета`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Площадка',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Бюджет',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: Donut_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `3. Распределение Reach/Frequency OLV`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: '',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '1',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '2',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '3',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '4',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '5',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '6',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '7',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '8',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '9',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '10',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '11',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '12',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '14',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '14',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '15',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '16',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '17',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '18',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: '19',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: RfN_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: -1,
      columns: [],
      data: RfNPlus_Sheet1,
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: `4. OLV: CBU & CPRP на частоте ${freqAll}`,
          width: { wpx: 280 },
          style: {
            font: { bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Variable_OLV_CBU_CPRP',
          width: { wpx: 280 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Freq',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[2].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[3].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[4].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[5].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[6].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[7].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[8].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[9].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[10].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[11].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[12].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[13].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[14].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[15].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[16].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[17].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[18].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[19].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[20].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[21].toLocaleString()} ₽`,
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: OLV_CBU_CPRP_sheet1,
    },
  ];

  return (
    <div>
      <ExcelFile element={<RedButton title={'Сохранить\n проект xls'} />} filename='save_videotron_olv_charts'>
        <ExcelSheet dataSet={sheet1} name='Сводные данные' />
        <ExcelSheet data={isChartsUpload ? dataSetPublishers_dataFromXL : dataSetPublishers} name='Площадки'>
          <ExcelColumn label='Publisher' value='Publisher' />
          <ExcelColumn label='Budget' value='Budget' />
          <ExcelColumn label='CPM' value='CPM' />
          <ExcelColumn label='Days' value='Days' />
          <ExcelColumn label='FrequencyLimit' value='FrequencyLimit' />
          <ExcelColumn label='DefaultImpressions' value='DefaultImpressions' />
          <ExcelColumn label='Impressions' value='Impressions' />
          <ExcelColumn label='OtherTargeting' value='OtherTargeting' />
          <ExcelColumn label='TRP' value='TRP' />
          <ExcelColumn label='TargetingSocDem' value='TargetingSocDem' />
          <ExcelColumn label='Tsa_percent' value='Tsa_percent' />
          <ExcelColumn label='Tsa_thousand' value='Tsa_thousand' />
          <ExcelColumn label='isChecked' value='isChecked' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASETOLV_CBU_CPRP_dataFromXL : DATASETOLV_CBU_CPRP} name='OLV_CBU_CPRP'>
          <ExcelColumn label='Variable_OLV_CBU_CPRP' value='Variable_OLV_CBU_CPRP' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[2] : Object.keys(DATASETOLV_CBU_CPRP[0])[2]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[2] : Object.keys(DATASETOLV_CBU_CPRP[0])[2]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(DATASETOLV_CBU_CPRP[0])[3]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(DATASETOLV_CBU_CPRP[0])[3]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(DATASETOLV_CBU_CPRP[0])[4]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(DATASETOLV_CBU_CPRP[0])[4]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(DATASETOLV_CBU_CPRP[0])[5]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(DATASETOLV_CBU_CPRP[0])[5]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(DATASETOLV_CBU_CPRP[0])[6]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(DATASETOLV_CBU_CPRP[0])[6]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(DATASETOLV_CBU_CPRP[0])[7]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(DATASETOLV_CBU_CPRP[0])[7]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(DATASETOLV_CBU_CPRP[0])[8]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(DATASETOLV_CBU_CPRP[0])[8]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(DATASETOLV_CBU_CPRP[0])[9]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(DATASETOLV_CBU_CPRP[0])[9]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(DATASETOLV_CBU_CPRP[0])[10]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(DATASETOLV_CBU_CPRP[0])[10]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(DATASETOLV_CBU_CPRP[0])[11]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(DATASETOLV_CBU_CPRP[0])[11]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(DATASETOLV_CBU_CPRP[0])[12]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(DATASETOLV_CBU_CPRP[0])[12]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(DATASETOLV_CBU_CPRP[0])[13]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(DATASETOLV_CBU_CPRP[0])[13]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(DATASETOLV_CBU_CPRP[0])[14]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(DATASETOLV_CBU_CPRP[0])[14]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(DATASETOLV_CBU_CPRP[0])[15]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(DATASETOLV_CBU_CPRP[0])[15]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(DATASETOLV_CBU_CPRP[0])[16]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(DATASETOLV_CBU_CPRP[0])[16]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(DATASETOLV_CBU_CPRP[0])[17]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(DATASETOLV_CBU_CPRP[0])[17]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(DATASETOLV_CBU_CPRP[0])[18]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(DATASETOLV_CBU_CPRP[0])[18]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(DATASETOLV_CBU_CPRP[0])[19]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(DATASETOLV_CBU_CPRP[0])[19]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(DATASETOLV_CBU_CPRP[0])[20]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(DATASETOLV_CBU_CPRP[0])[20]} />
          <ExcelColumn label={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(DATASETOLV_CBU_CPRP[0])[21]} value={isChartsUpload ? Object.keys(DATASETOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(DATASETOLV_CBU_CPRP[0])[21]} />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? DATASETCONTR_dataFromXL : DATASETCONTR} name='Reach_build_OLV'>
          <ExcelColumn label='MainMedia' value='MainMedia' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='PublisherContr' value='Publisher' />
          <ExcelColumn label='Reach' value='Reach' />
        </ExcelSheet>

        <ExcelSheet data={isChartsUpload ? dataSetInput_dataFromXL : dataSetInput} name='Вводные данные' hideElement={true}>
          <ExcelColumn label='agemax' value='agemax' />
          <ExcelColumn label='agemin' value='agemin' />
          <ExcelColumn label='gender' value='gender' />
          <ExcelColumn label='income' value='income' />
          <ExcelColumn label='ltv' value='ltv' />
          <ExcelColumn label='geo' value='geo' />
          <ExcelColumn label='geoPopulation' value='geoPopulation' />
          <ExcelColumn label='presenceOfTvFlight' value='presenceOfTvFlight' />
          <ExcelColumn label='isCBUUpload' value='isCBUUpload' />
          <ExcelColumn label='cpp' value='cpp' />
          <ExcelColumn label='universe' value='universe' />
          <ExcelColumn label='share' value='share' />
          <ExcelColumn label='isOptimization' value='isOptimization' />
          <ExcelColumn label='isRestrictions' value='isRestrictions' />
          <ExcelColumn label='budget' value='budget' />
          <ExcelColumn label='freq' value='freq' />
          <ExcelColumn label='isSupplierBudgetLimits' value='isSupplierBudgetLimits' />
          <ExcelColumn label='impChangesNotPossible' value='impChangesNotPossible' />
          <ExcelColumn label='bgtChangesNotPossible' value='bgtChangesNotPossible' />
          <ExcelColumn label='crmChangesNotPossible' value='crmChangesNotPossible' />
        </ExcelSheet>

        <ExcelSheet data={isChartsUpload ? dataSet_Optimization_data_dataFromXL : dataSet_Optimization_data} name='optimizationData'>
          <ExcelColumn label='Publisher_Opt' value='Publisher_Opt' />
          <ExcelColumn label='CPM_Opt' value='CPM_Opt' />
          <ExcelColumn label='Days_Opt' value='Days_Opt' />
          <ExcelColumn label='FrequencyLimit_Opt' value='FrequencyLimit_Opt' />
          <ExcelColumn label='Impressions_Opt' value='Impressions_Opt' />
          <ExcelColumn label='max_OTS_Opt' value='max_OTS_Opt' />
          <ExcelColumn label='targ_Opt' value='targ_Opt' />
          <ExcelColumn label='budget_Opt' value='budget_Opt' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSet_restrictions_dataFromXL : dataSet_restrictions} name='restrictions'>
          <ExcelColumn label='Publisher_Restr' value='Publisher_Restr' />
          <ExcelColumn label='min' value='min' />
          <ExcelColumn label='max' value='max' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetTotal_dataFromXL : dataSetTotal} name='Total'>
          <ExcelColumn label='total_Budget' value='total_Budget' />
          <ExcelColumn label='total_Impressions' value='total_Impressions' />
          <ExcelColumn label='total_CPM' value='total_CPM' />
          <ExcelColumn label='total_Tsa_thousand' value='total_Tsa_thousand' />
          <ExcelColumn label='total_Tsa_percent' value='total_Tsa_percent' />
          <ExcelColumn label='total_TRP' value='total_TRP' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetDonut_dataFromXL : dataSetDonut} name='Распределение бюджета'>
          <ExcelColumn label='PublisherDonut' value='Publisher' />
          <ExcelColumn label='Budget' value='Budget' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetRfNPlus_dataFromXL : dataSetRfNPlus} name='Распределение охвата(N+)'>
          <ExcelColumn label='OLVReachN+' value='OLVReachN+' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={isChartsUpload ? dataSetRfN_dataFromXL : dataSetRfN} name='Распределение охвата(N)'>
          <ExcelColumn label='OLVReachN' value='OLVReachN' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>

        <ExcelSheet data={isChartsUpload ? dataSet_Input_OLV_Budgets_dataFromXL : dataSet_Input_OLV_Budgets} name='Input_Budgets'>
          <ExcelColumn label='budget_OlvCbuCprpChart' value='budget_OlvCbuCprpChart' />
          <ExcelColumn label='budget_OLV_TvIncreaseAggregateCoverageChart' value='budget_OLV_TvIncreaseAggregateCoverageChart' />
          <ExcelColumn label='budget_TvDistributionChart' value='budget_TvDistributionChart' />
          <ExcelColumn label='budget_TV_TvIncreaseAggregateCoverageChart' value='budget_TV_TvIncreaseAggregateCoverageChart' />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
});
