import React from 'react';
import s from './OlvContributionOfEachMedia.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { round } from '../../../../helpers/chart_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getMaxOfArray } from '../../../../helpers/chart_helpers';
Chart.register(ChartDataLabels);

export const OlvContributionOfEachMedia = React.memo(props => {
  const { olvContributionOfEachMedia_data } = props 
  const contrData = olvContributionOfEachMedia_data.datasets && olvContributionOfEachMedia_data.datasets[0].data;
  const maxValue = getMaxOfArray(contrData);
  const visibleMaxValue = 10;
  const maxValuePr = (maxValue / 100) * visibleMaxValue;

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        position: 'top',
        ticks: {
          callback: function (val, index) {
            return round(val, 1);
          },
        },
      },
      y: {
        ticks: {
          display: true,
          font: {
            size: 11,
          },
          padding: 2,
        },
      },
    },
    plugins: {
      datalabels: {
        display: 'true',
        color: 'white',
        formatter: function (value) {
          if (value > maxValuePr) {
            const changedValue = round(value, 1);
            return changedValue + ` %`;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: '14px',
            },
          },
        },
        anchor: 'centre',
        align: 'centre',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.label + ': ' + round(TooltipItem.parsed.x, 1) + ` %`;
          },
        },
      },
    },
    indexAxis: 'y',
  };
  return (
    <div className={s.wrapper}>
      <Bar data={olvContributionOfEachMedia_data} options={options} width={300} height={170} />
    </div>
  );
});
