import React from 'react';
import s from './Span.module.css';
import { findValueView } from '../../../../helpers/common_helpers';

export const Span = React.memo(props => {
  const { value, variable } = props;
  return (
    <div>
      <span className={s.span}>{findValueView(value, variable)}</span>
    </div>
  );
});
