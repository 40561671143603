import React from 'react';
import s from './Checkbox.module.css';

export const Checkbox = React.memo(props => {
  const { checked, onChangeChecked, onChange, label, id, disabled, ...restProps } = props;

  return (
    <div className={s.wrapper}>
      <input type={'checkbox'} disabled={disabled ? true : false} id={id} onChange={onChangeChecked} checked={checked} className={s.input} {...restProps} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
});
