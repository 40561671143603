import React from 'react';
import { useSelector } from 'react-redux';
import s from './PageHeader.module.css';
import blackStar from '../../../assets/images/image17.png';
import { makeGeoTitle, makeIncomeTitle, makeGenderTitle } from '../../../helpers/common_helpers';

export const PageHeader = React.memo(props => {
  const { pageHeaderTitle, pageHeaderNumber, withRedTitle } = props;
  const gender = useSelector(state => state.input.gender);
  const income = useSelector(state => state.input.income);
  const geo = useSelector(state => state.input.geo);
  const agemin = useSelector(state => state.input.agemin);
  const agemax = useSelector(state => state.input.agemax);
  const ltv = useSelector(state => state.input.ltv);
  const ltvPost = ltv === 1 ? 'LTV' : null;

  return (
    <div className={s.pageBarContent}>
      <div className={s.titleWrapper}>
        <span className={s.pageHeaderNumber}>{pageHeaderNumber}</span>
        {pageHeaderTitle}
        {withRedTitle && (
          <span className={s.redText}>
            {' '}
            {makeGenderTitle(gender)} {agemin}-{agemax} {makeIncomeTitle(income)} {ltvPost} {makeGeoTitle(geo)}
          </span>
        )}
      </div>
      <div className={s.blackStarWrapper}>
        <img src={blackStar} alt='blackStar' style={{ width: '250px', height: '66px', display: 'block' }} />
      </div>
    </div>
  );
});
