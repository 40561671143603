import React from 'react';
import s from './ToggleSwitch.module.css';

export const ToggleSwitch = React.memo(props => {
  const { checked, onChangeChecked, onChange, labelStart, labelEnd, id, ...restProps } = props;

  return (
    <div className={s.toggle_switchWrapper}>
      <div className={checked ? s.label : s.muted}>{labelStart}</div>
      <div className={s.toggle_switch}>
        <input type={'checkbox'} className={s.toggle_switch_checkbox} id={id} onChange={onChangeChecked} checked={checked} {...restProps} />
        <label htmlFor={id} className={s.toggle_switch_label}>
          <span className={checked ? `${s.toggle_switch_inner} ${s.toggle_switch_inner_unchecked}` : s.toggle_switch_inner} />
          <span className={checked ? `${s.toggle_switch_switch} ${s.toggle_switch_switch_unchecked}` : s.toggle_switch_switch} />
        </label>
      </div>
      <div className={checked ? s.muted : s.label}>{labelEnd}</div>
    </div>
  );
});
