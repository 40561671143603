import { API } from '../dal/api';

const SET_STATUS = 'SET_STATUS';
const SET_ERROR = 'SET_ERROR';
const SET_TABBED_CALCULATOR_IS_ACTIVE = 'SET_TABBED_CALCULATOR_IS_ACTIVE';
const SET_TABBED_INSTRUCTION_IS_ACTIVE = 'SET_TABBED_INSTRUCTION_IS_ACTIVE';
const SET_IS_INITIALIZED = 'SET_IS_INITIALIZED';
const SET_CURRENT_PATHNAME = 'SET_CURRENT_PATHNAME ';

const initialState = {
  status: 'success',
  error: null,
  tabbedCalculatorIsActive: true,
  tabbedInstructionIsActive: false,
  isInitialized: true,
  currentPathname: '/',
};

export const app_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS:
      return { ...state, status: action.status };
    case SET_ERROR:
      return { ...state, error: action.error };
    case SET_TABBED_CALCULATOR_IS_ACTIVE:
      return { ...state, tabbedCalculatorIsActive: action.tabbedCalculatorIsActive };
    case SET_TABBED_INSTRUCTION_IS_ACTIVE:
      return { ...state, tabbedInstructionIsActive: action.tabbedInstructionIsActive };
    case SET_IS_INITIALIZED:
      return { ...state, isInitialized: action.isInitialized };
    case SET_CURRENT_PATHNAME:
      return { ...state, currentPathname: action.currentPathname };
    default:
      return state;
  }
};

// action creators
export const setStatus = status => ({ type: SET_STATUS, status });
export const setError = error => ({ type: SET_ERROR, error });
export const setTabbedCalculatorIsActive = tabbedCalculatorIsActive => ({ type: SET_TABBED_CALCULATOR_IS_ACTIVE, tabbedCalculatorIsActive });
export const setTabbedInstructionIsActive = tabbedInstructionIsActive => ({ type: SET_TABBED_INSTRUCTION_IS_ACTIVE, tabbedInstructionIsActive });
export const setInitialized = isInitialized => ({ type: SET_IS_INITIALIZED, isInitialized });
export const setCurrentPathname = currentPathname => ({ type: SET_CURRENT_PATHNAME, currentPathname });

//thunk creators

// export const signIn = (userName, userPassword) => async dispatch => {
//   const currentUser = usersData.find(item => item.userName === userName && item.userPassword === userPassword);
//   currentUser && dispatch(setInitialized(true));

//   localStorage.setItem(
//     'app',
//     JSON.stringify({
//       timestamp: Date.now(),
//     })
//   );
// };

export const getStartIp = history => async dispatch => {
  dispatch(setStatus('loading'));
  const currentUrl = window.location.href;

  try {
    const response = await API.getIp();
    response.data === 'AnonymousUser' && currentUrl.indexOf('http://localhost:3000/') > -1 && history.push('/localLogin');
    response.data === 'AnonymousUser' && currentUrl.indexOf('https://videotron.mediainstinctgroup.ru/') > -1 && history.push('/mainLogin');
    response.data === 'AnonymousUser' && currentUrl.indexOf('https://stage.videotron.mediainstinctgroup.ru/') > -1 && history.push('/stageLogin');
    // response.data.indexOf('172.16') > -1 || response.data.indexOf('10.100') > -1 || response.data.indexOf('192.168') > -1 ? dispatch(setInitialized(true)) : dispatch(setInitialized(false));
  } catch (error) {
    dispatch(setError(error.message));
  }
  dispatch(setStatus('success'));
};
