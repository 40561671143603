import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { StartButton } from '../../common/StartButton/StartButton';
import { round, getFreqNumber } from '../../../../helpers/chart_helpers';
import { makeGeoTitle_From_PostData, makePresenceOfTvFlight_From_PostData, makeLtv_From_PostData } from '../../../../helpers/common_helpers';
import save from '../../../../assets/images/saveNew.png';
import { variablesOLV_CBU_CPRP_Arr, freqStrArr } from '../../../../assets/data/data';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const SaveXLSFlightsOlv_Opt = props => {
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsPostData = useSelector(state => state.flights.flightsPostData);
  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const dataFromXL_Flights = useSelector(state => state.flights_charts.dataFromXL_Flights);
  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);
  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);
  const flightArr = useSelector(state => state.flights.flightArr);
  const sheet1Arr = [];
  const OptimalSplitOLVArr = [];

  const getDataForExportXLSPart1_DataFromXL = () => {
    const dataSetDonut_dataFromXL = dataFromXL_Flights
      .filter(i => i.Media1)
      .map(i => {
        return {
          flight: i.flight,
          name: i.Media1,
          budget: i.Budget,
        };
      });
    const dataSetTA_dataFromXL = dataFromXL_Flights.filter(i => i.agemin);
    const dataSetPublishers_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher);
    const dataSetTotal_dataFromXL = dataFromXL_Flights.filter(i => i.total_Budget);
    const dataSetRfNPlus_dataFromXL = dataFromXL_Flights.filter(i => i['OLVReachN+']);
    const dataSetRfN_dataFromXL = dataFromXL_Flights.filter(i => i['OLVReachN']);
    const dataSet_Optimization_data_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher_Opt);
    const dataSet_restrictions_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher_Restr);
    const dataSet_Input_OLV_Budgets_dataFromXL = dataFromXL_Flights.filter(i => i.budget_OlvCbuCprpChart);
    const dataSetContr_dataFromXL = dataFromXL_Flights
      .filter(i => i.MainMedia)
      .map(i => {
        return {
          Freq: i.Freq,
          MainMedia: i.MainMedia,
          Publisher: i.PublisherContr,
          Reach: i.Reach,
          flight: i.flight,
        };
      });
    const dataSetOLV_CBU_CPRP_dataFromXL = dataFromXL_Flights.filter(i => i.Variable_OLV_CBU_CPRP);
    const dataSet_OPT_INVEST_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher_OPT_INVEST);
    const dataSet_Reach_OPT_INVEST_dataFromXL = dataFromXL_Flights.filter(i => i.Reach_OPT_INVEST);
    const dataSet_OPT_COMPARISON_dataFromXL = dataFromXL_Flights.filter(i => i.Publisher_OPT_COMPARISON);
    const dataSet_Reach_OPT_COMPARISON_dataFromXL = dataFromXL_Flights.filter(i => i.Reach_OPT_COMPARISON);

    return [dataSetTA_dataFromXL, dataSetTotal_dataFromXL, dataSetPublishers_dataFromXL, dataSetDonut_dataFromXL, dataSetRfNPlus_dataFromXL, dataSetRfN_dataFromXL, dataSet_Optimization_data_dataFromXL, dataSet_restrictions_dataFromXL, dataSet_Input_OLV_Budgets_dataFromXL, dataSetContr_dataFromXL, dataSetOLV_CBU_CPRP_dataFromXL, dataSet_OPT_INVEST_dataFromXL, dataSet_Reach_OPT_INVEST_dataFromXL, dataSet_OPT_COMPARISON_dataFromXL, dataSet_Reach_OPT_COMPARISON_dataFromXL];
  };
  const dataSetTA_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[0];
  const dataSetTotal_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[1];
  const dataSetPublishers_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[2];
  const dataSetDonut_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[3];
  const dataSetRfNPlus_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[4];
  const dataSetRfN_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[5];
  const dataSet_Optimization_data_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[6];
  const dataSet_restrictions_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[7];
  const dataSet_Input_OLV_Budgets_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[8];
  const dataSetContr_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[9];
  const dataSetOLV_CBU_CPRP_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[10];
  const dataSet_OPT_INVEST_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[11];
  const dataSet_Reach_OPT_INVEST_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[12];
  const dataSet_OPT_COMPARISON_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[13];
  const dataSet_Reach_OPT_COMPARISON_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[14];

  const getDataForExportXLSPart1_DataFromChartsData = () => {
    const dataSetTA = flightsPostData;
    const dataSetPublishers = [];
    const dataSetDonut = [];
    const dataSetRfNPlus = [];
    const dataSetRfN = [];
    const dataSet_Optimization_data = [];
    const dataSet_restrictions = [];
    const dataSet_Input_OLV_Budgets = [];
    const dataSetContr = [];
    const dataSetOLV_CBU_CPRP = [];
    const dataSetTotal = [];

    const dataSet_OPT_INVEST = [];
    const dataSet_Reach_OPT_INVEST = [];
    const dataSet_OPT_COMPARISON = [];
    const dataSet_Reach_OPT_COMPARISON = [];

    flightsChartsData.map(item => {
      const currentFlightsTableParameters = flightsTableParametersArr.find(fl => fl.flight === item.flight);
      const publishers = currentFlightsTableParameters.publishers;

      const DONUT = publishers.filter(item => item.isChecked === 1);
      const donutMedia = DONUT.map(item => item.Publisher);
      const INCR = item.chartsData.incr;
      const incrPublNameArr = Object.keys(INCR);
      const Item = INCR[Object.keys(INCR)[0]][1];
      const pubNames = Object.keys(Item);
      const DATASETCONTR_ITEM = [];
      freqStrArr.map(item => {
        for (var i = 0; i < incrPublNameArr.length; i++) {
          for (var a = 0; a < pubNames.length; a++) {
            DATASETCONTR_ITEM.push(item + '*' + incrPublNameArr[i] + '/' + pubNames[a]);
          }
        }
        return item;
      });

      DATASETCONTR_ITEM.map(i => {
        const INCR_ITEM = INCR[i.slice(i.lastIndexOf('*') + 1, i.lastIndexOf('/'))][+i.substring(0, 2)];
        dataSetContr.push({
          flight: item.flight,
          MainMedia: i.slice(i.lastIndexOf('*') + 1, i.lastIndexOf('/')),
          Freq: +i.substring(0, 2),
          Publisher: i.slice(i.lastIndexOf('/') + 1),
          Reach: INCR_ITEM[i.slice(i.lastIndexOf('/') + 1)],
        });
        return i;
      });

      const RF = item.chartsData.RF;
      DONUT.map(i => {
        dataSetDonut.push({
          flight: item.flight,
          name: i.Publisher,
          budget: i.Budget,
        });
        return i;
      });

      dataSetRfNPlus.push({
        flight: item.flight,
        'OLVReachN+': 'OLVReachN+',
        1: RF['Total_media_rf_n+'][0],
        2: RF['Total_media_rf_n+'][1],
        3: RF['Total_media_rf_n+'][2],
        4: RF['Total_media_rf_n+'][3],
        5: RF['Total_media_rf_n+'][4],
        6: RF['Total_media_rf_n+'][5],
        7: RF['Total_media_rf_n+'][6],
        8: RF['Total_media_rf_n+'][7],
        9: RF['Total_media_rf_n+'][8],
        10: RF['Total_media_rf_n+'][9],
        11: RF['Total_media_rf_n+'][10],
        12: RF['Total_media_rf_n+'][11],
        13: RF['Total_media_rf_n+'][12],
        14: RF['Total_media_rf_n+'][13],
        15: RF['Total_media_rf_n+'][14],
        16: RF['Total_media_rf_n+'][15],
        17: RF['Total_media_rf_n+'][16],
        18: RF['Total_media_rf_n+'][17],
        19: RF['Total_media_rf_n+'][18],
      });

      dataSetRfN.push({
        flight: item.flight,
        OLVReachN: 'OLVReachN',
        1: RF['Total_media_rf_n'][0],
        2: RF['Total_media_rf_n'][1],
        3: RF['Total_media_rf_n'][2],
        4: RF['Total_media_rf_n'][3],
        5: RF['Total_media_rf_n'][4],
        6: RF['Total_media_rf_n'][5],
        7: RF['Total_media_rf_n'][6],
        8: RF['Total_media_rf_n'][7],
        9: RF['Total_media_rf_n'][8],
        10: RF['Total_media_rf_n'][9],
        11: RF['Total_media_rf_n'][10],
        12: RF['Total_media_rf_n'][11],
        13: RF['Total_media_rf_n'][12],
        14: RF['Total_media_rf_n'][13],
        15: RF['Total_media_rf_n'][14],
        16: RF['Total_media_rf_n'][15],
        17: RF['Total_media_rf_n'][16],
        18: RF['Total_media_rf_n'][17],
        19: RF['Total_media_rf_n'][18],
      });

      const currentBudget_OLV_TvIncreaseAggregateCoverageChart = item.budgets_TvIncreaseAggregateCoverageChart !== null ? item.budgets_TvIncreaseAggregateCoverageChart.olvBudget : null;
      const currentBudget_TV_TvIncreaseAggregateCoverageChart = item.budgets_TvIncreaseAggregateCoverageChart !== null ? item.budgets_TvIncreaseAggregateCoverageChart.tvBudget : null;
      dataSet_Input_OLV_Budgets.push({
        flight: item.flight,
        budget_OlvCbuCprpChart: item.budget_OlvCbuCprpChart,
        budget_OLV_TvIncreaseAggregateCoverageChart: currentBudget_OLV_TvIncreaseAggregateCoverageChart,
        budget_TvDistributionChart: item.budget_TvDistributionChart,
        budget_TV_TvIncreaseAggregateCoverageChart: currentBudget_TV_TvIncreaseAggregateCoverageChart,
      });

      const OLV_CBU_CPRP = item.chartsData.OLV_CBU_CPRP;
      variablesOLV_CBU_CPRP_Arr.map(i => {
        const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP[i.substring(2)][+i.substring(0, 2)];
        dataSetOLV_CBU_CPRP.push({
          flight: item.flight,
          Variable_OLV_CBU_CPRP: i.substring(2),
          Freq: +i.substring(0, 2),
          [Object.keys(OLV_CBU_CPRP_ITEM)[0]]: Object.values(OLV_CBU_CPRP_ITEM)[0],
          [Object.keys(OLV_CBU_CPRP_ITEM)[1]]: Object.values(OLV_CBU_CPRP_ITEM)[1],
          [Object.keys(OLV_CBU_CPRP_ITEM)[2]]: Object.values(OLV_CBU_CPRP_ITEM)[2],
          [Object.keys(OLV_CBU_CPRP_ITEM)[3]]: Object.values(OLV_CBU_CPRP_ITEM)[3],
          [Object.keys(OLV_CBU_CPRP_ITEM)[4]]: Object.values(OLV_CBU_CPRP_ITEM)[4],
          [Object.keys(OLV_CBU_CPRP_ITEM)[5]]: Object.values(OLV_CBU_CPRP_ITEM)[5],
          [Object.keys(OLV_CBU_CPRP_ITEM)[6]]: Object.values(OLV_CBU_CPRP_ITEM)[6],
          [Object.keys(OLV_CBU_CPRP_ITEM)[7]]: Object.values(OLV_CBU_CPRP_ITEM)[7],
          [Object.keys(OLV_CBU_CPRP_ITEM)[8]]: Object.values(OLV_CBU_CPRP_ITEM)[8],
          [Object.keys(OLV_CBU_CPRP_ITEM)[9]]: Object.values(OLV_CBU_CPRP_ITEM)[9],
          [Object.keys(OLV_CBU_CPRP_ITEM)[10]]: Object.values(OLV_CBU_CPRP_ITEM)[10],
          [Object.keys(OLV_CBU_CPRP_ITEM)[11]]: Object.values(OLV_CBU_CPRP_ITEM)[11],
          [Object.keys(OLV_CBU_CPRP_ITEM)[12]]: Object.values(OLV_CBU_CPRP_ITEM)[12],
          [Object.keys(OLV_CBU_CPRP_ITEM)[13]]: Object.values(OLV_CBU_CPRP_ITEM)[13],
          [Object.keys(OLV_CBU_CPRP_ITEM)[14]]: Object.values(OLV_CBU_CPRP_ITEM)[14],
          [Object.keys(OLV_CBU_CPRP_ITEM)[15]]: Object.values(OLV_CBU_CPRP_ITEM)[15],
          [Object.keys(OLV_CBU_CPRP_ITEM)[16]]: Object.values(OLV_CBU_CPRP_ITEM)[16],
          [Object.keys(OLV_CBU_CPRP_ITEM)[17]]: Object.values(OLV_CBU_CPRP_ITEM)[17],
          [Object.keys(OLV_CBU_CPRP_ITEM)[18]]: Object.values(OLV_CBU_CPRP_ITEM)[18],
          [Object.keys(OLV_CBU_CPRP_ITEM)[19]]: Object.values(OLV_CBU_CPRP_ITEM)[19],
        });
        return i;
      });
      const currentFlightsOptimizationArr = flightsOptimizationArr.find(fl => fl.flight === item.flight);
      const isOptimization = currentFlightsOptimizationArr.isOptimization;

      const take_OPT_DATA = () => {
        const OPT_INVEST = item.chartsData.bud_parts;
        const OPT_INVEST_labelsArr = [];
        OPT_INVEST.map(item => {
          const label = Object.values(item[1]).reduce(function (a, b) {
            return +a + +b;
          });
          OPT_INVEST_labelsArr.push(label);
          return item;
        });
        donutMedia.map(i => {
          dataSet_OPT_INVEST.push({
            flight: item.flight,
            Publisher_OPT_INVEST: i,
            1: OPT_INVEST[0][1][i],
            2: OPT_INVEST[1][1][i],
            3: OPT_INVEST[2][1][i],
            4: OPT_INVEST[3][1][i],
            5: OPT_INVEST[4][1][i],
            6: OPT_INVEST[5][1][i],
            7: OPT_INVEST[6][1][i],
            8: OPT_INVEST[7][1][i],
            9: OPT_INVEST[8][1][i],
          });
          return i;
        });
        dataSet_Reach_OPT_INVEST.push({
          flight: item.flight,
          Reach_OPT_INVEST: 'Reach_OPT_INVEST',
          1: OPT_INVEST[0][0],
          2: OPT_INVEST[1][0],
          3: OPT_INVEST[2][0],
          4: OPT_INVEST[3][0],
          5: OPT_INVEST[4][0],
          6: OPT_INVEST[5][0],
          7: OPT_INVEST[6][0],
          8: OPT_INVEST[7][0],
          9: OPT_INVEST[8][0],
        });

        const OPT_COMPARISON = item.chartsData.split;
        const OPT_COMPARISON_ReachArr = OPT_COMPARISON.map(i => i[0]);
        donutMedia.map(i => {
          dataSet_OPT_COMPARISON.push({
            flight: item.flight,
            Publisher_OPT_COMPARISON: i,
            1: OPT_COMPARISON[0][1][i],
            2: OPT_COMPARISON[1][1][i],
            3: OPT_COMPARISON[2][1][i],
            4: OPT_COMPARISON[3][1][i],
            5: OPT_COMPARISON[4][1][i],
            6: OPT_COMPARISON[5][1][i],
            7: OPT_COMPARISON[6][1][i],
            8: OPT_COMPARISON[7][1][i],
            9: OPT_COMPARISON[8][1][i],
          });
          return i;
        });
        dataSet_Reach_OPT_COMPARISON.push({
          flight: item.flight,
          Reach_OPT_COMPARISON: 'Reach_OPT_COMPARISON',
          1: OPT_COMPARISON_ReachArr[0],
          2: OPT_COMPARISON_ReachArr[1],
          3: OPT_COMPARISON_ReachArr[2],
          4: OPT_COMPARISON_ReachArr[3],
          5: OPT_COMPARISON_ReachArr[4],
          6: OPT_COMPARISON_ReachArr[5],
          7: OPT_COMPARISON_ReachArr[6],
          8: OPT_COMPARISON_ReachArr[7],
          9: OPT_COMPARISON_ReachArr[8],
        });
      };
      isOptimization > -1 && take_OPT_DATA();
    });

    flightsTableParametersArr.map(item => {
      item.publishers.map(i => {
        dataSetPublishers.push({
          flight: item.flight,
          Budget: i.Budget,
          CPM: i.CPM,
          Days: i.Days,
          DefaultImpressions: i.DefaultImpressions,
          FrequencyLimit: i.FrequencyLimit,
          Impressions: i.Impressions,
          OtherTargeting: i.OtherTargeting,
          Publisher: i.Publisher,
          TRP: i.TRP,
          TargetingSocDem: i.TargetingSocDem,
          Tsa_percent: i.Tsa_percent,
          Tsa_thousand: i.Tsa_thousand,
          isChecked: i.isChecked,
        });
        return i;
      });
      item.total.map(i => {
        dataSetTotal.push({
          flight: item.flight,
          total_Budget: i.total_Budget,
          total_Impressions: i.total_Impressions,
          total_CPM: i.total_CPM,
          total_Tsa_thousand: i.total_Tsa_thousand,
          total_Tsa_percent: i.total_Tsa_percent,
          total_TRP: i.total_TRP,
        });
        return i;
      });
      return {
        item,
      };
    });
    flightsOptimizationArr.map(i => {
      Object.keys(i.optimizationData).map(it => {
        dataSet_Optimization_data.push({
          flight: i.flight,
          Publisher_Opt: it,
          CPM_Opt: i.optimizationData[it].CPM,
          Days_Opt: i.optimizationData[it].Days,
          FrequencyLimit_Opt: i.optimizationData[it].FrequencyLimit,
          Impressions_Opt: i.optimizationData[it].Impressions,
          max_OTS_Opt: i.optimizationData[it].max_OTS,
          targ_Opt: i.optimizationData[it].targ,
          budget_Opt: i.optimizationData[it].budget ? i.optimizationData[it].budget : null,
        });
        return i;
      });
      i.restrictions.map(i => {
        dataSet_restrictions.push({
          Publisher_Restr: i.Publisher,
          min: i.min === null ? 0 : i.min,
          max: i.max === null ? 0 : i.max,
        });
        return i;
      });
      return i;
    });
    return [dataSetTA, dataSetTotal, dataSetPublishers, dataSetDonut, dataSetRfNPlus, dataSetRfN, dataSet_Optimization_data, dataSet_restrictions, dataSet_Input_OLV_Budgets, dataSetContr, dataSetOLV_CBU_CPRP, dataSet_OPT_INVEST, dataSet_Reach_OPT_INVEST, dataSet_OPT_COMPARISON, dataSet_Reach_OPT_COMPARISON];
  };

  const dataSetTA = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[0];
  const dataSetTotal = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[1];
  const dataSetPublishers = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[2];
  const dataSetDonut = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[3];
  const dataSetRfNPlus = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[4];
  const dataSetRfN = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[5];
  const dataSet_Optimization_data = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[6];
  const dataSet_restrictions = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[7];
  const dataSet_Input_OLV_Budgets = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[8];
  const dataSetContr = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[9];
  const dataSetOLV_CBU_CPRP = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[10];
  const dataSet_OPT_INVEST = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[11];
  const dataSet_Reach_OPT_INVEST = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[12];
  const dataSet_OPT_COMPARISON = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[13];
  const dataSet_Reach_OPT_COMPARISON = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[14];

  for (const flight of flightsChartsData) {
    const FLIGHT = flight.flight;
    const TA = flightsUpload ? `${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).gender} ${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).agemin}-${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).agemax} ${dataSetTA_dataFromXL.find(i => i.flight === flight.flight).income}` : `${dataSetTA.find(i => i.flight === flight.flight).gender} ${dataSetTA.find(i => i.flight === flight.flight).agemin}-${dataSetTA.find(i => i.flight === flight.flight).agemax} ${dataSetTA.find(i => i.flight === flight.flight).income}`;
    const Geo = flightsUpload ? makeGeoTitle_From_PostData(dataSetTA_dataFromXL.find(i => i.flight === flight.flight).geo) : makeGeoTitle_From_PostData(dataSetTA.find(i => i.flight === flight.flight).geo);
    const Universe = flightsUpload ? dataSetTA_dataFromXL.find(i => i.flight === flight.flight).universe : dataSetTA.find(i => i.flight === flight.flight).universe;
    const Share = flightsUpload ? dataSetTA_dataFromXL.find(i => i.flight === flight.flight).share : dataSetTA.find(i => i.flight === flight.flight).share;
    const PresenceOfTvFlight = flightsUpload ? makePresenceOfTvFlight_From_PostData(dataSetTA_dataFromXL.find(i => i.flight === flight.flight).presenceOfTvFlight) : makePresenceOfTvFlight_From_PostData(dataSetTA.find(i => i.flight === flight.flight).presenceOfTvFlight);
    const Ltv = flightsUpload ? makeLtv_From_PostData(dataSetTA_dataFromXL.find(i => i.flight === flight.flight).ltv) : makeLtv_From_PostData(dataSetTA.find(i => i.flight === flight.flight).ltv);
    const freqAll = flight.freqAll;
    const freqPost = freqAll && getFreqNumber(freqAll);
    const mainMedia = flight.mainMedia;
    const freq = flightsOptimizationArr.find(i => i.flight === flight.flight) && flightsOptimizationArr.find(i => i.flight === flight.flight).freq;
    const FLIGHT_NUMBER = flightArr.find(i => i.id === flight.flight) && flightArr.find(i => i.id === flight.flight).flight;
    const isOptimization = flightsOptimizationArr.find(i => i.flight === flight.flight) && flightsOptimizationArr.find(i => i.flight === flight.flight).isOptimization;

    const chooseDataSetTotal = () => {
      if (flightsUpload) {
        return dataSetTotal_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetTotal.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDataSetPublishers = () => {
      if (flightsUpload) {
        return dataSetPublishers_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetPublishers.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDATASETCONTR = () => {
      if (flightsUpload) {
        return dataSetContr_dataFromXL.filter(i => i.flight === flight.flight).filter(i => i.MainMedia === mainMedia && i.Freq === freqPost);
      } else {
        return dataSetContr.filter(i => i.flight === flight.flight).filter(i => i.MainMedia === mainMedia && i.Freq === freqPost);
      }
    };
    const choosedataSetDonut = () => {
      if (flightsUpload) {
        return dataSetDonut_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetDonut.filter(i => i.flight === flight.flight);
      }
    };
    const choosedataSetRfN = () => {
      if (flightsUpload) {
        return dataSetRfN_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetRfN.filter(i => i.flight === flight.flight);
      }
    };
    const choosedataSetRfNPlus = () => {
      if (flightsUpload) {
        return dataSetRfNPlus_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSetRfNPlus.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDATASETOLV_CBU_CPRP = () => {
      if (flightsUpload) {
        return dataSetOLV_CBU_CPRP_dataFromXL.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      } else {
        return dataSetOLV_CBU_CPRP.filter(i => i.flight === flight.flight).filter(item => item.Freq === freqPost);
      }
    };
    const choosedataSet_OPT_INVEST = () => {
      if (flightsUpload) {
        return dataSet_OPT_INVEST_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSet_OPT_INVEST.filter(i => i.flight === flight.flight);
      }
    };
    const choosedataSet_Reach_OPT_INVEST = () => {
      if (flightsUpload) {
        return dataSet_Reach_OPT_INVEST_dataFromXL.filter(i => i.flight === flight.flight);
      } else {
        return dataSet_Reach_OPT_INVEST.filter(i => i.flight === flight.flight);
      }
    };
    const chooseDataSetTotal_call_result = chooseDataSetTotal();
    const chooseDATASETOLV_CBU_CPRP_call_result = chooseDATASETOLV_CBU_CPRP();
    const choosedataSet_Reach_OPT_INVEST_call_result = choosedataSet_Reach_OPT_INVEST();
    const choosedataSet_OPT_INVEST_call_result = choosedataSet_OPT_INVEST();

    const lebel1_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[1])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel2_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[2])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel3_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[3])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel4_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[4])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel5_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[5])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel6_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[6])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel7_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[7])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel8_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[8])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const lebel9_OPT_INVEST =
      isOptimization > -1
        ? choosedataSet_OPT_INVEST_call_result
            .map(item => item[9])
            .reduce(function (a, b) {
              return +a + +b;
            })
        : null;
    const Opt1_OptimalSplitOLV = choosedataSet_OPT_INVEST().map((item, i) => {
      return [
        {
          value: String(item.Publisher_OPT_INVEST),
        },

        {
          value: item[1],
        },
        {
          value: item[2],
        },
        {
          value: item[3],
        },
        {
          value: item[4],
        },
        {
          value: item[5],
        },
        {
          value: item[6],
        },

        {
          value: item[7],
        },
        {
          value: item[8],
        },
        {
          value: item[9],
        },
      ];
    });
    const Publishers_Sheet1 = chooseDataSetPublishers().map((item, i) => {
      return [
        {
          value: String(item.Publisher),
          style: {
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: item.Budget,
          style: {
            numFmt: '#,##0 ₽',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Impressions,
          style: {
            numFmt: '#,##0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.CPM,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.TargetingSocDem === 1 ? 'Да' : 'Нет',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.OtherTargeting === 1 ? 'Да' : 'Нет',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Days,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: item.FrequencyLimit,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Tsa_percent / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Tsa_thousand,
          style: {
            numFmt: '0.0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.TRP,
          style: {
            numFmt: '0.0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const Contribution_Sheet1 = chooseDATASETCONTR().map((item, i) => {
      return [
        {
          value: String(item.Publisher),
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Reach / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const Donut_Sheet1 = choosedataSetDonut().map((item, i) => {
      return [
        {
          value: String(item.name),
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.budget,
          style: {
            numFmt: '#,##0 ₽',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const RfN_Sheet1 = choosedataSetRfN().map((item, i) => {
      return [
        {
          value: 'Закрытые часоты',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: 'olv',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[1] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[2] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[3] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const RfNPlus_Sheet1 = choosedataSetRfNPlus().map((item, i) => {
      return [
        {
          value: 'Открытые частоты',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: 'olv',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[1] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[2] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[3] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[4] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[5] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[6] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[7] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[8] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[9] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[10] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[11] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[12] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[13] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[14] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[15] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[16] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[17] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[18] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item[19] / 100,
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });
    const OLV_CBU_CPRP_sheet1 = chooseDATASETOLV_CBU_CPRP().map((item, i) => {
      return [
        {
          value: item.Variable_OLV_CBU_CPRP,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: item.Freq,
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[3] !== 'null' ? Object.values(item)[3] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[4] !== 'null' ? Object.values(item)[4] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[5] !== 'null' ? Object.values(item)[5] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[6] !== 'null' ? Object.values(item)[6] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[7] !== 'null' ? Object.values(item)[7] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[8] !== 'null' ? Object.values(item)[8] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[9] !== 'null' ? Object.values(item)[9] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[10] !== 'null' ? Object.values(item)[10] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[11] !== 'null' ? Object.values(item)[11] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[12] !== 'null' ? Object.values(item)[12] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[13] !== 'null' ? Object.values(item)[13] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[14] !== 'null' ? Object.values(item)[14] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[15] !== 'null' ? Object.values(item)[15] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[16] !== 'null' ? Object.values(item)[16] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[17] !== 'null' ? Object.values(item)[17] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[18] !== 'null' ? Object.values(item)[18] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[19] !== 'null' ? Object.values(item)[19] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[20] !== 'null' ? Object.values(item)[20] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[21] !== 'null' ? Object.values(item)[21] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: Object.values(item)[22] !== 'null' ? Object.values(item)[22] / 100 : '',
          style: {
            numFmt: '0.0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ];
    });

    sheet1Arr.push([
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: `Флайт ${FLIGHT_NUMBER}`,
            width: { wpx: 280 },
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `Целевая аудитория (флайт ${FLIGHT})`,
            width: { wpx: 280 },
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            title: String(TA),
            width: { wpx: 170 },
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: [
          [
            {
              value: 'Гео',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: String(Geo),
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          [
            {
              value: 'OLV бюджет',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Budget,
              style: {
                numFmt: '#,##0 ₽',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          [
            {
              value: 'Universe, OOO',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: Universe,
              style: {
                numFmt: '0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          [
            {
              value: 'Share',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: Share / 100,
              style: {
                numFmt: '0%',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],

          [
            {
              value: 'Наличие TV флайта',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: PresenceOfTvFlight,
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],

          [
            {
              value: 'LTV',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: Ltv,
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],

          [
            {
              value: 'Заданная эффективная частота в инструменте',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: freqAll,
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
        ],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: 'Площадка',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Бюджет',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Показы',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'CPM',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Таргет (soc dem)',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Таргет (other)',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Период (дни)',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Частота в месяц',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'ЦА %',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'ЦА тыс',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'TRP',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: Publishers_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: -1,
        columns: [],
        data: [
          [
            {
              value: 'Total',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Budget,
              style: {
                numFmt: '#,##0 ₽',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Impressions,
              style: {
                numFmt: '#,##0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },

            {
              value: chooseDataSetTotal_call_result[0].total_CPM,
              style: {
                numFmt: '0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: '',
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Tsa_percent,
              style: {
                numFmt: '0.0%',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_Tsa_thousand,
              style: {
                numFmt: '0.0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              value: chooseDataSetTotal_call_result[0].total_TRP,
              style: {
                numFmt: '0.0',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
        ],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: 'графики в цифрах: ',
            width: { wpx: 280 },
            style: { alignment: { wrapText: false, horizontal: 'center', vertical: 'top' } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: 'OLV only 1й - 4й графики из Videotron',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `1. Вклад каждой площадки в совокупный охват на частоте ${freqAll}`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Основное медиа',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },

              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: mainMedia,
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Площадка',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Охват',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: Contribution_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `2. Распределение бюджета`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Площадка',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Бюджет',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: Donut_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `3. Распределение Reach/Frequency OLV`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: '',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '1',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '2',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '3',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '4',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '5',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '6',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '7',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '8',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '9',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '10',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '11',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '12',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '14',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '14',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '15',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '16',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '17',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '18',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: '19',
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: RfN_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: -1,
        columns: [],
        data: RfNPlus_Sheet1,
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          {
            title: `4. OLV: CBU & CPRP на частоте ${freqAll}`,
            width: { wpx: 280 },
            style: {
              font: { bold: true },
            },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 0,
        columns: [
          {
            title: 'Variable_OLV_CBU_CPRP',
            width: { wpx: 280 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: 'Freq',
            width: { wpx: 170 },
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[3].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[4].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[5].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[6].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[7].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[8].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[9].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[10].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[11].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[12].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[13].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[14].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[15].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[16].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[17].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[18].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[19].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[20].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[21].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            title: chooseDATASETOLV_CBU_CPRP_call_result[0] && `${+Object.keys(chooseDATASETOLV_CBU_CPRP_call_result[0])[22].toLocaleString()} ₽`,
            style: {
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        data: OLV_CBU_CPRP_sheet1,
      },
    ]);
    isOptimization > -1 &&
      OptimalSplitOLVArr.push([
        {
          xSteps: 0,
          ySteps: 0,
          columns: [
            {
              title: `Флайт ${FLIGHT_NUMBER}`,
              width: { wpx: 280 },
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
              },
            },
          ],
          data: [],
        },
        {
          xSteps: 0,
          ySteps: 0,
          columns: [
            {
              title: `Optimization ${freq}`,
              width: { wpx: 170 },
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel1_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel2_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel3_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel4_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel5_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel6_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel7_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel8_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
            {
              title: String(round(lebel9_OPT_INVEST)),
              style: {
                font: { bold: false },
              },
            },
          ],
          data: Opt1_OptimalSplitOLV,
        },
        {
          xSteps: 0,
          ySteps: -1,
          columns: [],
          data: [
            [
              {
                value: `Reach ${freq}`,
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][1] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][2] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][3] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][4] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][5] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][6] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][7] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][8] / 100,
                style: {
                  numFmt: '0%',
                },
              },
              {
                value: choosedataSet_Reach_OPT_INVEST_call_result[0][9] / 100,
                style: {
                  numFmt: '0%',
                },
              },
            ],
          ],
        },
      ]);
  }

  return (
    <div>
      <ExcelFile
        element={
          <StartButton view={'red'}>
            <img src={save} alt='save' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

            <div>
              <div>Сохранить </div>
              <div> проект </div>
            </div>
          </StartButton>
        }
        filename='save_videotron_flights'
      >
        {sheet1Arr.map((data, index) => {
          return <ExcelSheet key={index} dataSet={data} name={'Сводные данные ' + `(${data[0].columns[0].title})`} />;
        })}
        <ExcelSheet data={flightsUpload ? dataSetPublishers_dataFromXL : dataSetPublishers} name='Площадки'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher' value='Publisher' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='Budget' value='Budget' />
          <ExcelColumn label='CPM' value='CPM' />
          <ExcelColumn label='Days' value='Days' />
          <ExcelColumn label='DefaultImpressions' value='DefaultImpressions' />
          <ExcelColumn label='FrequencyLimit' value='FrequencyLimit' />
          <ExcelColumn label='Impressions' value='Impressions' />
          <ExcelColumn label='OtherTargeting' value='OtherTargeting' />
          <ExcelColumn label='TRP' value='TRP' />
          <ExcelColumn label='TargetingSocDem' value='TargetingSocDem' />
          <ExcelColumn label='Tsa_percent' value='Tsa_percent' />
          <ExcelColumn label='Tsa_thousand' value='Tsa_thousand' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetOLV_CBU_CPRP_dataFromXL : dataSetOLV_CBU_CPRP} name='OLV_CBU_CPRP'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Variable_OLV_CBU_CPRP' value='Variable_OLV_CBU_CPRP' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(dataSetOLV_CBU_CPRP[0])[3]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[3] : Object.keys(dataSetOLV_CBU_CPRP[0])[3]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(dataSetOLV_CBU_CPRP[0])[4]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[4] : Object.keys(dataSetOLV_CBU_CPRP[0])[4]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(dataSetOLV_CBU_CPRP[0])[5]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[5] : Object.keys(dataSetOLV_CBU_CPRP[0])[5]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(dataSetOLV_CBU_CPRP[0])[6]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[6] : Object.keys(dataSetOLV_CBU_CPRP[0])[6]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(dataSetOLV_CBU_CPRP[0])[7]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[7] : Object.keys(dataSetOLV_CBU_CPRP[0])[7]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(dataSetOLV_CBU_CPRP[0])[8]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[8] : Object.keys(dataSetOLV_CBU_CPRP[0])[8]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(dataSetOLV_CBU_CPRP[0])[9]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[9] : Object.keys(dataSetOLV_CBU_CPRP[0])[9]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(dataSetOLV_CBU_CPRP[0])[10]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[10] : Object.keys(dataSetOLV_CBU_CPRP[0])[10]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(dataSetOLV_CBU_CPRP[0])[11]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[11] : Object.keys(dataSetOLV_CBU_CPRP[0])[11]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(dataSetOLV_CBU_CPRP[0])[12]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[12] : Object.keys(dataSetOLV_CBU_CPRP[0])[12]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(dataSetOLV_CBU_CPRP[0])[13]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[13] : Object.keys(dataSetOLV_CBU_CPRP[0])[13]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(dataSetOLV_CBU_CPRP[0])[14]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[14] : Object.keys(dataSetOLV_CBU_CPRP[0])[14]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(dataSetOLV_CBU_CPRP[0])[15]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[15] : Object.keys(dataSetOLV_CBU_CPRP[0])[15]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(dataSetOLV_CBU_CPRP[0])[16]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[16] : Object.keys(dataSetOLV_CBU_CPRP[0])[16]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(dataSetOLV_CBU_CPRP[0])[17]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[17] : Object.keys(dataSetOLV_CBU_CPRP[0])[17]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(dataSetOLV_CBU_CPRP[0])[18]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[18] : Object.keys(dataSetOLV_CBU_CPRP[0])[18]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(dataSetOLV_CBU_CPRP[0])[19]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[19] : Object.keys(dataSetOLV_CBU_CPRP[0])[19]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(dataSetOLV_CBU_CPRP[0])[20]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[20] : Object.keys(dataSetOLV_CBU_CPRP[0])[20]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(dataSetOLV_CBU_CPRP[0])[21]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[21] : Object.keys(dataSetOLV_CBU_CPRP[0])[21]} />
          <ExcelColumn label={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[22] : Object.keys(dataSetOLV_CBU_CPRP[0])[22]} value={flightsUpload ? Object.keys(dataSetOLV_CBU_CPRP_dataFromXL[0])[22] : Object.keys(dataSetOLV_CBU_CPRP[0])[22]} />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetContr_dataFromXL : dataSetContr} name='Reach_build_OLV'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='MainMedia' value='MainMedia' />
          <ExcelColumn label='Freq' value='Freq' />
          <ExcelColumn label='PublisherContr' value='Publisher' />
          <ExcelColumn label='Reach' value='Reach' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetTA_dataFromXL : dataSetTA} name='Вводные данные'>
          <ExcelColumn label='flightNumber' value='flightNumber' />
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='gender' value='gender' />
          <ExcelColumn label='agemin' value='agemin' />
          <ExcelColumn label='agemax' value='agemax' />
          <ExcelColumn label='income' value='income' />
          <ExcelColumn label='geo' value='geo' />
          <ExcelColumn label='currentFrequency' value='currentFrequency' />
          <ExcelColumn label='period' value='period' />
          <ExcelColumn label='startDate' value='startDate' />
          <ExcelColumn label='finishDate' value='finishDate' />
          <ExcelColumn label='geoPopulation' value='geoPopulation' />
          <ExcelColumn label='ltv' value='ltv' />
          <ExcelColumn label='presenceOfTvFlight' value='presenceOfTvFlight' />
          <ExcelColumn label='isCBUUpload' value='isCBUUpload' />
          <ExcelColumn label='isOptimization' value='isOptimization' />
          <ExcelColumn label='isRestrictions' value='isRestrictions' />
          <ExcelColumn label='isSupplierBudgetLimits' value='isSupplierBudgetLimits' />
          <ExcelColumn label='freq' value='freq' />
          <ExcelColumn label='flightCPP' value='flightCPP' />
          <ExcelColumn label='flightBudgetTV' value='flightBudgetTV' />
          <ExcelColumn label='budget' value='budget' />
          <ExcelColumn label='share' value='share' />
          <ExcelColumn label='universe' value='universe' />
          <ExcelColumn label='impChangesNotPossible' value='impChangesNotPossible' />
          <ExcelColumn label='crmChangesNotPossible' value='crmChangesNotPossible' />
          <ExcelColumn label='bgtChangesNotPossible' value='bgtChangesNotPossible' />
        </ExcelSheet>
        {OptimalSplitOLVArr.map((data, index) => {
          return <ExcelSheet key={index} dataSet={data} name={'Optimal_split_OLV ' + `(${data[0].columns[0].title})`} />;
        })}
        <ExcelSheet data={flightsUpload ? dataSet_Optimization_data_dataFromXL : dataSet_Optimization_data} name='optimizationData'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher_Opt' value='Publisher_Opt' />
          <ExcelColumn label='CPM_Opt' value='CPM_Opt' />
          <ExcelColumn label='Days_Opt' value='Days_Opt' />
          <ExcelColumn label='FrequencyLimit_Opt' value='FrequencyLimit_Opt' />
          <ExcelColumn label='Impressions_Opt' value='Impressions_Opt' />
          <ExcelColumn label='max_OTS_Opt' value='max_OTS_Opt' />
          <ExcelColumn label='targ_Opt' value='targ_Opt' />
          <ExcelColumn label='budget_Opt' value='budget_Opt' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_restrictions_dataFromXL : dataSet_restrictions} name='restrictions'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher_Restr' value='Publisher_Restr' />
          <ExcelColumn label='min' value='min' />
          <ExcelColumn label='max' value='max' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetTotal_dataFromXL : dataSetTotal} name='Total'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='total_Budget' value='total_Budget' />
          <ExcelColumn label='total_Impressions' value='total_Impressions' />
          <ExcelColumn label='total_CPM' value='total_CPM' />
          <ExcelColumn label='total_Tsa_thousand' value='total_Tsa_thousand' />
          <ExcelColumn label='total_Tsa_percent' value='total_Tsa_percent' />
          <ExcelColumn label='total_TRP' value='total_TRP' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetDonut_dataFromXL : dataSetDonut} name='Распределение бюджета'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Media1' value='name' />
          <ExcelColumn label='Budget' value='budget' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetRfNPlus_dataFromXL : dataSetRfNPlus} name='Распределение охвата(N+)'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='OLVReachN+' value='OLVReachN+' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetRfN_dataFromXL : dataSetRfN} name='Распределение охвата(N)'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='OLVReachN' value='OLVReachN' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
          <ExcelColumn label='10' value='10' />
          <ExcelColumn label='11' value='11' />
          <ExcelColumn label='12' value='12' />
          <ExcelColumn label='13' value='13' />
          <ExcelColumn label='14' value='14' />
          <ExcelColumn label='15' value='15' />
          <ExcelColumn label='16' value='16' />
          <ExcelColumn label='17' value='17' />
          <ExcelColumn label='18' value='18' />
          <ExcelColumn label='19' value='19' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_OPT_INVEST_dataFromXL : dataSet_OPT_INVEST} name='Opt_влияние прироста'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher_OPT_INVEST' value='Publisher_OPT_INVEST' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_Reach_OPT_INVEST_dataFromXL : dataSet_Reach_OPT_INVEST} name='Reach_OPT_INVEST'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Reach_OPT_INVEST' value='Reach_OPT_INVEST' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_OPT_COMPARISON_dataFromXL : dataSet_OPT_COMPARISON} name='Opt_split'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Publisher_OPT_COMPARISON' value='Publisher_OPT_COMPARISON' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_Reach_OPT_COMPARISON_dataFromXL : dataSet_Reach_OPT_COMPARISON} name='Reach_Opt_split'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Reach_OPT_COMPARISON' value='Reach_OPT_COMPARISON' />
          <ExcelColumn label='1' value='1' />
          <ExcelColumn label='2' value='2' />
          <ExcelColumn label='3' value='3' />
          <ExcelColumn label='4' value='4' />
          <ExcelColumn label='5' value='5' />
          <ExcelColumn label='6' value='6' />
          <ExcelColumn label='7' value='7' />
          <ExcelColumn label='8' value='8' />
          <ExcelColumn label='9' value='9' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSet_Input_OLV_Budgets_dataFromXL : dataSet_Input_OLV_Budgets} name='Input_Budgets'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='budget_OlvCbuCprpChart' value='budget_OlvCbuCprpChart' />
          <ExcelColumn label='budget_OLV_TvIncreaseAggregateCoverageChart' value='budget_OLV_TvIncreaseAggregateCoverageChart' />
          <ExcelColumn label='budget_TvDistributionChart' value='budget_TvDistributionChart' />
          <ExcelColumn label='budget_TV_TvIncreaseAggregateCoverageChart' value='budget_TV_TvIncreaseAggregateCoverageChart' />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};
