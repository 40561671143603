import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../common/Select/Select';
import { setFlightsFrequencyArr, setFlightsSomeInputDataChanged } from '../../../../bll/flights_reducer';

export const FlightsFrequency = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const currentFrequency = flightsFrequencyArr.find(item => item.flight === flight);

  const onChangeFlightsFrequency = (selectItem, flight) => {
    const findFlight = flightsFrequencyArr.find(item => item.flight === flight);
    if (findFlight) {
      const updatedStateFindFlight = flightsFrequencyArr.map(item => {
        const newItem = { ...item };
        newItem.frequency = selectItem;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsFrequencyArr(updatedStateFindFlight));
      dispatch(setFlightsSomeInputDataChanged(true))
    } else {
      const newFrequency = { flight: flight, frequency: selectItem };
      const updatedStateDontFindFlight = [...flightsFrequencyArr, newFrequency];
      dispatch(setFlightsFrequencyArr(updatedStateDontFindFlight));
      dispatch(setFlightsSomeInputDataChanged(true))
    }
  };

  return <Select isDisabled={isDisabled} name={flight} value={currentFrequency.frequency} onChange={onChangeFlightsFrequency} items={['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+']} location={'severalFlights'} />;
});
