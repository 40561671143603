import React from 'react';
import s from './OptimizationButton.module.css';

export const OptimizationButton = React.memo(props => {
  const { onClick, title } = props;
  return (
    <div>
      <button className={s.optButton} onClick={onClick}>
        {title}
      </button>
    </div>
  );
});
